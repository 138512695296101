import {
  LINKS_ADD_FAIL,
  LINKS_ADD_REQUEST,
  LINKS_ADD_RESET,
  LINKS_ADD_SUCCESS,
  LINKS_DELETE_FAIL,
  LINKS_DELETE_REQUEST,
  LINKS_DELETE_RESET,
  LINKS_DELETE_SUCCESS,
  LINKS_GET_ALL_FAIL,
  LINKS_GET_ALL_REQUEST,
  LINKS_GET_ALL_RESET,
  LINKS_GET_ALL_SUCCESS,
  LINKS_UPDATE_FAIL,
  LINKS_UPDATE_REQUEST,
  LINKS_UPDATE_RESET,
  LINKS_UPDATE_SUCCESS,
} from '../constants/usefulLinksConstants'

export const addLinkReducer = (state = {}, action) => {
  switch (action.type) {
    case LINKS_ADD_REQUEST:
      return { loading: true }
    case LINKS_ADD_SUCCESS:
      return { loading: false, link: action.payload }
    case LINKS_ADD_FAIL:
      return { loading: false, error: action.payload }
    case LINKS_ADD_RESET:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updateLinkReducer = (state = {}, action) => {
  switch (action.type) {
    case LINKS_UPDATE_REQUEST:
      return { loading: true }
    case LINKS_UPDATE_SUCCESS:
      return { loading: false, link: action.payload }
    case LINKS_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case LINKS_UPDATE_RESET:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const deleteLinkReducer = (state = {}, action) => {
  switch (action.type) {
    case LINKS_DELETE_REQUEST:
      return { loading: true }
    case LINKS_DELETE_SUCCESS:
      return { loading: false, link: action.payload }
    case LINKS_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case LINKS_DELETE_RESET:
      return {}
    default:
      return state
  }
}

export const getLinksListReducer = (state = { links: [] }, action) => {
  switch (action.type) {
    case LINKS_GET_ALL_REQUEST:
      return { loading: true, links: state.partners }
    case LINKS_GET_ALL_SUCCESS:
      return { loading: false, links: action.payload }
    case LINKS_GET_ALL_FAIL:
      return { loading: false, error: action.payload }
    case LINKS_GET_ALL_RESET:
      return { links: [] }
    default:
      return state
  }
}
