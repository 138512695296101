import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSavedSearchActions } from "../../actions/dashboardActions";
import Pagination from "../../components/utils/Pagination";
import { useTranslation } from "react-i18next";

const DashboardSaveSearch = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const getSavedSearch = useSelector((state) => state.getSavedSearch);
  const { data } = getSavedSearch;

  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 8;
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentSavedSearch = data?.slice(firstPostIndex, lastPostIndex);

  useEffect(() => {
    dispatch(getSavedSearchActions());
  }, [dispatch]);
  return (
    <>
      <main id="content" className="bg-gray-01">
        <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
          <div className="d-flex flex-wrap flex-md-nowrap mb-6">
            <div className="mr-0 mr-md-auto">
              <h2 className="mb-0 text-heading fs-22 lh-15">
                {t("saved search")}
                <span className="badge badge-white badge-pill text-primary fs-18 font-weight-bold ml-2">
                  {data && data.length}
                </span>
              </h2>
              <p> {t("Access and view all your customer Search")}</p>
            </div>
            <form className="form">
              <div className="input-group input-group-lg bg-white border">
                <div className="input-group-prepend">
                  <button className="btn pr-0 shadow-none" type="button">
                    <i className="far fa-search"></i>
                  </button>
                </div>
                <input
                  type="text"
                  className="form-control bg-transparent border-0 shadow-none text-body"
                  placeholder="Search listing"
                  name="search"
                />
              </div>
            </form>
          </div>
          <div className="table-responsive">
            <table className="table table-hover bg-white border rounded-lg">
              <thead className="thead-sm thead-black">
                <tr>
                  <th scope="col" className="border-top-0 px-6 pt-6 pb-3">
                    {t("Search")}
                  </th>

                  <th scope="col" className="border-top-0 pt-6 pb-3">
                    {t("Date Created")}
                  </th>
                  <th scope="col" className="border-top-0 pt-6 pb-3">
                    {t("Action")}
                  </th>
                </tr>
              </thead>
              {currentSavedSearch &&
                currentSavedSearch.map((save) => (
                  <tbody>
                    <tr className="shadow-hover-xs-2 bg-hover-white">
                      <td className="align-middle p-6">
                        <a
                          href="single-property-1.html"
                          className="text-heading fs-16 font-weight-500 lh-1625 hover-primary"
                        >
                          {save.search}
                        </a>
                      </td>

                      <td className="align-middle">
                        {save.createdAt
                          .slice(0, 10)
                          .split("-")
                          .reverse()
                          .join("-")}
                      </td>

                      <td className="align-middle text-end">
                        <a
                          href="#"
                          className="d-inline-block fs-18 text-muted hover-primary mr-5"
                        >
                          <i className="fal fa-pencil-alt"></i>
                        </a>
                        <a
                          href="#"
                          className="d-inline-block fs-18 text-muted hover-primary"
                        >
                          <i className="fal fa-trash-alt"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                ))}
            </table>
          </div>
          <nav className="mt-6">
            <Pagination
              totalPosts={data?.length}
              postsPerPage={postsPerPage}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          </nav>
        </div>
      </main>
    </>
  );
};

export default DashboardSaveSearch;
