import React, { useEffect, useState } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { json, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  getlistSettingsAction,
  updateSettingsAction,
} from "../../actions/settingsActions";
import { alertToast } from "../../components/utils/alertHandler";
import { SETTINGS_UPDATE_RESET } from "../../constants/settingsConstants";
import { Loader } from "../../components/utils/loader";

const DashboardSetting = ({ isEdit }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [settingData, setSettingData] = useState({
    items: [],
    logo: null,
    carousel_image: null,
  });

  const setkeyValue = (key, value) => {
    let settlingList = settingData.items;
    settlingList = settlingList.filter((setting) => setting.key != key);
    settlingList.push({ key, value });
    setSettingData({ ...settingData, items: settlingList });
  };

  const updateSettings = useSelector((state) => state.updateSettings);
  const { setting, loading } = updateSettings;

  const getSettingsList = useSelector((state) => state.getSettingsList);
  const { settings } = getSettingsList;

  useEffect(() => {
    if (setting) {
      alertToast("success", "setting updated !");
      dispatch({
        type: SETTINGS_UPDATE_RESET,
      });
    }
  }, [dispatch, setting]);

  useEffect(() => {
    if (settings) {
      setSettingData({ ...settingData, items: settings });
    }
  }, [settings]);

  useEffect(() => {
    dispatch(getlistSettingsAction());
  }, [dispatch]);

  const submitHnadler = () => {
    let formData = new FormData();
    formData.append(
      "items",
      JSON.stringify(
        settingData.items.filter(
          (item) => item.key != "logo" && item.key != "carousel_image"
        )
      )
    );
    formData.append("logo", settingData.logo);
    formData.append("carousel_image", settingData.carousel_image);
    dispatch(updateSettingsAction(formData));
  };
  return (
    <>
      <>
        <Helmet>
          <script src="/assets/js/theme.js"></script>
        </Helmet>
        <main id="content" className="bg-gray-01">
          <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10 my-profile">
            <div className="mb-6">
              <h2 className="mb-0 text-heading fs-22 lh-15">
                {t("Edit Setting")}
              </h2>
            </div>
            <div className="collapse-tabs ">
              <div className="shadow-none p-0">
                <form>
                  <div>
                    <div
                      className="tab-pane tab-pane-parent fade show active px-0"
                      id="description"
                      role="tabpanel"
                      aria-labelledby="description-tab"
                    >
                      <div className="card bg-transparent border-0">
                        <div
                          id="description-collapse"
                          className="collapse show collapsible"
                          aria-labelledby="heading-description"
                          data-parent="#collapse-tabs-accordion"
                        >
                          <div className="card-body py-4 py-md-0 px-0">
                            <div className="card mb-6">
                              <div className="card-body p-6">
                                <h3 className="  card-title mb-0 text-heading fs-22 lh-15">
                                  {t("Global Settings")}
                                </h3>
                                <div className="form-group mt-5">
                                  <label
                                    htmlFor="admin_email"
                                    className="text-heading"
                                  >
                                    Email
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      " form-control form-control-lg border-0"
                                    }
                                    id="admin_email"
                                    name="admin_email"
                                    value={
                                      settingData.items.find(
                                        (setting) =>
                                          setting.key == "admin_email"
                                      )?.value
                                    }
                                    onChange={(e) => {
                                      setkeyValue(
                                        "admin_email",
                                        e.target.value
                                      );
                                    }}
                                  />
                                </div>
                                <div className="form-group mt-5">
                                  <label
                                    htmlFor="admin_address"
                                    className="text-heading"
                                  >
                                    Address
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      " form-control form-control-lg border-0"
                                    }
                                    id="admin_address"
                                    name="admin_address"
                                    value={
                                      settingData.items.find(
                                        (setting) =>
                                          setting.key == "admin_address"
                                      )?.value
                                    }
                                    onChange={(e) => {
                                      setkeyValue(
                                        "admin_address",
                                        e.target.value
                                      );
                                    }}
                                  />
                                </div>
                                <div className="form-group mt-5">
                                  <label
                                    htmlFor="admin_phone"
                                    className="text-heading"
                                  >
                                    Phone
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      " form-control form-control-lg border-0"
                                    }
                                    id="admin_phone"
                                    name="admin_phone"
                                    value={
                                      settingData.items.find(
                                        (setting) =>
                                          setting.key == "admin_phone"
                                      )?.value
                                    }
                                    onChange={(e) => {
                                      setkeyValue(
                                        "admin_phone",
                                        e.target.value
                                      );
                                    }}
                                  />
                                </div>

                                <div className="form-group mt-5">
                                  <label
                                    htmlFor="logo"
                                    className="text-heading"
                                  >
                                    Logo
                                  </label>
                                  <input
                                    type="file"
                                    className=" form-control form-control-lg border-0"
                                    id="logo"
                                    accept="image/*"
                                    name="logo"
                                    onChange={(e) => {
                                      setSettingData({
                                        ...settingData,
                                        logo: e.target.files[0],
                                      });
                                    }}
                                  />
                                </div>

                                <div className="form-group mt-5">
                                  <label
                                    htmlFor="carousel_image"
                                    className="text-heading"
                                  >
                                    Image carousel
                                  </label>
                                  <input
                                    type="file"
                                    accept="image/*"
                                    className=" form-control form-control-lg border-0"
                                    id="carousel_image"
                                    name="carousel_image"
                                    onChange={(e) => {
                                      setSettingData({
                                        ...settingData,
                                        carousel_image: e.target.files[0],
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card-body py-4 py-md-0 px-0">
                            <div className="card mb-6">
                              <div className="card-body p-6">
                                <h3 className="  card-title mb-0 text-heading fs-22 lh-15">
                                  Section 1
                                  <span className="styleGetInfo">
                                    <i class="fas fa-question"></i>
                                    <img
                                      className="getInfoImg"
                                      src="/assets/images/setting/sec1.png"
                                      alt="sec1 setting"
                                    />
                                  </span>
                                </h3>

                                <div className="form-group mt-5">
                                  <label
                                    htmlFor="sec1_desc"
                                    className="text-heading"
                                  >
                                    Description (EN)
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      " form-control form-control-lg border-0"
                                    }
                                    id="sec1_desc"
                                    name="title"
                                    value={
                                      settingData.items.find(
                                        (setting) => setting.key == "sec1_desc"
                                      )?.value
                                    }
                                    onChange={(e) => {
                                      setkeyValue("sec1_desc", e.target.value);
                                    }}
                                  />
                                </div>

                                <div className="form-group mt-5">
                                  <label
                                    htmlFor="sec1_desc"
                                    className="text-heading"
                                  >
                                    Description (FR)
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      " form-control form-control-lg border-0"
                                    }
                                    id="sec1_desc"
                                    name="title"
                                    value={
                                      settingData.items.find(
                                        (setting) =>
                                          setting.key == "sec1_desc_fr"
                                      )?.value
                                    }
                                    onChange={(e) => {
                                      setkeyValue(
                                        "sec1_desc_fr",
                                        e.target.value
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card-body py-4 py-md-0 px-0">
                            <div className="card mb-6">
                              <div className="card-body p-6">
                                <h3 className="  card-title mb-0 text-heading fs-22 lh-15">
                                  Section 2
                                  <span className="styleGetInfo">
                                    <i class="fas fa-question"></i>
                                    <img
                                      className="getInfoImg"
                                      src="/assets/images/setting/sec_2.png"
                                      alt="sec1 setting"
                                    />
                                  </span>
                                </h3>

                                <div className="form-group mt-5">
                                  <label
                                    htmlFor="sec2_desc"
                                    className="text-heading"
                                  >
                                    Description (EN)
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      " form-control form-control-lg border-0"
                                    }
                                    id="sec2_desc"
                                    name="sec2_desc"
                                    value={
                                      settingData.items.find(
                                        (setting) => setting.key == "sec2_desc"
                                      )?.value
                                    }
                                    onChange={(e) => {
                                      setkeyValue("sec2_desc", e.target.value);
                                    }}
                                  />
                                </div>

                                <div className="form-group mt-5">
                                  <label
                                    htmlFor="sec2_desc"
                                    className="text-heading"
                                  >
                                    Description (FR)
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      " form-control form-control-lg border-0"
                                    }
                                    id="sec2_desc"
                                    name="sec2_desc"
                                    value={
                                      settingData.items.find(
                                        (setting) =>
                                          setting.key == "sec2_desc_fr"
                                      )?.value
                                    }
                                    onChange={(e) => {
                                      setkeyValue(
                                        "sec2_desc_fr",
                                        e.target.value
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card-body py-4 py-md-0 px-0">
                            <div className="card mb-6">
                              <div className="card-body p-6">
                                <h3 className="  card-title mb-0 text-heading fs-22 lh-15">
                                  Section 3
                                  <span className="styleGetInfo">
                                    <i class="fas fa-question"></i>
                                    <img
                                      className="getInfoImg"
                                      src="/assets/images/setting/sec_3.png"
                                      alt="sec1 setting"
                                    />
                                  </span>
                                </h3>

                                <div className="form-group mt-5">
                                  <label
                                    htmlFor="sec3_desc"
                                    className="text-heading"
                                  >
                                    Description (EN)
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      " form-control form-control-lg border-0"
                                    }
                                    id="sec3_desc"
                                    name="sec3_desc"
                                    value={
                                      settingData.items.find(
                                        (setting) => setting.key == "sec3_desc"
                                      )?.value
                                    }
                                    onChange={(e) => {
                                      setkeyValue("sec3_desc", e.target.value);
                                    }}
                                  />
                                </div>

                                <div className="form-group mt-5">
                                  <label
                                    htmlFor="sec3_desc"
                                    className="text-heading"
                                  >
                                    Description (FR)
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      " form-control form-control-lg border-0"
                                    }
                                    id="sec3_desc"
                                    name="sec3_desc"
                                    value={
                                      settingData.items.find(
                                        (setting) =>
                                          setting.key == "sec3_desc_fr"
                                      )?.value
                                    }
                                    onChange={(e) => {
                                      setkeyValue(
                                        "sec3_desc_fr",
                                        e.target.value
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card-body py-4 py-md-0 px-0">
                            <div className="card mb-6">
                              <div className="card-body p-6">
                                <h3 className="  card-title mb-0 text-heading fs-22 lh-15">
                                  Section 4
                                  <span className="styleGetInfo">
                                    <i class="fas fa-question"></i>
                                    <img
                                      className="getInfoImg"
                                      src="/assets/images/setting/sec_4.png"
                                      alt="sec1 setting"
                                    />
                                  </span>
                                </h3>

                                <div className="form-group mt-5">
                                  <label
                                    htmlFor="sec4_desc"
                                    className="text-heading"
                                  >
                                    Description (EN)
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      " form-control form-control-lg border-0"
                                    }
                                    id="sec4_desc"
                                    name="sec4_desc"
                                    value={
                                      settingData.items.find(
                                        (setting) => setting.key == "sec4_desc"
                                      )?.value
                                    }
                                    onChange={(e) => {
                                      setkeyValue("sec4_desc", e.target.value);
                                    }}
                                  />
                                </div>

                                <div className="form-group mt-5">
                                  <label
                                    htmlFor="sec4_desc"
                                    className="text-heading"
                                  >
                                    Description (FR)
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      " form-control form-control-lg border-0"
                                    }
                                    id="sec4_desc_fr"
                                    name="sec4_desc_fr"
                                    value={
                                      settingData.items.find(
                                        (setting) =>
                                          setting.key == "sec4_desc_fr"
                                      )?.value
                                    }
                                    onChange={(e) => {
                                      setkeyValue(
                                        "sec4_desc_fr",
                                        e.target.value
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card-body py-4 py-md-0 px-0">
                            <div className="card mb-6">
                              <div className="card-body p-6">
                                <h3 className="  card-title mb-0 text-heading fs-22 lh-15">
                                  Section 5
                                  <span className="styleGetInfo">
                                    <i class="fas fa-question"></i>
                                    <img
                                      className="getInfoImg"
                                      src="/assets/images/setting/sec_5.png"
                                      alt="sec1 setting"
                                    />
                                  </span>
                                </h3>

                                <div className="form-group mt-5">
                                  <label
                                    htmlFor="title"
                                    className="text-heading"
                                  >
                                    {t("Title")} (EN)
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      " form-control form-control-lg border-0"
                                    }
                                    id="title"
                                    name="title"
                                    value={
                                      settingData.items.find(
                                        (setting) => setting.key == "sec5_title"
                                      )?.value
                                    }
                                    onChange={(e) => {
                                      setkeyValue("sec5_title", e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="form-group mt-5">
                                  <label
                                    htmlFor="title"
                                    className="text-heading"
                                  >
                                    {t("Title")} (FR)
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      " form-control form-control-lg border-0"
                                    }
                                    id="title"
                                    name="title"
                                    value={
                                      settingData.items.find(
                                        (setting) =>
                                          setting.key == "sec5_title_fr"
                                      )?.value
                                    }
                                    onChange={(e) => {
                                      setkeyValue(
                                        "sec5_title_fr",
                                        e.target.value
                                      );
                                    }}
                                  />
                                </div>

                                <div className="row">
                                  <div className=" col-6 form-group mt-5">
                                    <label
                                      htmlFor="sec5_item1_title"
                                      className="text-heading"
                                    >
                                      {t("Item")} 1 {t("Title")} (EN)
                                    </label>
                                    <input
                                      type="text"
                                      className={
                                        " form-control form-control-lg border-0"
                                      }
                                      id="sec5_item1_title"
                                      name="sec5_item1_title"
                                      value={
                                        settingData.items.find(
                                          (setting) =>
                                            setting.key == "sec5_item1_title"
                                        )?.value
                                      }
                                      onChange={(e) => {
                                        setkeyValue(
                                          "sec5_item1_title",
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className=" col-6 form-group mt-5">
                                    <label
                                      htmlFor="sec5_item1_title"
                                      className="text-heading"
                                    >
                                      {t("Item")} 1 Description (EN)
                                    </label>
                                    <input
                                      type="text"
                                      className={
                                        " form-control form-control-lg border-0"
                                      }
                                      id="sec5_item1_title"
                                      name="sec5_item1_title"
                                      value={
                                        settingData.items.find(
                                          (setting) =>
                                            setting.key == "sec5_item1_desc"
                                        )?.value
                                      }
                                      onChange={(e) => {
                                        setkeyValue(
                                          "sec5_item1_desc",
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className=" col-6 form-group mt-5">
                                    <label
                                      htmlFor="sec5_item1_title"
                                      className="text-heading"
                                    >
                                      {t("Item")} 1 {t("Title")} (FR)
                                    </label>
                                    <input
                                      type="text"
                                      className={
                                        " form-control form-control-lg border-0"
                                      }
                                      id="sec5_item1_title"
                                      name="sec5_item1_title"
                                      value={
                                        settingData.items.find(
                                          (setting) =>
                                            setting.key == "sec5_item1_title_fr"
                                        )?.value
                                      }
                                      onChange={(e) => {
                                        setkeyValue(
                                          "sec5_item1_title_fr",
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className=" col-6 form-group mt-5">
                                    <label
                                      htmlFor="sec5_item1_title"
                                      className="text-heading"
                                    >
                                      {t("Item")} 1 Description (FR)
                                    </label>
                                    <input
                                      type="text"
                                      className={
                                        " form-control form-control-lg border-0"
                                      }
                                      id="sec5_item1_title"
                                      name="sec5_item1_title"
                                      value={
                                        settingData.items.find(
                                          (setting) =>
                                            setting.key == "sec5_item1_desc_fr"
                                        )?.value
                                      }
                                      onChange={(e) => {
                                        setkeyValue(
                                          "sec5_item1_desc_fr",
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="row">
                                  <div className=" col-6 form-group mt-5">
                                    <label
                                      htmlFor="sec5_item2_title"
                                      className="text-heading"
                                    >
                                      {t("Item")} 2 {t("Title")} (EN)
                                    </label>
                                    <input
                                      type="text"
                                      className={
                                        " form-control form-control-lg border-0"
                                      }
                                      id="sec5_item2_title"
                                      name="sec5_item2_title"
                                      value={
                                        settingData.items.find(
                                          (setting) =>
                                            setting.key == "sec5_item2_title"
                                        )?.value
                                      }
                                      onChange={(e) => {
                                        setkeyValue(
                                          "sec5_item2_title",
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className=" col-6 form-group mt-5">
                                    <label
                                      htmlFor="sec5_item2_desc"
                                      className="text-heading"
                                    >
                                      {t("Item")} 2 Description (EN)
                                    </label>
                                    <input
                                      type="text"
                                      className={
                                        " form-control form-control-lg border-0"
                                      }
                                      id="sec5_item2_desc"
                                      name="sec5_item2_desc"
                                      value={
                                        settingData.items.find(
                                          (setting) =>
                                            setting.key == "sec5_item2_desc"
                                        )?.value
                                      }
                                      onChange={(e) => {
                                        setkeyValue(
                                          "sec5_item2_desc",
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className=" col-6 form-group mt-5">
                                    <label
                                      htmlFor="sec5_item2_title"
                                      className="text-heading"
                                    >
                                      {t("Item")} 2 {t("Title")} (FR)
                                    </label>
                                    <input
                                      type="text"
                                      className={
                                        " form-control form-control-lg border-0"
                                      }
                                      id="sec5_item2_title"
                                      name="sec5_item2_title"
                                      value={
                                        settingData.items.find(
                                          (setting) =>
                                            setting.key == "sec5_item2_title_fr"
                                        )?.value
                                      }
                                      onChange={(e) => {
                                        setkeyValue(
                                          "sec5_item2_title_fr",
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className=" col-6 form-group mt-5">
                                    <label
                                      htmlFor="sec5_item2_desc"
                                      className="text-heading"
                                    >
                                      {t("Item")} 2 Description (FR)
                                    </label>
                                    <input
                                      type="text"
                                      className={
                                        " form-control form-control-lg border-0"
                                      }
                                      id="sec5_item2_desc"
                                      name="sec5_item2_desc"
                                      value={
                                        settingData.items.find(
                                          (setting) =>
                                            setting.key == "sec5_item2_desc_fr"
                                        )?.value
                                      }
                                      onChange={(e) => {
                                        setkeyValue(
                                          "sec5_item2_desc_fr",
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="row">
                                  <div className=" col-6 form-group mt-5">
                                    <label
                                      htmlFor="sec5_item3_title"
                                      className="text-heading"
                                    >
                                      {t("Item")} 3 {t("Title")} (EN)
                                    </label>
                                    <input
                                      type="text"
                                      className={
                                        " form-control form-control-lg border-0"
                                      }
                                      id="sec5_item3_title"
                                      name="sec5_item3_title"
                                      value={
                                        settingData.items.find(
                                          (setting) =>
                                            setting.key == "sec5_item3_title"
                                        )?.value
                                      }
                                      onChange={(e) => {
                                        setkeyValue(
                                          "sec5_item3_title",
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className=" col-6 form-group mt-5">
                                    <label
                                      htmlFor="sec5_item3_desc"
                                      className="text-heading"
                                    >
                                      {t("Item")} 3 Description (EN)
                                    </label>
                                    <input
                                      type="text"
                                      className={
                                        " form-control form-control-lg border-0"
                                      }
                                      id="sec5_item3_desc"
                                      name="sec5_item3_desc"
                                      value={
                                        settingData.items.find(
                                          (setting) =>
                                            setting.key == "sec5_item3_desc"
                                        )?.value
                                      }
                                      onChange={(e) => {
                                        setkeyValue(
                                          "sec5_item3_desc",
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className=" col-6 form-group mt-5">
                                    <label
                                      htmlFor="sec5_item3_title"
                                      className="text-heading"
                                    >
                                      {t("Item")} 3 {t("Title")} (FR)
                                    </label>
                                    <input
                                      type="text"
                                      className={
                                        " form-control form-control-lg border-0"
                                      }
                                      id="sec5_item3_title"
                                      name="sec5_item3_title"
                                      value={
                                        settingData.items.find(
                                          (setting) =>
                                            setting.key == "sec5_item3_title_fr"
                                        )?.value
                                      }
                                      onChange={(e) => {
                                        setkeyValue(
                                          "sec5_item3_title_fr",
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className=" col-6 form-group mt-5">
                                    <label
                                      htmlFor="sec5_item3_desc"
                                      className="text-heading"
                                    >
                                      {t("Item")} 3 Description (FR)
                                    </label>
                                    <input
                                      type="text"
                                      className={
                                        " form-control form-control-lg border-0"
                                      }
                                      id="sec5_item3_desc"
                                      name="sec5_item3_desc"
                                      value={
                                        settingData.items.find(
                                          (setting) =>
                                            setting.key == "sec5_item3_desc_fr"
                                        )?.value
                                      }
                                      onChange={(e) => {
                                        setkeyValue(
                                          "sec5_item3_desc_fr",
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card-body py-4 py-md-0 px-0">
                            <div className="card mb-6">
                              <div className="card-body p-6">
                                <h3 className="  card-title mb-0 text-heading fs-22 lh-15">
                                  Page {t("SELL")}
                                  <span className="styleGetInfo">
                                    <i class="fas fa-question"></i>
                                    <img
                                      className="getInfoImg"
                                      src="/assets/images/setting/page_sell.png"
                                      alt="sec1 setting"
                                    />
                                  </span>
                                </h3>

                                <div className="row">
                                  <div className=" col-6 form-group mt-5">
                                    <label
                                      htmlFor="sec5_item1_title"
                                      className="text-heading"
                                    >
                                      {t("Item")} 1 {t("Title")}
                                    </label>
                                    <input
                                      type="text"
                                      className={
                                        " form-control form-control-lg border-0"
                                      }
                                      id="sec5_item1_title"
                                      name="sec5_item1_title"
                                      value={t("ON YOUR SIDE")}
                                      disabled
                                    />
                                  </div>
                                  <div className=" col-6 form-group mt-5">
                                    <label
                                      htmlFor="sec5_item1_title"
                                      className="text-heading"
                                    >
                                      {t("Item")} 1 Description (EN)
                                    </label>
                                    <input
                                      type="text"
                                      className={
                                        " form-control form-control-lg border-0"
                                      }
                                      id="sec5_item1_title"
                                      name="sec5_item1_title"
                                      value={
                                        settingData.items.find(
                                          (setting) =>
                                            setting.key ==
                                            "sell_desc_on_your_side"
                                        )?.value
                                      }
                                      onChange={(e) => {
                                        setkeyValue(
                                          "sell_desc_on_your_side",
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className=" col-6 form-group mt-5"></div>
                                  <div className=" col-6 form-group mt-5">
                                    <label
                                      htmlFor="sec5_item1_title"
                                      className="text-heading"
                                    >
                                      {t("Item")} 1 Description (FR)
                                    </label>
                                    <input
                                      type="text"
                                      className={
                                        " form-control form-control-lg border-0"
                                      }
                                      id="sec5_item1_title"
                                      name="sec5_item1_title"
                                      value={
                                        settingData.items.find(
                                          (setting) =>
                                            setting.key ==
                                            "sell_desc_on_your_side_fr"
                                        )?.value
                                      }
                                      onChange={(e) => {
                                        setkeyValue(
                                          "sell_desc_on_your_side_fr",
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="row">
                                  <div className=" col-6 form-group mt-5">
                                    <label
                                      htmlFor="sec5_item2_title"
                                      className="text-heading"
                                    >
                                      {t("Item")} 2 {t("Title")}
                                    </label>
                                    <input
                                      type="text"
                                      className={
                                        " form-control form-control-lg border-0"
                                      }
                                      id="sec5_item2_title"
                                      name="sec5_item2_title"
                                      value={t("ON OUR SIDE")}
                                      disabled
                                    />
                                  </div>
                                  <div className=" col-6 form-group mt-5">
                                    <label
                                      htmlFor="sell_desc_on_our_side"
                                      className="text-heading"
                                    >
                                      {t("Item")} 2 Description (EN)
                                    </label>
                                    <input
                                      type="text"
                                      className={
                                        " form-control form-control-lg border-0"
                                      }
                                      id="sell_desc_on_our_side"
                                      name="sell_desc_on_our_side"
                                      value={
                                        settingData.items.find(
                                          (setting) =>
                                            setting.key ==
                                            "sell_desc_on_our_side"
                                        )?.value
                                      }
                                      onChange={(e) => {
                                        setkeyValue(
                                          "sell_desc_on_our_side",
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className=" col-6 form-group mt-5"></div>
                                  <div className=" col-6 form-group mt-5">
                                    <label
                                      htmlFor="sell_desc_on_our_side"
                                      className="text-heading"
                                    >
                                      {t("Item")} 2 Description (EN)
                                    </label>
                                    <input
                                      type="text"
                                      className={
                                        " form-control form-control-lg border-0"
                                      }
                                      id="sell_desc_on_our_side"
                                      name="sell_desc_on_our_side"
                                      value={
                                        settingData.items.find(
                                          (setting) =>
                                            setting.key ==
                                            "sell_desc_on_our_side_fr"
                                        )?.value
                                      }
                                      onChange={(e) => {
                                        setkeyValue(
                                          "sell_desc_on_our_side_fr",
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="row">
                                  <div className=" col-6 form-group mt-5">
                                    <label
                                      htmlFor="sec5_item3_title"
                                      className="text-heading"
                                    >
                                      {t("Item")} 3 {t("Title")}
                                    </label>
                                    <input
                                      type="text"
                                      className={
                                        " form-control form-control-lg border-0"
                                      }
                                      id="sec5_item3_title"
                                      name="sec5_item3_title"
                                      value={t("THE MARKET")}
                                      disabled
                                    />
                                  </div>
                                  <div className=" col-6 form-group mt-5">
                                    <label
                                      htmlFor="sell_desc_the_market"
                                      className="text-heading"
                                    >
                                      {t("Item")} 3 Description
                                    </label>
                                    <input
                                      type="text"
                                      className={
                                        " form-control form-control-lg border-0"
                                      }
                                      id="sell_desc_the_market"
                                      name="sell_desc_the_market"
                                      value={
                                        settingData.items.find(
                                          (setting) =>
                                            setting.key ==
                                            "sell_desc_the_market"
                                        )?.value
                                      }
                                      onChange={(e) => {
                                        setkeyValue(
                                          "sell_desc_the_market",
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className=" col-6 form-group mt-5"></div>
                                  <div className=" col-6 form-group mt-5">
                                    <label
                                      htmlFor="sell_desc_the_market"
                                      className="text-heading"
                                    >
                                      {t("Item")} 3 Description
                                    </label>
                                    <input
                                      type="text"
                                      className={
                                        " form-control form-control-lg border-0"
                                      }
                                      id="sell_desc_the_market"
                                      name="sell_desc_the_market"
                                      value={
                                        settingData.items.find(
                                          (setting) =>
                                            setting.key ==
                                            "sell_desc_the_market_fr"
                                        )?.value
                                      }
                                      onChange={(e) => {
                                        setkeyValue(
                                          "sell_desc_the_market_fr",
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="text-right">
                            <button
                              type="button"
                              className="btn btn-lg btn-primary"
                              onClick={() => submitHnadler()}
                              disabled={loading}
                            >
                              {loading ? <Loader type="btn" /> : "Submit"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      </>
    </>
  );
};

export default DashboardSetting;
