import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addReservationAction } from "../../actions/listingActions";
import { useParams } from "react-router-dom";
import { alertToast } from "../utils/alertHandler";
import { useTranslation } from "react-i18next";
import { LISTING_ADD_RESERVATION_RESET } from "../../constants/listingConstants";

export default function Reservation() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const dates = () => {
    let listOfDate = [];
    const date = new Date();

    for (let index = 0; index < 7; index++) {
      listOfDate.push({
        Day: date.toDateString().substring(0, 3),
        Dayn: date.getDate(),
        Month: date.toDateString().substring(4, 7),
        Date:
          date.getDate() +
          "/" +
          (date.getMonth() + 1) +
          "/" +
          date.getFullYear(),
      });
      date.setDate(date.getDate() + 1);
    }
    return listOfDate;
  };

  const [date, setDate] = useState(dates()[0].Date);
  const addReservation = useSelector((state) => state.addReservation);

  const { reservation, error } = addReservation;

  useEffect(() => {
    if (reservation) {
      alertToast("success", "reservation added successfully");
      dispatch({
        type: LISTING_ADD_RESERVATION_RESET,
      });
    }
  }, [reservation]);

  const submitHandler = () => {
    dispatch(
      addReservationAction(
        id,
        date,
        document.getElementById("reservationTime").value
      )
    );
  };

  return (
    <aside
      className="col-lg-4 pl-xl-4 primary-sidebar sidebar-sticky"
      id="sidebar"
    >
      <div className="primary-sidebar-inner">
        <div className="card border-0 widget-request-tour">
          <ul className="nav nav-tabs d-flex" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active px-3"
                data-toggle="tab"
                href="#schedule"
                role="tab"
                aria-selected="true"
              >
                {t("Schedule A Tour")}
              </a>
            </li>
          </ul>
          <div className="card-body px-sm-6 shadow-xxs-2 pb-5 pt-0">
            <form>
              <div className="tab-content pt-1 pb-0 px-0 shadow-none">
                <div
                  className="tab-pane fade show active"
                  id="schedule"
                  role="tabpanel"
                >
                  <div
                    className="slick-slider calendar arrow-hide-disable mx-n1"
                    data-slick-options='{"slidesToShow": 5, "autoplay":false,"dots":false}'
                  >
                    {dates().map((element, index) => (
                      <div className="box px-1 py-4">
                        <div
                          className={
                            "card pointer border-0 shadow-xxs-1 " +
                            (index == 0 && "active")
                          }
                          onClick={() => setDate(element.Date)}
                        >
                          <div className="card-body p-1 text-center">
                            <p className="day fs-12 text-muted lh-2 mb-0">
                              {element.Day}
                            </p>
                            <p className="date fs-18 text-heading lh-173 mb-0 font-weight-bold">
                              {element.Dayn}
                            </p>
                            <p className="month fs-13 letter-spacing-087 mb-0">
                              {element.Month}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="form-group mb-2">
                    <div className="input-group input-group-lg bootstrap-timepicker timepicker">
                      <input
                        type="text"
                        id="reservationTime"
                        className="form-control border-0 text-body shadow-none"
                        placeholder="Choose a time"
                      />
                      <div className="input-group-append input-group-addon">
                        <button
                          className="btn bg-input shadow-none fs-18 lh-1"
                          type="button"
                        >
                          <i className="fal fa-angle-down"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={() => submitHandler()}
                    type="button"
                    className="btn btn-primary btn-lg btn-block rounded"
                  >
                    {t("Schedule A Tour")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </aside>
  );
}
