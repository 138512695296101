import {
  COMPARE_ADD_FAIL,
  COMPARE_ADD_REQUEST,
  COMPARE_ADD_SUCCESS,
  COMPARE_DELETE_FAIL,
  COMPARE_DELETE_REQUEST,
  COMPARE_DELETE_SUCCESS,
  COMPARE_GET_ALL_FAIL,
  COMPARE_GET_ALL_REQUEST,
  COMPARE_GET_ALL_SUCCESS,
} from "../constants/compareConstants";

export const addCompareAction = (newListing) => async (dispatch) => {
  try {
    dispatch({ type: COMPARE_ADD_REQUEST });

    let listings = JSON.parse(localStorage.getItem("compare"));

    listings = listings ? listings : [];

    if (
      !listings?.find((listing) => listing._id == newListing._id) &&
      listings.length < 3
    ) {
      listings.push(newListing);
    }

    localStorage.setItem("compare", JSON.stringify(listings));

    dispatch({
      type: COMPARE_ADD_SUCCESS,
    });

    dispatch({
      type: COMPARE_GET_ALL_SUCCESS,
      payload: listings,
    });
  } catch (error) {
    dispatch({
      type: COMPARE_ADD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteCompareAction = (newListing) => async (dispatch) => {
  try {
    dispatch({ type: COMPARE_DELETE_REQUEST });

    let listings = JSON.parse(localStorage.getItem("compare"));

    listings = listings ? listings : [];

    listings = listings.filter((listing) => listing._id != newListing._id);

    localStorage.setItem("compare", JSON.stringify(listings));

    dispatch({
      type: COMPARE_DELETE_SUCCESS,
    });

    dispatch({
      type: COMPARE_GET_ALL_SUCCESS,
      payload: listings,
    });
  } catch (error) {
    dispatch({
      type: COMPARE_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getlistCompareAction = () => async (dispatch) => {
  try {
    dispatch({ type: COMPARE_GET_ALL_REQUEST });

    let listings = JSON.parse(localStorage.getItem("compare"));

    dispatch({
      type: COMPARE_GET_ALL_SUCCESS,
      payload: listings ? listings : [],
    });
  } catch (error) {
    dispatch({
      type: COMPARE_GET_ALL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
