import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { alertToast } from "../utils/alertHandler";
import { addCommentAction } from "../../actions/listingActions";
import { useTranslation } from "react-i18next";
import { LISTING_ADD_COMMENT_RESET } from "../../constants/listingConstants";

export default function ListingComments() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();

  const [comment, setComment] = useState("");
  const [rating, setRating] = useState(0);
  const addComment = useSelector((state) => state.addComment);
  const { comment: message, error } = addComment;
  const getListingByID = useSelector((state) => state.getListingByID);
  const { listing } = getListingByID;

  useEffect(() => {
    if (message) {
      alertToast("success", "comment added successfully");
      dispatch({
        type: LISTING_ADD_COMMENT_RESET,
      });
    }
  }, [message]);

  const submitHandler = () => {
    dispatch(addCommentAction(id, rating, comment));
  };

  return (
    <>
      <section>
        <h4 className="fs-22 text-heading lh-15 mb-5 pt-3">
          {t("Rating & Reviews")}
        </h4>
        <div className="card border-0 mb-4">
          <div className="card-body p-0">
            <div className="row">
              <div className="col-sm-6 mb-6 mb-sm-0">
                <div className="bg-gray-01 rounded-lg pt-2 px-6 pb-6">
                  <h5 className="fs-16 lh-2 text-heading mb-6">
                    {t("Avarage User Rating")}
                  </h5>
                  <p className="fs-40 text-heading font-weight-bold mb-6 lh-1">
                    {listing?.reviews?.length > 0
                      ? listing?.reviews?.reduce(
                          (accumulator, currentValue) => {
                            return accumulator + currentValue.rating;
                          },
                          0
                        ) / listing?.reviews?.length
                      : 0}
                    <span className="fs-18 text-gray-light font-weight-normal">
                      /5
                    </span>
                  </p>
                  <ul className="list-inline">
                    {[5, 4, 3, 2, 1].map((o, i) => (
                      <li
                        className={
                          ((listing?.reviews?.length > 0
                            ? listing?.reviews?.reduce(
                                (accumulator, currentValue) => {
                                  return accumulator + currentValue.rating;
                                },
                                0
                              ) / listing?.reviews?.length
                            : 0) > i
                            ? "bg-warning"
                            : "bg-gray-04") +
                          " list-inline-item text-white w-46px h-46 rounded-lg d-inline-flex align-items-center justify-content-center fs-18 mb-1"
                        }
                      >
                        <i className="fas fa-star"></i>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 pt-3">
                <h5 className="fs-16 lh-2 text-heading mb-5">
                  {t("Rating Breakdown")}
                </h5>
                {[5, 4, 3, 2, 1].map((o, i) => (
                  <div className="d-flex align-items-center mx-n1">
                    <ul className="list-inline d-flex px-1 mb-0">
                      <li
                        className={
                          (5 - i >= 1 ? "text-warning" : "text-border") +
                          " list-inline-item mr-1"
                        }
                      >
                        <i className="fas fa-star"></i>
                      </li>
                      <li
                        className={
                          (5 - i >= 2 ? "text-warning" : "text-border") +
                          " list-inline-item mr-1"
                        }
                      >
                        <i className="fas fa-star"></i>
                      </li>
                      <li
                        className={
                          (5 - i >= 3 ? "text-warning" : "text-border") +
                          " list-inline-item mr-1"
                        }
                      >
                        <i className="fas fa-star"></i>
                      </li>
                      <li
                        className={
                          (5 - i >= 4 ? "text-warning" : "text-border") +
                          " list-inline-item mr-1"
                        }
                      >
                        <i className="fas fa-star"></i>
                      </li>
                      <li
                        className={
                          (5 - i === 5 ? "text-warning" : "text-border") +
                          " list-inline-item mr-1"
                        }
                      >
                        <i className="fas fa-star"></i>
                      </li>
                    </ul>
                    <div className="d-block w-100 px-1">
                      <div className="progress rating-progress">
                        <div
                          className="progress-bar bg-warning"
                          role="progressbar"
                          style={{
                            width:
                              (listing?.reviews?.filter(
                                (review) => review.rating === 5 - i
                              ).length /
                                listing?.reviews?.length) *
                                100 +
                              "%",
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="text-muted px-1">
                      {(listing?.reviews?.filter(
                        (review) => review.rating === 5 - i
                      ).length /
                        listing?.reviews?.length) *
                        100}
                      %
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-5">
        <div className="card border-0 mb-4">
          <div className="card-body p-0">
            <h3 className="fs-16 lh-2 text-heading mb-0 d-inline-block pr-4 border-bottom border-primary">
              {listing?.reviews?.length} {t("Reviews")}
            </h3>

            {listing?.reviews?.map((review) => (
              <div className="media border-top py-6 d-sm-flex d-block text-sm-left text-center">
                <div className="w-82px h-82 mr-2 bg-gray-01 rounded-circle fs-25 font-weight-500 text-muted d-flex align-items-center justify-content-center text-uppercase mr-sm-8 mb-4 mb-sm-0 mx-auto">
                  {review.user.firstName.substring(0, 1) +
                    review.user.lastName.substring(0, 1)}
                </div>
                <div className="media-body">
                  <div className="row mb-1 align-items-center">
                    <div className="col-sm-6 mb-2 mb-sm-0">
                      <h4 className="mb-0 text-heading fs-14">
                        {review.user.firstName + " " + review.user.lastName}
                      </h4>
                    </div>
                    <div className="col-sm-6">
                      <ul className="list-inline d-flex justify-content-sm-end justify-content-center mb-0">
                        {[0, 0, 0, 0, 0].map((o, i) => (
                          <li className="list-inline-item mr-1">
                            <span
                              className={
                                (review.rating > i
                                  ? "text-warning"
                                  : "text-border") + " fs-12 lh-2"
                              }
                            >
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <p className="mb-3 pr-xl-17">{review.comment}</p>
                  <div className="d-flex justify-content-sm-start justify-content-center">
                    <p className="mb-0 text-muted fs-13 lh-1">
                      02 Dec 2020 at 2:40pm
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section>
        <div className="card border-0">
          <div className="card-body p-0">
            <h3 className="fs-16 lh-2 text-heading mb-4">
              {t("Write A Review")}
            </h3>
            <form>
              <div className="form-group mb-4 d-flex justify-content-start">
                <div className="rate-input">
                  <input
                    onClick={() => setRating(5)}
                    type="radio"
                    id="star5"
                    name="rate"
                    value="5"
                  />
                  <label
                    htmlFor="star5"
                    title="text"
                    className="mb-0 mr-1 lh-1"
                  >
                    <i className="fas fa-star"></i>
                  </label>
                  <input
                    type="radio"
                    onClick={() => setRating(4)}
                    id="star4"
                    name="rate"
                    value="4"
                  />
                  <label
                    htmlFor="star4"
                    title="text"
                    className="mb-0 mr-1 lh-1"
                  >
                    <i className="fas fa-star"></i>
                  </label>
                  <input
                    onClick={() => setRating(3)}
                    type="radio"
                    id="star3"
                    name="rate"
                    value="3"
                  />
                  <label
                    htmlFor="star3"
                    title="text"
                    className="mb-0 mr-1 lh-1"
                  >
                    <i className="fas fa-star"></i>
                  </label>
                  <input
                    onClick={() => setRating(2)}
                    type="radio"
                    id="star2"
                    name="rate"
                    value="2"
                  />
                  <label
                    htmlFor="star2"
                    title="text"
                    className="mb-0 mr-1 lh-1"
                  >
                    <i className="fas fa-star"></i>
                  </label>
                  <input
                    onClick={() => setRating(1)}
                    type="radio"
                    id="star1"
                    name="rate"
                    value="1"
                  />
                  <label
                    htmlFor="star1"
                    title="text"
                    className="mb-0 mr-1 lh-1"
                  >
                    <i className="fas fa-star"></i>
                  </label>
                </div>
              </div>
              <div className="form-group mb-6">
                <textarea
                  onChange={(e) => setComment(e.target.value)}
                  className="form-control form-control-lg border-0"
                  placeholder={t("your Review")}
                  name="message"
                  rows="5"
                ></textarea>
              </div>
              <button
                type="button"
                onClick={() => submitHandler()}
                className="btn btn-lg btn-primary px-10 mb-2"
              >
                {t("Submit")}
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
