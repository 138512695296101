import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import {
  deleteReservationAction,
  getReservationActions,
  reservationHandleAction,
} from '../../actions/dashboardActions'

import { BACKEND_IMAGE } from '../../utils/apiConfig'
import Pagination from '../../components/utils/Pagination'
import { useTranslation } from 'react-i18next'
import { getlistSellsAction } from '../../actions/sellAction'

const DashboardSells = () => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin
  const getSellList = useSelector(state => state.getSellList)
  const { sells } = getSellList

  const [currentPage, setCurrentPage] = useState(1)
  const postsPerPage = 6
  const lastPostIndex = currentPage * postsPerPage
  const firstPostIndex = lastPostIndex - postsPerPage
  const currentSells = sells?.slice(firstPostIndex, lastPostIndex)

  useEffect(() => {
    dispatch(getlistSellsAction())
  }, [dispatch])

  const deleleteHandler = id => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(deleteReservationAction(id))
        Swal.fire('Deleted!', 'Your product has been deleted.', 'success')
      }
    })
  }

  const reservationHandler = id => {
    dispatch(reservationHandleAction(id))
  }

  return (
    <>
      <main id='content' class='bg-gray-01'>
        <div class='px-3 px-lg-6 px-xxl-13 py-5 py-lg-10'>
          <div class='d-flex flex-wrap flex-md-nowrap mb-6'>
            <div class='mr-0 mr-md-auto'>
              <h2 class='mb-0 text-heading fs-22 lh-15'>
                {t('Reservations')}
                <span class='badge badge-white badge-pill text-primary fs-18 font-weight-bold ml-2'>
                  {/* {listings && listings?.listings?.length} */}
                </span>
              </h2>
              <p> {t('Access and manage all your listed Reservation')}</p>
            </div>
            <div className='form-inline justify-content-md-end mx-n2'>
              <div className='p-2'>
                <div className='input-group input-group-lg bg-white border'>
                  <div className='input-group-prepend'>
                    <button className='btn pr-0 shadow-none' type='button'>
                      <i className='far fa-search'></i>
                    </button>
                  </div>
                  <input
                    type='text'
                    className='form-control bg-transparent border-0 shadow-none text-body'
                    placeholder='Search listing'
                    name='search'
                  />
                </div>
              </div>
              <div className='p-2'>
                <div className='input-group input-group-lg bg-white border'>
                  <div className='input-group-prepend'>
                    <span className='input-group-text bg-transparent letter-spacing-093 border-0 pr-0'>
                      <i className='far fa-align-left mr-2'></i> {t('Sort by')}:
                    </span>
                  </div>
                  <select
                    className='form-control bg-transparent pl-0 selectpicker d-flex align-items-center sortby'
                    name='sort-by'
                    data-style='bg-transparent px-1 py-0 lh-1 font-weight-600 text-body'
                    id='status'>
                    <option>Alphabet</option>
                    <option>Price - Low to High</option>
                    <option>Price - High to Low</option>
                    <option>Date - Old to New</option>
                    <option>Date - New to Old</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className='table-responsive'>
            <table className='table table-hover bg-white border rounded-lg'>
              <thead className='thead-sm thead-black'>
                <tr>
                  <th scope='col' className='border-top-0 px-6 pt-5 pb-4'>
                    {t('First Name')}
                  </th>
                  {/* {userInfo && userInfo.isAdmin == true && (
                    <th scope='col' class='border-top-0 px-6 pt-5 pb-4'>
                      {t('Customer Details')}
                    </th>
                  )} */}
                  <th scope='col' className='border-top-0 pt-5 pb-4'>
                    {t('Last Name')}
                  </th>
                  <th scope='col' className='border-top-0 pt-5 pb-4'>
                    {t('email')}
                  </th>
                  <th scope='col' className='border-top-0 pt-5 pb-4'>
                    {t('Phone')}
                  </th>
                  <th scope='col' className='border-top-0 pt-5 pb-4'>
                    {t('Message')}
                  </th>
                </tr>
              </thead>
              {currentSells &&
                currentSells.map(data => (
                  <tbody>
                    <tr className='shadow-hover-xs-2 bg-hover-white'>
                      <td className='align-middle font-weight-bold pt-6 pb-4 px-6'>
                        {data.firstName}
                      </td>

                      <td className='align-middle  font-weight-bold fs-15'>
                        {data.lastName}
                      </td>

                      <td className='align-middle font-weight-bold fs-15'>
                        {data.email}
                      </td>
                      <td className='align-middle font-weight-bold fs-15'>
                        {data.phone}
                      </td>
                      <td className='align-middle font-weight-bold'>
                        {' '}
                        {data.message}
                      </td>
                    </tr>
                  </tbody>
                ))}
            </table>
          </div>
          <nav className='mt-6'>
            <Pagination
              totalPosts={sells?.length}
              postsPerPage={postsPerPage}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          </nav>
        </div>
      </main>
    </>
  )
}

export default DashboardSells
