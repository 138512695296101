import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      'Add PDF': 'Add PDF',
      best_sale: 'Best Properties For Sale',
      best_rent: 'Best Properties For Rent',
      find_neighborhood: 'Find your neighborhood',
      title: 'Find Your Dream Home',
      sub_title: 'LET US GUIDE YOUR HOME',
      for_rent: 'FOR RENT',
      for_sale: 'FOR SALE',
      search: 'Search',
      advanced_search: 'Advanced Search',
      find_something: 'Find something',
      see_all_prep: 'See all properties',
      house: 'House',
      villa: 'Villa',
      office: 'Office',
      apartement: 'Apartement',
      explore_by_type: 'Explore by Property Type',
      'Schedule A Tour': 'Schedule A Tour',
      'Facts and Features': 'Facts and Features',
      'year built': 'year built',
      bedrooms: 'bedrooms',
      bathrooms: 'bathrooms',
      'Additional Details': 'Additional Details',
      Amenities: 'Équipements',
      'Floor Plans': 'Floor Plans',
      'Rating & Reviews': 'Rating & Reviews',
      'Avarage User Rating': 'Avarage User Rating',
      'Rating Breakdown': 'Rating Breakdown',
      Reviews: 'Reviews',
      'Write A Review': 'Write A Review',
      'your Review': 'your Review',
      'Property Attachments': 'Property Attachments',
      'Virtual Tour': 'Virtual Tour',
      'Similar Homes You May Like': 'Similar Homes You May Like',
      Submit: 'Submit',
      Floor: 'Floor',
      Size: 'Size',
      Wishlist: 'Wishlist',
      Compare: 'Compare',
      Price: 'Price',
      'Property status': 'Property status',
      'Garage size': 'Garage size',
      'Property type': 'Property type',
      Rooms: 'Rooms',
      'Add new': 'Add new',
      'My Properties': 'My Properties',
      Reservation: 'Reservation',
      Favorities: 'Favorities',
      'Save Search': 'Save Search',
      'my Profile': 'my Profile',
      'Log Out': 'Log Out',
      dashboard: 'dashboard',
      Main: 'Main',
      'MANAGE LISTING': 'MANAGE LISTING',
      'MANAGE ACCOUNT': 'MANAGE ACCOUNT',
      'Welcome back': 'Welcome back',
      "Optimize performance with your admin dashboard's key stats":
        "Optimize performance with your admin dashboard's key stats",
      'Add New Property': 'Add New Property',
      Properties: 'Properties',
      'Total views': 'Total views',
      'Total Visitor Reviews': 'Total Visitor Reviews',
      'Total Favorites': 'Total Favorites',
      'Property for Sale': 'Property for Sale',
      'Property for Rent': 'Property for Rent',
      'Reservation Sale': 'Reservation Sale',
      'Reservation Rent': 'Reservation Rent',
      'Recent Activities': 'Recent Activities',
      'Easily enhance your property listings by adding comprehensive details such as media, location, and other essential information through our intuitive interface':
        'Easily enhance your property listings by adding comprehensive details such as media, location, and other essential information through our intuitive interface',
      Description: 'Description',
      Médias: 'Médias',
      Location: 'Location',
      Detail: 'Detail',
      'Property Description': 'Property Description',
      'Please provide a description of your property':
        'Please provide a description of your property',
      Title: 'Title',
      '(mandatory)': '(mandatory)',
      'Select Category': 'Select Category',
      'Please provide a Category of your property':
        'Please provide a Category of your property',
      Category: 'Category',
      'Listed in': 'Listed in',
      'Property Status': 'Property Status',
      'Property Price': 'Property Price',
      'Please provide a price of your property':
        'Please provide a price of your property',
      'Price in CA$': 'Price in CA$',
      Land: 'Land',
      Residential: 'Residential',
      'CO Owner Ship Fees': 'CO Owner Ship Fees',
      Building: 'Building',
      'Municipal Taxes': 'Municipal Taxes',
      'school Taxes': 'school Taxes',
      Insurance: 'Insurance',
      'Energy Cost': 'Energy Cost',
      monthly: 'monthly',
      'Next step': 'Next step',
      'Upload photos of your property': 'Upload photos of your property',
      'Property Images': 'Property Images',
      'Drag and drop image or': 'Drag and drop image or',
      'Browse file': 'Browse file',
      'Photos must be JPEG or PNG format and least 1024x768':
        'Photos must be JPEG or PNG format and least 1024x768',
      'images uploaded': 'images uploaded',
      'Reset photo': 'Reset photo',
      'Floor images': 'Floor images',
      'Video Option': 'Video Option',
      'Please provide a video of your property':
        'Please provide a price of your property',
      'Embed Video id': 'Embed Video id',
      'Please provide a Virtual Tour Link of your property':
        'Please provide a Virtual Tour Link of your property',
      'Prev step': 'Prev step',
      'Listing Location': 'Listing Location',
      'Please provide the Location of your property':
        'Please provide the Location of your property',
      Address: 'Address',
      city: 'city',
      Neighborhood: 'Neighborhood',
      Zip: 'Zip',
      Country: 'Country',
      'Place the listing pin on the map': 'Place the listing pin on the map',
      Latitude: 'Latitude',
      Longitude: 'Longitude',
      'Listing Detail': 'Listing Detail',
      'Please provide Details of your property':
        'Please provide a price of your property',
      'in ft': 'in ft',
      'Lot size': 'Lot size',
      'Year built': 'Year built',
      Garages: 'Garages',
      Basement: 'Basement',
      Roofing: 'Roofing',
      'Floors no': 'Floors no',
      'Please provide Amenities of your property':
        'Please provide Amenities of your property',
      'Access and manage all your listed properties in one place':
        'Access and manage all your listed properties in one place',
      'Search listing': 'Search listing',
      'Sort by': 'Sort by',
      'Listing title': 'Listing title',
      'Date Published': 'Date Published',
      Status: 'Status',
      View: 'View',
      Action: 'Action',
      month: 'month',
      Active: 'Active',
      Sold: 'Sold',
      Results: 'Results',
      Reservations: 'Reservations',
      'Access and manage all your listed Reservation':
        'Access and manage all your listed Reservation',
      'Client Details': 'Client Details',
      Date: 'Date',
      Time: 'Time',
      Pending: 'Pending',
      Approuved: 'Approuved',
      Favorites: 'Favorites',
      'Access and view all your customer Favorites':
        'Access and view all your customer Favorites',
      'saved search': 'saved search',
      'Access and view all your customer Search':
        'Access and view all your customer Search',
      Search: 'Search',
      'Date Created': 'Date Created',
      ' Welcome to the Reviews section. Here, you can view all yourcustomer feedback,which will help enhance your products and services based on valuable insights':
        ' Welcome to the Reviews section. Here, you can view all your customer feedback,which will help enhance your products and services based on valuable insights.',
      'My Profile': 'My Profile',
      'Access and manage your profile account':
        'Access and manage your profile account',
      Photo: 'Photo',
      'Upload your profile photo': 'Upload your profile photo',
      'Upload profile image': 'Upload profile image',
      '*minimum 500px x 500px': '*minimum 500px x 500px',
      'Contact information': 'Contact information',
      'Please provide your contact information':
        'Please provide your contact information',
      'First name': 'First name',
      'Last name': 'Last name',
      Phone: 'Phone',
      Email: 'Email',
      'Change password': 'Change password',
      'New Password': 'New Password',
      'Confirm New Password': 'Confirm New Password',
      'Update Profile': 'Update Profile',
      SELL: 'SELL',
      BUY: 'BUY',
      'FIND A PROPERTY': 'FIND A PROPERTY',
      'SIGN IN': 'SIGN IN',
      'Submit property': 'Submit property',
      'Delete Profile': 'Delete Profile',
      'Useful links': 'Useful links',
      'DELETE_MESSAGE':"Are you sure you want to delete this item?",
      'DELETE_TITLE':"Are you sure?",
      'deleted_message':"This item has been deleted.",
      Tools: 'Tools',
      'Entrust us with the sale of your property':
        'Entrust us with the sale of your property',
      'sell page p':
        'Are you thinking of selling your property? It isessential to have all the elements that will allow youto complete your transaction as quickly as possibleand at the price that suits you.Give yourself peace of mind by entrusting yourhome to our team of real estate experts. We will accompany you with professionalism in all the steps surrounding the sale of your property, from the evaluation to the taking of possession by the buyers.',
      'The game plan for selling with Our plateform':
        'The game plan for selling with Our plateform',
      'Documents to download': 'Documents to download',
      'The sale of a property begins with the identification of its market value. To help your project succeed, let our team of real estate experts evaluate its real value and then guide you through all the steps of its sale':
        'The sale of a property begins with the identification of its market value. To help your project succeed, let our team of real estate experts evaluate its real value and then guide you through all the steps of its sale.',
      'Tools and Resources': 'Tools and Resources',
      'Download the tools and resources created by our experts to help you in your real estate process':
        'Download the tools and resources created by our experts to help you in your real estate process',
      'Useful Links': 'Useful Links',
      'Mortgage calculator': 'Mortgage calculator',
      'Mortgage amount': 'Mortgage amount',
      'Length of the mortgage': 'Length of the mortgage',
      'Interest Rate': 'Interest Rate',
      'Payment Period': 'Payment Period',
      Caculate: 'Caculate',
      Payment: 'Payment',
      'Total tax': 'Total tax',
      Total: 'Total',
      'Transfer Tax Calculator': 'Transfer Tax Calculator',
      'Property price': 'Property price',
      'Calculate for Montreal': 'Calculate for Montreal',
      'Calculate for the outside of Montreal':
        'Calculate for the outside of Montreal',
      'Edit Setting': 'Edit Setting',
      'Global Settings': 'Global Settings',
      Logo: 'Logo',
      'Image carousel': 'Image carousel',
      Section: 'Section',
      Item: 'Title',
      'ON YOUR SIDE': 'ON YOUR SIDE',
      'ON OUR SIDE': 'ON OUR SIDE',
      'THE MARKET': 'THE MARKET',
      'Get an estimate for your property': 'Get an estimate for your property',
      Monthely: 'Monthely',
      Yearly: 'Yearly',
      'Popular Searches': 'Popular Searches',
      'Sign Up for Our Plateform': 'Sign Up for Our Plateform',
      Subscribe: 'Subscribe',
      'All Rights Reserved': 'All Rights Reserved',

      'Access and manage all your partners in one place':
        'Access and manage all your partners in one place',
      'List of partners': 'List of partners',
      'Add partner': 'Add partner',
      'PDF List': 'PDF List',
      Partners: 'Partner',
      'Access and manage all your PDF in one place':
        'Access and manage all your PDF in one place',
      'Calculating tools': 'Calculating tools',
      Rent: 'RENT',
    },
  },
  fr: {
    translation: {
      'Add PDF': 'Ajouter PDF',
      best_sale: 'Meilleures propriétés à vendre',
      best_rent: 'Meilleures propriétés à louer',
      find_neighborhood: 'Trouvez votre quartier',
      title: 'Trouvez la maison de vos rêves',
      sub_title: 'LAISSEZ-NOUS GUIDER VOTRE MAISON',
      for_rent: 'À LOUER',
      Rent: ' LOUER',
      for_sale: 'À VENDRE',
      search: 'Rechercher',
      advanced_search: 'Recherche avancée',
      find_something: 'Trouver quelque chose',
      see_all_prep: 'Voir toutes les propriétés',
      house: 'Maison',
      villa: 'Villa',
      office: 'Office',
      apartement: 'Appartements',
      explore_by_type: 'Explorer par type de propriété',
      'Schedule A Tour': 'Planifier une visite',
      'Facts and Features': 'Faits et caractéristiques',
      'year built': 'année de construction',
      bedrooms: 'chambres',
      bathrooms: 'salles de bain',
      Amenities: 'Agréments',
      'Additional Details': 'Détails supplémentaires',
      'Floor Plans': "Plans d'étage",
      'Rating & Reviews': 'Évaluation et avis',
      'Avarage User Rating': 'Note moyenne des utilisateurs',
      'Rating Breakdown': 'Répartition des notes',
      Reviews: 'Commentaires',
      'Write A Review': 'Rédiger un avis',
      'your Review': 'Votre avis',
      'Property Attachments': 'Pièces jointes à la propriété',
      'Virtual Tour': 'Tour virtuel',
      'Similar Homes You May Like':
        'Maisons similaires que vous pourriez aimer',
      Submit: 'Envoyer',
      Floor: 'étage',
      Size: 'Taille',
      Wishlist: 'Liste de souhaits',
      Compare: 'Comparer',
      Price: 'Prix',
      'Property status': 'Statut de la propriété',
      'Garage size': 'Taille du garage',
      'Property type': 'Type de propriété',
      Rooms: 'Chambres',
      'Add new': 'Ajouter nouveau',
      'My Properties': 'Mes propriétés',
      Reservation: 'Réservation',
      Favorities: 'Favorites',
      'Save Search': 'Recherche sauvegardée',
      'my Profile': 'Mon profil',
      'Log Out': 'Déconnexion',
      dashboard: 'Tableau de bord',
      Main: 'Principal',
      'MANAGE LISTING': 'Gérer publications',
      'MANAGE ACCOUNT': 'Gérer compte',
      'Welcome back': 'Bienvenue',
      "Optimize performance with your admin dashboard's key stats":
        'Optimisez les performances avec les statistiques clés de votre tableau de bord administrateur.',
      'Add New Property': 'Ajouter une nouvelle propriété',
      Properties: 'Propriétés',
      'Total views': 'Vues totales',
      'Total Visitor Reviews': 'Avis totaux des visiteurs',
      'Total Favorites': 'Total de favoris',
      'Property for Sale': 'Propriété à vendre',
      'Property for Rent': 'Propriété à louer',
      'Reservation Sale': 'Réservation de Vente',
      'Reservation Rent': 'Réservation de Location',
      'Recent Activities': 'Activités récentes',
      'Easily enhance your property listings by adding comprehensive details such as media, location, and other essential information through our intuitive interface':
        'Ajouter facilement vos annonces immobilières en ajoutant des détails complets tels que médias, localisation et autres informations essentielles grâce à notre interface intuitive ',
      Description: 'Description',
      Médias: 'Médias',
      Location: 'Localisation',
      Detail: 'Détails',
      'Property Description': 'Description de la propriété',
      'Please provide a description of your property':
        'Veuillez fournir une description de votre propriété.',
      Title: 'Titre',
      '(mandatory)': '(Obligatoire)',
      'Select Category': 'Sélectionner une catégorie',
      'Please provide a Category of your property':
        'Veuillez fournir la categorie de votre propriété.',
      Category: 'Catégorie',
      'Listed in': 'Listé dans',
      'Property Status': 'Statut de la propriété',
      'Property Price': 'Prix de la propriété',
      'Please provide a price of your property':
        'Veuillez fournir un prix pour votre propriété.',
      'Price in CA$': 'Prix en dollars canadiens (CA$)',
      Land: 'Terrain',
      Residential: 'Résidentiel',
      'CO Owner Ship Fees': 'Frais de copropriété',
      Building: 'Immeuble',
      'Municipal Taxes': 'Taxes municipales',
      'school Taxes': 'Taxes scolaires',
      Insurance: 'Assurance',
      'Energy Cost': 'Coûts énergétiques',
      monthly: 'Mensuel',
      'Next step': 'Étape suivante',
      'Upload photos of your property':
        'Téléchargez des photos de votre propriété',
      'Property Images': 'Images de la propriété',
      'Drag and drop image or': 'Glissez et déposez une image ou',
      'Browse file': 'Parcourir le fichier',
      'Photos must be JPEG or PNG format and least 1024x768':
        'Les photos doivent être au format JPEG ou PNG et avoir une résolution minimale de 1024x768.',
      'images uploaded': 'Images téléchargées.',
      'Reset photo': 'Réinitialiser la photo',
      'Floor images': 'Images des étages',
      'Video Option': 'Option vidéo',
      'Please provide a video of your property':
        'Veuillez fournir un prix pour votre propriété',
      'Embed Video id': 'Embed Video id',
      'Please provide a Virtual Tour Link of your property':
        "Incorporer l'identifiant de la vidéo",
      'Prev step': 'Étape précédente',
      'Listing Location': 'Emplacement de la propriété',
      'Please provide the Location of your property':
        "Veuillez fournir l'emplacement de votre propriété.",
      Address: 'Adresse',
      city: 'Ville',
      Neighborhood: 'Quartier',
      Zip: 'Code postal',
      Country: 'Pays',
      'Place the listing pin on the map': "Placez l'épingle sur la carte",
      'Listing Detail': "Détail de l'annonce",
      'Please provide Details of your property':
        'Veuillez fournir un prix pour votre propriété.',
      'in ft': 'en pieds',
      'Lot size': 'Taille du terrain',
      'Year built': 'Année de construction',
      Garages: 'Garages',
      Basement: 'Sous-sol',
      Roofing: 'Toiture',
      'Floors no': "Nombre d'étages",
      'Please provide Amenities of your property':
        'Veuillez fournir les équipements de votre propriété.',
      'Access and manage all your listed properties in one place':
        'Accédez et gérez toutes vos propriétés répertoriées en un seul endroit.',
      'Search listing': "Recherche d'annonces",
      'Sort by': 'Trier par',
      'Listing title': "Titre de l'annonce",
      'Date Published': 'Date de publication',
      Status: 'Statut',
      View: 'Afficher',
      Action: 'Actions',
      month: 'mois',
      Active: 'Actif',
      Sold: 'Vendu',
      Results: 'Résultats',
      Reservations: 'Réservations',
      'Access and manage all your listed Reservation':
        'Accédez et gérez toutes vos réservations répertoriées.',
      'Customer Details': 'Détails du client',
      Date: 'Date',
      Time: 'Heure',
      Pending: 'En attente',
      Approuved: 'Approuvé',
      Favorites: 'Favoris',
      'Access and view all your customer Favorites':
        'Accédez et visualisez tous les favoris de vos clients',
      'saved search': 'Recherche sauvegardée',
      'Access and view all your customer Search':
        'Accédez et visualisez toutes les recherches de vos clients.',
      Search: 'Recherche',
      'Date Created': 'Date de création',
      ' Welcome to the Reviews section. Here, you can view all yourcustomer feedback,which will help enhance your products and services based on valuable insights':
        ' Bienvenue dans la section des avis. Ici, vous pouvez consulter tous les retours de vos clients, ce qui vous aidera à améliorer vos produits et services grâce à des informations précieuses.',
      'My Profile': 'Mon profil',
      'Access and manage your profile account':
        'Accédez et gérez votre compte profil.',
      Photo: 'Photo',
      'Upload your profile photo': 'Téléchargez votre photo de profil',
      'Upload profile image': "Téléchargez l'image de profil",
      '*minimum 500px x 500px': '*minimum 500px x 500px (pixels)',
      'Contact information': 'Informations de contact',
      'Please provide your contact information':
        'Veuillez fournir vos informations de contact.',
      'First name': 'Prénom',
      'Last name': 'Nom',
      Phone: 'Numéro de téléphone',
      Email: 'Email',
      'Change password': 'Changer le mot de passe',
      'New Password': 'Nouveau mot de passe',
      'Confirm New Password': 'Confirmer le nouveau mot de passe',
      'Update Profile': 'Mettre à jour le profil',
      SELL: 'VENDRE',
      BUY: 'ACHETER',
      'FIND A PROPERTY': 'Chercher une propriété',
      'SIGN IN': 'Se connecter',
      'Submit property': 'Soumettre une propriété',
      'Delete Profile': 'Supprimer profil',
      'DELETE_MESSAGE':"Est ce que vous-êtes sûr de supprimer sa?",
      'DELETE_TITLE':"Es-tu sûr?",
      'deleted_message':"Cet élément a été supprimé.",
      'Useful links': 'Liens Utiles',
      Tools: 'Outils',
      'Mortgage amount': "Montant de l'hypothèque",
      'Length of the mortgage': 'Durée de l’hypothèque',
      'Transfer Tax Calculator': 'Calculatrice de la taxe de mutation',
      'Property price': "Montant de l'hypothèque",
      'Calculate for Montreal': 'Calculer pour Montréal',
      'Calculate for the outside of Montreal':
        "Calculer pour l'extérieur de Montréal",
      'Entrust us with the sale of your property':
        'Confiez-nous la vente de votre propriété',
      'sell page p':
        "Pensez-vous à vendre votre propriété ? Il est essentiel d'avoir tous les éléments qui vous permettront de mener à bien votre transaction le plus rapidement possible et au prix qui vous convient. Offrez-vous la tranquillité d'esprit en confiant votre maison à notre équipe d'experts en immobilier. Nous vous accompagnerons avec professionnalisme à toutes les étapes entourant la vente de votre propriété, de l'évaluation à la remise des clés aux acheteurs.",
      'The game plan for selling with Our plateform':
        'Le plan de jeu pour vendre avec notre plateforme.',
      'Documents to download': 'Documents à télécharger.',
      'The sale of a property begins with the identification of its market value. To help your project succeed, let our team of real estate experts evaluate its real value and then guide you through all the steps of its sale':
        "La vente d'une propriété commence par l'identification de sa valeur marchande. Pour vous aider à réussir votre projet, laissez notre équipe d'experts en immobilier évaluer sa véritable valeur, puis vous guider à travers toutes les étapes de sa vente.",
      'Tools and Resources': 'Outils et ressources.',
      'Download the tools and resources created by our experts to help you in your real estate process':
        'Téléchargez les outils et ressources créés par nos experts pour vous aider dans votre processus immobilier.',
      'Useful Links': 'Liens utiles.',
      'Interest Rate': "Taux d'intérêt.",
      'Payment Period': 'Période de paiement.',
      Caculate: 'Calculer',
      Payment: 'Paiement',
      'Total tax': 'Taxe totale',
      'Edit Setting': 'Modifier les paramètres',
      'Global Settings': 'Paramètres globaux',
      Logo: 'Logo',
      'Image carousel': 'Image carousel',
      Section: 'Section',
      Item: 'Élément',
      'ON YOUR SIDE': 'DE VOTRE CÔTÉ',
      'ON OUR SIDE': 'DE NOTRE CÔTÉ',
      'THE MARKET': 'LE MARCHÉ',
      'Get an estimate for your property':
        'Obtenir une estimation de votre propriété',
      'Mortgage calculator': "Calculateur d'hypothèque",
      Monthely: 'mensuellement',
      Yearly: 'annuellement',
      'Popular Searches': 'Recherches populaires',
      'Sign Up for Our Plateform': 'Inscrivez-vous à notre plateforme',
      Subscribe: 'Souscrire',
      'All Rights Reserved': 'Tous les droits sont réservés',

      'Access and manage all your partners in one place':
        'Accédez et gérez tous vos partenaires en un seul endroit',
      'List of partners': 'Liste des partenaires',
      'Add partner': 'Ajouter un partenaire',
      Partners: 'Partenaire',
      'PDF List': 'Liste de PDF',
      'Access and manage all your PDF in one place':
        'Accéder et gérer tous vos PDF en un seul endroit',
      'Calculating tools': 'Outils de calcul',
      "Living space area":"Aire habitable"
    },
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'fr', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
