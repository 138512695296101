import {
  AMENITY_ADD_FAIL,
  AMENITY_ADD_REQUEST,
  AMENITY_ADD_RESET,
  AMENITY_ADD_SUCCESS,
  AMENITY_DELETE_FAIL,
  AMENITY_DELETE_REQUEST,
  AMENITY_DELETE_RESET,
  AMENITY_DELETE_SUCCESS,
  AMENITY_GET_ALL_FAIL,
  AMENITY_GET_ALL_REQUEST,
  AMENITY_GET_ALL_RESET,
  AMENITY_GET_ALL_SUCCESS,
} from "../constants/amenityConstants";

export const addAmentiesReducer = (state = {}, action) => {
  switch (action.type) {
    case AMENITY_ADD_REQUEST:
      return { loading: true };
    case AMENITY_ADD_SUCCESS:
      return { loading: false, amenity: action.payload };
    case AMENITY_ADD_FAIL:
      return { loading: false, error: action.payload };
    case AMENITY_ADD_RESET:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteAmenityReducer = (state = {}, action) => {
  switch (action.type) {
    case AMENITY_DELETE_REQUEST:
      return { loading: true };
    case AMENITY_DELETE_SUCCESS:
      return { loading: false, wishlist: action.payload };
    case AMENITY_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case AMENITY_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const getAmenitiesListReducer = (state = { amenities: [] }, action) => {
  switch (action.type) {
    case AMENITY_GET_ALL_REQUEST:
      return { loading: true };
    case AMENITY_GET_ALL_SUCCESS:
      return { loading: false, amenities: action.payload };
    case AMENITY_GET_ALL_FAIL:
      return { loading: false, error: action.payload };
    case AMENITY_GET_ALL_RESET:
      return { amenities: [] };
    default:
      return state;
  }
};
