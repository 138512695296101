import {
  DASHBOARD_DELETE_RESERVATION_FAIL,
  DASHBOARD_DELETE_RESERVATION_REQUEST,
  DASHBOARD_DELETE_RESERVATION_RESET,
  DASHBOARD_DELETE_RESERVATION_SUCCESS,
  DASHBOARD_GET_RESERVATION_FAIL,
  DASHBOARD_GET_RESERVATION_REQUEST,
  DASHBOARD_GET_RESERVATION_RESET,
  DASHBOARD_GET_RESERVATION_SUCCESS,
  DASHBOARD_GET_REVIEWS_FAIL,
  DASHBOARD_GET_REVIEWS_REQUEST,
  DASHBOARD_GET_REVIEWS_RESET,
  DASHBOARD_GET_REVIEWS_SUCCESS,
  DASHBOARD_RESERVATION_HANDLE_FAIL,
  DASHBOARD_RESERVATION_HANDLE_REQUEST,
  DASHBOARD_RESERVATION_HANDLE_RESET,
  DASHBOARD_RESERVATION_HANDLE_SUCCESS,
  DASHBOARD_SAVED_SEARCH_FAIL,
  DASHBOARD_SAVED_SEARCH_REQUEST,
  DASHBOARD_SAVED_SEARCH_RESET,
  DASHBOARD_SAVED_SEARCH_SUCCESS,
} from '../constants/dashboardConstants'

export const getReviewsReducer = (state = {}, action) => {
  switch (action.type) {
    case DASHBOARD_GET_REVIEWS_REQUEST:
      return { loading: true }
    case DASHBOARD_GET_REVIEWS_SUCCESS:
      return { loading: false, data: action.payload }
    case DASHBOARD_GET_REVIEWS_FAIL:
      return { loading: false, error: action.payload }
    case DASHBOARD_GET_REVIEWS_RESET:
      return { allWishlists: [] }
    default:
      return state
  }
}

export const getReservationReducer = (state = {}, action) => {
  switch (action.type) {
    case DASHBOARD_GET_RESERVATION_REQUEST:
      return { loading: true }
    case DASHBOARD_GET_RESERVATION_SUCCESS:
      return { loading: false, data: action.payload }
    case DASHBOARD_GET_RESERVATION_FAIL:
      return { loading: false, error: action.payload }
    case DASHBOARD_GET_RESERVATION_RESET:
      return { state: [] }
    default:
      return state
  }
}

export const deleteReservationReducer = (state = {}, action) => {
  switch (action.type) {
    case DASHBOARD_DELETE_RESERVATION_REQUEST:
      return {
        loading: true,
      }
    case DASHBOARD_DELETE_RESERVATION_SUCCESS:
      return {
        success: true,
        data: action.payload,
      }
    case DASHBOARD_DELETE_RESERVATION_FAIL:
      return {
        error: action.payload,
      }
    case DASHBOARD_DELETE_RESERVATION_RESET:
      return {}
    default:
      return state
  }
}

export const editListingReducer = (state = {}, action) => {
  switch (action.type) {
    case DASHBOARD_RESERVATION_HANDLE_REQUEST:
      return { loading: true }
    case DASHBOARD_RESERVATION_HANDLE_SUCCESS:
      return { loading: false, data: action.payload }
    case DASHBOARD_RESERVATION_HANDLE_FAIL:
      return { loading: false, error: action.payload }
    case DASHBOARD_RESERVATION_HANDLE_RESET:
      return {}
    default:
      return state
  }
}

export const getSavedSearchReducer = (state = {}, action) => {
  switch (action.type) {
    case DASHBOARD_SAVED_SEARCH_REQUEST:
      return { loading: true }
    case DASHBOARD_SAVED_SEARCH_SUCCESS:
      return { loading: false, data: action.payload }
    case DASHBOARD_SAVED_SEARCH_FAIL:
      return { loading: false, error: action.payload }
    case DASHBOARD_SAVED_SEARCH_RESET:
      return { state: [] }
    default:
      return state
  }
}
