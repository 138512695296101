import React, { useState } from 'react'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
export default function CalculeMutation() {
  const { t, i18n } = useTranslation()
  const [calculeData, setCalculeData] = useState({
    total: 0,
    type: 'montreal',
    result: {
      total: 0,
    },
  })

  const submitCalcule = () => {
    let total = 0
    if (calculeData.total < 264001) {
      if (calculeData.total < 52801) total = 0.005 * calculeData.total
      else if (calculeData.total < 264001) total = 0.01 * calculeData.total
    } else if (calculeData.type == 'montreal') {
      if (calculeData.total < 527901) total = 0.015 * calculeData.total
      else if (calculeData.total < 1055801) total = 0.02 * calculeData.total
      else if (calculeData.total < 2041901) total = 0.025 * calculeData.total
      else total = 0.03 * calculeData.total
    } else {
      total = 0.015 * calculeData.total
    }

    setCalculeData({
      ...calculeData,
      result: {
        total: total,
      },
    })
  }
  return (
    <div
      className='modal fade'
      id='calculMutation-modal'
      tabIndex='-1'
      role='dialog'
      aria-labelledby='calculMutation-modal'
      aria-hidden='true'>
      <div
        className='modal-dialog modal-lg modal-dialog-centered '
        role='document'>
        <div className='modal-content'>
          <div class='modal-header'>
            <h5 class='modal-title'> {t('Transfer Tax Calculator')}</h5>
            <button
              type='button'
              class='close'
              data-dismiss='modal'
              aria-label='Close'>
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='modal-body p-4 py-sm-7 px-sm-8'>
            <div className='tab-content shadow-none p-0' id='myTabContent'>
              <div
                className='tab-pane fade show active'
                id='login'
                role='tabpanel'
                aria-labelledby='login-tab'>
                <section class='py-6 border-bottom'>
                  <form>
                    <div class='row'>
                      <div class='col-12 mb-4'>
                        <label> {t('Property price')} :</label>

                        <div class='form-check'>
                          <input
                            class='form-check-input'
                            type='radio'
                            name='type'
                            id='montereal1'
                            onChange={e => {
                              if (e.target.checked)
                                setCalculeData({
                                  ...calculeData,
                                  type: 'montreal',
                                })
                            }}
                            checked={calculeData.type == 'montreal'}
                          />
                          <label class='form-check-label' for='montereal1'>
                            {t('Calculate for Montreal')}
                          </label>
                        </div>
                        <div class='form-check'>
                          <input
                            class='form-check-input'
                            type='radio'
                            name='type'
                            id='autre'
                            onChange={e => {
                              if (e.target.checked)
                                setCalculeData({
                                  ...calculeData,
                                  type: 'autre',
                                })
                            }}
                            checked={calculeData.type == 'autre'}
                          />
                          <label class='form-check-label' for='autre'>
                            {t('Calculate for the outside of Montreal')}
                          </label>
                        </div>
                      </div>
                      <div class='col-12 mb-4'>
                        <label>Prix de la propriété :</label>
                        <div class='position-relative'>
                          <input
                            type='number'
                            class='form-control border-0 pr-3 h-52 pl-7'
                            name='total-amount'
                            value={calculeData.total}
                            onChange={e =>
                              setCalculeData({
                                ...calculeData,
                                total: e.target.value,
                              })
                            }
                          />
                          <span class='position-absolute pl-3 pos-fixed-left-center fs-13 font-weight-600'></span>
                        </div>
                      </div>
                    </div>

                    <div class='row'>
                      <div class='col-lg-6'>
                        <button
                          class='btn btn-primary fs-14 h-52 px-8'
                          type='button'
                          onClick={e => submitCalcule()}>
                          {t('Caculate')}
                        </button>
                      </div>
                      <div class='col-lg-6'>
                        <div class='shadow-xxs-2 pt-1 pb-2 px-6 border-bottom border-primary border-5x rounded-lg'>
                          <dl class='d-flex mb-0 justify-content-between border-top py-2'>
                            <dt class='font-weight-normal'>Total</dt>
                            <dd class='font-weight-500 text-heading mb-0'>
                              ${calculeData.result?.total}
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </form>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
