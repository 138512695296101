import axios from "axios";

import {
  SEARCH_ADD_FAIL,
  SEARCH_ADD_REQUEST,
  SEARCH_ADD_SUCCESS,
} from "../constants/searchConstants";
import { ADD_SEARCH } from "../utils/apiConfig";

export const addSearchAction = (search) => async (dispatch) => {
  try {
    dispatch({ type: SEARCH_ADD_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(ADD_SEARCH, search, config);

    dispatch({
      type: SEARCH_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_ADD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
