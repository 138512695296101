import {
  SEARCH_ADD_FAIL,
  SEARCH_ADD_REQUEST,
  SEARCH_ADD_RESET,
  SEARCH_ADD_SUCCESS,
  HEADER_TYPE
} from "../constants/searchConstants";

export const addSearchReducer = (state = {}, action) => {
  switch (action.type) {
    case SEARCH_ADD_REQUEST:
      return { loading: true };
    case SEARCH_ADD_SUCCESS:
      return { loading: false, search: action.payload };
    case SEARCH_ADD_FAIL:
      return { loading: false, error: action.payload };
    case SEARCH_ADD_RESET:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const headerReducer = (state = {}, action) => {
  switch (action.type) {
    case HEADER_TYPE:
      localStorage.setItem('header', action.header);
      return  action.header ;
    default:
      return state;
  }
};

