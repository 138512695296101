import axios from "axios";
import {
  LISTING_ADD_COMMENT_FAIL,
  LISTING_ADD_COMMENT_REQUEST,
  LISTING_ADD_COMMENT_SUCCESS,
  LISTING_ADD_FAIL,
  LISTING_ADD_REQUEST,
  LISTING_ADD_RESERVATION_FAIL,
  LISTING_ADD_RESERVATION_REQUEST,
  LISTING_ADD_RESERVATION_SUCCESS,
  LISTING_ADD_SUCCESS,
  LISTING_ADD_WISHLIST_FAIL,
  LISTING_ADD_WISHLIST_REQUEST,
  LISTING_ADD_WISHLIST_SUCCESS,
  LISTING_EDIT_FAIL,
  LISTING_EDIT_REQUEST,
  LISTING_EDIT_SUCCESS,
  LISTING_DELETE_PROPERTY_FAIL,
  LISTING_DELETE_PROPERTY_REQUEST,
  LISTING_DELETE_PROPERTY_SUCCESS,
  LISTING_GET_ALL_WISHLIST_FAIL,
  LISTING_GET_ALL_WISHLIST_REQUEST,
  LISTING_GET_ALL_WISHLIST_SUCCESS,
  LISTING_LIST_FAIL,
  LISTING_LIST_REQUEST,
  LISTING_LIST_SUCCESS,
  LISTING_LIST_WISHLIST_FAIL,
  LISTING_LIST_WISHLIST_REQUEST,
  LISTING_LIST_WISHLIST_SUCCESS,
  LSITING_GET_BY_ID_FAIL,
  LSITING_GET_BY_ID_REQUEST,
  LSITING_GET_BY_ID_SUCCESS,
} from "../constants/listingConstants";
import {
  ADD_COMMENT,
  ADD_LISTING,
  ADD_RESERVATION,
  ADD_WISHLIST,
  EDIT_LISTING,
  DELETE_LISTING,
  GET_ALL_WISHLIST_LIST,
  GET_LISTING_BY_ID,
  GET_LISTING_LIST,
  GET_WISHLIST_LIST,
} from "../utils/apiConfig";

export const addReservationAction =
  (listing, date, time) => async (dispatch, getState) => {
    try {
      dispatch({ type: LISTING_ADD_RESERVATION_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        ADD_RESERVATION,
        { listing, date, time },
        config
      );

      dispatch({
        type: LISTING_ADD_RESERVATION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: LISTING_ADD_RESERVATION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const addCommentAction =
  (listing, rating, comment) => async (dispatch, getState) => {
    try {
      dispatch({ type: LISTING_ADD_COMMENT_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        ADD_COMMENT(listing),
        { rating, comment },
        config
      );

      dispatch({
        type: LISTING_ADD_COMMENT_SUCCESS,
        payload: data,
      });
      dispatch(getListingByIDActions(listing));
    } catch (error) {
      dispatch({
        type: LISTING_ADD_COMMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const addListingAction = (listing) => async (dispatch, getState) => {
  try {
    dispatch({ type: LISTING_ADD_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",

        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(ADD_LISTING, listing, config);

    dispatch({
      type: LISTING_ADD_SUCCESS,
      payload: data,
    });
    dispatch(getListingsListActions({}));
  } catch (error) {
    dispatch({
      type: LISTING_ADD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const editListingAction =
  (id, listing) => async (dispatch, getState) => {
    try {
      dispatch({ type: LISTING_EDIT_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(EDIT_LISTING(id), listing, config);

      dispatch({
        type: LISTING_EDIT_SUCCESS,
        payload: data,
      });
      dispatch(getListingsListActions({}));
    } catch (error) {
      dispatch({
        type: LISTING_EDIT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const addWishListAction = (listing) => async (dispatch, getState) => {
  try {
    dispatch({ type: LISTING_ADD_WISHLIST_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(ADD_WISHLIST(listing), {}, config);

    dispatch({
      type: LISTING_ADD_WISHLIST_SUCCESS,
      payload: data,
    });
    dispatch(getWishListActions());
  } catch (error) {
    dispatch({
      type: LISTING_ADD_WISHLIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getListingByIDActions = (id) => async (dispatch) => {
  try {
    dispatch({ type: LSITING_GET_BY_ID_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(GET_LISTING_BY_ID(id), config);

    dispatch({
      type: LSITING_GET_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LSITING_GET_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getListingsListActions = (dataParam) => async (dispatch) => {
  try {
    dispatch({ type: LISTING_LIST_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        ...dataParam,
      },
    };

    const { data } = await axios.get(GET_LISTING_LIST, config);

    dispatch({
      type: LISTING_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LISTING_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getWishListActions = () => async (dispatch, getState) => {
  try {
    dispatch({ type: LISTING_LIST_WISHLIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(GET_WISHLIST_LIST, config);

    dispatch({
      type: LISTING_LIST_WISHLIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LISTING_LIST_WISHLIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deletePropertyAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LISTING_DELETE_PROPERTY_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.delete(DELETE_LISTING(id), config);
    dispatch({
      type: LISTING_DELETE_PROPERTY_SUCCESS,
      payload: data,
    });
    dispatch(getListingsListActions());
  } catch (error) {
    dispatch({
      type: LISTING_LIST_WISHLIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getAllWishListActions = () => async (dispatch, getState) => {
  try {
    dispatch({ type: LISTING_GET_ALL_WISHLIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(GET_ALL_WISHLIST_LIST, config);

    dispatch({
      type: LISTING_GET_ALL_WISHLIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LISTING_GET_ALL_WISHLIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
