import React, { useEffect, useState } from "react";
import { ReactComponent as BedroomIcon } from "../svg/bedroom.svg";
import { ReactComponent as Shower } from "../svg/shower.svg";
import { ReactComponent as Squere } from "../svg/square.svg";
import { ReactComponent as Garage } from "../svg/Garage.svg";
import { ReactComponent as Year } from "../svg/year.svg";
import Reservation from "../components/listing/reservation";
import { useDispatch, useSelector } from "react-redux";
import ListingComments from "../components/listing/listingComments";
import { getListingByIDActions } from "../actions/listingActions";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getDate } from "../utils/constants";
import { BACKEND_IMAGE } from "../utils/apiConfig";
import { Helmet } from "react-helmet";
import { MapContainer, TileLayer, useMap, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import ListingCard from "../components/listing/listingCard";
import { Loader } from "../components/utils/loader";
import { useTranslation } from "react-i18next";
import { alertToast } from "../components/utils/alertHandler";
const SignleProperty = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [isActive, setisActive] = useState(false);
  const { t } = useTranslation();

  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  const getListingByID = useSelector((state) => state.getListingByID);
  const { listing, error } = getListingByID;

  const getWishList = useSelector((state) => state.getWishList);
  const { wishlists } = getWishList;

  useEffect(() => {
    if (wishlists) {
      setisActive(
        wishlists.find((wl) => wl?.listing?._id === listing?._id) ? true : false
      );
    }
  }, [wishlists, listing]);

  useEffect(() => {
    if (error) {
      alertToast("error", "Listing not found");
      navigate("/");
    }
  }, [error, navigate]);

  useEffect(() => {
    dispatch(getListingByIDActions(id));
  }, [dispatch, id]);
  const position = [listing?.address?.lat, listing?.address?.long];

  const myIcon = L.icon({
    iconUrl: require("../images/googlle-market-01.png"),
    iconSize: [45, 45],
    iconAnchor: [25, 40],
  });
  return (
    <>
      {!listing ? (
        <Loader fullScreen={true} />
      ) : (
        <>
          <Helmet>
            <script src="/assets/js/theme.js"></script>
          </Helmet>
          <main id="content" className="mt-10">
            <section className="mt-10">
              <div className="container mt-10">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb pt-lg-0 pb-3">
                    <li className="breadcrumb-item fs-12 letter-spacing-087">
                      <a href=".">Home</a>
                    </li>
                    <li className="breadcrumb-item fs-12 letter-spacing-087">
                      <a href="listing-grid-with-left-filter.html">Listing</a>
                    </li>
                    <li className="breadcrumb-item fs-12 letter-spacing-087 active">
                      {listing?.title}
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="container-fluid">
                <div className="position-relative" data-animate="zoomIn">
                  <div className="position-absolute pos-fixed-top-right z-index-3">
                    <ul className="list-inline pt-4 pr-5">
                      <li className="list-inline-item mr-2">
                        <a
                          href="#"
                          data-toggle="tooltip"
                          title="Favourite"
                          className="d-flex align-items-center justify-content-center w-40px h-40 bg-white text-heading bg-hover-primary hover-white rounded-circle"
                        >
                          <i
                            className={
                              (isActive ? "fas text-primary" : "far") +
                              " fa-heart"
                            }
                          ></i>
                        </a>
                      </li>
                      <li className="list-inline-item mr-2">
                        <button
                          type="button"
                          className="btn btn-white p-0 d-flex align-items-center justify-content-center w-40px h-40 text-heading bg-hover-primary hover-white rounded-circle border-0 shadow-none"
                          data-container="body"
                          data-toggle="popover"
                          data-placement="top"
                          data-html="true"
                          data-content=' <ul className="list-inline mb-0">
                    <li className="list-inline-item">
                      <a href="#" className="text-muted fs-15 hover-dark lh-1 px-2"><i
                                                        className="fab fa-twitter"></i></a>
                    </li>
                    <li className="list-inline-item ">
                      <a href="#" className="text-muted fs-15 hover-dark lh-1 px-2"><i
                                                        className="fab fa-facebook-f"></i></a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#" className="text-muted fs-15 hover-dark lh-1 px-2"><i
                                                        className="fab fa-instagram"></i></a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#" className="text-muted fs-15 hover-dark lh-1 px-2"><i
                                                        className="fab fa-youtube"></i></a>
                    </li>
                  </ul>
                  '
                        >
                          <i className="far fa-share-alt"></i>
                        </button>
                      </li>
                      <li className="list-inline-item">
                        <a
                          href="#"
                          data-toggle="tooltip"
                          title="Print"
                          className="d-flex align-items-center justify-content-center w-40px h-40 bg-white text-heading bg-hover-primary hover-white rounded-circle"
                        >
                          <i className="far fa-print"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="row galleries m-n1">
                    <div className="col-lg-6 p-1">
                      <div className="item item-size-4-3">
                        <div className="card p-0 hover-zoom-in">
                          <a
                            href={
                              BACKEND_IMAGE + listing?.images?.split(",")[0]
                            }
                            className="card-img"
                            data-gtf-mfp="true"
                            data-gallery-id="01"
                            style={{
                              backgroundImage:
                                "url('" +
                                BACKEND_IMAGE +
                                listing?.images
                                  ?.split(",")[0]
                                  .replace("\\", "/") +
                                "')",
                            }}
                          ></a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 p-1">
                      <div className="row m-n1">
                        {listing?.images.split(",").map(
                          (image, index) =>
                            index !== 0 && (
                              <div key={index} className="col-md-6 p-1">
                                <div className="item item-size-4-3">
                                  <div className="card p-0 hover-zoom-in">
                                    <a
                                      href={BACKEND_IMAGE + image}
                                      className="card-img"
                                      data-gtf-mfp="true"
                                      data-gallery-id="01"
                                      style={{
                                        backgroundImage:
                                          "url('" +
                                          BACKEND_IMAGE +
                                          image.replace("\\", "/") +
                                          "')",
                                      }}
                                    ></a>
                                  </div>
                                </div>
                              </div>
                            )
                        )}
                        {/* <div className="col-md-6 p-1">
                          <div className="item item-size-4-3">
                            <div className="card p-0 hover-zoom-in">
                              <a
                                href="/assets/images/single-property-lg-5.jpg"
                                className="card-img"
                                data-gtf-mfp="true"
                                data-gallery-id="01"
                                style={{
                                  backgroundImage:
                                    "url('/assets/images/single-property-22.jpg')",
                                }}
                              ></a>
                              <a
                                href="#"
                                className="card-img-overlay d-flex flex-column align-items-center justify-content-center hover-image bg-dark-opacity-04"
                              >
                                <p className="fs-48 font-weight-600 text-white lh-1 mb-4">
                                  +12
                                </p>
                                <p className="fs-16 font-weight-bold text-white lh-1625 text-uppercase">
                                  View more
                                </p>
                              </a>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="primary-content pt-8">
              <div className="container">
                <div className="row">
                  <article className="col-lg-8 pr-xl-7">
                    <section className="pb-7 border-bottom">
                      <ul className="list-inline d-sm-flex align-items-sm-center mb-2">
                        <li className="list-inline-item badge badge-primary mr-3">
                          {listing?.listedIn}
                        </li>
                        <li className="list-inline-item mr-2 mt-2 mt-sm-0">
                          <i className="fal fa-clock mr-1"></i>
                          {getDate(listing?.createdAt)} ago
                        </li>
                        <li className="list-inline-item mt-2 mt-sm-0">
                          <i className="fal fa-eye mr-1"></i>
                          {listing?.nbrViews} views
                        </li>
                      </ul>
                      <div className="d-sm-flex justify-content-sm-between">
                        <div>
                          <h2 className="fs-35 font-weight-600 lh-15 text-heading">
                            {listing?.title}
                          </h2>
                          <p className="mb-0">
                            <i className="fal fa-map-marker-alt mr-2"></i>
                            {listing?.address.adresse}
                          </p>
                        </div>
                        <div className="mt-2 text-lg-right">
                          <p className="fs-22 text-heading font-weight-bold mb-0">
                            ${listing?.price.price}
                          </p>
                        </div>
                      </div>
                      <h4 className="fs-22 text-heading mt-6 mb-2">
                        Description
                      </h4>
                      <p className="mb-0 lh-214">{listing?.description}</p>
                    </section>
                    <section className="pt-6 border-bottom">
                      <h4 className="fs-22 text-heading mb-6">
                        {t("Facts and Features")}
                      </h4>
                      <div className="row">
                        <div className="col-lg-3 col-sm-4 mb-6">
                          <div className="media">
                            <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                              <svg className="icon icon-family fs-32 text-primary">
                                <use xlinkHref="#icon-family"></use>
                              </svg>
                            </div>
                            <div className="media-body">
                              <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">
                                {t("Type")}
                              </h5>
                              <p className="mb-0 fs-13 font-weight-bold text-heading">
                                {listing?.category}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-4 mb-6">
                          <div className="media">
                            <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                              <Year className="icon icon-year fs-32 text-primary" />
                            </div>
                            <div className="media-body">
                              <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">
                                {t("year built")}
                              </h5>
                              <p className="mb-0 fs-13 font-weight-bold text-heading">
                                {listing?.details?.yearBuilt}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3 col-sm-4 mb-6">
                          <div className="media">
                            <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                              <svg className="icon icon-heating fs-32 text-primary">
                                <use xlinkHref="#icon-price"></use>
                              </svg>
                            </div>

                            <div className="media-body">
                              <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">
                                {t("Living space area")}
                              </h5>
                              <p className="mb-0 fs-13 font-weight-bold text-heading">
                                {listing?.details?.size+" m²"} <br/> {"("+(listing?.details?.size*10.764).toFixed(2) +" pi² )" }
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-4 mb-6">
                          <div className="media">
                            <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                              <BedroomIcon className="icon icon-bedroom fs-18 text-primary mr-1" />
                            </div>
                            <div className="media-body">
                              <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">
                                {t("bedrooms")}
                              </h5>
                              <p className="mb-0 fs-13 font-weight-bold text-heading">
                                {listing?.details?.bedroom}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-4 mb-6">
                          <div className="media">
                            <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                              <svg className="icon icon-sofa fs-32 text-primary">
                                <use xlinkHref="#icon-sofa"></use>
                              </svg>
                            </div>
                            <div className="media-body">
                              <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">
                                {t("bathrooms")}
                              </h5>
                              <p className="mb-0 fs-13 font-weight-bold text-heading">
                                {listing?.details?.bathRoom}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-4 mb-6">
                          <div className="media">
                            <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                              <Garage className="icon icon-bedroom fs-18 text-primary mr-1" />
                            </div>
                            <div className="media-body">
                              <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">
                                GARAGE
                              </h5>
                              <p className="mb-0 fs-13 font-weight-bold text-heading">
                                {listing?.details?.garage}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-4 mb-6">
                          <div className="media">
                            <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                              <svg className="icon icon-status fs-32 text-primary">
                                <use xlinkHref="#icon-status"></use>
                              </svg>
                            </div>
                            <div className="media-body">
                              <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">
                                Status
                              </h5>
                              <p className="mb-0 fs-13 font-weight-bold text-heading">
                                {listing?.status ? "Active" : "Not Active"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="pt-6 border-bottom pb-4">
                      <h4 className="fs-22 text-heading mb-4">
                        {t("Additional Details")}
                      </h4>
                      <div className="row">
                        <dl className="col-sm-6 mb-0 d-flex">
                          <dt className="w-110px fs-14 font-weight-500 text-heading pr-2">
                            {t("Price")}
                          </dt>
                          <dd>${listing?.price?.price}</dd>
                        </dl>
                        <dl className="col-sm-6 mb-0 d-flex">
                          <dt className="w-110px fs-14 font-weight-500 text-heading pr-2">
                            {t("Property type")}
                          </dt>
                          <dd>{listing?.category}</dd>
                        </dl>
                        <dl className="col-sm-6 mb-0 d-flex">
                          <dt className="w-110px fs-14 font-weight-500 text-heading pr-2">
                            {t("Property status")}
                          </dt>
                          <dd>{listing?.listedIn}</dd>
                        </dl>
                        <dl className="col-sm-6 mb-0 d-flex">
                          <dt className="w-110px fs-14 font-weight-500 text-heading pr-2">
                            {t("Rooms")}
                          </dt>
                          <dd>{listing?.details?.room}</dd>
                        </dl>
                        <dl className="col-sm-6 mb-0 d-flex">
                          <dt className="w-110px fs-14 font-weight-500 text-heading pr-2">
                            {t("bedrooms")}
                          </dt>
                          <dd>{listing?.details?.bedroom}</dd>
                        </dl>
                        <dl className="col-sm-6 mb-0 d-flex">
                          <dt className="w-110px fs-14 font-weight-500 text-heading pr-2">
                            {t("Size")}
                          </dt>
                          <dd>{listing?.details?.size+" m²"}  {"("+(listing?.details?.size*10.764).toFixed(2) +" pi² )" }</dd>
                        </dl>
                        <dl className="col-sm-6 mb-0 d-flex">
                          <dt className="w-110px fs-14 font-weight-500 text-heading pr-2">
                            {t("bathrooms")}
                          </dt>
                          <dd>{listing?.details?.bathRoom}</dd>
                        </dl>
                        <dl className="col-sm-6 mb-0 d-flex">
                          <dt className="w-110px fs-14 font-weight-500 text-heading pr-2">
                            Garage
                          </dt>
                          <dd>{listing?.details?.garage}</dd>
                        </dl>
                        <dl className="col-sm-6 mb-0 d-flex">
                          <dt className="w-110px fs-14 font-weight-500 text-heading pr-2">
                            {t("Garage size")}
                          </dt>
                          <dd>{listing?.details?.garageSize+" m²"}  {"("+(listing?.details?.garageSize*10.764).toFixed(2) +" pi² )" }</dd>
                          
                        </dl>
                        <dl className="col-sm-6 mb-0 d-flex">
                          <dt className="w-110px fs-14 font-weight-500 text-heading pr-2">
                            {t("Year built")}
                          </dt>
                          <dd>{listing?.details?.yearBuilt}</dd>
                        </dl>
                      </div>
                    </section>
                    <section className="pt-6 border-bottom pb-4">
                      <h4 className="fs-22 text-heading mb-4">
                        {t("Amenities")}
                      </h4>
                      <div className="row">
                      {listing?.amenities?.map((amenity, index) => (
                      <div className="col-lg-3 col-sm-4 mb-6" key={index}>
                      <div className="media">
                        <img
                          style={{ width: '50px', height: '50px' }}
                          src={BACKEND_IMAGE + listing?.allAmenities.find(item => item._id.includes(amenity))?.icon}
                          alt='Partner 1'
                          className='image'
                        />
                        <div className="media-body ml-2">
                          <h5 className="my-1 fs-14 text-uppercase letter-spacing-093 font-weight-normal">
                            {listing?.allAmenities.find(item => item._id.includes(amenity))?.title}
                          </h5>
                         
                        </div>
                      </div>
                       </div>
                        ))}

                        </div>

                    </section>
                    <section className="py-6">
                      <h4 className="fs-22 text-heading mb-6">
                        {t("Floor Plans")}
                      </h4>
                      {listing?.floorImages?.split(",").map((image, index) => (
                        <div
                          key={index}
                          className="accordion accordion-03 mb-3"
                          id={"accordion-" + index}
                        >
                          <div className="card border-0 shadow-xxs-2">
                            <div
                              className="card-header bg-gray-01 border-gray border-0 p-0"
                              id={"floor-plans-" + index}
                            >
                              <div
                                className="heading d-flex justify-content-between align-items-center px-6"
                                data-toggle="collapse"
                                data-target={"#collapse-" + index}
                                aria-expanded="true"
                                aria-controls={"collapse-" + index}
                                role="button"
                              >
                                <h2 className="mb-0 fs-16 text-heading font-weight-500 py-4 lh-13">
                                  {t("Floor")} {index}
                                </h2>
                              </div>
                            </div>
                            <div
                              id={"collapse-" + index}
                              className="collapse show"
                              aria-labelledby={"floor-plans-" + index}
                              data-parent={"#accordion-" + index}
                            >
                              <div className="card-body card-body col-sm-6 offset-sm-3 mb-3">
                                <img
                                  src={BACKEND_IMAGE + image}
                                  className="card-img"
                                  alt="Floor Plans"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </section>
                    <ListingComments />
                    <section className="py-6 border-bottom">
                      <h4 className="fs-22 text-heading mb-6">
                        {t("Property Attachments")}
                      </h4>
                      <div className="d-sm-flex">
                        <div className="w-sm-170 mb-sm-0 mb-6 mr-sm-6">
                          <div className="card text-center pt-4">
                            <img
                              src="/assets/images/single-detail-property-05.png"
                              className="card-img card-img w-78px mx-auto"
                              alt="Villa Called Archangel Word Document"
                            />
                            <div className="card-body p-0 mt-4">
                              <p className="fs-13 lh-2  mb-0 py-0 px-2">
                                Villa Called Archangel Word Document
                              </p>
                              <a
                                href="#"
                                className="btn btn-block bg-gray-01 border-0 fs-14 text-heading"
                              >
                                Download
                                <i className="far fa-arrow-alt-circle-down ml-1 text-primary"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="w-sm-170 mb-sm-0 mb-6 mr-sm-6">
                          <div className="card text-center pt-4">
                            <img
                              src="/assets/images/single-detail-property-06.png"
                              className="card-img card-img w-78px mx-auto"
                              alt="Villa Called Archangel PDF Document"
                            />
                            <div className="card-body p-0 mt-4">
                              <p className="fs-13 lh-2  mb-0 py-0 px-2">
                                Villa Called Archangel PDF Document
                              </p>
                              <a
                                href="#"
                                className="btn btn-block bg-gray-01 border-0 fs-14 text-heading"
                              >
                                Download
                                <i className="far fa-arrow-alt-circle-down ml-1 text-primary"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    {listing && listing.virtualToolUrl.length > 0 && (
                      <section className="py-6 border-bottom">
                        <h4 className="fs-22 text-heading mb-6">
                          {t("Virtual Tour")}
                        </h4>
                        <iframe
                          height="430"
                          src={listing.virtualToolUrl}
                          allowFullScreen=""
                          className="w-100"
                        ></iframe>
                      </section>
                    )}
                    <section className="py-6 border-bottom">
                      <h4 className="fs-22 text-heading mb-6">Location</h4>
                      {/* <div className="position-relative">
                        <div
                          id="map"
                          className="mapbox-gl map-point-animate"
                          data-mapbox-access-token="pk.eyJ1IjoiZHVvbmdsaCIsImEiOiJjanJnNHQ4czExMzhyNDVwdWo5bW13ZmtnIn0.f1bmXQsS6o4bzFFJc8RCcQ"
                          data-mapbox-options='{"center":[-73.9927227, 40.6741035],"setLngLat":[-73.9927227, 40.6741035]}'
                          data-mapbox-marker='[{"position":[-73.9927227, 40.6741035],"className":"marker","backgroundImage":"","backgroundRepeat":"no-repeat","width":"30px","height":"40px"}]'
                        ></div> */}
                      <div>
                        <MapContainer
                          style={{ height: "400px", width: "100%" }}
                          center={position}
                          zoom={13}
                          attributionControl={false}
                          scrollWheelZoom={true}
                        >
                          <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          />
                          <Marker position={position} icon={myIcon}></Marker>
                        </MapContainer>
                      </div>

                      <p
                        style={{ zIndex: "99999" }}
                        className="mb-0 p-3 bg-white shadow rounded-lg position-absolute pos-fixed-bottom mb-4 ml-4 lh-17 "
                      >
                        {listing?.address?.adresse}
                      </p>
                      {/* </div> */}
                    </section>
                  </article>
                  {userInfo && <Reservation />}
                </div>
              </div>
            </div>
            {listing && listing.listings?.length > 0 && (
              <section className="pt-6 pb-8">
                <div className="container">
                  <h4 className="fs-22 text-heading mb-6">
                    {t("Similar Homes You May Like")}
                  </h4>
                  <div
                    className="slick-slider"
                    data-slick-options='{"slidesToShow": 3, "dots":false,"arrows":false,"responsive":[{"breakpoint": 1200,"settings": {"slidesToShow":3,"arrows":false}},{"breakpoint": 992,"settings": {"slidesToShow":2}},{"breakpoint": 768,"settings": {"slidesToShow": 1}},{"breakpoint": 576,"settings": {"slidesToShow": 1}}]}'
                  >
                    {listing?.listings?.map((similarListing, index) => (
                      <ListingCard key={index} listing={similarListing} />
                    ))}
                  </div>
                </div>
              </section>
            )}
            <section>
              <div className="d-flex bottom-bar-action bottom-bar-action-01 py-2 px-4 bg-gray-01 align-items-center position-fixed fixed-bottom d-sm-none">
                <div className="media align-items-center">
                  <img
                    src="/assets/images/irene-wallace.png"
                    alt="Irene Wallace"
                    className="mr-4 rounded-circle"
                  />
                  <div className="media-body">
                    <a
                      href="#"
                      className="d-block text-dark fs-15 font-weight-500 lh-15"
                    >
                      Irene Wallace
                    </a>
                    <span className="fs-13 lh-2">Sales Excutive</span>
                  </div>
                </div>
                <div className="ml-auto">
                  <button
                    type="button"
                    className="btn btn-primary fs-18 p-2 lh-1 mr-1 mb-1 shadow-none"
                    data-toggle="modal"
                    data-target="#modal-messenger"
                  >
                    <i className="fal fa-comment"></i>
                  </button>
                  <a
                    href="tel:(+84)2388-969-888"
                    className="btn btn-primary fs-18 p-2 lh-1 mb-1 shadow-none"
                    target="_blank"
                  >
                    <i className="fal fa-phone"></i>
                  </a>
                </div>
              </div>
              <div
                className="modal fade"
                id="modal-messenger"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header border-0 pb-0">
                      <h4
                        className="modal-title text-heading"
                        id="exampleModalLabel"
                      >
                        Contact Form
                      </h4>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body pb-6">
                      <div className="form-group mb-2">
                        <input
                          type="text"
                          className="form-control form-control-lg border-0"
                          placeholder="First Name, Last Name"
                        />
                      </div>
                      <div className="form-group mb-2">
                        <input
                          type="email"
                          className="form-control form-control-lg border-0"
                          placeholder="Your Email"
                        />
                      </div>
                      <div className="form-group mb-2">
                        <input
                          type="tel"
                          className="form-control form-control-lg border-0"
                          placeholder="Your phone"
                        />
                      </div>
                      <div className="form-group mb-2">
                        <textarea className="form-control border-0" rows="4">
                          Hello, I'm interested in Villa Called Archangel
                        </textarea>
                      </div>
                      <div className="form-group form-check mb-4">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="exampleCheck3"
                        />
                        <label
                          className="form-check-label fs-13"
                          htmlFor="exampleCheck3"
                        >
                          Egestas fringilla phasellus faucibus scelerisque
                          eleifend donec.
                        </label>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg btn-block rounded"
                      >
                        Request Info
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </>
      )}
    </>
  );
};

export default SignleProperty;
