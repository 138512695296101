import React, { useEffect, useState } from "react";
import {
  addPartnerAction,
  deletePartnerAction,
  getlistPartnersAction,
  updatePartnerAction,
} from "../../actions/partnersActions";
import { useDispatch, useSelector } from "react-redux";
import { BACKEND_IMAGE } from "../../utils/apiConfig";
import Swal from "sweetalert2";
import { alertToast } from "../../components/utils/alertHandler";
import {
  PARTNER_ADD_RESET,
  PARTNER_UPDATE_RESET,
} from "../../constants/partnersConstants";
import Pagination from "../../components/utils/Pagination";
import { useTranslation } from "react-i18next";

export default function DashboardPartners() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const getPartners = useSelector((state) => state.getPartners);
  const { partners } = getPartners;

  const addPartner = useSelector((state) => state.addPartner);
  const { partner,error } = addPartner;

  const updatePartner = useSelector((state) => state.updatePartner);
  const { partner: uPartner } = updatePartner;

  const [partnerData, setPartnerData] = useState({
    title: "",
    url: "",
    logo: null,
  });

  const [isAdd, setIsAdd] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 6;
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPartners = partners?.slice(firstPostIndex, lastPostIndex);

  useEffect(() => {
    dispatch(getlistPartnersAction());
  }, [dispatch]);

  const deleleteHandler = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deletePartnerAction(id));
        Swal.fire("Deleted!", "Your product has been deleted.", "success");
      }
    });
  };

  useEffect(() => {
    if (uPartner) {
      alertToast("success", "Partner updated successfully");
      dispatch({ type: PARTNER_UPDATE_RESET });
      document.getElementById("addTT").click();
    }
  }, [uPartner, dispatch]);

  useEffect(() => {
    if (partner) {
      alertToast("success", "Partner added successfully");
      dispatch({ type: PARTNER_ADD_RESET });
      document.getElementById("addTT").click();
    }
  }, [partner, dispatch]);

  // useEffect(() => {
  //   if (error) {
  //     Swal.fire({
  //       icon: "error",
  //       text: "Please feel all fileds",
       
  //     });
  //     dispatch({ type: PARTNER_ADD_RESET });
  //     // document.getElementById("addTT").click();
  //   }
  // }, [error, dispatch]);

  const submitPartnerData = () => {
    let formData = new FormData();
    formData.append("title", partnerData.title);
    formData.append("url", partnerData.url);
    formData.append("logo", partnerData.logo);
    if (isAdd) {
      dispatch(addPartnerAction(formData));
    } else {
      dispatch(updatePartnerAction(formData, partnerData._id));
    }
  };


  console.log("error",error);
  return (
    <main id="content" className="bg-gray-01">
      <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10 invoice-listing">
        <div className="mb-6">
          <div className="row">
            <div className="col-sm-12 col-md-6 d-flex justify-content-md-start justify-content-center">
              <div className="mr-0 mr-md-auto">
                <h2 className="mb-0 text-heading fs-22 lh-15">
                  {t("List of partners")}
                  <span className="badge badge-white badge-pill text-primary fs-18 font-weight-bold ml-2">
                    {partners?.length}
                  </span>
                </h2>
                <p>{t("Access and manage all your partners in one place")}</p>
              </div>
            </div>

            <div className="col-sm-12 col-md-6 d-flex justify-content-md-end justify-content-center mt-md-0 mt-3">
              <div className="align-self-center" style={{ minWidth: "140px" }}>
                <button
                  className="btn btn-primary btn-lg"
                  tabindex="0"
                  onClick={() => {
                    setIsAdd(true);
                    setPartnerData({ title: "", url: "", logo: null });
                  }}
                  data-toggle="modal"
                  href="#add_edit-modal"
                  id="addTT"
                >
                  <span>{t("Add partner")}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-hover bg-white border rounded-lg">
            <thead>
              <tr role="row">
                <th className="py-6">{t("Image")}</th>
                <th className="py-6">{t("Title")}</th>

                <th className="no-sort py-6">{t("Actions")}</th>
              </tr>
            </thead>
            <tbody>
              {currentPartners &&
                currentPartners?.map((partner) => (
                  <tr role="row">
                    <td className="align-middle">
                      <div className="d-flex align-items-center">
                        <div className="usr-img-frame mr-2 rounded-circle">
                          <img
                            alt="avatar"
                            className="img-fluid w-70px"
                            src={BACKEND_IMAGE + partner.image}
                          />
                        </div>
                      </div>
                    </td>
                    <td className="align-middle">
                      <a target="_blank" href={partner.url}>
                        {partner.title}
                      </a>
                    </td>

                    <td className="align-right">
                      <div
                        onClick={() => {
                          setIsAdd(false);
                          setPartnerData({ ...partner, logo: null });
                        }}
                        data-toggle="modal"
                        href="#add_edit-modal"
                        title="Edit"
                        className="d-inline-block fs-18 text-muted hover-primary mr-5"
                      >
                        <i className="fal fa-pencil-alt"></i>
                      </div>
                      <div
                        onClick={() => deleleteHandler(partner._id)}
                        data-toggle="tooltip"
                        title="Delete"
                        className="d-inline-block fs-18 text-muted hover-primary"
                      >
                        <i className="fal fa-trash-alt"></i>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <nav className="mt-6">
          <Pagination
            totalPosts={partners?.length}
            postsPerPage={postsPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </nav>
      </div>
      <div
        className="modal fade"
        id="add_edit-modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="add_edit-modal"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered "
          role="document"
        >
          <div className="modal-content">
          <div className="modal-header">
           
            <h5 className="modal-title">{t("Partner list")}</h5>
             
 
  <button
    type="button"
    className="close"
    data-dismiss="modal"
    aria-label="Close"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
            <div className="modal-body p-4 py-sm-7 px-sm-8">
            {error != null ? (
             <div className="alert alert-danger">

             
                 {("please feel all fileds")}
            
            </div>
              ) : null}
              <div className="tab-content shadow-none p-0" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="login"
                  role="tabpanel"
                  aria-labelledby="login-tab"
                >
                  <section className="py-2">
                    <form>
                      <div className="row">
                        <div className="col-md-6 mb-4">
                          <label>{t("Title")} :</label>
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control border-0 pr-3 h-52 pl-7"
                              value={partnerData.title}
                              name="total-amount"
                              onChange={(e) =>
                                setPartnerData({
                                  ...partnerData,
                                  title: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label>URL :</label>
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control border-0 pr-3 h-52 pl-7"
                              name="total-amount"
                              value={partnerData.url}
                              onChange={(e) =>
                                setPartnerData({
                                  ...partnerData,
                                  url: e.target.value,
                                })
                              }
                            />
                            <span className="position-absolute pl-3 pos-fixed-left-center fs-13 font-weight-600"></span>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 mb-4">
                          <label>Logo :</label>
                          <div className="position-relative">
                            <input
                              type="file"
                              className="form-control border-0 pr-3 h-52 pl-7"
                              accept="image/*"
                              onChange={(e) =>
                                setPartnerData({
                                  ...partnerData,
                                  logo: e.target.files[0],
                                })
                              }
                              name="interest-rate"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <button
                            className="btn btn-primary fs-14 h-52 px-8"
                            type="button"
                            onClick={(e) => submitPartnerData()}
                          >
                            {t("Submit")}
                          </button>
                        </div>
                      </div>
                    </form>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
