export const DASHBOARD_GET_REVIEWS_REQUEST = 'DASHBOARD_GET_REVIEWS_REQUEST'
export const DASHBOARD_GET_REVIEWS_SUCCESS = 'DASHBOARD_GET_REVIEWS_SUCCESS'
export const DASHBOARD_GET_REVIEWS_FAIL = 'DASHBOARD_GET_REVIEWS_FAIL'
export const DASHBOARD_GET_REVIEWS_RESET = 'DASHBOARD_GET_REVIEWS_RESET'

export const DASHBOARD_GET_RESERVATION_REQUEST =
  'DASHBOARD_GET_RESERVATION_REQUEST'
export const DASHBOARD_GET_RESERVATION_SUCCESS =
  'DASHBOARD_GET_RESERVATION_SUCCESS'
export const DASHBOARD_GET_RESERVATION_FAIL = 'DASHBOARD_GET_RESERVATION_FAIL'
export const DASHBOARD_GET_RESERVATION_RESET = 'DASHBOARD_GET_RESERVATION_RESET'

export const DASHBOARD_DELETE_RESERVATION_REQUEST =
  'DASHBOARD_DELETE_RESERVATION_REQUEST'
export const DASHBOARD_DELETE_RESERVATION_SUCCESS =
  'DASHBOARD_DELETE_RESERVATION_SUCCESS'
export const DASHBOARD_DELETE_RESERVATION_FAIL =
  'DASHBOARD_DELETE_RESERVATION_FAIL'
export const DASHBOARD_DELETE_RESERVATION_RESET =
  'DASHBOARD_DELETE_RESERVATION_RESET'

export const DASHBOARD_RESERVATION_HANDLE_REQUEST =
  'DASHBOARD_RESERVATION_HANDLE_REQUEST'
export const DASHBOARD_RESERVATION_HANDLE_SUCCESS =
  'DASHBOARD_RESERVATION_HANDLE_SUCCESS'
export const DASHBOARD_RESERVATION_HANDLE_FAIL =
  'DASHBOARD_RESERVATION_HANDLE_FAIL'
export const DASHBOARD_RESERVATION_HANDLE_RESET =
  'DASHBOARD_DELETE_RESERVATION_RESET'

export const DASHBOARD_SAVED_SEARCH_REQUEST = 'DASHBOARD_SAVED_SEARCH_REQUEST'
export const DASHBOARD_SAVED_SEARCH_SUCCESS = 'DASHBOARD_SAVED_SEARCH_SUCCESS'
export const DASHBOARD_SAVED_SEARCH_FAIL = 'DASHBOARD_SAVED_SEARCH_FAIL'
export const DASHBOARD_SAVED_SEARCH_RESET = 'DASHBOARD_DELETE_RESERVATION_RESET'
