import axios from 'axios'
import {
  AMENITY_ADD_REQUEST,
  AMENITY_ADD_RESET,
  AMENITY_ADD_SUCCESS,
  AMENITY_DELETE_FAIL,
  AMENITY_DELETE_REQUEST,
  AMENITY_DELETE_SUCCESS,
  AMENITY_GET_ALL_FAIL,
  AMENITY_GET_ALL_REQUEST,
  AMENITY_GET_ALL_SUCCESS,
} from '../constants/amenityConstants'
import {
  ADD_AMENITY,
  DELETE_AMENITY,
  GET_LIST_AMENITY,
} from '../utils/apiConfig'

export const addAmenityAction = amenity => async (dispatch, getState) => {
  try {
    dispatch({ type: AMENITY_ADD_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(ADD_AMENITY, amenity, config)

    dispatch({
      type: AMENITY_ADD_SUCCESS,
      payload: data,
    })
    dispatch(getlistAmenitirsAction())
  } catch (error) {
    dispatch({
      type: AMENITY_ADD_RESET,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteAmenityAction = amenity => async (dispatch, getState) => {
  try {
    dispatch({ type: AMENITY_DELETE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.delete(DELETE_AMENITY(amenity), config)

    dispatch({
      type: AMENITY_DELETE_SUCCESS,
      payload: data,
    })
    dispatch(getlistAmenitirsAction())
  } catch (error) {
    dispatch({
      type: AMENITY_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getlistAmenitirsAction = () => async dispatch => {
  try {
    dispatch({ type: AMENITY_GET_ALL_REQUEST })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const { data } = await axios.get(GET_LIST_AMENITY, config)

    dispatch({
      type: AMENITY_GET_ALL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: AMENITY_GET_ALL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
