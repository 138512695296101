import axios from "axios";
import {
  GET_DASHBOARD_INFO_FAIL,
  GET_DASHBOARD_INFO_REQUEST,
  GET_DASHBOARD_INFO_SUCCESS,
  GET_HOMEPAGE_INFO_FAIL,
  GET_HOMEPAGE_INFO_REQUEST,
  GET_HOMEPAGE_INFO_SUCCESS,
} from "../constants/publicConstants";
import { DASHBOARD_INFO, HOMEPAGE_INFO } from "../utils/apiConfig";

export const getPublicInfoActions = () => async (dispatch) => {
  try {
    dispatch({ type: GET_HOMEPAGE_INFO_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(HOMEPAGE_INFO, config);

    dispatch({
      type: GET_HOMEPAGE_INFO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_HOMEPAGE_INFO_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getDashboardInfoActions = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_DASHBOARD_INFO_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(DASHBOARD_INFO, config);

    dispatch({
      type: GET_DASHBOARD_INFO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_DASHBOARD_INFO_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
