import axios from 'axios'
import {
  SELL_ADD_FAIL,
  SELL_ADD_REQUEST,
  SELL_ADD_SUCCESS,
  SELL_GET_ALL_FAIL,
  SELL_GET_ALL_REQUEST,
  SELL_GET_ALL_SUCCESS,
} from '../constants/sellConstants'
import { ADD_Sell, GET_LIST_SELL } from '../utils/apiConfig'

export const addSellAction = sell => async (dispatch, getState) => {
  try {
    dispatch({ type: SELL_ADD_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(ADD_Sell, sell, config)

    dispatch({
      type: SELL_ADD_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: SELL_ADD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getlistSellsAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SELL_GET_ALL_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(GET_LIST_SELL, config)

    dispatch({
      type: SELL_GET_ALL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: SELL_GET_ALL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
