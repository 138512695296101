import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const UsefulLinks = () => {
  const { t, i18n } = useTranslation()

  const getLinksList = useSelector(state => state.getLinksList)
  const { links } = getLinksList

  return (
    <>
      <main id='content'>
        <section className='pt-4 pt-lg-12'>
          <div className='page-title mb-2'></div>
          <h2 className='fs-32 lh-16 mb-3 text-dark text-center'>
            {t('Tools and Resources')}
          </h2>
          <p
            className='fs-20 lh-16 mb-8 text-dark text-center'
            style={{ color: '#7f665e' }}>
            {t(
              'Download the tools and resources created by our experts to help you in your real estate process'
            )}
          </p>
          <div className='collapse-tabs'>
            <div className='tab-content shadow-none rounded-0 pt-8 pt-md-7 pb-10 pb-md-12 px-0 bg-gray-01'>
              <div id='collapse-tabs-accordion-01'>
                <div
                  className='tab-pane tab-pane-parent fade show active container '
                  id='selling1'
                  role='tabpanel'>
                  <div className='card border-0 bg-transparent'>
                    <h3 className='text-center mb-7'> {t('Useful Links')}</h3>
                    <div
                      id='selling-collapse-01'
                      className='collapse show collapsible'
                      aria-labelledby='headingSelling-01'
                      data-parent='#collapse-tabs-accordion-01'>
                      <div className=' row my-7 my-md-0 mx-3 mx-md-0'>
                        {links &&
                          links?.map((link, index) => (
                            <div className='col-md-6' key={index}>
                              <div
                                style={{ padding: 0 }}
                                className='card border-2 border-dark shadow-xxs-2 mb-6 rounded-top overflow-hidden'>
                                <div
                                  className='card-header border-0 p-0'
                                  id={`heading_${index}`}>
                                  <h5 className='mb-0'>
                                    <a
                                      className='btn d-flex justify-content-between font-weight-500 pl-6 pr-7 py-3 fs-16 position-relative w-100 text-left rounded-0 collapsed'
                                      href={link.url}
                                      rel='noreferrer'
                                      target='_blank'>
                                      {link.title}
                                      <i className='fas fa-external-link-alt fa-lg  mt-1'></i>
                                    </a>
                                  </h5>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default UsefulLinks
