export const PARTNER_GET_ALL_REQUEST = "PARTNER_GET_ALL_REQUEST";
export const PARTNER_GET_ALL_SUCCESS = "PARTNER_GET_ALL_SUCCESS";
export const PARTNER_GET_ALL_FAIL = "PARTNER_GET_ALL_FAIL";
export const PARTNER_GET_ALL_RESET = "PARTNER_GET_ALL_RESET";

export const PARTNER_ADD_REQUEST = "PARTNER_ADD_REQUEST";
export const PARTNER_ADD_SUCCESS = "PARTNER_ADD_SUCCESS";
export const PARTNER_ADD_FAIL = "PARTNER_ADD_FAIL";
export const PARTNER_ADD_RESET = "PARTNER_ADD_RESET";

export const PARTNER_UPDATE_REQUEST = "PARTNER_UPDATE_REQUEST";
export const PARTNER_UPDATE_SUCCESS = "PARTNER_UPDATE_SUCCESS";
export const PARTNER_UPDATE_FAIL = "PARTNER_UPDATE_FAIL";
export const PARTNER_UPDATE_RESET = "PARTNER_UPDATE_RESET";

export const PARTNER_DELETE_REQUEST = "PARTNER_DELETE_REQUEST";
export const PARTNER_DELETE_SUCCESS = "PARTNER_DELETE_SUCCESS";
export const PARTNER_DELETE_FAIL = "PARTNER_DELETE_FAIL";
export const PARTNER_DELETE_RESET = "PARTNER_DELETE_RESET";
