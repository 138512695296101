import {
  PARTNER_ADD_FAIL,
  PARTNER_ADD_REQUEST,
  PARTNER_ADD_RESET,
  PARTNER_ADD_SUCCESS,
  PARTNER_DELETE_FAIL,
  PARTNER_DELETE_REQUEST,
  PARTNER_DELETE_RESET,
  PARTNER_DELETE_SUCCESS,
  PARTNER_GET_ALL_FAIL,
  PARTNER_GET_ALL_REQUEST,
  PARTNER_GET_ALL_RESET,
  PARTNER_GET_ALL_SUCCESS,
  PARTNER_UPDATE_FAIL,
  PARTNER_UPDATE_REQUEST,
  PARTNER_UPDATE_RESET,
  PARTNER_UPDATE_SUCCESS,
} from "../constants/partnersConstants";

export const addPartnerReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNER_ADD_REQUEST:
      return { loading: true };
    case PARTNER_ADD_SUCCESS:
      return { loading: false, partner: action.payload };
    case PARTNER_ADD_FAIL:
      return { loading: false, error: action.payload };
    case PARTNER_ADD_RESET:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updatePartnerReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNER_UPDATE_REQUEST:
      return { loading: true };
    case PARTNER_UPDATE_SUCCESS:
      return { loading: false, partner: action.payload };
    case PARTNER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case PARTNER_UPDATE_RESET:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deletePartnerReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNER_DELETE_REQUEST:
      return { loading: true };
    case PARTNER_DELETE_SUCCESS:
      return { loading: false, partner: action.payload };
    case PARTNER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case PARTNER_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const getPartnersListReducer = (state = { partners: [] }, action) => {
  switch (action.type) {
    case PARTNER_GET_ALL_REQUEST:
      return { loading: true, partners: state.partners };
    case PARTNER_GET_ALL_SUCCESS:
      return { loading: false, partners: action.payload };
    case PARTNER_GET_ALL_FAIL:
      return { loading: false, error: action.payload };
    case PARTNER_GET_ALL_RESET:
      return { partners: [] };
    default:
      return state;
  }
};
