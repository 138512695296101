import React from "react";
import { useSelector } from "react-redux";
import { BACKEND_IMAGE } from "../utils/apiConfig";

export default function ComparePage() {
  const getCompare = useSelector((state) => state.getCompare);
  const { compares } = getCompare;
  return (
    <main id="content">
      <section className="pb-8 page-title shadow">
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb pt-6 pt-lg-2 lh-15 pb-5">
              <li className="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Compare
              </li>
            </ol>
            <h1 className="fs-30 lh-1 mb-0 text-heading font-weight-600">
              Compare
            </h1>
          </nav>
        </div>
      </section>
      <section className="py-10">
        <div className="container container-xxl">
          <div className="table-responsive-xl">
            <table className="table table-bordered table-hover text-dark table-border-gray table-align-middle table-th-min-width-300">
              <thead className="table-p-6">
                <tr className="bg-gray-03 h-90">
                  <th className="w-25" scope="col"></th>
                  {compares &&
                    compares?.map((listing, index) => (
                      <th key={index} scope="col">
                        <div className="fs-16 font-weight-normal text-dark mb-0">
                          {listing.title}
                        </div>
                      </th>
                    ))}
                </tr>
                <tr>
                  <th scope="col"></th>
                  {compares &&
                    compares?.map((listing, index) => (
                      <th scope="col" key={index}>
                        <div className="card border-0">
                          <div className="rounded-lg">
                            <img
                              className="card-img-top"
                              style={{ height: "200px" }}
                              src={
                                BACKEND_IMAGE + listing?.images.split(",")[0]
                              }
                              alt={listing?.title}
                            />
                          </div>
                          <div className="card-body pt-2 pb-0 px-0">
                            <p className="font-weight-500 text-gray-light mb-0">
                              {listing?.address?.adresse}
                            </p>
                            <p className="fs-17 font-weight-bold text-heading mb-0 lh-16">
                              ${listing?.price?.price}
                            </p>
                          </div>
                        </div>
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody className="text-center table-p-4">
                <tr>
                  <td>Type</td>
                  {compares &&
                    compares?.map((listing, index) => (
                      <td key={index}> {listing?.category} </td>
                    ))}
                </tr>
                <tr>
                  <td>Views</td>
                  {compares &&
                    compares?.map((listing, index) => (
                      <td key={index}> {listing?.nbrViews} </td>
                    ))}
                </tr>
                <tr>
                  <td>Size</td>
                  {compares &&
                    compares?.map((listing, index) => (
                      <td key={index}> {listing?.details?.size} Sqft </td>
                    ))}
                </tr>
                <tr>
                  <td>Land Area</td>
                  {compares &&
                    compares?.map((listing, index) => (
                      <td key={index}> {listing?.details?.lotSize} Sqft </td>
                    ))}
                </tr>
                <tr>
                  <td>Rooms</td>
                  {compares &&
                    compares?.map((listing, index) => (
                      <td key={index}> {listing?.details?.room}</td>
                    ))}
                </tr>
                <tr>
                  <td>Bedrooms</td>
                  {compares &&
                    compares?.map((listing, index) => (
                      <td key={index}> {listing?.details?.bedroom}</td>
                    ))}
                </tr>
                <tr>
                  <td>Bathrooms</td>
                  {compares &&
                    compares?.map((listing, index) => (
                      <td key={index}> {listing?.details?.bathRoom}</td>
                    ))}
                </tr>
                <tr>
                  <td>Garages</td>
                  {compares &&
                    compares?.map((listing, index) => (
                      <td key={index}> {listing?.details?.garage}</td>
                    ))}
                </tr>
                <tr>
                  <td>Garage Size</td>
                  {compares &&
                    compares?.map((listing, index) => (
                      <td key={index}> {listing?.details?.garageSize}</td>
                    ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </main>
  );
}
