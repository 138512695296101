import {
  RECENT_ACTIVITY_GET_ALL_FAIL,
  RECENT_ACTIVITY_GET_ALL_REQUEST,
  RECENT_ACTIVITY_GET_ALL_RESET,
  RECENT_ACTIVITY_GET_ALL_SUCCESS,
} from "../constants/recentActivityConstants";

export const getRecentActivityListReducer = (
  state = { recentActivity: [] },
  action
) => {
  switch (action.type) {
    case RECENT_ACTIVITY_GET_ALL_REQUEST:
      return { loading: true, recentActivity: state.recentActivity };
    case RECENT_ACTIVITY_GET_ALL_SUCCESS:
      return { loading: false, recentActivity: action.payload };
    case RECENT_ACTIVITY_GET_ALL_FAIL:
      return { loading: false, error: action.payload };
    case RECENT_ACTIVITY_GET_ALL_RESET:
      return { amenities: [] };
    default:
      return state;
  }
};
