import {
  COMPARE_ADD_FAIL,
  COMPARE_ADD_REQUEST,
  COMPARE_ADD_RESET,
  COMPARE_ADD_SUCCESS,
  COMPARE_DELETE_FAIL,
  COMPARE_DELETE_REQUEST,
  COMPARE_DELETE_RESET,
  COMPARE_DELETE_SUCCESS,
  COMPARE_GET_ALL_FAIL,
  COMPARE_GET_ALL_REQUEST,
  COMPARE_GET_ALL_RESET,
  COMPARE_GET_ALL_SUCCESS,
} from "../constants/compareConstants";

export const addCompareReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPARE_ADD_REQUEST:
      return { loading: true };
    case COMPARE_ADD_SUCCESS:
      return { loading: false, compare: action.payload };
    case COMPARE_ADD_FAIL:
      return { loading: false, error: action.payload };
    case COMPARE_ADD_RESET:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteCompareReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPARE_DELETE_REQUEST:
      return { loading: true };
    case COMPARE_DELETE_SUCCESS:
      return { loading: false, compare: action.payload };
    case COMPARE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case COMPARE_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const getCompareListReducer = (state = { compares: [] }, action) => {
  switch (action.type) {
    case COMPARE_GET_ALL_REQUEST:
      return { loading: true };
    case COMPARE_GET_ALL_SUCCESS:
      return { loading: false, compares: action.payload };
    case COMPARE_GET_ALL_FAIL:
      return { loading: false, error: action.payload };
    case COMPARE_GET_ALL_RESET:
      return { compares: [] };
    default:
      return state;
  }
};
