export const COMPARE_GET_ALL_REQUEST = "COMPARE_GET_ALL_REQUEST";
export const COMPARE_GET_ALL_SUCCESS = "COMPARE_GET_ALL_SUCCESS";
export const COMPARE_GET_ALL_FAIL = "COMPARE_GET_ALL_FAIL";
export const COMPARE_GET_ALL_RESET = "COMPARE_GET_ALL_RESET";

export const COMPARE_ADD_REQUEST = "COMPARE_ADD_REQUEST";
export const COMPARE_ADD_SUCCESS = "COMPARE_ADD_SUCCESS";
export const COMPARE_ADD_FAIL = "COMPARE_ADD_FAIL";
export const COMPARE_ADD_RESET = "COMPARE_ADD_RESET";

export const COMPARE_DELETE_REQUEST = "COMPARE_DELETE_REQUEST";
export const COMPARE_DELETE_SUCCESS = "COMPARE_DELETE_SUCCESS";
export const COMPARE_DELETE_FAIL = "COMPARE_DELETE_FAIL";
export const COMPARE_DELETE_RESET = "COMPARE_DELETE_RESET";
