import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadScript } from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { userLogout } from "../../actions/userActions";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BACKEND_IMAGE } from "../../utils/apiConfig";

export default function DashboardHeader() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    loadScript("/assets/js/theme.js");
  }, [location]);

  return (
    <>
      <header className="main-header shadow-none shadow-lg-xs-1 bg-white position-relative d-none d-xl-block">
        <div className="container-fluid">
          <nav className="navbar navbar-light py-0 row no-gutters px-3 px-lg-0">
            <div className="col-md-4 px-0 px-md-6 order-1 order-md-0">
              <form>
                <div className="input-group">
                  <div className="input-group-prepend mr-0">
                    <button
                      className="btn border-0 shadow-none fs-20 text-muted p-0"
                      type="submit"
                    >
                      <i className="far fa-search"></i>
                    </button>
                  </div>
                  <input
                    type="text"
                    className="form-control border-0 bg-transparent shadow-none"
                    placeholder="Search for..."
                    name="search"
                  />
                </div>
              </form>
            </div>
            <div className="col-md-6 d-flex flex-wrap justify-content-md-end order-0 order-md-1">
              <div className="dropdown border-md-right border-0 py-3 text-right">
                <a
                  href="#"
                  className="dropdown-toggle text-heading pr-3 pr-sm-6 d-flex align-items-center justify-content-end"
                  data-toggle="dropdown"
                >
                  <div className="mr-4 w-48px">
                    <img
                      src={
                        userInfo?.image
                          ? BACKEND_IMAGE + userInfo?.image
                          : "/assets/images/testimonial-5.jpg"
                      }
                      alt="Ronald Hunter"
                      className="rounded-circle"
                    />
                  </div>

                  <div className="fs-13 font-weight-500 lh-1">
                    {userInfo.firstName + " " + userInfo.lastName}
                  </div>
                </a>
                <div className="dropdown-menu dropdown-menu-right w-100">
                  <Link className="dropdown-item" href="/dashboard/profile">
                    {t("My Profile")}
                  </Link>
                  <div
                    className="dropdown-item"
                    onClick={() => {
                      dispatch(userLogout());
                      navigate("/");
                    }}
                  >
                    {t("Logout")}
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
}
