import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getReviewsActions } from '../../actions/dashboardActions'
import { Link } from 'react-router-dom'
import Pagination from '../../components/utils/Pagination'
import { useTranslation } from 'react-i18next'
const DashboardReviews = () => {
  let stars = ''
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  const getReviews = useSelector(state => state.getReviews)
  const { data } = getReviews

  const [currentPage, setCurrentPage] = useState(1)
  const postsPerPage = 8
  const lastPostIndex = currentPage * postsPerPage
  const firstPostIndex = lastPostIndex - postsPerPage
  const currentReviews = data?.slice(firstPostIndex, lastPostIndex)

  useEffect(() => {
    dispatch(getReviewsActions())
  }, [dispatch])

  const generateStars = num => {
    const stars = []
    for (let i = 0; i < num; i++) {
      stars.push(
        <li key={i} className='list-inline-item mr-1'>
          <span className='text-warning fs-12 lh-2'>
            <i className='fas fa-star'></i>
          </span>
        </li>
      )
    }
    return stars
  }

  // Appeler la fonction pour générer la liste d'étoiles

  return (
    <>
      <main id='content' className='bg-gray-01'>
        <div className='px-3 px-lg-6 px-xxl-13 py-5 py-lg-10'>
          <div className='mb-6'>
            <h2 className='mb-0 text-heading fs-22 lh-15'>
              {t('Reviews')}
              <span className='badge badge-white badge-pill text-primary fs-18 font-weight-bold ml-2'>
                {data && data.length}
              </span>
            </h2>
            <p className='mb-1'>
              {t(
                'Welcome to the Reviews section. Here, you can view all yourcustomer feedback,which will help enhance your products and services based on valuable insights'
              )}
            </p>
          </div>
          <div className='card border-0 mb-4'>
            <div className='card-body p-0 p-sm-8 p-4'>
              <h3 className='fs-16 lh-2 text-heading mb-0 d-inline-block pr-4 border-bottom border-primary'>
                {data && data.length} {t('Reviews')}
              </h3>
              {currentReviews &&
                currentReviews.map(review => (
                  <div className='media border-top pt-7 pb-6 d-sm-flex d-block text-sm-left text-center'>
                    <img
                      src='/assets/images/review-07.jpg'
                      alt='Danny Fox'
                      className='mr-sm-8 mb-4 mb-sm-0'
                    />
                    <div className='media-body'>
                      <div className='row mb-1 align-items-center'>
                        <div className='col-sm-6 mb-2 mb-sm-0'>
                          <h4 className='mb-0 text-heading font-weight-bold fs-15'>
                            {review.user.firstName + ' ' + review.user.lastName}
                          </h4>
                        </div>
                        <div className='col-sm-6'>
                          <ul className='list-inline d-flex justify-content-sm-end justify-content-center mb-0'>
                            {generateStars(review.rating)}
                          </ul>
                        </div>
                      </div>
                      <p className='mb-3 pr-xl-17 fs-15 font-weight-bold '>
                        {review.comment}
                      </p>
                      <div className='d-flex justify-content-sm-start justify-content-center'>
                        <p className='mb-0 text-muted fs-13 lh-1'>
                          {review.createdAt
                            .slice(0, 10)
                            .split('-')
                            .reverse()
                            .join('-') + ' '}
                          at {review.createdAt.slice(11, 19)}
                        </p>
                        <Link
                          to={'/listing/' + review.listing?._id}
                          className='mb-0 text-heading border-left border-dark hover-primary lh-1 ml-2 pl-2'>
                          {review.listing?.title}
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <nav className='mt-6'>
            <Pagination
              totalPosts={data?.length}
              postsPerPage={postsPerPage}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          </nav>
        </div>
      </main>
    </>
  )
}

export default DashboardReviews
