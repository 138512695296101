import axios from 'axios'
import {
  LINKS_ADD_REQUEST,
  LINKS_ADD_RESET,
  LINKS_ADD_SUCCESS,
  LINKS_DELETE_FAIL,
  LINKS_DELETE_REQUEST,
  LINKS_DELETE_SUCCESS,
  LINKS_GET_ALL_FAIL,
  LINKS_GET_ALL_REQUEST,
  LINKS_GET_ALL_SUCCESS,
  LINKS_UPDATE_REQUEST,
  LINKS_UPDATE_RESET,
  LINKS_UPDATE_SUCCESS,
} from '../constants/usefulLinksConstants'
import {
  ADD_Links,
  DELETE_Links,
  GET_LIST_Links,
  UPDATE_Links,
} from '../utils/apiConfig'

export const addLinkAction = link => async (dispatch, getState) => {
  try {
    dispatch({ type: LINKS_ADD_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(ADD_Links, link, config)

    dispatch({
      type: LINKS_ADD_SUCCESS,
      payload: data,
    })
    dispatch(getlistLinksAction())
  } catch (error) {
    dispatch({
      type: LINKS_ADD_RESET,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateLinkAction = (link, id) => async (dispatch, getState) => {
  try {
    dispatch({ type: LINKS_UPDATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(UPDATE_Links(id), link, config)

    dispatch({
      type: LINKS_UPDATE_SUCCESS,
      payload: data,
    })
    dispatch(getlistLinksAction())
  } catch (error) {
    dispatch({
      type: LINKS_UPDATE_RESET,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteLinkAction = link => async (dispatch, getState) => {
  try {
    dispatch({ type: LINKS_DELETE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.delete(DELETE_Links(link), config)

    dispatch({
      type: LINKS_DELETE_SUCCESS,
      payload: data,
    })
    dispatch(getlistLinksAction())
  } catch (error) {
    dispatch({
      type: LINKS_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getlistLinksAction = () => async dispatch => {
  try {
    dispatch({ type: LINKS_GET_ALL_REQUEST })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const { data } = await axios.get(GET_LIST_Links, config)

    dispatch({
      type: LINKS_GET_ALL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: LINKS_GET_ALL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
