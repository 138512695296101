import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfile } from "../../actions/userActions";
import { alertToast } from "../../components/utils/alertHandler";
import { useTranslation } from "react-i18next";
import { BACKEND_IMAGE } from "../../utils/apiConfig";

const DashboardProfile = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { userInfo: updatedUserInfo } = userUpdateProfile;

  const [userData, setuserData] = useState({
    // image: userInfo?.image,
    firstName: userInfo?.firstName,
    lastName: userInfo?.lastName,
    email: userInfo?.email,
    password: "",
    phone: userInfo?.phone,
    image: null,
  });

  const [currentAvatar, setCurrentAvatar] = useState("");

  const submitSaveChanges = () => {
    let formData = new FormData();
    formData.append("firstName", userData.firstName);
    formData.append("lastName", userData.lastName);
    formData.append("email", userData.email);
    formData.append("password", userData.password);
    formData.append("phone", userData.phone);
    formData.append("image", userData.image);
    dispatch(updateUserProfile(formData));
  };

  useEffect(() => {
    if (updatedUserInfo) {
      alertToast("success", "Profile updated succesfully");
    }
  }, [updatedUserInfo]);

  useEffect(() => {
    if (userInfo) {
      setCurrentAvatar(BACKEND_IMAGE + userInfo?.image);
    }
  }, [userInfo]);

  return (
    <>
      <main id="content" className="bg-gray-01">
        <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
          <div className="mb-6">
            <h2 className="mb-0 text-heading fs-22 lh-15">
              {" "}
              {t("My Profile")}
            </h2>
            <p className="mb-1">
              {" "}
              {t("Access and manage your profile account")}
            </p>
          </div>
          <form>
            <div className="row mb-6">
              <div className="col-lg-6">
                <div className="card mb-6">
                  <div className="card-body px-6 pt-6 pb-5">
                    <div className="row">
                      <div className="col-sm-4 col-xl-12 col-xxl-7 mb-6">
                        <h3 className="card-title mb-0 text-heading fs-22 lh-15">
                          {t("Photo")}
                        </h3>
                        <p className="card-text">
                          {t("Upload your profile photo")}
                        </p>
                      </div>
                      <div className="col-sm-8 col-xl-12 col-xxl-5">
                        <img
                          src={
                            currentAvatar
                              ? currentAvatar
                              : "/assets/images/my-profile.png"
                          }
                          alt="My Profile"
                          className="w-50 "
                        />
                        <div className="custom-file mt-4 h-auto">
                          <input
                            type="file"
                            className="custom-file-input"
                            hidden
                            id="customFile"
                            name="file"
                            accept="image/*"
                            onChange={(e) => {
                              setuserData({
                                ...userData,
                                image: e.target.files[0],
                              });
                              if (e.target.files && e.target.files[0]) {
                                const reader = new FileReader();

                                reader.onload = function (e) {
                                  setCurrentAvatar(e.target.result);
                                };

                                reader.readAsDataURL(e.target.files[0]);
                              }
                            }}
                          />
                          <label
                            className="btn btn-secondary btn-lg btn-block"
                            htmlFor="customFile"
                          >
                            <span className="d-inline-block mr-1">
                              <i className="fal fa-cloud-upload"></i>
                            </span>
                            {t("Upload profile image")}
                          </label>
                        </div>
                        <p className="mb-0 mt-2">
                          {" "}
                          {t("*minimum 500px x 500px")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card mb-6">
                  <div className="card-body px-6 pt-6 pb-5">
                    <h3 className="card-title mb-0 text-heading fs-22 lh-15">
                      {t("Contact information")}
                    </h3>
                    <p className="card-text">
                      {t("Please provide your contact information")}
                    </p>
                    <div className="form-row mx-n4">
                      <div className="form-group col-md-6 px-4">
                        <label htmlFor="firstName" className="text-heading">
                          {t("First name")}
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg border-0"
                          id="firstName"
                          name="firsName"
                          value={userData.firstName}
                          onChange={(e) =>
                            setuserData({
                              ...userData,
                              firstName: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="form-group col-md-6 px-4">
                        <label htmlFor="lastName" className="text-heading">
                          {t("Last name")}
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg border-0"
                          id="lastName"
                          name="lastname"
                          value={userData.lastName}
                          onChange={(e) =>
                            setuserData({
                              ...userData,
                              lastName: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="form-row mx-n4">
                      <div className="form-group col-md-6 px-4">
                        <label htmlFor="phone" className="text-heading">
                          {t("Phone")}
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg border-0"
                          id="phone"
                          name="phone"
                          value={userData.phone}
                          onChange={(e) =>
                            setuserData({
                              ...userData,
                              phone: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="form-group col-md-6 px-4 mb-md-0">
                        <label htmlFor="email" className="text-heading">
                          {t("Email")}
                        </label>
                        <input
                          type="email"
                          className="form-control form-control-lg border-0"
                          id="email"
                          name="email"
                          value={userData.email}
                          onChange={(e) =>
                            setuserData({
                              ...userData,
                              email: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className='card mb-6 mb-lg-0'>
                  <div className='card-body px-6 pt-6 pb-5'>
                    <h3 className='card-title mb-0 text-heading fs-22 lh-15'>
                      User detail
                    </h3>
                    <p className='card-text'>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit
                    </p>
                    <div className='form-group mb-0'>
                      <label htmlFor='title' className='text-heading'>
                        Title / Posittion
                      </label>
                      <input
                        type='text'
                        className='form-control form-control-lg border-0'
                        id='title'
                        name='title'
                      />
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="col-lg-6">
                <div className="card">
                  <div className="card-body px-6 pt-6 pb-5">
                    <h3 className="card-title mb-0 text-heading fs-22 lh-15">
                      {t("Change password")}
                    </h3>
                    <p className="card-text"></p>

                    <div className="form-row mx-n4">
                      <div className="form-group col-md-6 col-lg-12 col-xxl-6 px-4">
                        <label htmlFor="newPassword" className="text-heading">
                          {t("New Password")}
                        </label>
                        <input
                          type="password"
                          className="form-control form-control-lg border-0"
                          id="newPassword"
                          name="newPassword"
                          onChange={(e) =>
                            setuserData({
                              ...userData,
                              password: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="form-group col-md-6 col-lg-12 col-xxl-6 px-4">
                        <label
                          htmlFor="confirmNewPassword"
                          className="text-heading"
                        >
                          {t("Confirm New Password")}
                        </label>
                        <input
                          type="password"
                          className="form-control form-control-lg border-0"
                          id="confirmNewPassword"
                          name="confirmNewPassword"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end flex-wrap">
              <button className="btn btn-lg bg-hover-white border rounded-lg mb-3">
                {t("Delete Profile")}
              </button>
              <button
                type="button"
                className="btn btn-lg btn-primary ml-4 mb-3"
                onClick={() => {
                  submitSaveChanges();
                }}
              >
                {t("Update Profile")}
              </button>
            </div>
          </form>
        </div>
      </main>
    </>
  );
};

export default DashboardProfile;
