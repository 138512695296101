import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { getlistAmenitirsAction } from '../actions/amintiesActions'
import { Loader } from '../components/utils/loader'
import { getPublicInfoActions } from '../actions/publicActions'
import ListingCard from '../components/listing/listingCard'
import { useTranslation } from 'react-i18next'
import { addSearchAction } from '../actions/searchActions'
import { BACKEND_IMAGE } from '../utils/apiConfig'
import { HEADER_TYPE } from '../constants/searchConstants'

const LandingPage = () => {
  const navigate = useNavigate()
  const dispatech = useDispatch()
  const location = useLocation()
  const { t, i18n } = useTranslation()
  const [searchParams, setSearchParams] = useState({
    keyword: '',
    price: {
      min: 0,
      max: 80000000,
    },
    areaSize: {
      min: 0,
      max: 15000,
    },
    amenities: [],
    listedIn: '',
    neighborhood: '',
  })

  const getAmenities = useSelector(state => state.getAmenities)
  const { amenities } = getAmenities

  const getPublicInfo = useSelector(state => state.getPublicInfo)
  const { publicInfo } = getPublicInfo

  const headerType = useSelector(state => state.header)

  useEffect(() => {
    dispatech(getlistAmenitirsAction())
    dispatech(getPublicInfoActions())
  }, [dispatech])

  const toSearchPage = () => {
    const price = document.getElementById('price-1-4').value.split(' ')
    let data = searchParams
    data = {
      ...data,
      price: {
        min: price[0].substring(1, price[0].length).replaceAll(',', ''),
        max: price[2].substring(1, price[2].length).replaceAll(',', ''),
      },
    }

    const sizeArea = document.getElementById('area-size-4').value.split(' ')

    data = {
      ...data,
      areaSize: {
        min: sizeArea[0],
        max: sizeArea[3],
      },
    }
    dispatech(addSearchAction({ search: searchParams.keyword }))
    navigate('/listings', { state: data })
  }

  const amenityHandler = id => {
    let newAmenities = searchParams.amenities
    if (!searchParams.amenities.find(amenity => amenity == id)) {
      newAmenities.push(id)
    } else {
      newAmenities = newAmenities.filter(x => x !== id)
    }
    setSearchParams({ ...searchParams, amenities: newAmenities })
  }

  console.log(publicInfo)
  return (
    <>
      {publicInfo && (
        <Helmet>
          <script src='/assets/js/theme.js'></script>
        </Helmet>
      )}
      <main id='content'>
        <section className='d-flex flex-column'>
          <div
            style={{
              backgroundImage:
                "url('" +
                BACKEND_IMAGE +
                publicInfo?.settings
                  ?.filter(setting => setting.key == 'carousel_image')[0]
                  .value?.replace('\\', '/') +
                "')",
            }}
            className='bg-cover d-flex align-items-center custom-vh-100'>
            <div className='container pt-lg-15 py-8' data-animate='zoomIn'>
              <p className='text-white fs-md-22 fs-18 font-weight-500 letter-spacing-367 mb-6 text-center text-uppercase'>
                {t('sub_title')}
              </p>
              <h2 className='text-white display-2 text-center mb-sm-13 mb-8'>
                {t('title')}
              </h2>
              <form className='property-search py-lg-0 z-index-2 position-relative d-none d-lg-block'>
                <div className='row no-gutters'>
                  <div className='col-md-5 col-lg-4 col-xl-3'>
                    <input
                      className='search-field'
                      type='hidden'
                      name='status'
                      value='for-sale'
                      data-default-value=''
                    />
                    <ul className='nav nav-pills property-search-status-tab'>
                      <li
                        className='nav-item bg-secondary rounded-top'
                        role='presentation'
                        onClick={() => {
                          setSearchParams({
                            ...searchParams,
                            listedIn: 'sale',
                          })
                          dispatech({type:HEADER_TYPE, header:"sale"})
                        }}>
                        <a
                          href='#'
                          role='tab'
                          aria-selected={headerType == "sale"}
                          className={
                            headerType === 'sale'
                              ? 'nav-link btn shadow-none rounded-bottom-0 text-white text-btn-focus-secondary text-uppercase d-flex align-items-center fs-13 rounded-bottom-0 bg-active-white text-active-secondary letter-spacing-087 flex-md-1 px-4 py-2 active'
                              : 'nav-link btn shadow-none rounded-bottom-0 text-white text-btn-focus-secondary text-uppercase d-flex align-items-center fs-13 rounded-bottom-0 bg-active-white text-active-secondary letter-spacing-087 flex-md-1 px-4 py-2 '
                          }
                          data-toggle='pill'
                          data-value='for-sale'>
                          <svg className='icon icon-villa fs-22 mr-2'>
                            <use xlinkHref='#icon-villa'></use>
                          </svg>
                          {t('for_sale')}
                        </a>
                      </li>
                      <li
                        className='nav-item bg-secondary rounded-top'
                        role='presentation'>
                        <div
                          role='tab'
                          aria-selected={headerType !== 'sale'}
                          className={
                            headerType !== 'sale'
                              ? 'nav-link btn shadow-none rounded-bottom-0 text-white text-btn-focus-secondary text-uppercase d-flex align-items-center fs-13 rounded-bottom-0 bg-active-white text-active-secondary letter-spacing-087 flex-md-1 px-4 py-2 active'
                              : 'nav-link btn shadow-none rounded-bottom-0 text-white text-btn-focus-secondary text-uppercase d-flex align-items-center fs-13 rounded-bottom-0 bg-active-white text-active-secondary letter-spacing-087 flex-md-1 px-4 py-2 '
                          }
                          data-toggle='pill'
                          data-value='for-rent'
                          onClick={() => {
                            setSearchParams({
                              ...searchParams,
                              listedIn: 'rent',
                            })
                            dispatech({type:HEADER_TYPE, header:"rent"})

                          }}>
                          <svg className='icon icon-building fs-22 mr-2'>
                            <use xlinkHref='#icon-building'></use>
                          </svg>
                          {t('for_rent')}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className='bg-white px-6 rounded-bottom rounded-top-right pb-6 pb-lg-0'>
                  <div className='row align-items-center' id='accordion-4'>
                    <div className='col-md-12 col-lg-7 col-xl-5 pt-6 pt-lg-0 order-2'>
                      <label className='text-uppercase font-weight-500 letter-spacing-093'>
                        {t('search')}
                      </label>
                      <div className='position-relative'>
                        <input
                          type='text'
                          name='search'
                          className='form-control bg-transparent shadow-none border-top-0 border-right-0 border-left-0 border-bottom rounded-0 h-24 lh-17 pl-0 pr-4 font-weight-600 border-color-input placeholder-muted'
                          placeholder={t('find_something') + '...'}
                          value={searchParams.keyword}
                          onChange={e =>
                            setSearchParams({
                              ...searchParams,
                              keyword: e.target.value,
                            })
                          }
                        />
                        <i className='far fa-search position-absolute pos-fixed-right-center pr-0 fs-18 mt-n3'></i>
                      </div>
                    </div>
                    <div className='col-sm pr-lg-0 pt-6 pt-lg-0 order-3'>
                      <a
                        href='#advanced-search-filters-4'
                        className='btn advanced-search btn-accent h-lg-100 w-100 shadow-none text-secondary rounded-0 fs-14 fs-sm-16 font-weight-600 text-left d-flex align-items-center collapsed'
                        data-toggle='collapse'
                        data-target='#advanced-search-filters-4'
                        aria-expanded='true'
                        aria-controls='advanced-search-filters-4'>
                        {t('advanced_search')}
                      </a>
                    </div>
                    <div className='col-sm pt-6 pt-lg-0 order-sm-4 order-5'>
                      <button
                        onClick={() => toSearchPage()}
                        type='button'
                        className='btn btn-primary shadow-none fs-16 font-weight-600 w-100 py-lg-2 lh-213'>
                        {t('search')}
                      </button>
                    </div>
                    <div
                      id='advanced-search-filters-4'
                      className='col-12 pt-4 pb-sm-4 order-sm-5 order-4 collapse'
                      data-parent='#accordion-4'>
                      <div className='row pt-2'>
                        <div className='col-md-6 col-lg-6 pt-6 slider-range slider-range-secondary'>
                          <label
                            htmlFor='price-1-4'
                            className='mb-4 text-gray-light'>
                            Price Range
                          </label>
                          <div
                            data-slider='true'
                            data-slider-options={
                              '{"min":0,"max":'+searchParams.price.max+',"values":[' +
                              searchParams.price.min +
                              ',' +
                              searchParams.price.max +
                              '],"type":"currency"}'
                            }></div>
                          <div className='text-center mt-2'>
                            <input
                              id='price-1-4'
                              type='text'
                              readOnly
                              name='price'
                              className='border-0 amount text-center text-body font-weight-500 bg-transparent'
                            />
                          </div>
                        </div>
                        <div className='col-md-6 col-lg-6 pt-6 slider-range slider-range-secondary'>
                          <label
                            htmlFor='area-size-4'
                            className='mb-4 text-gray-light'>
                            Area Size
                          </label>
                          <div
                            data-slider='true'
                            data-slider-options={
                              '{"min":0,"max":15000,"values":[' +
                              searchParams.areaSize.min +
                              ',' +
                              searchParams.areaSize.max +
                              '],"type":"sqrt"}'
                            }></div>
                          <div className='text-center mt-2'>
                            <input
                              id='area-size-4'
                              type='text'
                              readOnly
                              name='area'
                              className='border-0 amount text-center text-body font-weight-500 bg-transparent'
                            />
                          </div>
                        </div>

                        <div className='col-12 pt-6 pb-2'>
                          <a
                            className='lh-17 d-inline-block other-feature collapsed'
                            data-toggle='collapse'
                            href='#other-feature-4'
                            role='button'
                            aria-expanded='false'
                            aria-controls='other-feature-4'>
                            <span className='fs-15 text-heading font-weight-500 hover-primary'>
                              Other Features
                            </span>
                          </a>
                        </div>
                        <div
                          className='collapse row mx-0 w-100'
                          id='other-feature-4'>
                          {amenities?.map((amenity, index) => (
                            <div
                              key={index}
                              className='col-sm-6 col-md-4 col-lg-3 py-2'>
                              <div className='custom-control custom-checkbox'>
                                <input
                                  type='checkbox'
                                  className='custom-control-input'
                                  id={'check1-' + index}
                                  name='features[]'
                                  onClick={() => amenityHandler(amenity._id)}
                                />
                                <label
                                  className='custom-control-label'
                                  htmlFor={'check1-' + index}>
                                  {amenity.title}
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <form className='property-search property-search-mobile d-lg-none z-index-2 position-relative bg-white rounded mx-md-10'>
                <div
                  className='row align-items-lg-center'
                  id='accordion-4-mobile'>
                  <div className='col-12'>
                    <div className='form-group mb-0 position-relative'>
                      <a
                        href='#advanced-search-filters-4-mobile'
                        className='text-secondary btn advanced-search shadow-none pr-3 pl-0 d-flex align-items-center position-absolute pos-fixed-left-center py-0 h-100 border-right collapsed'
                        data-toggle='collapse'
                        data-target='#advanced-search-filters-4-mobile'
                        aria-expanded='true'
                        aria-controls='advanced-search-filters-4-mobile'></a>
                      <input
                        type='text'
                        className='form-control form-control-lg border shadow-none pr-9 pl-11 bg-white placeholder-muted'
                        name='key-word'
                        placeholder='Search...'
                      />
                      <button
                        type='submit'
                        className='btn position-absolute pos-fixed-right-center p-0 text-heading fs-20 px-3 shadow-none h-100 border-left'>
                        <i className='far fa-search'></i>
                      </button>
                    </div>
                  </div>
                  <div
                    id='advanced-search-filters-4-mobile'
                    className='col-12 pt-2 px-7 collapse'
                    data-parent='#accordion-4-mobile'>
                    <div className='row mx-n2'>
                      <div className='col-sm-6 pt-4 px-2'>
                        <select
                          className='form-control border shadow-none form-control-lg selectpicker bg-transparent'
                          title='Select'
                          data-style='btn-lg py-2 h-52 bg-transparent'
                          name='type'>
                          <option>All status</option>
                          <option>For Rent</option>
                          <option>For Sale</option>
                        </select>
                      </div>
                      <div className='col-sm-6 pt-4 px-2'>
                        <select
                          className='form-control border shadow-none form-control-lg selectpicker bg-transparent'
                          title='All Types'
                          data-style='btn-lg py-2 h-52 bg-transparent'
                          name='type'>
                          <option>Condominium</option>
                          <option>Single-Family Home</option>
                          <option>Townhouse</option>
                          <option>Multi-Family Home</option>
                        </select>
                      </div>
                      <div className='col-sm-6 pt-4 px-2'>
                        <select
                          className='form-control border shadow-none form-control-lg selectpicker bg-transparent'
                          name='bedroom'
                          title='Bedrooms'
                          data-style='btn-lg py-2 h-52 bg-transparent'>
                          <option>All Bedrooms</option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          <option>6</option>
                          <option>7</option>
                          <option>8</option>
                          <option>9</option>
                          <option>10</option>
                        </select>
                      </div>
                      <div className='col-sm-6 pt-4 px-2'>
                        <select
                          className='form-control border shadow-none form-control-lg selectpicker bg-transparent'
                          name='bathrooms'
                          title='Bathrooms'
                          data-style='btn-lg py-2 h-52 bg-transparent'>
                          <option>All Bathrooms</option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          <option>6</option>
                          <option>7</option>
                          <option>8</option>
                          <option>9</option>
                          <option>10</option>
                        </select>
                      </div>
                      <div className='col-sm-6 pt-4 px-2'>
                        <select
                          className='form-control border shadow-none form-control-lg selectpicker bg-transparent'
                          title='All Cities'
                          data-style='btn-lg py-2 h-52 bg-transparent'
                          name='city'>
                          <option>All Cities</option>
                          <option>New York</option>
                          <option>Los Angeles</option>
                          <option>Chicago</option>
                          <option>Houston</option>
                          <option>San Diego</option>
                          <option>Las Vegas</option>
                          <option>Las Vegas</option>
                          <option>Atlanta</option>
                        </select>
                      </div>
                      <div className='col-sm-6 pt-4 px-2'>
                        <select
                          className='form-control border shadow-none form-control-lg selectpicker bg-transparent'
                          name='areas'
                          title='All Areas'
                          data-style='btn-lg py-2 h-52 bg-transparent'>
                          <option>All Areas</option>
                          <option>Albany Park</option>
                          <option>Altgeld Gardens</option>
                          <option>Andersonville</option>
                          <option>Beverly</option>
                          <option>Brickel</option>
                          <option>Central City</option>
                          <option>Coconut Grove</option>
                        </select>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-6 pt-6 slider-range slider-range-secondary'>
                        <label
                          htmlFor='price-4-mobile'
                          className='mb-4 text-white'>
                          Price Range
                        </label>
                        <div
                          data-slider='true'
                          data-slider-options='{"min":0,"max":1000000,"values":[100000,700000],"type":"currency"}'></div>
                        <div className='text-center mt-2'>
                          <input
                            id='price-4-mobile'
                            type='text'
                            readOnly
                            className='border-0 amount text-center bg-transparent font-weight-500'
                            name='price'
                          />
                        </div>
                      </div>
                      <div className='col-md-6 pt-6 slider-range slider-range-secondary'>
                        <label htmlFor='area-size-4-mobile' className='mb-4'>
                          Area Size
                        </label>
                        <div
                          data-slider='true'
                          data-slider-options='{"min":0,"max":15000,"values":[0,12000],"type":"sqrt"}'></div>
                        <div className='text-center mt-2'>
                          <input
                            id='area-size-4-mobile'
                            type='text'
                            readOnly
                            className='border-0 amount text-center bg-transparent font-weight-500'
                            name='area'
                          />
                        </div>
                      </div>
                      <div className='col-12 pt-4 pb-2'>
                        <a
                          className='lh-17 d-inline-block other-feature collapsed'
                          data-toggle='collapse'
                          href='#other-feature-4-mobile'
                          role='button'
                          aria-expanded='false'
                          aria-controls='other-feature-4-mobile'>
                          <span className='fs-15 font-weight-500 hover-primary'>
                            Other Features
                          </span>
                        </a>
                      </div>
                      <div
                        className='collapse row mx-0 w-100'
                        id='other-feature-4-mobile'>
                        <div className='col-sm-6 py-2'>
                          <div className='custom-control custom-checkbox'>
                            <input
                              type='checkbox'
                              className='custom-control-input'
                              name='features[]'
                              id='check1-4-mobile'
                            />
                            <label
                              className='custom-control-label'
                              htmlFor='check1-4-mobile'>
                              Air Conditioning
                            </label>
                          </div>
                        </div>
                        <div className='col-sm-6 py-2'>
                          <div className='custom-control custom-checkbox'>
                            <input
                              type='checkbox'
                              className='custom-control-input'
                              name='features[]'
                              id='check2-4-mobile'
                            />
                            <label
                              className='custom-control-label'
                              htmlFor='check2-4-mobile'>
                              Laundry
                            </label>
                          </div>
                        </div>
                        <div className='col-sm-6 py-2'>
                          <div className='custom-control custom-checkbox'>
                            <input
                              type='checkbox'
                              className='custom-control-input'
                              name='features[]'
                              id='check4-4-mobile'
                            />
                            <label
                              className='custom-control-label'
                              htmlFor='check4-4-mobile'>
                              Washer
                            </label>
                          </div>
                        </div>
                        <div className='col-sm-6 py-2'>
                          <div className='custom-control custom-checkbox'>
                            <input
                              type='checkbox'
                              className='custom-control-input'
                              name='features[]'
                              id='check5-4-mobile'
                            />
                            <label
                              className='custom-control-label'
                              htmlFor='check5-4-mobile'>
                              Barbeque
                            </label>
                          </div>
                        </div>
                        <div className='col-sm-6 py-2'>
                          <div className='custom-control custom-checkbox'>
                            <input
                              type='checkbox'
                              className='custom-control-input'
                              name='features[]'
                              id='check6-4-mobile'
                            />
                            <label
                              className='custom-control-label'
                              htmlFor='check6-4-mobile'>
                              Lawn
                            </label>
                          </div>
                        </div>
                        <div className='col-sm-6 py-2'>
                          <div className='custom-control custom-checkbox'>
                            <input
                              type='checkbox'
                              className='custom-control-input'
                              name='features[]'
                              id='check7-4-mobile'
                            />
                            <label
                              className='custom-control-label'
                              htmlFor='check7-4-mobile'>
                              Sauna
                            </label>
                          </div>
                        </div>
                        <div className='col-sm-6 py-2'>
                          <div className='custom-control custom-checkbox'>
                            <input
                              type='checkbox'
                              className='custom-control-input'
                              name='features[]'
                              id='check8-4-mobile'
                            />
                            <label
                              className='custom-control-label'
                              htmlFor='check8-4-mobile'>
                              WiFi
                            </label>
                          </div>
                        </div>
                        <div className='col-sm-6 py-2'>
                          <div className='custom-control custom-checkbox'>
                            <input
                              type='checkbox'
                              className='custom-control-input'
                              name='features[]'
                              id='check9-4-mobile'
                            />
                            <label
                              className='custom-control-label'
                              htmlFor='check9-4-mobile'>
                              Dryer
                            </label>
                          </div>
                        </div>
                        <div className='col-sm-6 py-2'>
                          <div className='custom-control custom-checkbox'>
                            <input
                              type='checkbox'
                              className='custom-control-input'
                              name='features[]'
                              id='check10-4-mobile'
                            />
                            <label
                              className='custom-control-label'
                              htmlFor='check10-4-mobile'>
                              Microwave
                            </label>
                          </div>
                        </div>
                        <div className='col-sm-6 py-2'>
                          <div className='custom-control custom-checkbox'>
                            <input
                              type='checkbox'
                              className='custom-control-input'
                              name='features[]'
                              id='check11-4-mobile'
                            />
                            <label
                              className='custom-control-label'
                              htmlFor='check11-4-mobile'>
                              Swimming Pool
                            </label>
                          </div>
                        </div>
                        <div className='col-sm-6 py-2'>
                          <div className='custom-control custom-checkbox'>
                            <input
                              type='checkbox'
                              className='custom-control-input'
                              name='features[]'
                              id='check12-4-mobile'
                            />
                            <label
                              className='custom-control-label'
                              htmlFor='check12-4-mobile'>
                              Window Coverings
                            </label>
                          </div>
                        </div>
                        <div className='col-sm-6 py-2'>
                          <div className='custom-control custom-checkbox'>
                            <input
                              type='checkbox'
                              className='custom-control-input'
                              name='features[]'
                              id='check13-4-mobile'
                            />
                            <label
                              className='custom-control-label'
                              htmlFor='check13-4-mobile'>
                              Gym
                            </label>
                          </div>
                        </div>
                        <div className='col-sm-6 py-2'>
                          <div className='custom-control custom-checkbox'>
                            <input
                              type='checkbox'
                              className='custom-control-input'
                              name='features[]'
                              id='check14-4-mobile'
                            />
                            <label
                              className='custom-control-label'
                              htmlFor='check14-4-mobile'>
                              Outdoor Shower
                            </label>
                          </div>
                        </div>
                        <div className='col-sm-6 py-2'>
                          <div className='custom-control custom-checkbox'>
                            <input
                              type='checkbox'
                              className='custom-control-input'
                              name='features[]'
                              id='check15-4-mobile'
                            />
                            <label
                              className='custom-control-label'
                              htmlFor='check15-4-mobile'>
                              TV Cable
                            </label>
                          </div>
                        </div>
                        <div className='col-sm-6 py-2'>
                          <div className='custom-control custom-checkbox'>
                            <input
                              type='checkbox'
                              className='custom-control-input'
                              name='features[]'
                              id='check16-4-mobile'
                            />
                            <label
                              className='custom-control-label'
                              htmlFor='check16-4-mobile'>
                              Refrigerator
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
        <section className='pt-lg-12 pb-lg-10 py-11'>
          <div className='container container-xxl'>
            <div className='row'>
              <div className='col-md-6'>
                <h2 className='text-heading'>{t('best_sale')}</h2>
                <span className='heading-divider'></span>
                <p className='mb-6'>
                  {
                    publicInfo?.settings?.filter(
                      setting =>
                        (i18n.language == 'en' && setting.key == 'sec1_desc') ||
                        (i18n.language == 'fr' && setting.key == 'sec1_desc_fr')
                    )[0].value
                  }
                </p>
              </div>
              <div className='col-md-6 text-md-right'>
                <div
                  onClick={() =>
                    navigate('/listings', {
                      state: {
                        ...searchParams,
                        keyword: '',
                        listedIn: 'sale',
                        amenities: [],
                      },
                    })
                  }
                  className='btn fs-14 text-secondary btn-accent py-3 lh-15 px-7 mb-6 mb-lg-0'>
                  {t('see_all_prep')}
                  <i className='far fa-long-arrow-right ml-1'></i>
                </div>
              </div>
            </div>
            {!publicInfo || !publicInfo?.listingSale ? (
              <Loader />
            ) : (
              <div
                className='slick-slider slick-dots-mt-0 custom-arrow-spacing-30'
                data-slick-options='{"slidesToShow": 4, "autoplay":true,"dots":true,"responsive":[{"breakpoint": 1600,"settings": {"slidesToShow":3,"arrows":false}},{"breakpoint": 992,"settings": {"slidesToShow":2,"arrows":false}},{"breakpoint": 768,"settings": {"slidesToShow": 2,"arrows":false,"dots":true,"autoplay":true}},{"breakpoint": 576,"settings": {"slidesToShow": 1,"arrows":false,"dots":true,"autoplay":true}}]}'>
                {publicInfo?.listingSale.map((listing, index) => (
                  <ListingCard key={index} listing={listing} />
                ))}
              </div>
            )}
          </div>
        </section>
        <section>
          <div className='bg-gray-02 py-lg-13 pt-11 pb-6'>
            <div className='container container-xxl'>
              <div className='row'>
                <div className='col-lg-4 pr-xl-13' data-animate='fadeInLeft'>
                  <h2 className='text-heading lh-1625'>
                    {t('explore_by_type')}
                  </h2>
                  <span className='heading-divider'></span>
                  <p className='mb-6'>
                    {
                      publicInfo?.settings?.filter(
                        setting =>
                          (i18n.language == 'en' &&
                            setting.key == 'sec2_desc') ||
                          (i18n.language == 'fr' &&
                            setting.key == 'sec2_desc_fr')
                      )[0].value
                    }
                  </p>
                  <Link
                    to='/listings'
                    className='btn btn-lg text-secondary btn-accent'>
                    {t('see_all_prep')}
                    <i className='far fa-long-arrow-right ml-1'></i>
                  </Link>
                </div>
                <div className='col-lg-8' data-animate='fadeInRight'>
                  <div
                    className='slick-slider arrow-haft-inner custom-arrow-xxl-hide mx-0'
                    data-slick-options='{"slidesToShow": 4, "autoplay":true,"dots":false,"responsive":[{"breakpoint": 1200,"settings": {"slidesToShow":3,"arrows":false}},{"breakpoint": 992,"settings": {"slidesToShow":3,"arrows":false}},{"breakpoint": 768,"settings": {"slidesToShow": 3,"arrows":false,"autoplay":true}},{"breakpoint": 576,"settings": {"slidesToShow": 2,"arrows":false,"autoplay":true}}]}'>
                    <div className='box px-0 py-6'>
                      <div
                        onClick={() =>
                          navigate('/listings', {
                            state: {
                              ...searchParams,
                              keyword: '',
                              category: 'house',
                              amenities: [],
                            },
                          })
                        }
                        className='card border-0 align-items-center justify-content-center pt-7 pb-5 px-3 shadow-hover-3 bg-transparent bg-hover-white text-decoration-none'>
                        <img
                          src='/assets/images/sofa.png'
                          className='card-img-top'
                          alt='House'
                        />
                        <div className='card-body px-0 pt-5 pb-0'>
                          <h4 className='card-title fs-16 lh-2 text-dark mb-0'>
                            {t('house')}
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className='box px-0 py-6'>
                      <div
                        onClick={() =>
                          navigate('/listings', {
                            state: {
                              ...searchParams,
                              keyword: '',
                              category: 'office',
                              amenities: [],
                            },
                          })
                        }
                        className='card border-0 align-items-center justify-content-center pt-7 pb-5 px-3 shadow-hover-3 bg-transparent bg-hover-white text-decoration-none'>
                        <img
                          src='/assets/images/architecture-and-city.png'
                          className='card-img-top'
                          alt='Office'
                        />
                        <div className='card-body px-0 pt-5 pb-0'>
                          <h4 className='card-title fs-16 lh-2 text-dark mb-0'>
                            {t('office')}
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className='box px-0 py-6'>
                      <div
                        onClick={() =>
                          navigate('/listings', {
                            state: {
                              ...searchParams,
                              keyword: '',
                              category: 'villa',
                              amenities: [],
                            },
                          })
                        }
                        className='card border-0 align-items-center justify-content-center pt-7 pb-5 px-3 shadow-hover-3 bg-transparent bg-hover-white text-decoration-none'>
                        <img
                          src='/assets/images/eco-house.png'
                          className='card-img-top'
                          alt='Villa'
                        />
                        <div className='card-body px-0 pt-5 pb-0'>
                          <h4 className='card-title fs-16 lh-2 text-dark mb-0'>
                            {t('villa')}
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className='box px-0 py-6'>
                      <div
                        onClick={() =>
                          navigate('/listings', {
                            state: {
                              ...searchParams,
                              keyword: '',
                              category: 'apartment',
                              amenities: [],
                            },
                          })
                        }
                        className='card border-0 align-items-center justify-content-center pt-7 pb-5 px-3 shadow-hover-3 bg-transparent bg-hover-white text-decoration-none'>
                        <img
                          src='/assets/images/verified.png'
                          className='card-img-top'
                          alt='Apartment'
                        />
                        <div className='card-body px-0 pt-5 pb-0'>
                          <h4 className='card-title fs-16 lh-2 text-dark mb-0'>
                            {t('apartement')}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='pt-lg-12 pb-lg-11 py-11'>
          <div className='container container-xxl'>
            <div className='row'>
              <div className='col-md-6'>
                <h2 className='text-heading'>{t('best_rent')}</h2>
                <span className='heading-divider'></span>
                <p className='mb-6'>
                  {
                    publicInfo?.settings?.filter(
                      setting =>
                        (i18n.language == 'en' && setting.key == 'sec3_desc') ||
                        (i18n.language == 'fr' && setting.key == 'sec3_desc_fr')
                    )[0].value
                  }
                </p>
              </div>
              <div className='col-md-6 text-md-right'>
                <div
                  onClick={() =>
                    navigate('/listings', {
                      state: {
                        ...searchParams,
                        keyword: '',
                        listedIn: 'rent',
                        amenities: [],
                      },
                    })
                  }
                  className='btn fs-14 text-secondary btn-accent py-3 lh-15 px-7 mb-6 mb-lg-0'>
                  {t('see_all_prep')}
                  <i className='far fa-long-arrow-right ml-1'></i>
                </div>
              </div>
            </div>
            {!publicInfo || !publicInfo?.listingRent ? (
              <Loader />
            ) : (
              <div
                className='slick-slider slick-dots-mt-0 custom-arrow-spacing-30'
                data-slick-options='{"slidesToShow": 4, "autoplay":true,"dots":true,"responsive":[{"breakpoint": 1600,"settings": {"slidesToShow":3,"arrows":false}},{"breakpoint": 992,"settings": {"slidesToShow":2,"arrows":false}},{"breakpoint": 768,"settings": {"slidesToShow": 2,"arrows":false,"dots":true,"autoplay":true}},{"breakpoint": 576,"settings": {"slidesToShow": 1,"arrows":false,"dots":true,"autoplay":true}}]}'>
                {publicInfo?.listingRent.map((listing, index) => (
                  <ListingCard key={index} listing={listing} />
                ))}
              </div>
            )}
          </div>
        </section>
        <section>
          <div
            className='bg-single-image pt-lg-13 pb-lg-12 py-11'
            style={{ backgroundColor: '#1e1d85' }}>
            <div className='container container-xxl'>
              <div className='row align-items-center'>
                <div
                  className='col-lg-6 pr-xl-8 pb-lg-0 pb-6'
                  data-animate='fadeInLeft'>
                  <a href='#' className='hover-shine d-block'>
                    <img
                      src='/assets/images/single-image-01.jpg'
                      className='rounded-lg w-100'
                      alt='Find your neighborhood'
                    />
                  </a>
                </div>
                <div className='col-lg-6 pl-xl-8' data-animate='fadeInRight'>
                  <h2 className='text-white lh-1625'>
                    {t('find_neighborhood')}
                  </h2>
                  {/* <span className='heading-divider'></span> */}
                  <p className='mb-6 text-white'>
                    {
                      publicInfo?.settings?.filter(
                        setting =>
                          (i18n.language == 'en' &&
                            setting.key == 'sec4_desc') ||
                          (i18n.language == 'fr' &&
                            setting.key == 'sec4_desc_fr')
                      )[0].value
                    }
                  </p>
                  <div className='input-group input-group-lg pr-sm-17'>
                    <input
                      type='text'
                      className='form-control fs-13 font-weight-500 text-gray-light rounded-lg rounded-right-0 border-0 shadow-none h-52 bg-white'
                      name='search'
                      placeholder='Enter an address, neighbourhood'
                      onChange={e =>
                        setSearchParams({
                          ...searchParams,
                          neighborhood: e.target.value,
                        })
                      }
                    />
                    <button
                      onClick={() =>
                        navigate('/listings', {
                          state: {
                            ...searchParams,
                            keyword: '',
                            listedIn: '',
                            amenities: [],
                          },
                        })
                      }
                      type='button'
                      className='btn btn-primary fs-18 rounded-left-0 rounded-lg px-6 border-0'>
                      <i className='far fa-search'></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className=' pt-10 pb-lg-11 pb-8 bg-patten-04'
          style={{ color: '#0ca5b1', backgroundColor: '#fbfbfb' }}>
          <div className='container container-xxl'>
            <h2 className='text-dark text-center mxw-751 fs-26 lh-184 px-md-8'>
              {
                publicInfo?.settings?.filter(
                  setting =>
                    (i18n.language == 'en' && setting.key == 'sec5_title') ||
                    (i18n.language == 'fr' && setting.key == 'sec5_title_fr')
                )[0].value
              }
            </h2>
            <span className='heading-divider mx-auto'></span>
            <div className='row mt-8'>
              <div className='col-lg-4 mb-6 mb-lg-0' data-animate='zoomIn'>
                <div className='card border-hover shadow-1 shadow-hover-lg-1 pl-5 pr-6 py-6 h-100 hover-change-image'>
                  <div className='row no-gutters'>
                    <div className='col-sm-3'>
                      <img
                        src='/assets/images/group-16.png'
                        alt='Buy a new home'
                      />
                    </div>
                    <div className='col-sm-9'>
                      <div className='card-body p-0 pl-0 pl-sm-5 pt-5 pt-sm-0'>
                        <a
                          href='single-property-1.html'
                          className='d-flex align-items-center text-dark hover-secondary'>
                          <h4 className='fs-20 lh-1625 mb-1'>
                            {
                              publicInfo?.settings?.filter(
                                setting =>
                                  (i18n.language == 'en' &&
                                    setting.key == 'sec5_item1_title') ||
                                  (i18n.language == 'fr' &&
                                    setting.key == 'sec5_item1_title_fr')
                              )[0].value
                            }
                          </h4>
                          <span className='ml-2 text-primary fs-42 lh-1 hover-image d-inline-flex align-items-center'>
                            <svg className='icon icon-long-arrow'>
                              <use xlinkHref='#icon-long-arrow'></use>
                            </svg>
                          </span>
                        </a>
                        <p className='mb-0'>
                          {
                            publicInfo?.settings?.filter(
                              setting =>
                                (i18n.language == 'en' &&
                                  setting.key == 'sec5_item1_desc') ||
                                (i18n.language == 'fr' &&
                                  setting.key == 'sec5_item1_desc_fr')
                            )[0].value
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 mb-6 mb-lg-0' data-animate='zoomIn'>
                <div className='card border-hover shadow-1 shadow-hover-lg-1 pl-5 pr-6 py-6 h-100 hover-change-image'>
                  <div className='row no-gutters'>
                    <div className='col-sm-3'>
                      <img
                        src='/assets/images/group-17.png'
                        alt='Sell a home'
                      />
                    </div>
                    <div className='col-sm-9'>
                      <div className='card-body p-0 pl-0 pl-sm-5 pt-5 pt-sm-0'>
                        <a
                          href='single-property-1.html'
                          className='d-flex align-items-center text-dark hover-secondary'>
                          <h4 className='fs-20 lh-1625 mb-1'>
                            {' '}
                            {
                              publicInfo?.settings?.filter(
                                setting =>
                                  (i18n.language == 'en' &&
                                    setting.key == 'sec5_item2_title') ||
                                  (i18n.language == 'fr' &&
                                    setting.key == 'sec5_item2_title_fr')
                              )[0].value
                            }
                          </h4>
                          <span className='ml-2 text-primary fs-42 lh-1 hover-image d-inline-flex align-items-center'>
                            <svg className='icon icon-long-arrow'>
                              <use xlinkHref='#icon-long-arrow'></use>
                            </svg>
                          </span>
                        </a>
                        <p className='mb-0'>
                          {
                            publicInfo?.settings?.filter(
                              setting =>
                                (i18n.language == 'en' &&
                                  setting.key == 'sec5_item2_desc') ||
                                (i18n.language == 'fr' &&
                                  setting.key == 'sec5_item2_desc_fr')
                            )[0].value
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 mb-6 mb-lg-0' data-animate='zoomIn'>
                <div className='card border-hover shadow-1 shadow-hover-lg-1 pl-5 pr-6 py-6 h-100 hover-change-image'>
                  <div className='row no-gutters'>
                    <div className='col-sm-3'>
                      <img
                        src='/assets/images/group-21.png'
                        alt='Rent a home'
                      />
                    </div>
                    <div className='col-sm-9'>
                      <div className='card-body p-0 pl-0 pl-sm-5 pt-5 pt-sm-0'>
                        <a
                          href='single-property-1.html'
                          className='d-flex align-items-center text-dark hover-secondary'>
                          <h4 className='fs-20 lh-1625 mb-1'>
                            {' '}
                            {
                              publicInfo?.settings?.filter(
                                setting =>
                                  (i18n.language == 'en' &&
                                    setting.key == 'sec5_item3_title') ||
                                  (i18n.language == 'fr' &&
                                    setting.key == 'sec5_item3_title_fr')
                              )[0].value
                            }{' '}
                          </h4>
                          <span className='ml-2 text-primary fs-42 lh-1 hover-image d-inline-flex align-items-center'>
                            <svg className='icon icon-long-arrow'>
                              <use xlinkHref='#icon-long-arrow'></use>
                            </svg>
                          </span>
                        </a>
                        <p className='mb-0'>
                          {
                            publicInfo?.settings?.filter(
                              setting =>
                                (i18n.language == 'en' &&
                                  setting.key == 'sec5_item3_desc') ||
                                (i18n.language == 'fr' &&
                                  setting.key == 'sec5_item3_desc_fr')
                            )[0].value
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {publicInfo && publicInfo.partners?.length > 0 ? (
          <section>
            <div className='container container-xxl'>
              <div className='py-lg-8 py-6 border-top'>
                <div
                  className='slick-slider mx-0 partners'
                  data-slick-options='{"slidesToShow": 6, "autoplay":true,"dots":false,"arrows":false,"responsive":[{"breakpoint": 1200,"settings": {"slidesToShow":4}},{"breakpoint": 992,"settings": {"slidesToShow":3}},{"breakpoint": 768,"settings": {"slidesToShow": 3}},{"breakpoint": 576,"settings": {"slidesToShow": 2}}]}'>
                  {publicInfo.partners.map((partner, index) => (
                    <div
                      key={index}
                      className='box d-flex align-items-center justify-content-center'
                      data-animate='fadeInUp'>
                      <a
                        target='_blank'
                        href={partner.url}
                        rel='noreferrer'
                        className='item position-relative hover-change-image'>
                       
                        <img
                          style={{ width: '100%', height: '100%' }}
                          src={BACKEND_IMAGE + partner.image}
                          alt='Partner 1'
                          className='image'
                        />
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        ) : (
          ''
        )}
      </main>
    </>
  )
}

export default LandingPage
