import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { addSellAction } from '../actions/sellAction'
import { alertToast } from '../components/utils/alertHandler'
import { SELL_ADD_RESET } from '../constants/sellConstants'
import { getlistPdfsAction } from '../actions/pdfActions'
import { BACKEND_IMAGE } from '../utils/apiConfig'
import { getlistSettingsAction } from '../actions/settingsActions'

const Sell = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const addSell = useSelector(state => state.addSell)
  const { data } = addSell

  const [sell, setSell] = useState({
    firstName: userInfo?.firstName,
    lastName: userInfo?.lastName,
    phone: userInfo?.phone,
    email: userInfo?.email,
    message: '',
  })

  useEffect(() => {
    if (data) {
      alertToast('success', 'Sell request has added succesfully')
      dispatch({ type: SELL_ADD_RESET })
      setSell({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        message: '',
      })
    }
  }, [data, dispatch])

  const submitSellHandler = () => {
    dispatch(addSellAction(sell))
  }

  const getPDFsList = useSelector(state => state.getPDFsList)
  const { pdfs } = getPDFsList

  const getSettingsList = useSelector(state => state.getSettingsList)
  const { settings } = getSettingsList

  useEffect(() => {
    dispatch(getlistPdfsAction())
    dispatch(getlistSettingsAction())
  }, [dispatch])

  return (
    <>
      <main id='content'>
        <section className='pt-10 '>
          <div className=' rounded-0 pt-9 pb-12 px-0 container'>
            <div>
              <div className=''>
                <div className='card border-0 bg-transparent'>
                  <div className='collapse show collapsible'>
                    <div className='row'>
                      <div className='col-md-6 mb-6 mb-md-0'>
                        <div className='card border-0 pl-lg-6'>
                          <img
                            src='/assets/images/faqs.jpg'
                            alt='FAQs'
                            className='card-img'
                          />
                        </div>
                      </div>
                      <div class='col-md-6 mb-0 mb-md-0'>
                        <h2 class='fs-32 lh-16 mb-0 text-dark text-center'>
                          {t('Entrust us with the sale of your property')}
                        </h2>
                        <p className='fs-20 lh-16  mb-7  '>
                          {t('sell page p')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class='bg-patten-05 pt-7'>
          <div class='container'>
            <div class='card mt-n13 z-index-3 pt-10 border-0'>
              <div class='card-body p-0'>
                <h2 class='text-dark lh-1625 text-center mb-2'>
                  {t('The game plan for selling with Our plateform')}
                </h2>
              </div>
            </div>
            <div className='row mb-9'>
              <div className='col-sm-6 col-lg-4 mb-6'>
                <div className='card border-hover shadow-hover-lg-1 px-7 pb-6 pt-4 h-100 bg-transparent bg-hover-white'>
                  <div className='card-img-top d-flex align-items-end justify-content-center'>
                    <span className='text-primary fs-90 lh-1'>
                      <svg className='icon icon-e1'>
                        <use xlinkHref='#icon-e1'></use>
                      </svg>
                    </span>
                  </div>
                  <div class='card-body px-0 pt-6 pb-0 text-center'>
                    <h4 class='card-title fs-18 lh-17 text-dark mb-2'>
                      {t('ON YOUR SIDE')}
                    </h4>
                    <p className='card-text px-2'>
                      {
                        settings?.filter(
                          setting =>
                            (i18n.language == 'en' &&
                              setting.key == 'sell_desc_on_your_side') ||
                            (i18n.language == 'fr' &&
                              setting.key == 'sell_desc_on_your_side_fr')
                        )[0]?.value
                      }
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-sm-6 col-lg-4 mb-6'>
                <div className='card border-hover shadow-hover-lg-1 px-7 pb-6 pt-4 h-100 bg-transparent bg-hover-white'>
                  <div className='card-img-top d-flex align-items-end justify-content-center'>
                    <span className='text-primary fs-90 lh-1'>
                      <svg className='icon icon-e2'>
                        <use xlinkHref='#icon-e2'></use>
                      </svg>
                    </span>
                  </div>
                  <div class='card-body px-0 pt-6 pb-0 text-center'>
                    <h4 class='card-title fs-18 lh-17 text-dark mb-2'>
                      {t('ON OUR SIDE')}
                    </h4>
                    <p className='card-text px-2'>
                      {
                        settings?.filter(
                          setting =>
                            (i18n.language == 'en' &&
                              setting.key == 'sell_desc_on_our_side') ||
                            (i18n.language == 'fr' &&
                              setting.key == 'sell_desc_on_our_side_fr')
                        )[0]?.value
                      }
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-sm-6 col-lg-4 mb-6'>
                <div className='card border-hover shadow-hover-lg-1 px-7 pb-6 pt-4 h-100 bg-transparent bg-hover-white'>
                  <div className='card-img-top d-flex align-items-end justify-content-center'>
                    <span className='text-primary fs-90 lh-1'>
                      <svg className='icon icon-e3'>
                        <use xlinkHref='#icon-e3'></use>
                      </svg>
                    </span>
                  </div>
                  <div class='card-body px-0 pt-6 text-center pb-0'>
                    <h4 class='card-title fs-18 lh-17 text-dark mb-2'>
                      {t('THE MARKET')}
                    </h4>
                    <p className='card-text px-2'>
                      {
                        settings?.filter(
                          setting =>
                            (i18n.language == 'en' &&
                              setting.key == 'sell_desc_the_market') ||
                            (i18n.language == 'fr' &&
                              setting.key == 'sell_desc_the_market_fr')
                        )[0]?.value
                      }
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className=''>
          <div className='collapse-tabs'>
            <div className='tab-content shadow-none rounded-0 pt-8 pt-md-7 pb-10 pb-md-12 px-0 bg-gray-01'>
              <div id='collapse-tabs-accordion-01'>
                <div
                  class='tab-pane tab-pane-parent fade show active container '
                  id='selling1'
                  role='tabpanel'>
                  <div class='card border-0 bg-transparent'>
                    <h3 className='text-center mb-7'>
                      {' '}
                      {t('Documents to download')}
                    </h3>
                    <div
                      id='selling-collapse-01'
                      className='collapse show collapsible'
                      aria-labelledby='headingSelling-01'
                      data-parent='#collapse-tabs-accordion-01'>
                      {/* <div className=' row my-7 my-md-0 mx-3 mx-md-0'>
                        <div className='col-md-6'>
                          {pdfs &&
                            pdfs?.map((pdf, index) => (
                              <div
                                key={index}
                                style={{ padding: 0 }}
                                className=' card border-2 border-dark shadow-xxs-2 mb-6 rounded-top overflow-hidden'>
                                <div
                                  className='card-header border-0 p-0'
                                  id='heading_2'>
                                  <h5 className='mb-0'>
                                    <a
                                      className='btn d-flex justify-content-between font-weight-500 pl-6 pr-7 py-3 fs-16 position-relative w-100 text-left rounded-0 collapsed'
                                      href={BACKEND_IMAGE + pdf.pdf}
                                      rel='noreferrer'
                                      target='_blank'>
                                      {pdf.title}
                                      <i className='far fa-file-pdf fa-2x '></i>
                                    </a>
                                  </h5>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div> */}
                      <div className='row my-7 my-md-0 mx-3 mx-md-0'>
                        {pdfs &&
                          pdfs?.map((pdf, index) => (
                            <div className='col-md-6' key={index}>
                              <div
                                style={{ padding: 0 }}
                                className='card border-2 border-dark shadow-xxs-2 mb-6 rounded-top overflow-hidden'>
                                <div
                                  className='card-header border-0 p-0'
                                  id={`heading_${index}`}>
                                  <h5 className='mb-0'>
                                    <a
                                      className='btn d-flex justify-content-between font-weight-500 pl-6 pr-7 py-3 fs-16 position-relative w-100 text-left rounded-0 collapsed'
                                      href={BACKEND_IMAGE + pdf.pdf}
                                      rel='noreferrer'
                                      target='_blank'>
                                      {pdf.title}
                                      <i className='far fa-file-pdf fa-2x'></i>
                                    </a>
                                  </h5>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='mt-10'>
          <div class='container'>
            <div class='card border-0 mt-n13 z-index-3 pb-8 pt-10'>
              <div class='card-body p-0'>
                <h2 class='text-heading mb-2 fs-22 fs-md-32 text-center lh-16'>
                  {t('Get an estimate for your property')} !
                </h2>
                <p class='text-center mxw-670 mb-8'>
                  {t(
                    'The sale of a property begins with the identification of its market value. To help your project succeed, let our team of real estate experts evaluate its real value and then guide you through all the steps of its sale'
                  )}
                </p>
                <form className='mxw-751 px-md-5'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <input
                          type='text'
                          placeholder={t('First name')}
                          class='form-control form-control-lg border-0'
                          name='first-name'
                          value={sell.firstName}
                          onChange={e =>
                            setSell({
                              ...sell,
                              firstName: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <input
                          type='text'
                          placeholder={t('Last name')}
                          name='last-name'
                          class='form-control form-control-lg border-0'
                          value={sell.lastName}
                          onChange={e =>
                            setSell({
                              ...sell,
                              lastName: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <input
                          placeholder={t('Email')}
                          class='form-control form-control-lg border-0'
                          type='email'
                          name='email'
                          value={sell.email}
                          onChange={e =>
                            setSell({
                              ...sell,
                              email: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <input
                          type='text'
                          placeholder={t('Phone')}
                          name='phone'
                          class='form-control form-control-lg border-0'
                          value={sell.phone}
                          onChange={e =>
                            setSell({
                              ...sell,
                              phone: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className='form-group mb-6'>
                    <textarea
                      class='form-control border-0'
                      placeholder='Message'
                      name='message'
                      rows='5'
                      value={sell.message}
                      onChange={e =>
                        setSell({
                          ...sell,
                          message: e.target.value,
                        })
                      }></textarea>
                  </div>
                  <div class='text-center'>
                    <button
                      type='button'
                      class='btn btn-lg btn-primary px-9'
                      onClick={() => {
                        submitSellHandler()
                      }}>
                      {t('Submit')}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default Sell
