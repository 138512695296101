import axios from "axios";
import {
  SETTINGS_GET_ALL_FAIL,
  SETTINGS_GET_ALL_REQUEST,
  SETTINGS_GET_ALL_SUCCESS,
  SETTINGS_UPDATE_REQUEST,
  SETTINGS_UPDATE_RESET,
  SETTINGS_UPDATE_SUCCESS,
} from "../constants/settingsConstants";
import { GET_SETTNGS, UPDATE_SETTNGS } from "../utils/apiConfig";

export const updateSettingsAction =
  (settings) => async (dispatch, getState) => {
    try {
      dispatch({ type: SETTINGS_UPDATE_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(UPDATE_SETTNGS, settings, config);

      dispatch({
        type: SETTINGS_UPDATE_SUCCESS,
        payload: data,
      });
      dispatch(getlistSettingsAction());
    } catch (error) {
      dispatch({
        type: SETTINGS_UPDATE_RESET,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getlistSettingsAction = () => async (dispatch) => {
  try {
    dispatch({ type: SETTINGS_GET_ALL_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(GET_SETTNGS, config);

    dispatch({
      type: SETTINGS_GET_ALL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SETTINGS_GET_ALL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
