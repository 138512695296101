import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { getWishListActions } from '../../actions/listingActions'
import { loadScript } from '../../utils/constants'
import { HashLink } from 'react-router-hash-link'
import { useTranslation, Trans } from 'react-i18next'
import { BACKEND_IMAGE } from '../../utils/apiConfig'

const Header = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const [menuClasses, setmenuClasses] = useState('')
  const userLogin = useSelector(state => state.userLogin)
  const getWishList = useSelector(state => state.getWishList)
  const headerType = useSelector(state => state.header)
  const { wishlists } = getWishList
  const { userInfo } = userLogin

  useEffect(() => {
    if (location.pathname == '/') {
      setmenuClasses(
        'main-header position-absolute fixed-top m-0 navbar-dark header-sticky header-sticky-smart header-mobile-xl'
      )
    } else {
      setmenuClasses(
        'main-header navbar-light header-sticky header-sticky-smart header-mobile-lg'
      )
    }

    loadScript('/assets/js/theme.js')
  }, [location])

  useEffect(() => {
    if (userInfo) {
      dispatch(getWishListActions())
    }
  }, [dispatch, userInfo])

  const getSettingsList = useSelector(state => state.getSettingsList)
  const { settings } = getSettingsList

  const toSearchPage = (listedIn) => {
    navigate('/listings', { state: {listedIn,fromHeader : true} })
  }

  return (
    <header className={menuClasses}>
      <div className='sticky-area'>
        <div className='container container-xxl'>
          <div className='d-flex align-items-center'>
            <nav className='navbar navbar-expand-xl bg-transparent px-0 w-100 w-xl-auto'>
              <HashLink className='navbar-brand mr-7' to='/'>
                <img
                  src={
                    BACKEND_IMAGE +
                    settings?.filter(setting => setting.key == 'logo')[0]?.value
                  }
                  alt='Immoflex'
                  className='normal-logo'
                  style={{ maxWidth: '200px', maxHeight: '100px' }}
                />
                <img
                  src={
                    BACKEND_IMAGE +
                    settings?.filter(setting => setting.key == 'logo')[0]?.value
                  }
                  alt='immoflex'
                  className='sticky-logo'
                  style={{ maxWidth: '200px', maxHeight: '100px' }}
                />
              </HashLink>
              <a
                className='d-block d-xl-none ml-auto mr-4 position-relative text-white p-2'
                href='#'>
                <i className='fal fa-heart fs-large-4'></i>
                <span className='badge badge-primary badge-circle badge-absolute'>
                  1
                </span>
              </a>
              <button
                className='navbar-toggler border-0 px-0'
                type='button'
                data-toggle='collapse'
                data-target='#primaryMenu02'
                aria-controls='primaryMenu02'
                aria-expanded='false'
                aria-label='Toggle navigation'>
                <span className='text-white fs-24'>
                  <i className='fal fa-bars'></i>
                </span>
              </button>
              
              {
                headerType == "sale" ?
                <div
                  className='collapse navbar-collapse mt-3 mt-xl-0'
                  id='primaryMenu02'>
                  <ul className='navbar-nav hover-menu main-menu px-0 mx-xl-n4'>
                    <li className='nav-item dropdown py-2 py-xl-5 px-0 px-xl-4'>
                      <HashLink className='nav-link cursor-pointer  p-0' to="/sell">
                        {t('SELL')}
                        <span className='caret'></span>
                      </HashLink>
                    </li>
                    <li className='active nav-item dropdown py-2 py-xl-5 px-0 px-xl-4'>
                      <a className='nav-link  p-0' role="button" onClick={() => {toSearchPage("sale")}}>
                        {t('BUY')}
                        <span className='caret'></span>
                      </a>
                    </li>

                    <li className='nav-item dropdown py-2 py-xl-5 px-0 px-xl-4'>
                      <HashLink className='nav-link  p-0' to='/useful-links'>
                        {t('Useful links')}
                        <span className='caret'></span>
                      </HashLink>
                    </li>
                    <li
                      id='navbar-item-pages'
                      aria-haspopup='true'
                      aria-expanded='false'
                      className='nav-item dropdown py-2 py-xl-5 px-0 px-xl-4'>
                      <a
                        target='_blank'
                        className='nav-link dropdown-toggle p-0'
                        href='https://extranet.tal.gouv.qc.ca/internet/asp/Outildecalcul/Calcul.asp'>
                        {t('Calculating tools')}

                        <span className='caret'></span>
                      </a>
                      <ul
                        className='dropdown-menu pt-3 pb-0 pb-lg-3'
                        aria-labelledby='navbar-item-pages'>
                        <li className='dropdown-item'>
                          <a
                            href='https://extranet.tal.gouv.qc.ca/internet/asp/Outildecalcul/Calcul.asp'
                            className='dropdown-link '
                            role='button'
                            // data-toggle='modal'
                            target='_blank'>
                            Calcule Hypo
                          </a>
                        </li>

                        <li className='dropdown-item'>
                          <div
                            className='dropdown-link '
                            role='button'
                            data-toggle='modal'
                            href='#calculMutation-modal'>
                            Calcule Mutation
                          </div>
                        </li>
                        <li className='dropdown-item'>
                          <a
                            target='_blank'
                            className='dropdown-link '
                            role='button'
                            href='https://extranet.tal.gouv.qc.ca/internet/asp/Outildecalcul/Calcul.asp'>
                            Calcul augmentation de loyer
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <div className='d-block d-xl-none'>
                    <ul className='navbar-nav flex-row ml-auto align-items-center justify-content-lg-end flex-wrap py-2'>
                      <li className='nav-item dropdown'>
                        <a
                          className='nav-link dropdown-toggle mr-md-2 pr-2 pl-0 pl-lg-2'
                          href='#'
                          id='bd-versions-mobile'
                          data-toggle='dropdown'
                          aria-haspopup='true'
                          aria-expanded='false'>
                          {i18n.language}
                        </a>
                        <div
                          className='dropdown-menu dropdown-sm dropdown-menu-left'
                          aria-labelledby='bd-versions-mobile'>
                          <a
                            className='dropdown-item active'
                            href='#'
                            onClick={() => i18n.changeLanguage('en')}>
                            ENG
                          </a>
                          <a
                            className='dropdown-item'
                            href='#'
                            onClick={() => i18n.changeLanguage('fr')}>
                            FR
                          </a>
                        </div>
                      </li>
                      <li className='divider'></li>
                      <li className='nav-item '>
                        <a
                          className='nav-link pl-3 pr-2'
                          data-toggle='modal'
                          href='#login-register-modal'>
                          {userInfo
                            ? userInfo.firstName + ' ' + userInfo.lastName
                            : 'SIGN IN'}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div> : 
                <div
                className='collapse navbar-collapse mt-3 mt-xl-0'
                id='primaryMenu02'>
                <ul className='navbar-nav hover-menu main-menu px-0 mx-xl-n4'>
                  <li className='nav-item dropdown py-2 py-xl-5 px-0 px-xl-4'>
                    <a className='nav-link cursor-pointer  p-0' role="button" to='#' onClick={() => {toSearchPage("rent")}}>
                      {t('Rent')}
                      <span className='caret'></span>
                    </a>
                  </li>
                </ul>
                <div className='d-block d-xl-none'>
                  <ul className='navbar-nav flex-row ml-auto align-items-center justify-content-lg-end flex-wrap py-2'>
                    <li className='nav-item dropdown'>
                      <a
                        className='nav-link dropdown-toggle mr-md-2 pr-2 pl-0 pl-lg-2'
                        href='#'
                        id='bd-versions-mobile'
                        data-toggle='dropdown'
                        aria-haspopup='true'
                        aria-expanded='false'>
                        {i18n.language}
                      </a>
                      <div
                        className='dropdown-menu dropdown-sm dropdown-menu-left'
                        aria-labelledby='bd-versions-mobile'>
                        <a
                          className='dropdown-item active'
                          href='#'
                          onClick={() => i18n.changeLanguage('en')}>
                          ENG
                        </a>
                        <a
                          className='dropdown-item'
                          href='#'
                          onClick={() => i18n.changeLanguage('fr')}>
                          FR
                        </a>
                      </div>
                    </li>
                    <li className='divider'></li>
                    <li className='nav-item '>
                      <a
                        className='nav-link pl-3 pr-2'
                        data-toggle='modal'
                        href='#login-register-modal'>
                        {userInfo
                          ? userInfo.firstName + ' ' + userInfo.lastName
                          : 'SIGN IN'}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              }
            </nav>
            <div className='ml-auto d-none d-xl-block'>
              <ul className='navbar-nav flex-row ml-auto align-items-center justify-content-lg-end flex-wrap py-2'>
                <li className='nav-item dropdown'>
                  <a
                    className='nav-link dropdown-toggle mr-md-2 pr-2 pl-0 pl-lg-2'
                    href='#'
                    id='bd-versions'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'>
                    {i18n.language.toUpperCase()}
                  </a>
                  <div
                    className='dropdown-menu dropdown-sm dropdown-menu-right'
                    aria-labelledby='bd-versions'>
                    <a
                      className={
                        'dropdown-item ' +
                        (i18n.language == 'en' ? 'active' : '')
                      }
                      href='#'
                      onClick={() => i18n.changeLanguage('en')}>
                      EN
                    </a>
                    <a
                      className={
                        'dropdown-item ' +
                        (i18n.language == 'fr' ? 'active' : '')
                      }
                      href='#'
                      onClick={() => i18n.changeLanguage('fr')}>
                      FR
                    </a>
                  </div>
                </li>

                <li className='divider'></li>
                <li className='nav-item '>
                  {userInfo ? (
                    <HashLink
                      className='nav-link pl-3 pr-2'
                      to='/dashboard#content'>
                      <i className='fal fa-user fs-large-4'></i>
                      <span style={{ fontSize: '20px', marginLeft: '10px' }}>
                        {userInfo.firstName + ' ' + userInfo.lastName}
                      </span>
                    </HashLink>
                  ) : (
                    <a
                      className='nav-link pl-3 pr-2'
                      data-toggle='modal'
                      href='#login-register-modal'>
                      {t('SIGN IN')}
                    </a>
                  )}
                </li>
                <li className='nav-item mr-auto mr-lg-3'>
                  <a className='nav-link px-2 position-relative'>
                    <i className='fal fa-heart fs-large-4'></i>
                    <span className='badge badge-primary badge-circle badge-absolute'>
                      {wishlists?.length}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
