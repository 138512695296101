/* eslint-disable eqeqeq */
import React, { useEffect, useMemo, useState } from 'react'
import Dropzone from 'react-dropzone'
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import { useDispatch, useSelector } from 'react-redux'

import { useTranslation } from 'react-i18next'

import Select from 'react-select'
import {
  categoryList,
  countryList,
  defaulPosition,
  listedInList,
  statusList,
} from '../../utils/constants'
import {
  addListingAction,
  editListingAction,
  getListingByIDActions,
} from '../../actions/listingActions'
import { useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
  addAmenityAction,
  deleteAmenityAction,
  getlistAmenitirsAction,
} from '../../actions/amintiesActions'
import { alertToast } from '../../components/utils/alertHandler'
import {
  LISTING_ADD_RESET,
  LISTING_EDIT_RESET,
} from '../../constants/listingConstants'
import { AMENITY_ADD_RESET } from '../../constants/amenityConstants'
import Swal from 'sweetalert2'
import { BACKEND_IMAGE } from '../../utils/apiConfig'

const DashboardAddProperty = ({ isEdit }) => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()

  const [lefletmap, setLefletmap] = useState(null)

  const setMap = map => {
    console.log(map)
    console.log('map')
    setLefletmap(map)
  }

  const getListingByID = useSelector(state => state.getListingByID)
  const { listing, error: listingError } = getListingByID
  const getAmenities = useSelector(state => state.getAmenities)
  const { amenities } = getAmenities

  const editListing = useSelector(state => state.editListing)
  const { listing: editedListing } = editListing

  const addListing = useSelector(state => state.addListing)
  const { listing: addedListing , error } = addListing

  const addAmenty = useSelector(state => state.addAmenty)
  const { amenity } = addAmenty

  const [listingData, setListingData] = useState(
    isEdit && id
      ? { ...listing, images: [], floorImages: [], oldImages: listing?.images?.split(','), oldfloorImages: listing?.floorImages?.split(',')}
      : {
          title: '',
          description: '',
          listedIn: '',
          category: '',
          status: false,
          amenities: [],
          images: [],
          floorImages: [],
          price: {
            price: 0,
            land: 0,
            building: 0,
            municipalTaxes: 0,
            schoolTaxes: 0,
            insurance: 0,
            energyCost: 0,
            residential: 0,
            coOwnerShipFees: 0,
          },
          virtualToolUrl: '',
          videoUrl: '',
          address: {
            adresse: '',
            city: '',
            neighborhood: '',
            zip: '',
            country: '',
          },
          details: {
            size: 0,
            lotSize: 0,
            room: 0,
            bedroom: 0,

            bathRoom: 0,

            garage: 0,

            garageSize:0,

            yearBuilt: '',
            basement: 0,
            roofing: 0,
            floorsNumber: 0,
          },
        }
  )

  const [amenty, setAmenty] = useState({
    title:'',
    icon: null
  })

  const [position, setPosition] = useState({
    long: -115.1958723,
    lat: 61.2450318,
  })

  const deleteHandler = (id) => {
    Swal.fire({
      title: t('DELETE_TITLE'),
      text: t("DELETE_MESSAGE"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteAmenityAction(id));
        Swal.fire("Deleted!", t("deleted_message"), "success");
      }
    });
  };

  useEffect(() => {
    if (editedListing) {
      alertToast('success', 'Listing edited succesfully')
      dispatch({
        type: LISTING_EDIT_RESET,
      })
      setListingData({
        title: '',
        description: '',
        listedIn: '',
        category: '',
        status: false,
        amenities: [],
        images: [],
        floorImages: [],
        price: {
          price: 0,
          land: 0,
          building: 0,
          municipalTaxes: 0,
          schoolTaxes: 0,
          insurance: 0,
          energyCost: 0,
          residential: 0,
          coOwnerShipFees: 0,
        },
        virtualToolUrl: '',
        videoUrl: '',
        address: {
          adresse: '',
          city: '',
          neighborhood: '',
          zip: '',
          country: '',
        },
        details: {
          size: 0,
          lotSize: 0,
          room: 0,
          bedroom: 0,

          bathRoom: 0,

          garage: 0,

          garageSize: '',

          yearBuilt: '',
          basement: '',
          roofing: '',
          floorsNumber: 0,
        },
      })
      navigate('/dashboard/myProperty')
    }
  }, [editedListing])

  useEffect(() => {
    if (addedListing) {
      alertToast('success', 'Listing added succesfully')
      dispatch({
        type: LISTING_ADD_RESET,
      })
      navigate('/dashboard/myProperty')
    }
  }, [addedListing])

  useEffect(() => {
    if (error) {
      alertToast('error', 'Error')
      dispatch({
        type: LISTING_ADD_RESET,
      })
      navigate('/dashboard/myProperty')
    }
  }, [error])

  const resizeLeflet = () => {
    setTimeout(() => {
      lefletmap?.invalidateSize()
    }, 100)
  }

  const eventHandlers = useMemo(
    () => ({
      dragend(e) {
        const cposition = e.target.getLatLng()
        setPosition({
          lat: cposition.lat,
          long: cposition.lng,
        })
      },
    }),
    []
  )

  useEffect(() => {
    if (!amenities || amenities.length === 0) {
      dispatch(getlistAmenitirsAction())
    }
  }, [])

  useEffect(() => {
    if (amenity) {
      alertToast('success', 'Amenties has added succesfully')
      dispatch({ type: AMENITY_ADD_RESET })
      setAmenty({ title: '',icon: null})
      document.getElementById('amenutyModel')?.click()
    }
  }, [amenity, dispatch])

  useEffect(() => {
    if (listingError) {
      navigate('/')
    } else if (listing) {
      setListingData({ ...listing, images: [], floorImages: [], oldImages: listing?.images?.split(','), oldfloorImages: listing?.floorImages?.split(',')})
      setPosition({
        lat: listing?.address.lat,
        long: listing?.address.long,
      })
    }
  }, [listingError, navigate, listing])

  useEffect(() => {
    if (isEdit) {
      dispatch(getListingByIDActions(id))
    }
  }, [id, dispatch, isEdit])

  const submitHandler = () => {
    const formData = new FormData()
    listingData?.images?.forEach(element => {
      formData.append('images', element)
    })

    listingData?.floorImages?.forEach(element => {
      formData.append('floorImages', element)
    })
    formData.append('title', listingData.title)
    formData.append('description', listingData.description)
    formData.append('listedIn', listingData.listedIn)
    formData.append('category', listingData.category)
    formData.append('status', listingData.status)
    formData.append('amenities', listingData.amenities)
    formData.append('price', JSON.stringify(listingData.price))
    formData.append('virtualToolUrl', listingData.virtualToolUrl)
    formData.append('videoUrl', listingData.videoUrl)
    formData.append('details', JSON.stringify(listingData.details))
    formData.append('oldImages', JSON.stringify(listingData.oldImages))
    formData.append('oldfloorImages', JSON.stringify(listingData.oldfloorImages))
    formData.append(
      'address',
      JSON.stringify({ ...listingData.address, ...position })
    )

    if (isEdit) {
      dispatch(editListingAction(id, formData))
    } else dispatch(addListingAction(formData))
  }


  const submitAmentiesHandler = () => {
    let formData = new FormData();
    formData.append("title", amenty.title);
    formData.append("icon", amenty.icon);
    console.log(formData);
    dispatch(addAmenityAction(formData));
  };

  const amenityHandler = id => {
    let newAmenities = listingData.amenities
    if (!listingData.amenities.find(amenity => amenity == id)) {
      newAmenities.push(id)
    } else {
      newAmenities = newAmenities.filter(x => x !== id)
    }
    setListingData({ ...listingData, amenities: newAmenities })
  }

  const myIcon = L.icon({
    iconUrl: require('../../images/googlle-market-01.png'),
    iconSize: [32, 32],
    iconAnchor: [16, 32],
  })
  return (
    <>
      {((listing && isEdit) || !isEdit) && (
        <>
          <Helmet>
            <script src='/assets/js/theme.js'></script>
          </Helmet>
          <main id='content' className='bg-gray-01'>
            <div className='px-3 px-lg-6 px-xxl-13 py-5 py-lg-10 my-profile'>
              <div className='mb-6'>
                <h2 className='mb-0 text-heading fs-22 lh-15'>
                  {t('Add New Property')}
                </h2>
                <p className='mb-1'>
                  Lorem ipsum dolor sit amet, consec tetur cing elit. Suspe
                  ndisse suscipit
                </p>
              </div>
              <div className='collapse-tabs new-property-step'>
                <ul
                  className='nav nav-pills border py-2 px-3 mb-6 d-none d-md-flex mb-6'
                  role='tablist'>
                  <li className='nav-item col'>
                    <a
                      className='nav-link active bg-transparent shadow-none py-2 font-weight-500 text-center lh-214 d-block'
                      id='description-tab'
                      data-toggle='pill'
                      data-number='1.'
                      href='#description'
                      role='tab'
                      aria-controls='description'
                      aria-selected='true'>
                      <span className='number'>1.</span> {t('Description')}
                    </a>
                  </li>
                  <li className='nav-item col'>
                    <a
                      className='nav-link bg-transparent shadow-none py-2 font-weight-500 text-center lh-214 d-block'
                      id='media-tab'
                      data-toggle='pill'
                      data-number='2.'
                      href='#media'
                      role='tab'
                      aria-controls='media'
                      aria-selected='false'>
                      <span className='number'>2.</span> {t('Media')}
                    </a>
                  </li>
                  <li className='nav-item col' onClick={() => resizeLeflet()}>
                    <a
                      className='nav-link bg-transparent shadow-none py-2 font-weight-500 text-center lh-214 d-block'
                      id='location-tab'
                      data-toggle='pill'
                      data-number='3.'
                      href='#location'
                      role='tab'
                      aria-controls='location'
                      aria-selected='false'>
                      <span className='number'>3.</span> {t('Location')}
                    </a>
                  </li>
                  <li className='nav-item col'>
                    <a
                      className='nav-link bg-transparent shadow-none py-2 font-weight-500 text-center lh-214 d-block'
                      id='detail-tab'
                      data-toggle='pill'
                      data-number='4.'
                      href='#detail'
                      role='tab'
                      aria-controls='detail'
                      aria-selected='false'>
                      <span className='number'>4.</span> {t('Detail')}
                    </a>
                  </li>
                  <li className='nav-item col'>
                    <a
                      className='nav-link bg-transparent shadow-none py-2 font-weight-500 text-center lh-214 d-block'
                      id='amenities-tab'
                      data-toggle='pill'
                      data-number='5.'
                      href='#amenities'
                      role='tab'
                      aria-controls='amenities'
                      aria-selected='false'>
                      <span className='number'>5.</span> {t('Amenities')}
                    </a>
                  </li>
                </ul>
                <div className='tab-content shadow-none p-0'>
                  <form>
                    <div id='collapse-tabs-accordion'>
                      <div
                        className='tab-pane tab-pane-parent fade show active px-0'
                        id='description'
                        role='tabpanel'
                        aria-labelledby='description-tab'>
                        <div className='card bg-transparent border-0'>
                          <div
                            className='card-header d-block d-md-none bg-transparent px-0 py-1 border-bottom-0'
                            id='heading-description'>
                            <h5 className='mb-0'>
                              <button
                                className='btn btn-lg collapse-parent btn-block border shadow-none'
                                data-toggle='collapse'
                                data-number='1.'
                                data-target='#description-collapse'
                                aria-expanded='true'
                                aria-controls='description-collapse'>
                                <span className='number'>1.</span>
                                {t('Description')}
                              </button>
                            </h5>
                          </div>
                          <div
                            id='description-collapse'
                            className='collapse show collapsible'
                            aria-labelledby='heading-description'
                            data-parent='#collapse-tabs-accordion'>
                            <div className='card-body py-4 py-md-0 px-0'>
                              <div className='row'>
                                <div className='col-lg-6'>
                                  <div className='card mb-6'>
                                    <div className='card-body p-6'>
                                      <h3 className='card-title mb-0 text-heading fs-22 lh-15'>
                                        {t('Property Description')}
                                      </h3>
                                      <p className='card-text mb-5'>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit
                                      </p>
                                      <div className='form-group'>
                                        <label
                                          htmlFor='title'
                                          className='text-heading'>
                                          {t('Title')}
                                          <span className='text-muted'>
                                            {t('(mandatory)')}
                                          </span>
                                        </label>
                                        <input
                                          type='text'
                                          className={
                                            (listingData?.title?.length > 0
                                              ? ''
                                              : 'error') +
                                            ' form-control form-control-lg border-0'
                                          }
                                          id='title'
                                          name='title'
                                          value={listingData?.title}
                                          onChange={e => {
                                            setListingData({
                                              ...listingData,
                                              title: e.target.value,
                                            })
                                          }}
                                        />
                                        {listingData?.title?.length === 0 && (
                                          <span className='errorSpan'>
                                            Title is required
                                          </span>
                                        )}
                                      </div>

                                      <div className='form-group mb-0'>
                                        <label
                                          htmlFor='description-01'
                                          className='text-heading'>
                                          {t('Description')}
                                        </label>
                                        <textarea
                                          className={
                                            (listingData?.description?.length >
                                            0
                                              ? ''
                                              : 'error') +
                                            ' form-control border-0'
                                          }
                                          rows='5'
                                          name='description'
                                          id='description-01'
                                          onChange={e => {
                                            setListingData({
                                              ...listingData,
                                              description: e.target.value,
                                            })
                                          }}>
                                          {listingData.description}
                                        </textarea>
                                        {listingData?.description?.length ==
                                          0 && (
                                          <span className='errorSpan'>
                                            Description is required
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className='card mb-6'>
                                    <div className='card-body p-6'>
                                      <h3 className='card-title mb-0 text-heading fs-22 lh-15'>
                                        {t('Select Category')}
                                      </h3>
                                      <p className='card-text mb-5'>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit
                                      </p>
                                      <div className='form-row mx-n2'>
                                        <div className='col-md-6 col-lg-12 col-xxl-6 px-2 mb-4 mb-md-0'>
                                          <div className='form-group mb-0'>
                                            <label
                                              htmlFor='category'
                                              className='text-heading'>
                                              {t('Category')}
                                            </label>
                                            <Select
                                              value={categoryList.filter(
                                                option =>
                                                  option.value ===
                                                  listingData?.category
                                              )}
                                              options={categoryList}
                                              onChange={e => {
                                                setListingData({
                                                  ...listingData,
                                                  category: e.value,
                                                })
                                              }}
                                              className={
                                                listingData?.category?.length ==
                                                  0 && 'error'
                                              }
                                            />
                                            {listingData?.category?.length ==
                                              0 && (
                                              <span className='errorSpan'>
                                                Description is required
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                        <div className='col-md-6 col-lg-12 col-xxl-6 px-2 mb-4 mb-md-0'>
                                          <div className='form-group mb-0'>
                                            <label
                                              htmlFor='list-in'
                                              className='text-heading'>
                                              {t('Listed in')}
                                            </label>
                                            <Select
                                              options={listedInList}
                                              onChange={e =>
                                                setListingData({
                                                  ...listingData,
                                                  listedIn: e.value,
                                                })
                                              }
                                              value={listedInList.filter(
                                                option =>
                                                  option.value ===
                                                  listingData?.listedIn
                                              )}
                                              className={
                                                listingData?.listedIn?.length ==
                                                  0 && 'error'
                                              }
                                            />
                                            {listingData?.listedIn?.length ==
                                              0 && (
                                              <span className='errorSpan'>
                                                Listed In is required
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                        <div className='col-md-6 col-lg-12 col-xxl-6 px-2 mb-4 mb-md-0'>
                                          <div className='form-group mb-0'>
                                            <div className='form-group mb-0'>
                                              <label
                                                htmlFor='status'
                                                className='text-heading'>
                                                {t('Property Status')}
                                              </label>
                                              <Select
                                                options={statusList}
                                                onChange={e =>
                                                  setListingData({
                                                    ...listingData,
                                                    status: e.value,
                                                  })
                                                }
                                                className={
                                                  listingData?.status?.length ==
                                                    0 && 'error'
                                                }
                                                value={statusList.filter(
                                                  option =>
                                                    option.value ===
                                                    listingData?.status
                                                )}
                                              />
                                              {listingData?.status?.length ==
                                                0 && (
                                                <span className='errorSpan'>
                                                  Status is required
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='col-lg-6'>
                                  <div className='card mb-6'>
                                    <div className='card-body p-6'>
                                      <h3 className='card-title mb-0 text-heading fs-22 lh-15'>
                                        {t('Property Price')}
                                      </h3>
                                      <p className='card-text mb-5'>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit
                                      </p>
                                      <div className='form-row mx-n2'>
                                        <div className='col-md-6 col-lg-12 col-xxl-6 px-2'>
                                          <div className='form-group'>
                                            <label
                                              htmlFor='price'
                                              className='text-heading'>
                                              {t('Price in CA$')}
                                            </label>
                                            <input
                                              type='number'
                                              className={
                                                (listingData?.price?.price > 0
                                                  ? ''
                                                  : 'error') +
                                                ' form-control form-control-lg border-0'
                                              }
                                              id='price'
                                              name='price'
                                              value={listingData?.price?.price}
                                              onChange={e =>
                                                setListingData({
                                                  ...listingData,
                                                  price: {
                                                    ...listingData?.price,
                                                    price: e.target.value,
                                                  },
                                                })
                                              }
                                            />
                                            {listingData?.price?.price <= 0 && (
                                              <span className='errorSpan'>
                                                Price is required
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                        <div className='col-md-6 col-lg-12 col-xxl-6 px-2'>
                                          <div className='form-group'>
                                            <label
                                              htmlFor='tax'
                                              className='text-heading'>
                                              {t('Land')}
                                            </label>
                                            <input
                                              type='text'
                                              name='tax'
                                              className='form-control form-control-lg border-0'
                                              id='tax'
                                              value={listingData?.price?.land}
                                              onChange={e =>
                                                setListingData({
                                                  ...listingData,
                                                  price: {
                                                    ...listingData.price,
                                                    land: e.target.value,
                                                  },
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className='form-row mx-n2'>
                                        <div className='col-md-6 col-lg-12 col-xxl-6 px-2'>
                                          <div className='form-group'>
                                            <label
                                              htmlFor='fee'
                                              className='text-heading'>
                                              {t('Residential')}
                                              <span className='text-muted'>
                                                (monthly)
                                              </span>
                                            </label>
                                            <input
                                              type='text'
                                              className='form-control form-control-lg border-0'
                                              id='fee'
                                              name='fee'
                                              value={
                                                listingData.price?.residential
                                              }
                                              onChange={e =>
                                                setListingData({
                                                  ...listingData,
                                                  price: {
                                                    ...listingData.price,
                                                    residential: e.target.value,
                                                  },
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className='col-md-6 col-lg-12 col-xxl-6 px-2'>
                                          <div className='form-group'>
                                            <label
                                              htmlFor='after-price'
                                              className='text-heading'>
                                              {t('CO Owner Ship Fees')}
                                              <span className='text-muted'>
                                                ( ex: /month)
                                              </span>
                                            </label>
                                            <input
                                              type='text'
                                              className='form-control form-control-lg border-0'
                                              id='after-price'
                                              name='after-price'
                                              value={
                                                listingData.price
                                                  ?.coOwnerShipFees
                                              }
                                              onChange={e =>
                                                setListingData({
                                                  ...listingData,
                                                  price: {
                                                    ...listingData.price,
                                                    coOwnerShipFees:
                                                      e.target.value,
                                                  },
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className='form-row mx-n2'>
                                        <div className='col-md-6 col-lg-12 col-xxl-6 px-2'>
                                          <div className='form-group'>
                                            <label
                                              htmlFor='fee'
                                              className='text-heading'>
                                              {t('Building')}
                                              <span className='text-muted'>
                                                (monthly)
                                              </span>
                                            </label>
                                            <input
                                              type='text'
                                              className='form-control form-control-lg border-0'
                                              id='fee'
                                              name='fee'
                                              value={
                                                listingData.price?.building
                                              }
                                              onChange={e =>
                                                setListingData({
                                                  ...listingData,
                                                  price: {
                                                    ...listingData.price,
                                                    building: e.target.value,
                                                  },
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className='col-md-6 col-lg-12 col-xxl-6 px-2'>
                                          <div className='form-group'>
                                            <label
                                              htmlFor='after-price'
                                              className='text-heading'>
                                              {t('Municipal Taxes')}
                                              <span className='text-muted'>
                                                ( ex: /month)
                                              </span>
                                            </label>
                                            <input
                                              type='text'
                                              className='form-control form-control-lg border-0'
                                              id='after-price'
                                              name='after-price'
                                              value={
                                                listingData.price
                                                  ?.municipalTaxes
                                              }
                                              onChange={e =>
                                                setListingData({
                                                  ...listingData,
                                                  price: {
                                                    ...listingData.price,
                                                    municipalTaxes:
                                                      e.target.value,
                                                  },
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className='form-row mx-n2'>
                                        <div className='col-md-6 col-lg-12 col-xxl-6 px-2'>
                                          <div className='form-group'>
                                            <label
                                              htmlFor='fee'
                                              className='text-heading'>
                                              {t('school Taxes')}
                                              <span className='text-muted'>
                                                (monthly)
                                              </span>
                                            </label>
                                            <input
                                              type='text'
                                              className='form-control form-control-lg border-0'
                                              id='fee'
                                              name='fee'
                                              value={
                                                listingData.price?.schoolTaxes
                                              }
                                              onChange={e =>
                                                setListingData({
                                                  ...listingData,
                                                  price: {
                                                    ...listingData.price,
                                                    schoolTaxes: e.target.value,
                                                  },
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className='col-md-6 col-lg-12 col-xxl-6 px-2'>
                                          <div className='form-group'>
                                            <label
                                              htmlFor='after-price'
                                              className='text-heading'>
                                              {t('Insurance')}
                                              <span className='text-muted'>
                                                ( ex: /month)
                                              </span>
                                            </label>
                                            <input
                                              type='text'
                                              className='form-control form-control-lg border-0'
                                              id='after-price'
                                              name='after-price'
                                              value={
                                                listingData.price?.insurance
                                              }
                                              onChange={e =>
                                                setListingData({
                                                  ...listingData,
                                                  price: {
                                                    ...listingData.price,
                                                    insurance: e.target.value,
                                                  },
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className='form-row mx-n2'>
                                        <div className='col-md-6 col-lg-12 col-xxl-6 px-2'>
                                          <div className='form-group mb-0'>
                                            <label
                                              htmlFor='before-price'
                                              className='text-heading'>
                                              {t('Energy Cost')}
                                              <span className='text-muted'>
                                                (ex: "from")
                                              </span>
                                            </label>
                                            <input
                                              type='text'
                                              className='form-control form-control-lg border-0'
                                              id='before-price'
                                              name='before-price'
                                              value={
                                                listingData.price?.energyCost
                                              }
                                              onChange={e =>
                                                setListingData({
                                                  ...listingData,
                                                  price: {
                                                    ...listingData.price,
                                                    energyCost: e.target.value,
                                                  },
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='text-right'>
                                <button
                                  type='button'
                                  className='btn btn-lg btn-primary next-button'
                                  disabled={
                                    listingData?.price?.price <= 0 ||
                                    listingData?.title?.length == 0 ||
                                    listingData?.description?.length == 0 ||
                                    listingData?.listedIn?.length == 0 ||
                                    listingData?.category?.length == 0 ||
                                    listingData?.status?.length == 0
                                  }>
                                  {t('Next step')}
                                  <span className='d-inline-block ml-2 fs-16'>
                                    <i className='fal fa-long-arrow-right'></i>
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className='tab-pane tab-pane-parent fade px-0'
                        id='media'
                        role='tabpanel'
                        aria-labelledby='media-tab'>
                        <div className='card bg-transparent border-0'>
                          <div
                            className='card-header d-block d-md-none bg-transparent px-0 py-1 border-bottom-0'
                            id='heading-media'>
                            <h5 className='mb-0'>
                              <button
                                className='btn btn-lg collapse-parent btn-block border shadow-none'
                                data-toggle='collapse'
                                data-number='2.'
                                data-target='#media-collapse'
                                aria-expanded='true'
                                aria-controls='media-collapse'>
                                <span className='number'>2.</span> {t('Media')}
                              </button>
                            </h5>
                          </div>
                          <div
                            id='media-collapse'
                            className='collapse collapsible'
                            aria-labelledby='heading-media'
                            data-parent='#collapse-tabs-accordion'>
                            <div className='card-body py-4 py-md-0 px-0'>
                              <div className='row'>
                                <div className='col-lg-6'>
                                  <div className='card mb-6'>
                                    <div className='card-body p-6'>
                                      <h3 className='card-title mb-0 text-heading fs-22 lh-15'>
                                        {t('Upload photos of your property')}
                                      </h3>
                                      <p className='card-text mb-5'>
                                        {t('Property Images')}
                                      </p>
                                      <div className='upload-file text-center py-5'>
                                      {
                                          isEdit ? 
                                          <>
                                           { listingData?.oldImages?.map((image) => 
                                           
                                              <div className="box" style={{margin:"15px", display:"inline-block", position:"relative"}} >
                                                <div style={{ marginTop:"5px",
                                                      position:"absolute", 
                                                      right:"5px" , top:"0px", 
                                                      zIndex:"99" ,
                                                      color:"black", 
                                                      cursor:"pointer", 
                                                      borderRadius:"50%",
                                                      width:"25px",
                                                      height:"25px",
                                                      backgroundColor:"white" }} 
                                                      onClick={() =>  setListingData({
                                                        ...listingData,
                                                        oldImages: listingData.oldImages.filter(image1 =>image1 != image),
                                                      })}
                                                      >
                                                      <span style={{fontWeight:"bold"}}
                                                       > X </span>
                                                </div>
                                                <div className="card shadow-hover-2">
                                                  <div
                                                    style={{ textAlign: "center", maxWidth: "150px" }}
                                                    className="hover-change-image rounded-lg card-img-top"
                                                  >
                                                    <img
                                                      src={BACKEND_IMAGE + image}
                                                    />
                                                  </div>
                                                </div>
                                            </div>
                                           
                                           )}
                                          </>: ''
                                        }
                                        <Dropzone
                                          onDrop={acceptedFiles => {
                                            setListingData({
                                              ...listingData,
                                              images: acceptedFiles,
                                            })
                                          }}>
                                          {({
                                            getRootProps,
                                            getInputProps,
                                          }) => (
                                            <div
                                              className='dz-default dz-message'
                                              {...getRootProps()}>
                                              {listingData?.images?.length ==
                                              0 ? (
                                                <>
                                                  <input
                                                    type='file'
                                                    hidden
                                                    {...getInputProps()}
                                                  />
                                                  <span className='upload-icon lh-1 d-inline-block mb-4'>
                                                    <i className='fal fa-cloud-upload-alt'></i>
                                                  </span>
                                                  <p className='text-heading fs-22 lh-15 mb-4'>
                                                    {t(
                                                      'Drag and drop image or'
                                                    )}
                                                  </p>
                                                  <button
                                                    className='btn btn-indigo px-7 mb-2'
                                                    type='button'>
                                                    {t('Browse file')}
                                                  </button>
                                                  <p>
                                                    {t(
                                                      'Photos must be JPEG or PNG format and least 1024x768'
                                                    )}
                                                  </p>
                                                </>
                                              ) : (
                                                <>
                                                  <p className='text-heading fs-22 lh-15 mb-4'>
                                                    {listingData?.images?.length}{' '}
                                                    {t('images uploaded')}
                                                  </p>

                                                  <button
                                                    className='btn btn-indigo px-7 mb-2'
                                                    type='button'
                                                    onClick={() =>
                                                      setListingData({
                                                        ...listingData,
                                                        images: [],
                                                      })
                                                    }>
                                                    {t('Reset photo')}
                                                  </button>
                                                </>
                                              )}
                                            </div>
                                          )}
                                        </Dropzone>
                                        {listingData?.images?.length == 0 && !isEdit && (
                                          <span className='errorSpan'>
                                            {("Images is required")}
                                          </span>
                                        )}
                                      </div>
                                      <p className='card-text mb-5 mt-5 '>
                                        {t('Floor images')}
                                      </p>
                                      <div className='upload-file text-center py-5'>
                                        {
                                          isEdit ? 
                                          <>
                                           { listingData?.oldfloorImages?.map((image) =>
                                            <div className="box" style={{margin:"15px", display:"inline-block", position:"relative"}} >
                                            <div style={{ marginTop:"5px",
                                                  position:"absolute", 
                                                  right:"5px" , top:"0px", 
                                                  zIndex:"99" ,
                                                  color:"black", 
                                                  cursor:"pointer", 
                                                  borderRadius:"50%",
                                                  width:"25px",
                                                  height:"25px",
                                                  backgroundColor:"white" }} >
                                                  <span style={{fontWeight:"bold"}} onClick={() =>  setListingData({
                                                      ...listingData,
                                                      oldfloorImages: listingData.oldfloorImages.filter(image1 =>image1 != image),
                                                    })} > X </span>
                                            </div>
                                            <div className="card shadow-hover-2">
                                              <div
                                                style={{ textAlign: "center", maxWidth: "150px" }}
                                                className="hover-change-image rounded-lg card-img-top"
                                              >
                                                <img
                                                  src={BACKEND_IMAGE + image}
                                                />
                                              </div>
                                            </div>
                                        </div>
                                            
                                            )}
                                          </>: ''
                                        }
                                        <Dropzone
                                          onDrop={acceptedFiles => {
                                            setListingData({
                                              ...listingData,
                                              floorImages: acceptedFiles,
                                            })
                                          }}>
                                          {({
                                            getRootProps,
                                            getInputProps,
                                          }) => (
                                            <div
                                              className='dz-default dz-message'
                                              {...getRootProps()}>
                                              {listingData?.floorImages?.length ==
                                              0 ? (
                                                <>
                                                  <input
                                                    type='file'
                                                    hidden
                                                    {...getInputProps()}
                                                  />
                                                  <span className='upload-icon lh-1 d-inline-block mb-4'>
                                                    <i className='fal fa-cloud-upload-alt'></i>
                                                  </span>
                                                  <p className='text-heading fs-22 lh-15 mb-4'>
                                                    {t(
                                                      'Drag and drop image or'
                                                    )}
                                                  </p>
                                                  <button
                                                    className='btn btn-indigo px-7 mb-2'
                                                    type='button'>
                                                    {t('Browse file')}
                                                  </button>
                                                  <p>
                                                    {t(
                                                      'Photos must be JPEG or PNG format and least 1024x768'
                                                    )}
                                                  </p>
                                                </>
                                              ) : (
                                                <>
                                                  <p className='text-heading fs-22 lh-15 mb-4'>
                                                    {
                                                      listingData?.floorImages?.length
                                                    }{' '}
                                                    {t('images uploaded')}
                                                  </p>

                                                  <button
                                                    className='btn btn-indigo px-7 mb-2'
                                                    type='button'
                                                    onClick={() =>
                                                      setListingData({
                                                        ...listingData,
                                                        floorImages: [],
                                                      })
                                                    }>
                                                    {t('Reset photo')}
                                                  </button>
                                                </>
                                              )}
                                            </div>
                                          )}
                                        </Dropzone>
                                        {/* {listingData?.floorImages?.length ==
                                          0 && (
                                          <span className='errorSpan'>
                                            {t("Floor Image is required")}
                                          </span>
                                        )} */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='col-lg-6'>
                                  <div className='card mb-6'>
                                    <div className='card-body p-6'>
                                      <h3 className='card-title mb-0 text-heading fs-22 lh-15'>
                                        {t('Video Option')}
                                      </h3>
                                      <p className='card-text mb-5'>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit
                                      </p>
                                      <div className='form-row mx-n2'>
                                        <div className='col-md-6 col-lg-12 col-xxl-6 px-2'>
                                          <div className='form-group mb-md-0'>
                                            <label
                                              htmlFor='embed-video-id'
                                              className='text-heading'>
                                              {t('Embed Video id')}
                                            </label>
                                            <input
                                              type='text'
                                              name='embed-video-id'
                                              className='form-control form-control-lg border-0'
                                              id='embed-video-id'
                                              value={listingData.videoUrl}
                                              onChange={e =>
                                                setListingData({
                                                  ...listingData,
                                                  videoUrl: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='card mb-6'>
                                    <div className='card-body p-6'>
                                      <h3 className='card-title mb-0 text-heading fs-22 lh-15'>
                                        {t('Virtual Tour')}
                                      </h3>
                                      <div className='form-group mb-0'>
                                        <label
                                          htmlFor='virtual-tour'
                                          className='text-heading'>
                                          {t('Virtual Tour')}
                                        </label>
                                        <input
                                          type='text'
                                          className='form-control form-control-lg border-0'
                                          id='virtual-tour'
                                          name='virtual-tour'
                                          value={listingData.virtualToolUrl}
                                          onChange={e =>
                                            setListingData({
                                              ...listingData,
                                              virtualToolUrl: e.target.value,
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='d-flex flex-wrap'>
                                <a
                                  href='#'
                                  className='btn btn-lg bg-hover-white border rounded-lg mb-3 mr-auto prev-button'>
                                  <span className='d-inline-block text-primary mr-2 fs-16'>
                                    <i className='fal fa-long-arrow-left'></i>
                                  </span>
                                  {t('Prev step')}
                                </a>
                                <button
                                  type='button'
                                  className='btn btn-lg btn-primary next-button mb-3'
                                  onClick={() => {
                                    resizeLeflet()
                                  }}
                                  disabled={
                           
                                    isEdit? false : listingData?.images?.length == 0
                                  }>
                                  {t('Next step')}
                                  <span className='d-inline-block ml-2 fs-16'>
                                    <i className='fal fa-long-arrow-right'></i>
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className='tab-pane tab-pane-parent fade px-0'
                        id='location'
                        role='tabpanel'
                        aria-labelledby='location-tab'>
                        <div className='card bg-transparent border-0'>
                          <div
                            className='card-header d-block d-md-none bg-transparent px-0 py-1 border-bottom-0'
                            id='heading-location'>
                            <h5 className='mb-0'>
                              <button
                                className='btn btn-block collapse-parent collapsed border shadow-none'
                                data-toggle='collapse'
                                data-number='3.'
                                data-target='#location-collapse'
                                aria-expanded='true'
                                aria-controls='location-collapse'>
                                <span className='number'>3.</span>{' '}
                                {t('Location')}
                              </button>
                            </h5>
                          </div>
                          <div
                            id='location-collapse'
                            className='collapse collapsible'
                            aria-labelledby='heading-location'
                            data-parent='#collapse-tabs-accordion'>
                            <div className='card-body py-4 py-md-0 px-0'>
                              <div className='row'>
                                <div className='col-lg-6'>
                                  <div className='card mb-6'>
                                    <div className='card-body p-6'>
                                      <h3 className='card-title mb-0 text-heading fs-22 lh-15'>
                                        {t('Listing Location')}
                                      </h3>
                                      <p className='card-text mb-5'>
                                        {t("Lorem ipsum dolor sit amet, consectetur adipiscing elit")}
                                      </p>
                                      <div className='form-group'>
                                        <label
                                          htmlFor='address'
                                          className='text-heading'>
                                          {t('Address')}
                                        </label>
                                        <input
                                          type='text'
                                          className={
                                            (listingData?.address?.adresse
                                              ? ''
                                              : 'error') +
                                            ' form-control form-control-lg border-0'
                                          }
                                          id='address'
                                          name='address'
                                          value={listingData.address?.adresse}
                                          onChange={e =>
                                            setListingData({
                                              ...listingData,
                                              address: {
                                                ...listingData.address,
                                                adresse: e.target.value,
                                              },
                                            })
                                          }
                                        />
                                        {listingData.address?.adresse?.length ==
                                          0 && (
                                          <span className='errorSpan'>
                                            {t("Address is required")}
                                          </span>
                                        )}
                                      </div>
                                      <div className='form-row mx-n2'>
                                        <div className='col-md-6 col-lg-12 col-xxl-6 px-2'>
                                          <div className='form-group'>
                                            <label
                                              htmlFor='city'
                                              className='text-heading'>
                                              {t('City')}
                                            </label>
                                            <input
                                              type='text'
                                              className={
                                                (listingData?.address?.city
                                                  ? ''
                                                  : 'error') +
                                                ' form-control form-control-lg border-0'
                                              }
                                              id='city'
                                              name='city'
                                              value={listingData.address?.city}
                                              onChange={e =>
                                                setListingData({
                                                  ...listingData,
                                                  address: {
                                                    ...listingData.address,
                                                    city: e.target.value,
                                                  },
                                                })
                                              }
                                            />
                                            {listingData.address?.city
                                              ?.length == 0 && (
                                              <span className='errorSpan'>
                                                {t("City is required")}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className='form-row mx-n2'>
                                        <div className='col-md-6 col-lg-12 col-xxl-6 px-2'>
                                          <div className='form-group'>
                                            <label
                                              htmlFor='neighborhood'
                                              className='text-heading'>
                                              {t('Neighborhood')}
                                            </label>
                                            <input
                                              type='text'
                                              className={
                                                (listingData?.address
                                                  ?.neighborhood
                                                  ? ''
                                                  : 'error') +
                                                ' form-control form-control-lg border-0'
                                              }
                                              id='neighborhood'
                                              name='neighborhood'
                                              value={
                                                listingData.address
                                                  ?.neighborhood
                                              }
                                              onChange={e =>
                                                setListingData({
                                                  ...listingData,
                                                  address: {
                                                    ...listingData.address,
                                                    neighborhood:
                                                      e.target.value,
                                                  },
                                                })
                                              }
                                            />
                                            {listingData.address?.neighborhood
                                              ?.length == 0 && (
                                              <span className='errorSpan'>
                                                {t("Neighborhood is required")}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                        <div className='col-md-6 col-lg-12 col-xxl-6 px-2'>
                                          <div className='form-group'>
                                            <label
                                              htmlFor='zip'
                                              className='text-heading'>
                                              {t('Zip')}
                                            </label>
                                            <input
                                              type='text'
                                              className={
                                               
                                                ' form-control form-control-lg border-0'
                                              }
                                              id='zip'
                                              name='zip'
                                              value={listingData.address?.zip}
                                              onChange={e =>
                                                setListingData({
                                                  ...listingData,
                                                  address: {
                                                    ...listingData.address,
                                                    zip: e.target.value,
                                                  },
                                                })
                                              }
                                            />
                                           
                                          </div>
                                        </div>
                                      </div>
                                      <div className='form-group mb-md-0'>
                                        <label
                                          htmlFor='country'
                                          className='text-heading'>
                                          {t('Country')}
                                        </label>
                                        <Select
                                         
                                          options={countryList}
                                          onChange={e =>
                                            setListingData({
                                              ...listingData,
                                              address: {
                                                ...listingData.address,
                                                country: e.value,
                                              },
                                            })
                                          }
                                        />
                                        
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='col-lg-6'>
                                  <div className='card mb-6'>
                                    <div className='card-body p-6'>
                                      <h3 className='card-title mb-6 text-heading fs-22 lh-15'>
                                        {t('Place the listing pin on the map')}
                                      </h3>
                                      <div className='form-row mx-n2'>
                                        <div className='col-md-6 col-lg-12 col-xxl-6 px-2'>
                                          <div className='form-group mb-md-0'>
                                            <MapContainer
                                              id='map-container'
                                              ref={setMap}
                                              style={{
                                                height: '350px',
                                                width: '100%',
                                              }}
                                              center={
                                                listingData.address
                                                  ? [
                                                      position.lat,
                                                      position.long,
                                                    ]
                                                  : defaulPosition
                                              }
                                              zoom={13}>
                                              <TileLayer
                                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                                              />
                                              <Marker
                                                draggable={true}
                                                position={
                                                  listingData.address
                                                    ? [
                                                        position.lat,
                                                        position.long,
                                                      ]
                                                    : defaulPosition
                                                }
                                                icon={myIcon}
                                                eventHandlers={
                                                  eventHandlers
                                                }></Marker>
                                            </MapContainer>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='card mb-6'>
                                    <div className='card-body p-6'>
                                      <h3 className='card-title mb-6 text-heading fs-22 lh-15'>
                                        {t('Place the listing pin on the map')}
                                      </h3>

                                      <div className='form-row mx-n2'>
                                        <div className='col-md-6 col-lg-12 col-xxl-6 px-2'>
                                          <div className='form-group mb-md-0'>
                                            <label
                                              htmlFor='latitude'
                                              className='text-heading'>
                                              {t('Latitude')}
                                            </label>
                                            <input
                                              type='text'
                                              className='form-control form-control-lg border-0'
                                              id='latitude'
                                              name='latitude'
                                              value={position?.lat}
                                            />
                                          </div>
                                        </div>
                                        <div className='col-md-6 col-lg-12 col-xxl-6 px-2'>
                                          <div className='form-group mb-md-0'>
                                            <label
                                              htmlFor='longitude'
                                              className='text-heading'>
                                              {t('Longitude')}
                                            </label>
                                            <input
                                              type='text'
                                              className='form-control form-control-lg border-0'
                                              id='longitude'
                                              name='longitude'
                                              value={position?.long}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='d-flex flex-wrap'>
                                <a
                                  href='#'
                                  className='btn btn-lg bg-hover-white border rounded-lg mb-3 mr-auto prev-button'>
                                  <span className='d-inline-block text-primary mr-2 fs-16'>
                                    <i className='fal fa-long-arrow-left'></i>
                                  </span>
                                  {t('Prev step')}
                                </a>
                                <button
                                  type='button'
                                  className='btn btn-lg btn-primary next-button mb-3'
                                  disabled={
                                    listingData?.address?.adresse?.length ==
                                      0 ||
                                    listingData?.address?.city?.length == 0 ||
                                    listingData?.address?.neighborhood
                                      ?.length == 0
                                  }>
                                  {t('Next step')}
                                  <span className='d-inline-block ml-2 fs-16'>
                                    <i className='fal fa-long-arrow-right'></i>
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className='tab-pane tab-pane-parent fade px-0'
                        id='detail'
                        role='tabpanel'
                        aria-labelledby='detail-tab'>
                        <div className='card bg-transparent border-0'>
                          <div
                            className='card-header d-block d-md-none bg-transparent px-0 py-1 border-bottom-0'
                            id='heading-detail'>
                            <h5 className='mb-0'>
                              <button
                                className='btn btn-block collapse-parent collapsed border shadow-none'
                                data-toggle='collapse'
                                data-number='4.'
                                data-target='#detail-collapse'
                                aria-expanded='true'
                                aria-controls='detail-collapse'>
                                <span className='number'>4.</span> {t('Detail')}
                              </button>
                            </h5>
                          </div>
                          <div
                            id='detail-collapse'
                            className='collapse collapsible'
                            aria-labelledby='heading-detail'
                            data-parent='#collapse-tabs-accordion'>
                            <div className='card-body py-4 py-md-0 px-0'>
                              <div className='card mb-6'>
                                <div className='card-body p-6'>
                                  <h3 className='card-title mb-0 text-heading fs-22 lh-15'>
                                    {t('Listing Detail')}
                                  </h3>
                                  <p className='card-text mb-5'>
                                    {t("Lorem ipsum dolor sit amet, consectetur  adipiscing elit")}
                                  </p>
                                  <div className='row'>
                                    <div className='col-lg-4'>
                                      <div className='form-group'>
                                        <label
                                          htmlFor='size-in-ft'
                                          className='text-heading'>
                                          {t('Size')}
                                          <span className='text-muted'>
                                            ({t('m²')})
                                          </span>
                                        </label>
                                        <input
                                          type='text'
                                          className={
                                           
                                            ' form-control form-control-lg border-0'
                                          }
                                          id='size-in-ft'
                                          name='size-in-ft'
                                          value={listingData.details?.size}
                                          onChange={e =>
                                            setListingData({
                                              ...listingData,
                                              details: {
                                                ...listingData.details,
                                                size: e.target.value,
                                              },
                                            })
                                          }
                                        />
                                        {/* {listingData.details?.size <= 0 && (
                                          <span className='errorSpan'>
                                            Size is required
                                          </span>
                                        )} */}
                                      </div>
                                    </div>
                                    <div className='col-lg-4'>
                                      <div className='form-group'>
                                        <label
                                          htmlFor='lot-size-in-ft'
                                          className='text-heading'>
                                          {t('Lot size')}
                                          <span className='text-muted'>
                                            ({t('m²')})
                                          </span>
                                        </label>
                                        <input
                                          type='text'
                                          className={
                                           
                                            ' form-control form-control-lg border-0'
                                          }
                                          id='lot-size-in-ft'
                                          name='lot-size-in-ft'
                                          value={listingData.details?.lotSize}
                                          onChange={e =>
                                            setListingData({
                                              ...listingData,
                                              details: {
                                                ...listingData.details,
                                                lotSize: e.target.value,
                                              },
                                            })
                                          }
                                        />
                                        {/* {listingData.details?.lotSize <= 0 && (
                                          <span className='errorSpan'>
                                            lotSize is required
                                          </span>
                                        )} */}
                                      </div>
                                    </div>
                                    <div className='col-lg-4'>
                                      <div className='form-group'>
                                        <label
                                          htmlFor='year-built'
                                          className='text-heading'>
                                          {t('Year built')}
                                        </label>
                                        <input
                                          type='text'
                                          className={
                                            
                                            ' form-control form-control-lg border-0'
                                          }
                                          id='year-built'
                                          name='year-built'
                                          placeholder='DD-MM-YY'
                                          value={listingData.details?.yearBuilt}
                                          onChange={e =>
                                            setListingData({
                                              ...listingData,
                                              details: {
                                                ...listingData.details,
                                                yearBuilt: e.target.value,
                                              },
                                            })
                                          }
                                        />
                                        {/* {listingData.details?.yearBuilt <=
                                          0 && (
                                          <span className='errorSpan'>
                                            Year built is required
                                          </span>
                                        )} */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className='row'>
                                    <div className='col-lg-4'>
                                      <div className='form-group'>
                                        <label
                                          htmlFor='room'
                                          className='text-heading'>
                                          {t('Rooms')}
                                        </label>
                                        <input
                                          type='text'
                                          className={
                                           
                                            ' form-control form-control-lg border-0'
                                          }
                                          id='room'
                                          name='rooms'
                                          value={listingData.details?.room}
                                          onChange={e =>
                                            setListingData({
                                              ...listingData,
                                              details: {
                                                ...listingData.details,
                                                room: e.target.value,
                                              },
                                            })
                                          }
                                        />
                                        {/* {listingData.details?.room <= 0 && (
                                          <span className='errorSpan'>
                                            Rooms is required
                                          </span>
                                        )} */}
                                      </div>
                                    </div>
                                    <div className='col-lg-4'>
                                      <div className='form-group'>
                                        <label
                                          htmlFor='bedrooms'
                                          className='text-heading'>
                                          {t('bedrooms')}
                                        </label>
                                        <input
                                          type='text'
                                          className={
                                            
                                            ' form-control form-control-lg border-0'
                                          }
                                          id='bedrooms'
                                          name='bedrooms'
                                          value={listingData.details?.bedroom}
                                          onChange={e =>
                                            setListingData({
                                              ...listingData,
                                              details: {
                                                ...listingData.details,
                                                bedroom: e.target.value,
                                              },
                                            })
                                          }
                                        />
                                        {/* {listingData.details?.bedroom <= 0 && (
                                          <span className='errorSpan'>
                                            Bedroom is required
                                          </span>
                                        )} */}
                                      </div>
                                    </div>
                                    <div className='col-lg-4'>
                                      <div className='form-group'>
                                        <label
                                          htmlFor='bathrooms'
                                          className='text-heading'>
                                          {t('bathrooms')}
                                        </label>
                                        <input
                                          type='text'
                                          className={
                                            
                                            ' form-control form-control-lg border-0'
                                          }
                                          id='bathrooms'
                                          name='bathrooms'
                                          value={listingData.details?.bathRoom}
                                          onChange={e =>
                                            setListingData({
                                              ...listingData,
                                              details: {
                                                ...listingData.details,
                                                bathRoom: e.target.value,
                                              },
                                            })
                                          }
                                        />
                                        {/* {listingData.details?.bathRoom <= 0 && (
                                          <span className='errorSpan'>
                                            Bathroom is required
                                          </span>
                                        )} */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className='row'>
                                    <div className='col-lg-4'>
                                      <div className='form-group'>
                                        <label
                                          htmlFor='garages'
                                          className='text-heading'>
                                          {t('Garages')}
                                        </label>
                                        <input
                                          type='text'
                                          className=
                                
                                            ' form-control form-control-lg border-0'
                                        
                                          id='garages'
                                          name='garages'
                                          value={listingData.details?.garage}
                                          onChange={e =>
                                            setListingData({
                                              ...listingData,
                                              details: {
                                                ...listingData.details,
                                                garage: e.target.value,
                                              },
                                            })
                                          }
                                        />
                                       
                                      </div>
                                    </div>
                                    <div className='col-lg-4'>
                                      <div className='form-group'>
                                        <label
                                          htmlFor='garage-size'
                                          className='text-heading'>
                                          {t('Garage size')}
                                          <span className='text-muted'>
                                            ({t('m²')})
                                          </span>
                                        </label>
                                        <input
                                          type='text'
                                          className={
                                           
                                            ' form-control form-control-lg border-0'
                                          }
                                          id='garage-size'
                                          name='garage-size'
                                          value={
                                            listingData.details?.garageSize
                                          }
                                          onChange={e =>
                                            setListingData({
                                              ...listingData,
                                              details: {
                                                ...listingData.details,
                                                garageSize: e.target.value,
                                              },
                                            })
                                          }
                                        />
                                        {/* {listingData.details?.garageSize <=
                                          0 && (
                                          <span className='errorSpan'>
                                            Garage size is required
                                          </span>
                                        )} */}
                                      </div>
                                    </div>

                                    <div className='col-lg-4'>
                                      <div className='form-group'>
                                        <label
                                          htmlFor='basement'
                                          className='text-heading'>
                                          {t('Basement')}
                                        </label>
                                        <input
                                          type='text'
                                          className={
                                           
                                            ' form-control form-control-lg border-0'
                                          }
                                          id='basement'
                                          name='basement'
                                          value={listingData.details?.basement}
                                          onChange={e =>
                                            setListingData({
                                              ...listingData,
                                              details: {
                                                ...listingData.details,
                                                basement: e.target.value,
                                              },
                                            })
                                          }
                                        />
                                        {/* {listingData.details?.basement
                                          ?.length <= 0 && (
                                          <span className='errorSpan'>
                                            Basement is required
                                          </span>
                                        )} */}
                                      </div>
                                    </div>
                                  </div>

                                  <div className='row'>
                                    <div className='col-lg-4'>
                                      <div className='form-group'>
                                        <label
                                          htmlFor='roofing'
                                          className='text-heading'>
                                          {t('Roofing')}
                                        </label>
                                        <input
                                          type='text'
                                          className={
                                            
                                            ' form-control form-control-lg border-0'
                                          }
                                          id='roofing'
                                          name='roofing'
                                          value={listingData.details?.roofing}
                                          onChange={e =>
                                            setListingData({
                                              ...listingData,
                                              details: {
                                                ...listingData.details,
                                                roofing: e.target.value,
                                              },
                                            })
                                          }
                                        />
                                        {/* {listingData.details?.roofing <= 0 && (
                                          <span className='errorSpan'>
                                            Roofing is required
                                          </span>
                                        )} */}
                                      </div>
                                    </div>

                                    <div className='col-lg-8'>
                                      <div className='form-group'>
                                        <label
                                          htmlFor='floors-no'
                                          className='text-heading'>
                                          {t('Floors no')}
                                        </label>
                                        <input
                                          type='text'
                                          className={
                                           
                                            ' form-control form-control-lg border-0'
                                          }
                                          id='roofing'
                                          name='roofing'
                                          value={
                                            listingData.details?.floorsNumber
                                          }
                                          onChange={e =>
                                            setListingData({
                                              ...listingData,
                                              details: {
                                                ...listingData.details,
                                                floorsNumber: e.target.value,
                                              },
                                            })
                                          }
                                        />
                                        {/* {listingData.details?.floorsNumber <=
                                          0 && (
                                          <span className='errorSpan'>
                                            Floor number is required
                                          </span>
                                        )} */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className='d-flex flex-wrap'>
                                <a
                                  href='#'
                                  className='btn btn-lg bg-hover-white border rounded-lg mb-3 mr-auto prev-button'>
                                  <span className='d-inline-block text-primary mr-2 fs-16'>
                                    <i className='fal fa-long-arrow-left'></i>
                                  </span>
                                  {t('Prev step')}
                                </a>
                                <button
                                  type='button'
                                  className='btn btn-lg btn-primary next-button mb-3'
                                  // disabled={
                                  //   listingData?.details?.size <= 0 ||
                                  //   listingData?.details?.lotSize <= 0 ||
                                  //   listingData?.details?.room <= 0 ||
                                  //   listingData?.details?.bedroom <= 0 ||
                                  //   listingData?.details?.bathRoom <= 0 ||
                                  //   listingData?.details?.garage <= 0 ||
                                  //   listingData?.details?.garageSize <= 0 ||
                                  //   listingData?.details?.yearBuilt <= 0 ||
                                  //   listingData?.details?.basement?.length <=
                                  //     0 ||
                                  //   listingData?.details?.roofing <= 0 ||
                                  //   listingData?.details?.floorsNumber <= 0
                                  // }
                                  >
                                  {t('Next step')}
                                  <span className='d-inline-block ml-2 fs-16'>
                                    <i className='fal fa-long-arrow-right'></i>
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className='tab-pane tab-pane-parent fade px-0'
                        id='amenities'
                        role='tabpanel'
                        aria-labelledby='amenities-tab'>
                        <div className='card bg-transparent border-0'>
                          <div
                            className='card-header d-block d-md-none bg-transparent px-0 py-1 border-bottom-0'
                            id='heading-amenities'>
                            <h5 className='mb-0'>
                              <button
                                className='btn btn-block collapse-parent collapsed border shadow-none'
                                data-toggle='collapse'
                                data-number='5.'
                                data-target='#amenities-collapse'
                                aria-expanded='true'
                                aria-controls='amenities-collapse'>
                                <span className='number'>5.</span> Amenities
                              </button>
                            </h5>
                          </div>
                          <div
                            id='amenities-collapse'
                            className='collapse collapsible'
                            aria-labelledby='heading-amenities'
                            data-parent='#collapse-tabs-accordion'>
                            <div className='card-body py-4 py-md-0 px-0'>
                              <div className='d-flex justify-content-end'>
                                <button
                                  className='btn btn-lg btn-info mb-3'
                                  type='button'
                                  data-toggle='modal'
                                  id='amenutyModel'
                                  href='#login-register-modal'>
                                  {t('Add new')}
                                </button>
                                <div
                                  className='modal fade login-register login-register-modal'
                                  id='login-register-modal'
                                  tabIndex='-1'
                                  role='dialog'
                                  aria-labelledby='login-register-modal'
                                  aria-hidden='true'>
                                  <div
                                    className='modal-dialog modal-dialog-centered mxw-571'
                                    role='document'>
                                    <div className='modal-content'>
                                      <div className='modal-body p-4 py-sm-7 px-sm-8'>
                                        <div
                                          className='tab-content shadow-none p-0'
                                          id='myTabContent'>
                                          <div
                                            className='tab-pane fade show active'
                                            id='login'
                                            role='tabpanel'
                                            aria-labelledby='login-tab'>
                                            <form className='form'>
                                              <div className='form-group mb-4'>
                                                <h3 className='text-center mb-5'>
                                                  add new Amenties
                                                </h3>
                                                <div className='input-group input-group-lg'>
                                                  <input
                                                    type='text'
                                                    className='form-control border-0 shadow-none fs-13'
                                                    id='username'
                                                    name='amenity'
                                                    value={amenty.title}
                                                    onChange={e =>{ setAmenty({ ...amenty,
                                                          title:e.target.value,  })}}
                                                    required
                                                    placeholder='Amenties'
                                                  />
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-md-12 mb-4">
                                                  <label>Icon :</label>
                                                  <div className="position-relative">
                                                    <input
                                                      type="file"
                                                      className="form-control border-0 pr-3 h-52 pl-7"
                                                      accept="image/*"
                                                      onChange={(e) =>{
                                                        setAmenty({
                                                          ...amenty,
                                                          icon: e.target.files[0],
                                                        })
                                                      }}
                                                      name="interest-rate"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className='d-flex mb-4'></div>
                                            </form>
                                            <button
                                              onClick={() =>
                                                submitAmentiesHandler()
                                              }
                                              type='button'
                                              className='btn btn-primary btn-lg btn-block'>
                                              Add
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='card mb-6'>
                                <div className='card-body p-6'>
                                  <h3 className='card-title mb-0 text-heading fs-22 lh-15'>
                                    {t('Listing Detail')}
                                  </h3>
                                  {/* <p className='card-text mb-5'>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit
                                  </p> */}
                                  <div className='row'>
                                    {amenities?.map(amenity => (
                                      <div className='col-sm-6 col-lg-3 mb-3'>
                                        {amenity?.icon?.length > 0 ? <div className="d-inline-block fs-18 text-muted hover-primary ml-5">
                                          <img style={{width:"30px"}} src={
                                            BACKEND_IMAGE + amenity.icon
                                          } class="rounded" alt={amenity.title} />
                                        </div> : ""}
                                        <div className='custom-control custom-checkbox'>
                                          <input
                                            type='checkbox'
                                            className='custom-control-input'
                                            name='features[]'
                                            checked={listingData?.amenities?.find(
                                              amen => amen == amenity._id
                                            )}
                                            id={'amenity' + amenity._id}
                                            onChange={() =>
                                              amenityHandler(amenity._id)
                                            }
                                          />
                                          <label
                                            className='custom-control-label'
                                            htmlFor={'amenity' + amenity._id}>
                                            {amenity.title}
                                          </label>
                                           <div
                                            onClick={() => deleteHandler(amenity._id)}
                                            data-toggle="tooltip"
                                            title="Delete"
                                            className="d-inline-block fs-18 text-muted hover-primary ml-5"
                                          >
                                            <i className="fal fa-trash-alt"></i>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                              <div className='d-flex flex-wrap'>
                                <a
                                  href='#'
                                  className='btn btn-lg bg-hover-white border rounded-lg mb-3 mr-auto prev-button'>
                                  <span className='d-inline-block text-primary mr-2 fs-16'>
                                    <i className='fal fa-long-arrow-left'></i>
                                  </span>
                                  {t('Prev step')}
                                </a>
                                <button
                                  className='btn btn-lg btn-primary mb-3'
                                  type='button'
                                  onClick={() => submitHandler()}>
                                  {t('Submit property')}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </main>
        </>
      )}
    </>
  )
}

export default DashboardAddProperty
