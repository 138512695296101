import axios from "axios";
import {
  PDFS_ADD_REQUEST,
  PDFS_ADD_RESET,
  PDFS_ADD_SUCCESS,
  PDFS_DELETE_FAIL,
  PDFS_DELETE_REQUEST,
  PDFS_DELETE_SUCCESS,
  PDFS_GET_ALL_FAIL,
  PDFS_GET_ALL_REQUEST,
  PDFS_GET_ALL_SUCCESS,
  PDFS_UPDATE_REQUEST,
  PDFS_UPDATE_RESET,
  PDFS_UPDATE_SUCCESS,
} from "../constants/pdfsConstants";
import {
  ADD_PDF,
  DELETE_PDFS,
  GET_LIST_PDFS,
  UPDATE_PDFS,
} from "../utils/apiConfig";
export const addPdfAction = (pdf) => async (dispatch, getState) => {
  try {
    dispatch({ type: PDFS_ADD_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(ADD_PDF, pdf, config);

    dispatch({
      type: PDFS_ADD_SUCCESS,
      payload: data,
    });
    dispatch(getlistPdfsAction());
  } catch (error) {
    dispatch({
      type: PDFS_ADD_RESET,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updatePdfAction = (partner, id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PDFS_UPDATE_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(UPDATE_PDFS(id), partner, config);

    dispatch({
      type: PDFS_UPDATE_SUCCESS,
      payload: data,
    });
    dispatch(getlistPdfsAction());
  } catch (error) {
    dispatch({
      type: PDFS_UPDATE_RESET,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deletePdfAction = (pdf) => async (dispatch, getState) => {
  try {
    dispatch({ type: PDFS_DELETE_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(DELETE_PDFS(pdf), config);

    dispatch({
      type: PDFS_DELETE_SUCCESS,
      payload: data,
    });
    dispatch(getlistPdfsAction());
  } catch (error) {
    dispatch({
      type: PDFS_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getlistPdfsAction = () => async (dispatch) => {
  try {
    dispatch({ type: PDFS_GET_ALL_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(GET_LIST_PDFS, config);

    dispatch({
      type: PDFS_GET_ALL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PDFS_GET_ALL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
