export const AMENITY_GET_ALL_REQUEST = "AMENITY_GET_ALL_REQUEST";
export const AMENITY_GET_ALL_SUCCESS = "AMENITY_GET_ALL_SUCCESS";
export const AMENITY_GET_ALL_FAIL = "AMENITY_GET_ALL_FAIL";
export const AMENITY_GET_ALL_RESET = "AMENITY_GET_ALL_RESET";

export const AMENITY_ADD_REQUEST = "AMENITY_ADD_REQUEST";
export const AMENITY_ADD_SUCCESS = "AMENITY_ADD_SUCCESS";
export const AMENITY_ADD_FAIL = "AMENITY_ADD_FAIL";
export const AMENITY_ADD_RESET = "AMENITY_ADD_RESET";

export const AMENITY_DELETE_REQUEST = "AMENITY_DELETE_REQUEST";
export const AMENITY_DELETE_SUCCESS = "AMENITY_DELETE_SUCCESS";
export const AMENITY_DELETE_FAIL = "AMENITY_DELETE_FAIL";
export const AMENITY_DELETE_RESET = "AMENITY_DELETE_RESET";
