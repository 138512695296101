import React, { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { userLogout } from '../../actions/userActions'
import { useTranslation } from 'react-i18next'
import { getDashboardInfoActions } from '../../actions/publicActions'
import { BACKEND_IMAGE } from '../../utils/apiConfig'

export default function DashboardMenu() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const userLogin = useSelector(state => state.userLogin)
  const location = useLocation()
  const { userInfo } = userLogin

  const getDashboard = useSelector(state => state.getDashboard)
  const { dashboardInfo } = getDashboard

  const getSettingsList = useSelector(state => state.getSettingsList)
  const { settings } = getSettingsList

  useEffect(() => {
    dispatch(getDashboardInfoActions())
  }, [dispatch])
  return (
    <>
      <div class='db-sidebar bg-white'>
        <nav class='navbar navbar-expand-xl navbar-light d-block px-0 header-sticky dashboard-nav py-0'>
          <div class='sticky-area shadow-xs-1 py-3'>
            <div class='d-flex px-3 px-xl-6 w-100'>
              <Link to='/' class='navbar-brand'>
                <img
                  src={
                    BACKEND_IMAGE +
                    settings?.filter(setting => setting.key == 'logo')[0]?.value
                  }
                  alt='ImmoFlex'
                />
              </Link>
              <div class='ml-auto d-flex align-items-center '>
                <div class='d-flex align-items-center d-xl-none'>
                  <div class='dropdown px-3'>
                    <a
                      href='#'
                      class='dropdown-toggle d-flex align-items-center text-heading'
                      data-toggle='dropdown'>
                      <div class='w-48px'>
                        <img
                          src='/assets/images/testimonial-5.jpg'
                          alt='Ronald Hunter'
                          class='rounded-circle'
                        />
                      </div>

                      <span class='fs-13 font-weight-500 d-none d-sm-inline ml-2'>
                        {userInfo.firstName}
                      </span>
                    </a>
                    <div class='dropdown-menu dropdown-menu-right'>
                      <Link class='dropdown-item' to='/dashboard/profile'>
                        My Profile
                      </Link>

                      <div
                        className='dropdown-item'
                        onClick={() => {
                          dispatch(userLogout())
                          navigate('/')
                        }}>
                        Logout
                      </div>
                    </div>
                  </div>
                  <div className='dropdown no-caret py-4 px-3 d-flex align-items-center notice mr-3'>
                    <a
                      href='#'
                      className='dropdown-toggle text-heading fs-20 font-weight-500 lh-1'
                      data-toggle='dropdown'>
                      <i className='far fa-bell'></i>
                      <span className='badge badge-primary badge-circle badge-absolute font-weight-bold fs-13'>
                        1
                      </span>
                    </a>
                    <div className='dropdown-menu dropdown-menu-right'>
                      <a className='dropdown-item' href='#'>
                        Action
                      </a>
                      <a className='dropdown-item' href='#'>
                        Another action
                      </a>
                      <a className='dropdown-item' href='#'>
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
                <button
                  class='navbar-toggler border-0 px-0'
                  type='button'
                  data-toggle='collapse'
                  data-target='#primaryMenuSidebar'
                  aria-controls='primaryMenuSidebar'
                  aria-expanded='false'
                  aria-label='Toggle navigation'>
                  <span class='navbar-toggler-icon'></span>
                </button>
              </div>
            </div>
            <div
              class='collapse navbar-collapse bg-white'
              id='primaryMenuSidebar'>
              <form class='d-block d-xl-none pt-5 px-3'>
                <div class='input-group'>
                  <div class='input-group-prepend mr-0 bg-input'>
                    <button
                      class='btn border-0 shadow-none fs-20 text-muted pr-0'
                      type='submit'>
                      <i class='far fa-search'></i>
                    </button>
                  </div>
                  <input
                    type='text'
                    class='form-control border-0 form-control-lg shadow-none'
                    placeholder='Search for...'
                    name='search'
                  />
                </div>
              </form>
              <ul class='list-group list-group-flush w-100'>
                <li class='list-group-item pt-6 pb-4'>
                  <h5 class='fs-13 letter-spacing-087 text-muted mb-3 text-uppercase px-3'>
                    {t('Main')}
                  </h5>
                  <ul class='list-group list-group-no-border rounded-lg'>
                    <li
                      class={
                        (location.pathname == '/dashboard' ? 'active' : '') +
                        ' list-group-item px-3 px-xl-4 py-2 sidebar-item'
                      }>
                      <Link
                        to='/dashboard'
                        class='text-heading lh-1 sidebar-link'>
                        <span class='sidebar-item-icon d-inline-block mr-3 fs-20'>
                          <i class='fal fa-cog'></i>
                        </span>
                        <span class='sidebar-item-text'> {t('dashboard')}</span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li class='list-group-item pt-6 pb-4'>
                  <h5 class='fs-13 letter-spacing-087 text-muted mb-3 text-uppercase px-3'>
                    {t('MANAGE LISTING')}
                  </h5>
                  <ul class='list-group list-group-no-border rounded-lg'>
                    {userInfo && userInfo.isAdmin == true && (
                      <li
                        class={
                          (location.pathname == '/dashboard/addProperty'
                            ? 'active'
                            : '') +
                          ' list-group-item px-3 px-xl-4 py-2 sidebar-item'
                        }>
                        <Link
                          to='/dashboard/addProperty'
                          class='text-heading lh-1 sidebar-link'>
                          <span class='sidebar-item-icon d-inline-block mr-3 text-muted fs-20 fs-20'>
                            <svg class='icon icon-add-new'>
                              <use xlinkHref='#icon-add-new'></use>
                            </svg>
                          </span>
                          <span class='sidebar-item-text'>{t('Add new')}</span>
                        </Link>
                      </li>
                    )}
                    {userInfo && userInfo.isAdmin == true && (
                      <li
                        class={
                          (location.pathname == '/dashboard/myProperty'
                            ? 'active'
                            : '') +
                          ' list-group-item px-3 px-xl-4 py-2 sidebar-item'
                        }>
                        <Link
                          to='/dashboard/myProperty'
                          class='text-heading lh-1 sidebar-link d-flex align-items-center'>
                          <span class='sidebar-item-icon d-inline-block mr-3 text-muted fs-20'>
                            <svg class='icon icon-my-properties'>
                              <use xlinkHref='#icon-my-properties'></use>
                            </svg>
                          </span>
                          <span class='sidebar-item-text'>
                            {t('My Properties')}
                          </span>
                          <span class='sidebar-item-number ml-auto text-primary fs-15 font-weight-bold'>
                            {dashboardInfo?.listings}
                          </span>
                        </Link>
                      </li>
                    )}
                    <li
                      class={
                        (location.pathname == '/dashboard/myReservation'
                          ? 'active'
                          : '') +
                        ' list-group-item px-3 px-xl-4 py-2 sidebar-item'
                      }>
                      <Link
                        to='/dashboard/myReservation'
                        class='text-heading lh-1 sidebar-link d-flex align-items-center'>
                        <span class='sidebar-item-icon d-inline-block mr-3 text-muted fs-20'>
                          <i class='fal fa-receipt'></i>
                        </span>
                        <span class='sidebar-item-text'>
                          {t('Reservations')}
                        </span>
                        <span class='sidebar-item-number ml-auto text-primary fs-15 font-weight-bold'>
                          {dashboardInfo?.reservations}
                        </span>
                      </Link>
                    </li>
                    {userInfo && userInfo.isAdmin == true && (
                      <li
                        class={
                          (location.pathname == '/dashboard/sells'
                            ? 'active'
                            : '') +
                          ' list-group-item px-3 px-xl-4 py-2 sidebar-item'
                        }>
                        <Link
                          to='/dashboard/sells'
                          class='text-heading lh-1 sidebar-link d-flex align-items-center'>
                          <span class='sidebar-item-icon d-inline-block mr-3 text-muted fs-20'>
                            <i class='fal fa-hand-holding-usd fa-lg'></i>
                          </span>
                          <span class='sidebar-item-text'>{t('Sells')}</span>
                          <span class='sidebar-item-number ml-auto text-primary fs-15 font-weight-bold'>
                            {dashboardInfo?.sells}
                          </span>
                        </Link>
                      </li>
                    )}
                    <li
                      class={
                        (location.pathname == '/dashboard/myFavorites'
                          ? 'active'
                          : '') +
                        ' list-group-item px-3 px-xl-4 py-2 sidebar-item'
                      }>
                      <Link
                        to='/dashboard/myFavorites'
                        class='text-heading lh-1 sidebar-link d-flex align-items-center'>
                        <span class='sidebar-item-icon d-inline-block mr-3 text-muted fs-20'>
                          <svg class='icon icon-heart'>
                            <use xlinkHref='#icon-heart'></use>
                          </svg>
                        </span>
                        <span class='sidebar-item-text'>
                          {userInfo && userInfo.isAdmin == false && 'My'}{' '}
                          {t('Favorites')}
                        </span>
                        <span class='sidebar-item-number ml-auto text-primary fs-15 font-weight-bold'>
                          {dashboardInfo?.favorites}
                        </span>
                      </Link>
                    </li>
                    {userInfo && userInfo.isAdmin == true && (
                      <li
                        class={
                          (location.pathname == '/dashboard/saveSearch'
                            ? 'active'
                            : '') +
                          ' list-group-item px-3 px-xl-4 py-2 sidebar-item'
                        }>
                        <Link
                          to='/dashboard/saveSearch'
                          class='text-heading lh-1 sidebar-link d-flex align-items-center'>
                          <span class='sidebar-item-icon d-inline-block mr-3 text-muted fs-20'>
                            <svg class='icon icon-save-search'>
                              <use xlinkHref='#icon-save-search'></use>
                            </svg>
                          </span>
                          <span class='sidebar-item-text'>
                            {t('Save Search')}
                          </span>
                          <span class='sidebar-item-number ml-auto text-primary fs-15 font-weight-bold'>
                            {dashboardInfo?.searches}
                          </span>
                        </Link>
                      </li>
                    )}

                    <li
                      class={
                        (location.pathname == '/dashboard/reviews'
                          ? 'active'
                          : '') +
                        ' list-group-item px-3 px-xl-4 py-2 sidebar-item'
                      }>
                      <Link
                        to='/dashboard/reviews'
                        class='text-heading lh-1 sidebar-link d-flex align-items-center'>
                        <span class='sidebar-item-icon d-inline-block mr-3 text-muted fs-20'>
                          <svg class='icon icon-review'>
                            <use xlinkHref='#icon-review'></use>
                          </svg>
                        </span>
                        <span class='sidebar-item-text'> {t('Reviews')}</span>
                        <span class='sidebar-item-number ml-auto text-primary fs-15 font-weight-bold'>
                          {dashboardInfo?.reviews}
                        </span>
                      </Link>
                    </li>

                    {userInfo && userInfo.isAdmin == true && (
                      <li
                        className={
                          (location.pathname == '/dashboard/partners'
                            ? 'active'
                            : '') +
                          ' list-group-item px-3 px-xl-4 py-2 sidebar-item'
                        }>
                        <Link
                          to='/dashboard/partners'
                          className='text-heading lh-1 sidebar-link'>
                          <span className='sidebar-item-icon d-inline-block mr-3 text-muted fs-20 fs-20'>
                            <i class='fas fa-handshake fa-sm'></i>
                          </span>
                          <span className='sidebar-item-text'>
                            {t('Partners')}
                          </span>
                        </Link>
                      </li>
                    )}

                    {userInfo && userInfo.isAdmin == true && (
                      <li
                        className={
                          (location.pathname == '/dashboard/pdfs'
                            ? 'active'
                            : '') +
                          ' list-group-item px-3 px-xl-4 py-2 sidebar-item'
                        }>
                        <Link
                          to='/dashboard/pdfs'
                          className='text-heading lh-1 sidebar-link'>
                          <span className='sidebar-item-icon d-inline-block mr-3 text-muted fs-20 fs-20'>
                            <i class='fas fa-file-pdf fa-sm'></i>
                          </span>
                          <span className='sidebar-item-text'>
                            {t('PDF List')}
                          </span>
                        </Link>
                      </li>
                    )}

                    {userInfo && userInfo.isAdmin == true && (
                      <li
                        className={
                          (location.pathname == '/dashboard/usefulLinks'
                            ? 'active'
                            : '') +
                          ' list-group-item px-3 px-xl-4 py-2 sidebar-item'
                        }>
                        <Link
                          to='/dashboard/usefulLinks'
                          className='text-heading lh-1 sidebar-link'>
                          <span className='sidebar-item-icon d-inline-block mr-3 text-muted fs-20 fs-20'>
                            <i class='fas fa-link'></i>
                          </span>
                          <span className='sidebar-item-text'>
                            {t('Useful Links')}
                          </span>
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
                <li class='list-group-item pt-6 pb-4'>
                  <h5 class='fs-13 letter-spacing-087 text-muted mb-3 text-uppercase px-3'>
                    {t('Manage Acount')}
                  </h5>
                  <ul class='list-group list-group-no-border rounded-lg'>
                    <li
                      class={
                        (location.pathname == '/dashboard/profile'
                          ? 'active'
                          : '') +
                        ' list-group-item px-3 px-xl-4 py-2 sidebar-item'
                      }>
                      <Link
                        to='/dashboard/profile'
                        class='text-heading lh-1 sidebar-link'>
                        <span class='sidebar-item-icon d-inline-block mr-3 text-muted fs-20'>
                          <svg class='icon icon-my-profile'>
                            <use xlinkHref='#icon-my-profile'></use>
                          </svg>
                        </span>
                        <span class='sidebar-item-text'>
                          {' '}
                          {t('My Profile')}
                        </span>
                      </Link>
                    </li>

                    {userInfo && userInfo.isAdmin == true && (
                      <li
                        class={
                          (location.pathname == '/dashboard/setting'
                            ? 'active'
                            : '') +
                          ' list-group-item px-3 px-xl-4 py-2 sidebar-item'
                        }>
                        <Link
                          to='/dashboard/setting'
                          class='text-heading lh-1 sidebar-link'>
                          <span class='sidebar-item-icon d-inline-block mr-3 text-muted fs-20'>
                            <i class='fal fa-user-cog'></i>
                          </span>
                          <span class='sidebar-item-text'>
                            {' '}
                            {t('Settings')}
                          </span>
                        </Link>
                      </li>
                    )}
                    <li
                      class={' list-group-item px-3 px-xl-4 py-2 sidebar-item'}>
                      <div
                        onClick={() => {
                          dispatch(userLogout())
                          navigate('/')
                        }}
                        class='text-heading lh-1 sidebar-link'>
                        <span class='sidebar-item-icon d-inline-block mr-3 text-muted fs-20'>
                          <svg class='icon icon-log-out'>
                            <use xlinkHref='#icon-log-out'></use>
                          </svg>
                        </span>
                        <span class='sidebar-item-text'> {t('Log Out')}</span>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}
