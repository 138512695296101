import {
  LISTING_ADD_COMMENT_FAIL,
  LISTING_ADD_COMMENT_REQUEST,
  LISTING_ADD_COMMENT_RESET,
  LISTING_ADD_COMMENT_SUCCESS,
  LISTING_ADD_FAIL,
  LISTING_ADD_REQUEST,
  LISTING_ADD_RESERVATION_FAIL,
  LISTING_ADD_RESERVATION_REQUEST,
  LISTING_ADD_RESERVATION_RESET,
  LISTING_ADD_RESERVATION_SUCCESS,
  LISTING_ADD_RESET,
  LISTING_ADD_SUCCESS,
  LISTING_ADD_WISHLIST_FAIL,
  LISTING_ADD_WISHLIST_REQUEST,
  LISTING_ADD_WISHLIST_RESET,
  LISTING_ADD_WISHLIST_SUCCESS,
  LISTING_EDIT_FAIL,
  LISTING_EDIT_REQUEST,
  LISTING_EDIT_RESET,
  LISTING_EDIT_SUCCESS,
  LISTING_DELETE_PROPERTY_FAIL,
  LISTING_DELETE_PROPERTY_REQUEST,
  LISTING_DELETE_PROPERTY_RESET,
  LISTING_DELETE_PROPERTY_SUCCESS,
  LISTING_GET_ALL_WISHLIST_FAIL,
  LISTING_GET_ALL_WISHLIST_REQUEST,
  LISTING_GET_ALL_WISHLIST_RESET,
  LISTING_GET_ALL_WISHLIST_SUCCESS,
  LISTING_LIST_FAIL,
  LISTING_LIST_REQUEST,
  LISTING_LIST_RESET,
  LISTING_LIST_SUCCESS,
  LISTING_LIST_WISHLIST_FAIL,
  LISTING_LIST_WISHLIST_REQUEST,
  LISTING_LIST_WISHLIST_RESET,
  LISTING_LIST_WISHLIST_SUCCESS,
  LSITING_GET_BY_ID_FAIL,
  LSITING_GET_BY_ID_REQUEST,
  LSITING_GET_BY_ID_RESET,
  LSITING_GET_BY_ID_SUCCESS,
} from "../constants/listingConstants";

export const addReservationReducer = (state = {}, action) => {
  switch (action.type) {
    case LISTING_ADD_RESERVATION_REQUEST:
      return { loading: true };
    case LISTING_ADD_RESERVATION_SUCCESS:
      return { loading: false, reservation: action.payload };
    case LISTING_ADD_RESERVATION_FAIL:
      return { loading: false, error: action.payload };
    case LISTING_ADD_RESERVATION_RESET:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const addCommentReducer = (state = {}, action) => {
  switch (action.type) {
    case LISTING_ADD_COMMENT_REQUEST:
      return { loading: true };
    case LISTING_ADD_COMMENT_SUCCESS:
      return { loading: false, comment: action.payload };
    case LISTING_ADD_COMMENT_FAIL:
      return { loading: false, error: action.payload };
    case LISTING_ADD_COMMENT_RESET:
      return {};
    default:
      return state;
  }
};

export const addListingReducer = (state = {}, action) => {
  switch (action.type) {
    case LISTING_ADD_REQUEST:
      return { loading: true };
    case LISTING_ADD_SUCCESS:
      return { loading: false, listing: action.payload };
    case LISTING_ADD_FAIL:
      return { loading: false, error: action.payload };
    case LISTING_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const editListingReducer = (state = {}, action) => {
  switch (action.type) {
    case LISTING_EDIT_REQUEST:
      return { loading: true };
    case LISTING_EDIT_SUCCESS:
      return { loading: false, listing: action.payload };
    case LISTING_EDIT_FAIL:
      return { loading: false, error: action.payload };
    case LISTING_EDIT_RESET:
      return {};
    default:
      return state;
  }
};

export const addWishListReducer = (state = {}, action) => {
  switch (action.type) {
    case LISTING_ADD_WISHLIST_REQUEST:
      return { loading: true };
    case LISTING_ADD_WISHLIST_SUCCESS:
      return { loading: false, wishlist: action.payload };
    case LISTING_ADD_WISHLIST_FAIL:
      return { loading: false, error: action.payload };
    case LISTING_ADD_WISHLIST_RESET:
      return {};
    default:
      return state;
  }
};

export const getListingByIDReducer = (state = {}, action) => {
  switch (action.type) {
    case LSITING_GET_BY_ID_REQUEST:
      return { loading: true };
    case LSITING_GET_BY_ID_SUCCESS:
      return { loading: false, success: true, listing: action.payload };
    case LSITING_GET_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case LSITING_GET_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};

export const getListingsListReducer = (state = { listings: [] }, action) => {
  switch (action.type) {
    case LISTING_LIST_REQUEST:
      return { loading: true };
    case LISTING_LIST_SUCCESS:
      return { loading: false, listings: action.payload };
    case LISTING_LIST_FAIL:
      return { loading: false, error: action.payload };
    case LISTING_LIST_RESET:
      return { listings: [] };
    default:
      return state;
  }
};

export const getWishListReducer = (state = { wishlists: [] }, action) => {
  switch (action.type) {
    case LISTING_LIST_WISHLIST_REQUEST:
      return { loading: true };
    case LISTING_LIST_WISHLIST_SUCCESS:
      return { loading: false, wishlists: action.payload };
    case LISTING_LIST_WISHLIST_FAIL:
      return { loading: false, error: action.payload };
    case LISTING_LIST_WISHLIST_RESET:
      return { wishlists: [] };
    default:
      return state;
  }
};

export const deletePropertyReducer = (state = {}, action) => {
  switch (action.type) {
    case LISTING_DELETE_PROPERTY_REQUEST:
      return {
        loading: true,
      };
    case LISTING_DELETE_PROPERTY_SUCCESS:
      return {
        success: true,
        data: action.payload,
      };
    case LISTING_DELETE_PROPERTY_FAIL:
      return {
        error: action.payload,
      };
    case LISTING_DELETE_PROPERTY_RESET:
      return {};
    default:
      return state;
  }
};

export const getAllWishListReducer = (state = { allWishlists: [] }, action) => {
  switch (action.type) {
    case LISTING_GET_ALL_WISHLIST_REQUEST:
      return { loading: true };
    case LISTING_GET_ALL_WISHLIST_SUCCESS:
      return { loading: false, allWishlists: action.payload };
    case LISTING_GET_ALL_WISHLIST_FAIL:
      return { loading: false, error: action.payload };
    case LISTING_GET_ALL_WISHLIST_RESET:
      return { allWishlists: [] };
    default:
      return state;
  }
};
