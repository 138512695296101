import {
  GET_DASHBOARD_INFO_FAIL,
  GET_DASHBOARD_INFO_REQUEST,
  GET_DASHBOARD_INFO_RESET,
  GET_DASHBOARD_INFO_SUCCESS,
  GET_HOMEPAGE_INFO_FAIL,
  GET_HOMEPAGE_INFO_REQUEST,
  GET_HOMEPAGE_INFO_RESET,
  GET_HOMEPAGE_INFO_SUCCESS,
} from "../constants/publicConstants";

export const getPublicInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_HOMEPAGE_INFO_REQUEST:
      return { loading: true };
    case GET_HOMEPAGE_INFO_SUCCESS:
      return { loading: false, publicInfo: action.payload };
    case GET_HOMEPAGE_INFO_FAIL:
      return { loading: false, error: action.payload };
    case GET_HOMEPAGE_INFO_RESET:
      return { listings: [] };
    default:
      return state;
  }
};

export const getDashboardReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_DASHBOARD_INFO_REQUEST:
      return { loading: true };
    case GET_DASHBOARD_INFO_SUCCESS:
      return { loading: false, dashboardInfo: action.payload };
    case GET_DASHBOARD_INFO_FAIL:
      return { loading: false, error: action.payload };
    case GET_DASHBOARD_INFO_RESET:
      return { listings: [] };
    default:
      return state;
  }
};
