import {
  legacy_createStore as createStore,
  combineReducers,
  applyMiddleware,
} from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from '@redux-devtools/extension'
import {
  deleteUserReducer,
  updateUserReducer,
  userListReducer,
  userLoginReducer,
  userProfileDetailsReducer,
  userRegisterReducer,
  userUpdateProfileReducer,
} from './reducers/userReducer'
import {
  addCommentReducer,
  addListingReducer,
  addReservationReducer,
  addWishListReducer,
  editListingReducer,
  getListingByIDReducer,
  getListingsListReducer,
  getWishListReducer,
  getAllWishListReducer,
} from './reducers/listingReducer'
import {
  addAmentiesReducer,
  deleteAmenityReducer,
  getAmenitiesListReducer,
} from './reducers/amenityReducer'
import {
  getDashboardReducer,
  getPublicInfoReducer,
} from './reducers/publicReducer'
import { addSearchReducer, headerReducer } from './reducers/searchReducer'

import {
  deleteReservationReducer,
  getReservationReducer,
  getReviewsReducer,
  getSavedSearchReducer,
} from './reducers/dashboardReducer'
import {
  addCompareReducer,
  deleteCompareReducer,
  getCompareListReducer,
} from './reducers/compareReducer'
import { getRecentActivityListReducer } from './reducers/recentActivityReducer'
import {
  getSettingsListReducer,
  updateSettingsReducer,
} from './reducers/settingsReducer'
import { addSellReducer, getSellListReducer } from './reducers/sellReducer'
import {
  addPartnerReducer,
  deletePartnerReducer,
  getPartnersListReducer,
  updatePartnerReducer,
} from './reducers/partnerReducer'
import {
  addPDFReducer,
  deletePDFReducer,
  getPDFsListReducer,
  updatePDFReducer,
} from './reducers/pdfsReducer'
import {
  addLinkReducer,
  deleteLinkReducer,
  getLinksListReducer,
  updateLinkReducer,
} from './reducers/usefulLinksReducer'
const reducer = combineReducers({
  //public
  getPublicInfo: getPublicInfoReducer,
  getDashboard: getDashboardReducer,

  //user
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userProfileDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: deleteUserReducer,
  userUpdate: updateUserReducer,

  //listing
  getListingByID: getListingByIDReducer,
  getListingsList: getListingsListReducer,
  addListing: addListingReducer,
  editListing: editListingReducer,

  //reservation
  addReservation: addReservationReducer,
  getReservation: getReservationReducer,
  deleteReservation: deleteReservationReducer,

  //comment
  addComment: addCommentReducer,
  getReviews: getReviewsReducer,

  // wishList
  addWishList: addWishListReducer,
  getWishList: getWishListReducer,
  getAllWishList: getAllWishListReducer,

  //amenities
  addAmenty: addAmentiesReducer,
  deleteAmenity: deleteAmenityReducer,
  getAmenities: getAmenitiesListReducer,

  //partners
  addPartner: addPartnerReducer,
  updatePartner: updatePartnerReducer,
  deletePartner: deletePartnerReducer,
  getPartners: getPartnersListReducer,

  //useful Links
  addLink: addLinkReducer,
  updateLink: updateLinkReducer,
  deleteLink: deleteLinkReducer,
  getLinksList: getLinksListReducer,

  //partners
  addPDF: addPDFReducer,
  updatePDF: updatePDFReducer,
  deletePDF: deletePDFReducer,
  getPDFsList: getPDFsListReducer,

  //search
  addSearch: addSearchReducer,
  getSavedSearch: getSavedSearchReducer,

  //compares
  addCompare: addCompareReducer,
  deleteCompare: deleteCompareReducer,
  getCompare: getCompareListReducer,

  //recent Activity
  getRecentActivity: getRecentActivityListReducer,

  //settings
  updateSettings: updateSettingsReducer,
  getSettingsList: getSettingsListReducer,

  // sell
  addSell: addSellReducer,
  getSellList: getSellListReducer,

  //header
  header: headerReducer,
})

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null

const initialState = {
  userLogin: {
    userInfo: userInfoFromStorage,
  },
  header: localStorage.getItem('header'),
}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
