export const LSITING_GET_BY_ID_REQUEST = "LSITING_GET_BY_ID_REQUEST";
export const LSITING_GET_BY_ID_SUCCESS = "LSITING_GET_BY_ID_SUCCESS";
export const LSITING_GET_BY_ID_FAIL = "USER_LOGIN_FAIL";
export const LSITING_GET_BY_ID_RESET = "USER_LOGIN_RESET";

export const LISTING_ADD_REQUEST = "LISTING_ADD_REQUEST";
export const LISTING_ADD_SUCCESS = "LISTING_ADD_SUCCESS";
export const LISTING_ADD_FAIL = "LISTING_ADD_FAIL";
export const LISTING_ADD_RESET = "LISTING_ADD_RESET";

export const LISTING_EDIT_REQUEST = "LISTING_EDIT_REQUEST";
export const LISTING_EDIT_SUCCESS = "LISTING_EDIT_SUCCESS";
export const LISTING_EDIT_FAIL = "LISTING_EDIT_FAIL";
export const LISTING_EDIT_RESET = "LISTING_EDIT_RESET";

export const LISTING_LIST_REQUEST = "LISTING_LIST_REQUEST";
export const LISTING_LIST_SUCCESS = "LISTING_LIST_SUCCESS";
export const LISTING_LIST_FAIL = "LISTING_LIST_FAIL";
export const LISTING_LIST_RESET = "LISTING_LIST_RESET";

export const LISTING_LIST_WISHLIST_REQUEST = "LISTING_LIST_WISHLIST_REQUEST";
export const LISTING_LIST_WISHLIST_SUCCESS = "LISTING_LIST_WISHLIST_SUCCESS";
export const LISTING_LIST_WISHLIST_FAIL = "LISTING_LIST_WISHLIST_FAIL";
export const LISTING_LIST_WISHLIST_RESET = "LISTING_LIST_WISHLIST_RESET";

export const LISTING_ADD_RESERVATION_REQUEST =
  "LISTING_ADD_RESERVATION_REQUEST";
export const LISTING_ADD_RESERVATION_SUCCESS =
  "LISTING_ADD_RESERVATION_SUCCESS";
export const LISTING_ADD_RESERVATION_FAIL = "LISTING_ADD_RESERVATION_FAIL";
export const LISTING_ADD_RESERVATION_RESET = "LISTING_ADD_RESERVATION_RESET";

export const LISTING_ADD_COMMENT_REQUEST = "LISTING_ADD_COMMENT_REQUEST";
export const LISTING_ADD_COMMENT_SUCCESS = "LISTING_ADD_COMMENT_SUCCESS";
export const LISTING_ADD_COMMENT_FAIL = "LISTING_ADD_COMMENT_FAIL";
export const LISTING_ADD_COMMENT_RESET = "LISTING_ADD_COMMENT_RESET";

export const LISTING_ADD_WISHLIST_REQUEST = "LISTING_ADD_WISHLIST_REQUEST";
export const LISTING_ADD_WISHLIST_SUCCESS = "LISTING_ADD_WISHLIST_SUCCESS";
export const LISTING_ADD_WISHLIST_FAIL = "LISTING_ADD_WISHLIST_FAIL";
export const LISTING_ADD_WISHLIST_RESET = "LISTING_ADD_WISHLIST_RESET";

export const LISTING_DELETE_PROPERTY_REQUEST =
  "LISTING_DELETE_PROPERTY_REQUEST";
export const LISTING_DELETE_PROPERTY_SUCCESS =
  "LISTING_DELETE_PROPERTY_SUCCESS";
export const LISTING_DELETE_PROPERTY_FAIL = "LISTING_DELETE_PROPERTY_FAIL";
export const LISTING_DELETE_PROPERTY_RESET = "LISTING_DELETE_PROPERTY_RESET";

export const LISTING_GET_ALL_WISHLIST_REQUEST =
  "LISTING_GET_ALL_WISHLIST_REQUEST";
export const LISTING_GET_ALL_WISHLIST_SUCCESS =
  "LISTING_GET_ALL_WISHLIST_SUCCESS";
export const LISTING_GET_ALL_WISHLIST_FAIL = "LISTING_GET_ALL_WISHLIST_FAIL";
export const LISTING_GET_ALL_WISHLIST_RESET = "LISTING_GET_ALL_WISHLIST_RESET";
