import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { alertToast } from "../../components/utils/alertHandler";
import Pagination from "../../components/utils/Pagination";
import {
  PDFS_ADD_RESET,
  PDFS_UPDATE_RESET,
} from "../../constants/pdfsConstants";
import {
  addPdfAction,
  deletePdfAction,
  getlistPdfsAction,
  updatePdfAction,
} from "../../actions/pdfActions";
import { BACKEND_IMAGE } from "../../utils/apiConfig";
import { useTranslation } from "react-i18next";

export default function DashboardPDFS() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const getPDFsList = useSelector((state) => state.getPDFsList);
  const { pdfs } = getPDFsList;

  const addPDF = useSelector((state) => state.addPDF);
  const { pdf } = addPDF;

  const updatePDF = useSelector((state) => state.updatePDF);
  const { pdf: uPdf } = updatePDF;

  const [pdfData, setPdfData] = useState({
    title: "",
    pdf: null,
  });

  const [isAdd, setIsAdd] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 6;
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPdfs = pdfs?.slice(firstPostIndex, lastPostIndex);

  useEffect(() => {
    dispatch(getlistPdfsAction());
  }, [dispatch]);

  const deleleteHandler = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deletePdfAction(id));
        Swal.fire("Deleted!", "Your product has been deleted.", "success");
      }
    });
  };

  useEffect(() => {
    if (uPdf) {
      alertToast("success", "PDF updated successfully");
      dispatch({ type: PDFS_UPDATE_RESET });
      document.getElementById("addTT").click();
    }
  }, [uPdf, dispatch]);

  useEffect(() => {
    if (pdf) {
      alertToast("success", "PDF added successfully");
      dispatch({ type: PDFS_ADD_RESET });
      document.getElementById("addTT").click();
    }
  }, [pdf, dispatch]);

  const submitPartnerData = () => {
    let formData = new FormData();
    formData.append("title", pdfData.title);
    formData.append("pdf", pdfData.pdf);
    if (isAdd) {
      dispatch(addPdfAction(formData));
    } else {
      dispatch(updatePdfAction(formData, pdfData._id));
    }
  };

  return (
    <main id="content" className="bg-gray-01">
      <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10 invoice-listing">
        <div className="mb-6">
          <div className="row">
            <div className="col-sm-12 col-md-6 d-flex justify-content-md-start justify-content-center">
              <div className="mr-0 mr-md-auto">
                <h2 className="mb-0 text-heading fs-22 lh-15">
                  {t("PDF List")}
                  <span className="badge badge-white badge-pill text-primary fs-18 font-weight-bold ml-2">
                    {pdfs?.length}
                  </span>
                </h2>
                <p>{t("Access and manage all your PDF in one place")}</p>
              </div>
            </div>

            <div className="col-sm-12 col-md-6 d-flex justify-content-md-end justify-content-center mt-md-0 mt-3">
              <div className="align-self-center" style={{ minWidth: "140px" }}>
                <button
                  className="btn btn-primary btn-lg"
                  tabindex="0"
                  onClick={() => {
                    setIsAdd(true);
                    setPdfData({ title: "", pdf: null });
                  }}
                  data-toggle="modal"
                  href="#add_edit-modal"
                  id="addTT"
                >
                  <span>{t("Add PDF")}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-hover bg-white border rounded-lg">
            <thead>
              <tr role="row">
                <th className="py-6">{t("Title")}</th>
                <th className="py-6">PDF</th>
                <th className="no-sort py-6">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentPdfs &&
                currentPdfs?.map((pdfsingle) => (
                  <tr role="row">
                    <td className="align-middle">{pdfsingle.title}</td>

                    <td className="align-middle">
                      <a target="_blank" href={BACKEND_IMAGE + pdfsingle.pdf}>
                        <i className="fal fa-file-pdf fa-lg"></i>
                      </a>
                    </td>

                    <td className="align-right">
                      <div
                        onClick={() => {
                          setIsAdd(false);
                          setPdfData({ ...pdfsingle, pdf: null });
                        }}
                        data-toggle="modal"
                        href="#add_edit-modal"
                        title="Edit"
                        className="d-inline-block fs-18 text-muted hover-primary mr-5"
                      >
                        <i className="fal fa-pencil-alt"></i>
                      </div>
                      <div
                        onClick={() => deleleteHandler(pdfsingle._id)}
                        data-toggle="tooltip"
                        title="Delete"
                        className="d-inline-block fs-18 text-muted hover-primary"
                      >
                        <i className="fal fa-trash-alt"></i>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <nav className="mt-6">
          <Pagination
            totalPosts={pdfs?.length}
            postsPerPage={postsPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </nav>
      </div>
      <div
        className="modal fade"
        id="add_edit-modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="add_edit-modal"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered "
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{t("PDF List")}</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body p-4 py-sm-7 px-sm-8">
              <div className="tab-content shadow-none p-0" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="login"
                  role="tabpanel"
                  aria-labelledby="login-tab"
                >
                  <section className="py-2">
                    <form>
                      <div className="row">
                        <div className="col-md-12 mb-4">
                          <label>{t("Title")} :</label>
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control border-0 pr-3 h-52 pl-7"
                              value={pdfData.title}
                              name="total-amount"
                              onChange={(e) =>
                                setPdfData({
                                  ...pdfData,
                                  title: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 mb-4">
                          <label>Logo :</label>
                          <div className="position-relative">
                            <input
                              type="file"
                              className="form-control border-0 pr-3 h-52 pl-7"
                              accept="application/pdf"
                              onChange={(e) =>
                                setPdfData({
                                  ...pdfData,
                                  pdf: e.target.files[0],
                                })
                              }
                              name="interest-rate"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <button
                            className="btn btn-primary fs-14 h-52 px-8"
                            type="button"
                            onClick={(e) => submitPartnerData()}
                          >
                            {t("Submit")}
                          </button>
                        </div>
                      </div>
                    </form>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
