import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import {
  deletePropertyAction,
  getListingsListActions,
} from '../../actions/listingActions'
import { BACKEND_IMAGE } from '../../utils/apiConfig'
import Pagination from '../../components/utils/Pagination'
import { useTranslation } from 'react-i18next'

const DashboardMyProperty = () => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  const getListingsList = useSelector(state => state.getListingsList)
  const { listings, error } = getListingsList

  const [currentPage, setCurrentPage] = useState(1)
  const postsPerPage = 8
  const lastPostIndex = currentPage * postsPerPage
  const firstPostIndex = lastPostIndex - postsPerPage
  const currentListings = listings?.listings?.slice(
    firstPostIndex,
    lastPostIndex
  )

  useEffect(() => {
    dispatch(getListingsListActions())
  }, [dispatch])

  const deleleteHandler = id => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(deletePropertyAction(id))
        Swal.fire('Deleted!', 'Your product has been deleted.', 'success')
      }
    })
  }

  return (
    <>
      <main id='content' className='bg-gray-01'>
        <div className='px-3 px-lg-6 px-xxl-13 py-5 py-lg-10'>
          <div className='d-flex flex-wrap flex-md-nowrap mb-6'>
            <div className='mr-0 mr-md-auto'>
              <h2 className='mb-0 text-heading fs-22 lh-15'>
                {t('My Properties')}
                <span className='badge badge-white badge-pill text-primary fs-18 font-weight-bold ml-2'>
                  {listings && listings?.listings?.length}
                </span>
              </h2>
              <p>
                {' '}
                {t('Access and manage all your listed properties in one place')}
              </p>
            </div>
            <div className='form-inline justify-content-md-end mx-n2'>
              <div className='p-2'>
                <div className='input-group input-group-lg bg-white border'>
                  <div className='input-group-prepend'>
                    <button className='btn pr-0 shadow-none' type='button'>
                      <i className='far fa-search'></i>
                    </button>
                  </div>
                  <input
                    type='text'
                    className='form-control bg-transparent border-0 shadow-none text-body'
                    placeholder='Search listing'
                    name='search'
                  />
                </div>
              </div>
              <div className='p-2'>
                <div className='input-group input-group-lg bg-white border'>
                  <div className='input-group-prepend'>
                    <span className='input-group-text bg-transparent letter-spacing-093 border-0 pr-0'>
                      <i className='far fa-align-left mr-2'></i> {t('Sort by')}{' '}
                      :
                    </span>
                  </div>
                  <select
                    className='form-control bg-transparent pl-0 selectpicker d-flex align-items-center sortby'
                    name='sort-by'
                    data-style='bg-transparent px-1 py-0 lh-1 font-weight-600 text-body'
                    id='status'>
                    <option>Alphabet</option>
                    <option>Price - Low to High</option>
                    <option>Price - High to Low</option>
                    <option>Date - Old to New</option>
                    <option>Date - New to Old</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className='table-responsive'>
            <table className='table table-hover bg-white border rounded-lg'>
              <thead className='thead-sm thead-black'>
                <tr>
                  <th scope='col' className='border-top-0 px-6 pt-5 pb-4'>
                    {t('Listing title')}
                  </th>
                  <th scope='col' className='border-top-0 pt-5 pb-4'>
                    {t('Date Published')}
                  </th>
                  <th scope='col' className='border-top-0 pt-5 pb-4'>
                    {t('Status')}
                  </th>
                  <th scope='col' className='border-top-0 pt-5 pb-4'>
                    {t('View')}
                  </th>
                  <th scope='col' className='border-top-0 pt-5 pb-4'>
                    {t('Action')}
                  </th>
                </tr>
              </thead>
              {currentListings &&
                currentListings?.map(listing => (
                  <tbody>
                    <tr className='shadow-hover-xs-2 bg-hover-white'>
                      <td className='align-middle pt-6 pb-4 px-6'>
                        <div className='media'>
                          <div className='w-120px mr-4 position-relative'>
                            <Link to={'/listing/' + listing._id}>
                              <img
                                src={
                                  BACKEND_IMAGE + listing.images?.split(',')[0]
                                }
                                alt='Home in Metric Way'
                              />
                            </Link>
                            <span className='badge badge-indigo position-absolute pos-fixed-top'>
                              {listing.listedIn}
                            </span>
                          </div>
                          <div className='media-body'>
                            <Link
                              to={'/listing/' + listing._id}
                              href='single-property-1.html'
                              className='text-dark
                              hover-primary'>
                              <h5 className='fs-16 mb-0 lh-18'>
                                {listing.title}
                              </h5>
                            </Link>
                            <p className='mb-1 font-weight-500'>
                              {listing.address.adresse}
                            </p>
                            <span className='text-heading lh-15 font-weight-bold fs-17'>
                              {listing.price.price}
                            </span>
                            {listing.listedIn == 'rent' ? (
                              <span className='text-gray-light'>
                                / {t('month')}{' '}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </td>
                      <td className='align-middle'>
                        {listing.createdAt
                          .slice(0, 10)
                          .split('-')
                          .reverse()
                          .join('-')}
                      </td>
                      {listing.status == true ? (
                        <td className='align-middle'>
                          <span className='badge text-capitalize font-weight-normal fs-12 badge-green'>
                            {t('Active')}
                          </span>
                        </td>
                      ) : (
                        <td className='align-middle'>
                          <span className='badge text-capitalize font-weight-normal fs-12 badge-danger'>
                            {t('Sold')}
                          </span>
                        </td>
                      )}

                      <td className='align-middle'>{listing.nbrViews}</td>
                      <td className='align-middle'>
                        <Link
                          to={'/dashboard/editProperty/' + listing._id}
                          data-toggle='tooltip'
                          title='Edit'
                          className='d-inline-block fs-18 text-muted hover-primary mr-5'>
                          <i className='fal fa-pencil-alt'></i>
                        </Link>
                        <button
                          onClick={() => deleleteHandler(listing._id)}
                          className='btn  d-inline-block fs-18 text-muted hover-primary'>
                          <i className='fal fa-trash-alt'></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                ))}
            </table>
          </div>
          <Pagination
            totalPosts={listings?.listings?.length}
            postsPerPage={postsPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </div>
      </main>
    </>
  )
}

export default DashboardMyProperty
