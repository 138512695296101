import axios from "axios";
import { GET_RECENT_ACTIVITY } from "../utils/apiConfig";
import {
  RECENT_ACTIVITY_GET_ALL_FAIL,
  RECENT_ACTIVITY_GET_ALL_REQUEST,
  RECENT_ACTIVITY_GET_ALL_SUCCESS,
} from "../constants/recentActivityConstants";

export const getlistRecentActivitiesAction =
  () => async (dispatch, getState) => {
    try {
      dispatch({ type: RECENT_ACTIVITY_GET_ALL_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(GET_RECENT_ACTIVITY, config);

      dispatch({
        type: RECENT_ACTIVITY_GET_ALL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: RECENT_ACTIVITY_GET_ALL_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
