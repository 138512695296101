import React, { useEffect, useState } from "react";

export const Loader = ({ fullScreen, type }) => {
  const [fullScreenParam, setFullScreenParam] = useState({
    textAlign: "center",
    minHeight: "150px",
    position: "relative",
  });
  useEffect(() => {
    if (fullScreen) {
      setFullScreenParam({
        ...fullScreenParam,
        height: "100vh",
      });
    }
  }, []);

  return type === "btn" ? (
    <i class="fas fa-spinner fa-spin" style={{ color: "#f4f5f5" }}></i>
  ) : (
    <div className="loader" style={fullScreenParam}>
      <img
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        src="/loader/loader.gif"
        alt="loader"
      />
    </div>
  );
};
