import React, { useState } from 'react'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
export default function CalculeHypo() {
  const { t, i18n } = useTranslation()
  const [calculeData, setCalculeData] = useState({
    total: 0,
    years: 0,
    tax: 0,
    type: 'Monthly',
    result: {
      payment: 0,
      tax: 0,
      total: 0,
    },
  })

  const submitCalcule = () => {
    let nbr = 0
    if (calculeData.type == 'Yearly') {
      nbr = 1
    } else if (calculeData.type == 'Monthly') {
      nbr = 12
    }

    const payment =
      calculeData.years > 0 ? calculeData.total / (calculeData.years * nbr) : 0
    const tax = (calculeData.total * calculeData.tax) / 100
    setCalculeData({
      ...calculeData,
      result: {
        payment: payment.toFixed(2),
        tax: tax.toFixed(2),
        total: (payment * nbr * calculeData.years + tax).toFixed(2),
      },
    })
  }
  return (
    <div
      className='modal fade'
      id='calculeHypo-modal'
      tabIndex='-1'
      role='dialog'
      aria-labelledby='calculeHypo-modal'
      aria-hidden='true'>
      <div
        className='modal-dialog modal-lg modal-dialog-centered '
        role='document'>
        <div className='modal-content'>
          <div class='modal-header'>
            <h5 class='modal-title'> {t('Mortgage calculator')}</h5>
            <button
              type='button'
              class='close'
              data-dismiss='modal'
              aria-label='Close'>
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='modal-body p-4 py-sm-7 px-sm-8'>
            <div className='tab-content shadow-none p-0' id='myTabContent'>
              <div
                className='tab-pane fade show active'
                id='login'
                role='tabpanel'
                aria-labelledby='login-tab'>
                <section class='py-6 border-bottom'>
                  <form>
                    <div class='row'>
                      <div class='col-md-6 mb-4'>
                        <label> {t('Mortgage amount')}:</label>
                        <div class='position-relative'>
                          <input
                            type='number'
                            class='form-control border-0 pr-3 h-52 pl-7'
                            value={calculeData.total}
                            name='total-amount'
                            onChange={e =>
                              setCalculeData({
                                ...calculeData,
                                total: e.target.value,
                              })
                            }
                          />
                          <span class='position-absolute pl-3 pos-fixed-left-center fs-13 font-weight-600'>
                            $
                          </span>
                        </div>
                      </div>
                      <div class='col-md-6 mb-4'>
                        <label> {t('Length of the mortgage')} </label>
                        <div class='position-relative'>
                          <input
                            type='number'
                            class='form-control border-0 pr-3 h-52 pl-7'
                            name='total-amount'
                            value={calculeData.years}
                            onChange={e =>
                              setCalculeData({
                                ...calculeData,
                                years: e.target.value,
                              })
                            }
                          />
                          <span class='position-absolute pl-3 pos-fixed-left-center fs-13 font-weight-600'></span>
                        </div>
                      </div>
                    </div>
                    <div class='row'>
                      <div class='col-md-6 mb-4'>
                        <label> {t('Interest Rate')}</label>
                        <div class='position-relative'>
                          <input
                            type='number'
                            class='form-control border-0 pr-3 h-52 pl-7'
                            value={calculeData.tax}
                            onChange={e =>
                              setCalculeData({
                                ...calculeData,
                                tax: e.target.value,
                              })
                            }
                            name='interest-rate'
                          />
                          <span class='position-absolute pl-3 pos-fixed-left-center fs-13 font-weight-600'>
                            %
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class='form-group mb-6'>
                      <label> {t('Payment Period')}</label>
                      <Select
                        onChange={e =>
                          setCalculeData({
                            ...calculeData,
                            type: e.value,
                          })
                        }
                        options={[
                          {
                            label: 'Monthely',
                            value: 'Monthly',
                          },
                          {
                            label: 'Yearly',
                            value: 'Yearly',
                          },
                        ]}
                      />
                    </div>
                    <div class='row'>
                      <div class='col-lg-6'>
                        <button
                          class='btn btn-primary fs-14 h-52 px-8'
                          type='button'
                          onClick={e => submitCalcule()}>
                          {t('Caculate')}
                        </button>
                      </div>
                      <div class='col-lg-6'>
                        <div class='shadow-xxs-2 pt-1 pb-2 px-6 border-bottom border-primary border-5x rounded-lg'>
                          <dl class='d-flex mb-0 justify-content-between py-2'>
                            <dt class='font-weight-normal'>
                              {calculeData.type} {t('Payment')}
                            </dt>
                            <dd class='font-weight-500 text-heading mb-0'>
                              ${calculeData.result?.payment}
                            </dd>
                          </dl>
                          <dl class='d-flex mb-0 justify-content-between border-top py-2'>
                            <dt class='font-weight-normal'>
                              {' '}
                              {t('Total tax')}
                            </dt>
                            <dd class='font-weight-500 text-heading mb-0'>
                              ${calculeData.result?.tax}
                            </dd>
                          </dl>
                          <dl class='d-flex mb-0 justify-content-between border-top py-2'>
                            <dt class='font-weight-normal'> {t('Total')}</dt>
                            <dd class='font-weight-500 text-heading mb-0'>
                              ${calculeData.result?.total}
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </form>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
