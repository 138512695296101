import React from "react";

export default function Pagination({
  totalPosts,
  postsPerPage,
  setCurrentPage,
  currentPage,
}) {
  let pages = [];

  const disabledStyle = {
    color: "gray",
    cursor: "not-allowed",
  };
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pages.push(i);
  }

  return (
    <>
      {totalPosts > 0 && (
        <nav className="mt-6">
          <ul className="pagination rounded-active justify-content-center">
            <li className="page-item">
              <div
                className="page-link"
                style={currentPage === 1 ? disabledStyle : {}}
              >
                <i className="far fa-angle-double-left"></i>
              </div>
            </li>
            {pages.map((page, index) => {
              return (
                <li
                  key={index}
                  className={
                    (page === currentPage ? "active" : "") + " page-item"
                  }
                >
                  <div
                    className="page-link"
                    onClick={() => setCurrentPage(page)}
                  >
                    {page}
                  </div>
                </li>
              );
            })}

            <li className="page-item">
              <div
                className="page-link"
                style={currentPage === pages.length ? disabledStyle : {}}
              >
                <i className="far fa-angle-double-right"></i>
              </div>
            </li>
          </ul>
        </nav>
      )}
    </>
  );
}
