export const getDate = (date) => {
  let deff = (new Date(Date.now()) - new Date(date)) / 1000;

  if (deff > 60) {
    deff = deff / 60;
    if (deff > 60) {
      deff = deff / 60;
      if (deff > 24) {
        deff = deff / 24;
        if (deff > 30) {
          deff = deff / 30;
          if (deff > 12) return Math.ceil(deff / 12) + " years";
          else return Math.ceil(deff) + " months";
        } else return Math.ceil(deff) + " days";
      } else return Math.ceil(deff) + " houres";
    } else return Math.ceil(deff) + " minutes";
  } else return Math.ceil(deff) + " seconds";
};

export const loadScript = (src) => {
  return new Promise(function (resolve, reject) {
    var script = document.createElement("script");
    script.onload = function () {
      resolve();
    };
    script.onerror = function () {
      reject();
    };
    script.src = src;
    document.body.appendChild(script);
  });
};

export const categoryList = [
  { value: "house", label: "House" },
  { value: "office", label: "Office" },
  { value: "villa", label: "Villa" },
  { value: "apartment", label: "Apartment" },
];

export const listedInList = [
  { value: "rent", label: "Rent" },
  { value: "sale", label: "Sale" },
];

export const statusList = [
  { value: "true", label: "Active" },
  { value: "false", label: "Not Active" },
];

export const countryList = [{ value: "Canada", label: "Canada" }];

export const defaulPosition = [-115.1958723, 61.2450318];
