import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userLoginAction, userRegister } from '../../actions/userActions'

const LoginRegister = () => {
  const dispatch = useDispatch()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const userLogin = useSelector(state => state.userLogin)

  const { loading, userInfo, error } = userLogin

  const submitHandler = e => {
    //Dispatch Login
    dispatch(userLoginAction(email, password))
  }

  /// register

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState('')

  const userRegist = useSelector(state => state.userRegister)
  const { loadingRegister, userInfoRegister, errorRegister } = userRegist

  const submitRegisterHandler = e => {
    if (password !== confirmPassword) {
      setMessage('Password do not match')
    } else {
      setMessage('')
      dispatch(userRegister(firstName, lastName, phone, email, password))
    }
  }

  useEffect(() => {
    if (userInfo || userInfoRegister) {
      document.getElementById('closeModale')?.click()
    }
  }, [userInfo, userInfoRegister])
  return (
    <>
      <div
        className='modal fade login-register login-register-modal'
        id='login-register-modal'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='login-register-modal'
        aria-hidden='true'>
        <div
          className='modal-dialog modal-dialog-centered mxw-571'
          role='document'>
          <div className='modal-content'>
            <div className='modal-header border-0 p-0'>
              <div
                className='nav nav-tabs row w-100 no-gutters'
                id='myTab'
                role='tablist'>
                <a
                  className='nav-item col-sm-3 ml-0 nav-link pr-6 py-4 pl-9 active fs-18'
                  id='login-tab'
                  data-toggle='tab'
                  href='#login'
                  role='tab'
                  aria-controls='login'
                  aria-selected='true'>
                  Login
                </a>
                <a
                  className='nav-item col-sm-3 ml-0 nav-link py-4 px-6 fs-18'
                  id='register-tab'
                  data-toggle='tab'
                  href='#register'
                  role='tab'
                  aria-controls='register'
                  aria-selected='false'>
                  Register
                </a>
                <div className='nav-item col-sm-6 ml-0 d-flex align-items-center justify-content-end'>
                  <button
                    type='button'
                    className='close m-0 fs-23'
                    data-dismiss='modal'
                    id='closeModale'
                    aria-label='Close'>
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
              </div>
            </div>
            <div className='modal-body p-4 py-sm-7 px-sm-8'>
              <div className='tab-content shadow-none p-0' id='myTabContent'>
                <div
                  className='tab-pane fade show active'
                  id='login'
                  role='tabpanel'
                  aria-labelledby='login-tab'>
                  <form className='form'>
                    {error && <div className='alert alert-danger'>{error}</div>}
                    <div className='form-group mb-4'>
                      <label htmlFor='username' className='sr-only'>
                        Username
                      </label>
                      <div className='input-group input-group-lg'>
                        <div className='input-group-prepend '>
                          <span
                            className='input-group-text bg-gray-01 border-0 text-muted fs-18'
                            id='inputGroup-sizing-lg'>
                            <i className='far fa-user'></i>
                          </span>
                        </div>
                        <input
                          type='text'
                          className='form-control border-0 shadow-none fs-13'
                          id='username'
                          name='username'
                          onChange={e => setEmail(e.target.value)}
                          required
                          placeholder='Username / Your email'
                        />
                      </div>
                    </div>
                    <div className='form-group mb-4'>
                      <label htmlFor='password' className='sr-only'>
                        Password
                      </label>
                      <div className='input-group input-group-lg'>
                        <div className='input-group-prepend '>
                          <span className='input-group-text bg-gray-01 border-0 text-muted fs-18'>
                            <i className='far fa-lock'></i>
                          </span>
                        </div>
                        <input
                          type='password'
                          className='form-control border-0 shadow-none fs-13'
                          id='password'
                          name='password'
                          onChange={e => setPassword(e.target.value)}
                          required
                          placeholder='Password'
                        />
                        <div className='input-group-append'>
                          <span className='input-group-text bg-gray-01 border-0 text-body fs-18'>
                            <i className='far fa-eye-slash'></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* <div className='d-flex mb-4'>
                      <div className='form-check'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='remember-me'
                          name='remember-me'
                        />
                        <label
                          className='form-check-label'
                          htmlFor='remember-me'>
                          Remember me
                        </label>
                      </div>
                      <a
                        href='password-recovery.html'
                        className='d-inline-block ml-auto text-orange fs-15'>
                        Lost password?
                      </a>
                    </div> */}
                    {/* <div className='d-flex p-2 border re-capchar align-items-center mb-4'>
                      <div className='form-check'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='verify'
                          name='verify'
                        />
                        <label className='form-check-label' htmlFor='verify'>
                          I'm not a robot
                        </label>
                      </div>
                      <a href='#' className='d-inline-block ml-auto'>
                        <img
                          src='/assets/images/re-captcha.png'
                          alt='Re-capcha'
                        />
                      </a>
                    </div> */}
                    <button
                      onClick={() => submitHandler()}
                      type='button'
                      className='btn btn-primary btn-lg btn-block'>
                      Log in
                    </button>
                  </form>

                  {/* <div className="row no-gutters mx-n2">
                    <div className="col-4 px-2 mb-4">
                      <a
                        href="#"
                        className="btn btn-lg btn-block facebook text-white px-0"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </div>
                    <div className="col-4 px-2 mb-4">
                      <a href="#" className="btn btn-lg btn-block google px-0">
                        <img src="/assets/images/google.png" alt="Google" />
                      </a>
                    </div>
                    <div className="col-4 px-2 mb-4">
                      <a
                        href="#"
                        className="btn btn-lg btn-block twitter text-white px-0"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    </div>
                  </div> */}
                </div>
                <div
                  className='tab-pane fade'
                  id='register'
                  role='tabpanel'
                  aria-labelledby='register-tab'>
                  <form className='form'>
                    {(error || message) && (
                      <div className='alert alert-danger'>
                        {error ? error : message}
                      </div>
                    )}
                    <div className='form-group mb-4'>
                      <label htmlFor='full-name' className='sr-only'>
                        First name
                      </label>
                      <div className='input-group input-group-lg'>
                        <div className='input-group-prepend '>
                          <span className='input-group-text bg-gray-01 border-0 text-muted fs-18'>
                            <i className='far fa-address-card'></i>
                          </span>
                        </div>
                        <input
                          type='text'
                          className='form-control border-0 shadow-none fs-13'
                          id='full-name'
                          name='full-name'
                          onChange={e => setFirstName(e.target.value)}
                          required
                          placeholder='First name'
                        />
                      </div>
                    </div>
                    <div className='form-group mb-4'>
                      <label htmlFor='full-name' className='sr-only'>
                        Last name
                      </label>
                      <div className='input-group input-group-lg'>
                        <div className='input-group-prepend '>
                          <span className='input-group-text bg-gray-01 border-0 text-muted fs-18'>
                            <i className='far fa-address-card'></i>
                          </span>
                        </div>
                        <input
                          type='text'
                          className='form-control border-0 shadow-none fs-13'
                          id='full-name'
                          onChange={e => setLastName(e.target.value)}
                          name='full-name'
                          required
                          placeholder='Last name'
                        />
                      </div>
                    </div>
                    <div className='form-group mb-4'>
                      <label htmlFor='full-name' className='sr-only'>
                        Phone
                      </label>
                      <div className='input-group input-group-lg'>
                        <div className='input-group-prepend '>
                          <span className='input-group-text bg-gray-01 border-0 text-muted fs-18'>
                            <i className='far fa-phone'></i>
                          </span>
                        </div>
                        <input
                          type='text'
                          className='form-control border-0 shadow-none fs-13'
                          id='full-name'
                          name='full-name'
                          onChange={e => setPhone(e.target.value)}
                          required
                          placeholder='Phone'
                        />
                      </div>
                    </div>
                    <div className='form-group mb-4'>
                      <label htmlFor='email' className='sr-only'>
                        Email
                      </label>
                      <div className='input-group input-group-lg'>
                        <div className='input-group-prepend '>
                          <span className='input-group-text bg-gray-01 border-0 text-muted fs-18'>
                            <i className='far fa-user'></i>
                          </span>
                        </div>
                        <input
                          type='email'
                          className='form-control border-0 shadow-none fs-13'
                          id='email'
                          name='email'
                          onChange={e => setEmail(e.target.value)}
                          required
                          placeholder='Username / Your email'
                        />
                      </div>
                    </div>
                    <div className='form-group mb-4'>
                      <label htmlFor='password01' className='sr-only'>
                        Password
                      </label>
                      <div className='input-group input-group-lg'>
                        <div className='input-group-prepend '>
                          <span className='input-group-text bg-gray-01 border-0 text-muted fs-18'>
                            <i className='far fa-lock'></i>
                          </span>
                        </div>
                        <input
                          type='text'
                          className='form-control border-0 shadow-none fs-13'
                          id='password01'
                          onChange={e => setPassword(e.target.value)}
                          name='password01'
                          required
                          placeholder='Password'
                        />
                        <div className='input-group-append'>
                          <span className='input-group-text bg-gray-01 border-0 text-body fs-18'>
                            <i className='far fa-eye-slash'></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='form-group mb-4'>
                      <label htmlFor='cpassword' className='sr-only'>
                        Confirmation Password
                      </label>
                      <div className='input-group input-group-lg'>
                        <div className='input-group-prepend '>
                          <span className='input-group-text bg-gray-01 border-0 text-muted fs-18'>
                            <i className='far fa-lock'></i>
                          </span>
                        </div>
                        <input
                          type='text'
                          className='form-control border-0 shadow-none fs-13'
                          id='cpassword'
                          onChange={e => setConfirmPassword(e.target.value)}
                          name='cpassword'
                          required
                          placeholder='Confirmation Password'
                        />
                        <div className='input-group-append'>
                          <span className='input-group-text bg-gray-01 border-0 text-body fs-18'>
                            <i className='far fa-eye-slash'></i>
                          </span>
                        </div>
                      </div>
                      <p className='form-text'>
                        Minimum 8 characters with 1 number and 1 letter
                      </p>
                    </div>
                    <button
                      onClick={() => submitRegisterHandler()}
                      type='button'
                      className='btn btn-primary btn-lg btn-block'>
                      Sign up
                    </button>
                  </form>
                  {/* <div className='divider text-center my-2'>
                    <span className='px-4 bg-white lh-17 text'>
                      or continue with
                    </span>
                  </div> */}
                  {/* <div className='row no-gutters mx-n2'>
                    <div className='col-4 px-2 mb-4'>
                      <a
                        href='#'
                        className='btn btn-lg btn-block facebook text-white px-0'>
                        <i className='fab fa-facebook-f'></i>
                      </a>
                    </div>
                    <div className='col-4 px-2 mb-4'>
                      <a href='#' className='btn btn-lg btn-block google px-0'>
                        <img src='/assets/images/google.png' alt='Google' />
                      </a>
                    </div>
                    <div className='col-4 px-2 mb-4'>
                      <a
                        href='#'
                        className='btn btn-lg btn-block twitter text-white px-0'>
                        <i className='fab fa-twitter'></i>
                      </a>
                    </div>
                  </div> */}
                  {/* <div className='mt-2'>
                    By creating an account, you agree to HomeID
                    <a className='text-heading' href='#'>
                      <u>Terms of Use</u>{' '}
                    </a>{' '}
                    and
                    <a className='text-heading' href='#'>
                      <u>Privacy Policy</u>
                    </a>
                    .
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginRegister
