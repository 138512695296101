import axios from "axios";
import {
  PARTNER_ADD_FAIL,
  PARTNER_ADD_REQUEST,
  PARTNER_ADD_RESET,
  PARTNER_ADD_SUCCESS,
  PARTNER_DELETE_FAIL,
  PARTNER_DELETE_REQUEST,
  PARTNER_DELETE_SUCCESS,
  PARTNER_GET_ALL_FAIL,
  PARTNER_GET_ALL_REQUEST,
  PARTNER_GET_ALL_SUCCESS,
  PARTNER_UPDATE_REQUEST,
  PARTNER_UPDATE_RESET,
  PARTNER_UPDATE_SUCCESS,
} from "../constants/partnersConstants";
import {
  ADD_PARTNER,
  DELETE_PARTNERS,
  GET_LIST_PARTNERS,
  UPDATE_PARTNERS,
} from "../utils/apiConfig";
export const addPartnerAction = (partner) => async (dispatch, getState) => {
  try {
    dispatch({ type: PARTNER_ADD_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(ADD_PARTNER, partner, config);

    dispatch({
      type: PARTNER_ADD_SUCCESS,
      payload: data,
    });
    dispatch(getlistPartnersAction());
  } catch (error) {
    dispatch({
      type: PARTNER_ADD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updatePartnerAction =
  (partner, id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PARTNER_UPDATE_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(UPDATE_PARTNERS(id), partner, config);

      dispatch({
        type: PARTNER_UPDATE_SUCCESS,
        payload: data,
      });
      dispatch(getlistPartnersAction());
    } catch (error) {
      dispatch({
        type: PARTNER_UPDATE_RESET,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deletePartnerAction = (amenity) => async (dispatch, getState) => {
  try {
    dispatch({ type: PARTNER_DELETE_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(DELETE_PARTNERS(amenity), config);

    dispatch({
      type: PARTNER_DELETE_SUCCESS,
      payload: data,
    });
    dispatch(getlistPartnersAction());
  } catch (error) {
    dispatch({
      type: PARTNER_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getlistPartnersAction = () => async (dispatch) => {
  try {
    dispatch({ type: PARTNER_GET_ALL_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(GET_LIST_PARTNERS, config);

    dispatch({
      type: PARTNER_GET_ALL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PARTNER_GET_ALL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
