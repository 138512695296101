import axios from 'axios'
import {
  DASHBOARD_DELETE_RESERVATION_FAIL,
  DASHBOARD_DELETE_RESERVATION_REQUEST,
  DASHBOARD_DELETE_RESERVATION_SUCCESS,
  DASHBOARD_GET_RESERVATION_FAIL,
  DASHBOARD_GET_RESERVATION_REQUEST,
  DASHBOARD_GET_RESERVATION_SUCCESS,
  DASHBOARD_GET_REVIEWS_FAIL,
  DASHBOARD_GET_REVIEWS_REQUEST,
  DASHBOARD_GET_REVIEWS_SUCCESS,
  DASHBOARD_RESERVATION_HANDLE_FAIL,
  DASHBOARD_RESERVATION_HANDLE_REQUEST,
  DASHBOARD_RESERVATION_HANDLE_SUCCESS,
  DASHBOARD_SAVED_SEARCH_FAIL,
  DASHBOARD_SAVED_SEARCH_REQUEST,
  DASHBOARD_SAVED_SEARCH_SUCCESS,
} from '../constants/dashboardConstants'
import {
  DELETE_RESERVATION,
  GET_COMMENT,
  GET_RESERVATION,
  GET_SAVED_SEARCH,
  RESERVATION_HANDLE,
} from '../utils/apiConfig'

export const getReviewsActions = () => async (dispatch, getState) => {
  try {
    dispatch({ type: DASHBOARD_GET_REVIEWS_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(GET_COMMENT, config)

    dispatch({
      type: DASHBOARD_GET_REVIEWS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: DASHBOARD_GET_REVIEWS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getReservationActions = () => async (dispatch, getState) => {
  try {
    dispatch({ type: DASHBOARD_GET_RESERVATION_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(GET_RESERVATION, config)

    dispatch({
      type: DASHBOARD_GET_RESERVATION_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: DASHBOARD_GET_RESERVATION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteReservationAction = id => async (dispatch, getState) => {
  try {
    dispatch({
      type: DASHBOARD_DELETE_RESERVATION_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.delete(DELETE_RESERVATION(id), config)
    dispatch({
      type: DASHBOARD_DELETE_RESERVATION_SUCCESS,
      payload: data,
    })
    dispatch(getReservationActions())
  } catch (error) {
    dispatch({
      type: DASHBOARD_DELETE_RESERVATION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const reservationHandleAction = id => async (dispatch, getState) => {
  try {
    dispatch({ type: DASHBOARD_RESERVATION_HANDLE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(RESERVATION_HANDLE(id), {}, config)

    dispatch({
      type: DASHBOARD_RESERVATION_HANDLE_SUCCESS,
      payload: data,
    })
    dispatch(getReservationActions())
  } catch (error) {
    dispatch({
      type: DASHBOARD_RESERVATION_HANDLE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getSavedSearchActions = () => async (dispatch, getState) => {
  try {
    dispatch({ type: DASHBOARD_SAVED_SEARCH_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(GET_SAVED_SEARCH, config)

    dispatch({
      type: DASHBOARD_SAVED_SEARCH_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: DASHBOARD_SAVED_SEARCH_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
