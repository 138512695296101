export const SETTINGS_GET_ALL_REQUEST = "SETTINGS_GET_ALL_REQUEST";
export const SETTINGS_GET_ALL_SUCCESS = "SETTINGS_GET_ALL_SUCCESS";
export const SETTINGS_GET_ALL_FAIL = "SETTINGS_GET_ALL_FAIL";
export const SETTINGS_GET_ALL_RESET = "SETTINGS_GET_ALL_RESET";

export const SETTINGS_UPDATE_REQUEST = "SETTINGS_UPDATE_REQUEST";
export const SETTINGS_UPDATE_SUCCESS = "SETTINGS_UPDATE_SUCCESS";
export const SETTINGS_UPDATE_FAIL = "SETTINGS_UPDATE_FAIL";
export const SETTINGS_UPDATE_RESET = "SETTINGS_UPDATE_RESET";

export const SETTINGS_DELETE_REQUEST = "SETTINGS_DELETE_REQUEST";
export const SETTINGS_DELETE_SUCCESS = "SETTINGS_DELETE_SUCCESS";
export const SETTINGS_DELETE_FAIL = "SETTINGS_DELETE_FAIL";
export const SETTINGS_DELETE_RESET = "SETTINGS_DELETE_RESET";
