import { Route, Routes } from 'react-router-dom'
import './lang/config'
import LandingPage from './screens/landingPage'
import Welcome from './screens/welcome'

import SignleProperty from './screens/signleProperty'
import ListingProperty from './screens/listingProperty'
import Dashboard from './screens/dashboard/dashboard'
import DashboardHome from './screens/dashboard/dashboardHome'
import DashboardAddProperty from './screens/dashboard/dashboardAddProperty'
import DashboardMyProperty from './screens/dashboard/dashboardMyProperty'
import DashboardMyFavorites from './screens/dashboard/dashboardMyFavorites'
import DashboardSaveSearch from './screens/dashboard/dashboardSaveSearch'
import DashboardReviews from './screens/dashboard/dashboardReviews'
import DashboardProfile from './screens/dashboard/dashboardProfile'
import DashboardMyReservation from './screens/dashboard/dashboardMyReservation'
import ComparePage from './screens/comparePage'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getlistCompareAction } from './actions/compareActions'
import DashboardSetting from './screens/dashboard/dashboardSetting'
import { getlistSettingsAction } from './actions/settingsActions'
import UsefulLinks from './screens/usefulLinks'
import Sell from './screens/sell'
import DashboardSells from './screens/dashboard/dashboardSell'
import DashboardPartners from './screens/dashboard/dashboardPartners'
import DashboardPDFS from './screens/dashboard/dashboardPDFS'
import DashboardUsefulLinks from './screens/dashboard/dashboardUsefulLinks'
function App() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getlistCompareAction())
    dispatch(getlistSettingsAction())
  }, [dispatch])

  return (
    <>
      <Routes>
        <Route
          path='/dashboard/myReservation'
          element={<Dashboard children={<DashboardMyReservation />} />}
          exact
        />
        <Route
          path='/dashboard/sells'
          element={<Dashboard children={<DashboardSells />} />}
          exact
        />
        <Route
          path='/dashboard/profile'
          element={<Dashboard children={<DashboardProfile />} />}
          exact
        />
        <Route
          path='/dashboard/reviews'
          element={<Dashboard children={<DashboardReviews />} />}
          exact
        />
        <Route
          path='/dashboard/saveSearch'
          element={<Dashboard children={<DashboardSaveSearch />} />}
          exact
        />
        <Route
          path='/dashboard/myFavorites'
          element={<Dashboard children={<DashboardMyFavorites />} />}
          exact
        />
        <Route
          path='/dashboard/myProperty'
          element={<Dashboard children={<DashboardMyProperty />} />}
          exact
        />
        <Route
          path='/dashboard/partners'
          element={<Dashboard children={<DashboardPartners />} />}
          exact
        />
        <Route
          path='/dashboard/usefulLinks'
          element={<Dashboard children={<DashboardUsefulLinks />} />}
          exact
        />
        <Route
          path='/dashboard/pdfs'
          element={<Dashboard children={<DashboardPDFS />} />}
          exact
        />
        <Route
          path='/dashboard/addProperty'
          element={<Dashboard children={<DashboardAddProperty isEdit={false} />} />}
          exact
        />
        <Route
          path='/dashboard/editProperty/:id'
          element={
            <Dashboard children={<DashboardAddProperty isEdit={true} />} />
          }
          exact
        />
        <Route
          path='/dashboard'
          element={<Dashboard children={<DashboardHome />} />}
          exact
        />
        <Route
          path='/dashboard/setting'
          element={<Dashboard children={<DashboardSetting />} />}
          exact
        />

        <Route
          path='/'
          element={<Welcome children={<LandingPage />} />}
          exact
        />

        <Route
          path='/listing/:id'
          element={<Welcome children={<SignleProperty />} />}
          exact
        />
        <Route
          path='/sell'
          exact={true}
          element={<Welcome children={<Sell />} />}
        />
        <Route
          path='/useful-links'
          element={<Welcome children={<UsefulLinks />} />}
          exact={true}
        />

        <Route
          path='/listings'
          element={<Welcome children={<ListingProperty />} />}
          exact={true}
        />
        <Route
          path='/compare'
          element={<Welcome children={<ComparePage />} />}
          exact={true}
        />
      </Routes>
    </>
  )
}

export default App
