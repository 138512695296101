import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllWishListActions } from '../../actions/listingActions'
import ListingCard from '../../components/listing/listingCard'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'

const DashboardMyFavorites = () => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  const [filtredWishList, setFiltredWishList] = useState([])
  const getAllWishList = useSelector(state => state.getAllWishList)
  const { allWishlists } = getAllWishList

  useEffect(() => {
    dispatch(getAllWishListActions())
  }, [dispatch])

  useEffect(() => {
    if (allWishlists) {
      setFiltredWishList(allWishlists)
    }
  }, [allWishlists])

  const users = () => {
    let extractedUsers = []
    allWishlists?.forEach((wishlist, index) => {
      if (!extractedUsers.find(el => el.value == wishlist?.user?._id)) {
        extractedUsers.push({
          label: wishlist.user?.firstName + '  ' + wishlist.user?.lastName,
          value: wishlist.user?._id,
          key: index,
        })
      }
    })
    return extractedUsers
  }

  const filterByUser = id => {
    let filtr = []

    if (id.includes('user')) {
      setFiltredWishList(allWishlists)
    } else {
      allWishlists.forEach(wishlist => {
        if (wishlist?.user?._id == id) filtr.push(wishlist)
      })

      setFiltredWishList(filtr)
    }
  }
  return (
    <>
      {allWishlists && (
        <main id='content' className='bg-gray-01'>
          <div className='px-3 px-lg-6 px-xxl-13 py-5 py-lg-10'>
            <div className='d-flex flex-wrap flex-md-nowrap mb-6'>
              <div className='mr-0 mr-md-auto'>
                <h2 className='mb-0 text-heading fs-22 lh-15'>
                  {t('Favorites')}
                  <span className='badge badge-white badge-pill text-primary fs-18 font-weight-bold ml-2'>
                    {filtredWishList?.length}
                  </span>
                </h2>
                <p> {t('Access and view all your customer Favorites')}</p>
              </div>
              <div className='form-inline justify-content-md-end mx-n2'>
                <div className='p-2'>
                  <div className='input-group input-group-lg bg-white border'>
                    <select
                      className='form-control'
                      onChange={e => filterByUser(e.target.value)}>
                      <option>Select user</option>
                      {users().map(user => (
                        <option value={user.value}>{user.label}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              {filtredWishList?.map((listings, index) => (
                <div className='col-md-6 col-xxl-3 mb-6'>
                  <ListingCard
                    listing={{ ...listings.listing, user: listings.user }}
                    isAdminWishList={true}
                  />
                </div>
              ))}
            </div>
          </div>
        </main>
      )}
    </>
  )
}

export default DashboardMyFavorites
