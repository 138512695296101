import {
  SELL_ADD_FAIL,
  SELL_ADD_REQUEST,
  SELL_ADD_RESET,
  SELL_ADD_SUCCESS,
  SELL_GET_ALL_FAIL,
  SELL_GET_ALL_REQUEST,
  SELL_GET_ALL_RESET,
  SELL_GET_ALL_SUCCESS,
} from '../constants/sellConstants'

export const addSellReducer = (state = {}, action) => {
  switch (action.type) {
    case SELL_ADD_REQUEST:
      return { loading: true }
    case SELL_ADD_SUCCESS:
      return { loading: false, data: action.payload }
    case SELL_ADD_FAIL:
      return { loading: false, error: action.payload }
    case SELL_ADD_RESET:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getSellListReducer = (state = { sells: [] }, action) => {
  switch (action.type) {
    case SELL_GET_ALL_REQUEST:
      return { loading: true }
    case SELL_GET_ALL_SUCCESS:
      return { loading: false, sells: action.payload }
    case SELL_GET_ALL_FAIL:
      return { loading: false, error: action.payload }
    case SELL_GET_ALL_RESET:
      return { sells: [] }
    default:
      return state
  }
}
