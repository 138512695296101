// export const BACKEND_URL = "http://localhost:5000/api/";
// export const BACKEND_IMAGE = "http://localhost:5000/";

export const BACKEND_URL =
  "https://immohobs-backend-vnb3tdxz5q-uc.a.run.app/api/";
export const BACKEND_IMAGE = "";

// public
export const HOMEPAGE_INFO = BACKEND_URL;
export const DASHBOARD_INFO = BACKEND_URL + "dashboardInfo";

// user
export const USER_EDIT_PROFILE = BACKEND_URL + "users/profile";
export const USER_LOGIN = BACKEND_URL + "users/login";
export const USER_REGISTER = BACKEND_URL + "users";

// listing
export const GET_LISTING_BY_ID = (id) => BACKEND_URL + "listings/" + id;
export const GET_LISTING_LIST = BACKEND_URL + "listings";
export const ADD_LISTING = BACKEND_URL + "listings";
export const EDIT_LISTING = (id) => BACKEND_URL + "listings/" + id;
export const DELETE_LISTING = (id) => BACKEND_URL + "listings/" + id;

// reservation
export const ADD_RESERVATION = BACKEND_URL + "reservations";
export const GET_RESERVATION = BACKEND_URL + "reservations/";
export const DELETE_RESERVATION = (id) => BACKEND_URL + "reservations/" + id;
export const RESERVATION_HANDLE = (id) =>
  BACKEND_URL + "reservations/handle/" + id;

// comment
export const ADD_COMMENT = (id) => BACKEND_URL + "listing/" + id + "/reviews";
export const GET_COMMENT = BACKEND_URL + "listing/reviews";

// wishList
export const ADD_WISHLIST = (id) => BACKEND_URL + "wishlist/" + id;
export const GET_WISHLIST_LIST = BACKEND_URL + "wishlist";
export const GET_ALL_WISHLIST_LIST = BACKEND_URL + "wishlist/all";

// amenity
export const ADD_AMENITY = BACKEND_URL + "amenities";
export const GET_LIST_AMENITY = BACKEND_URL + "amenities";
export const DELETE_AMENITY = (id) => BACKEND_URL + "amenities/" + id;

// partners
export const ADD_PARTNER = BACKEND_URL + "partners";
export const GET_LIST_PARTNERS = BACKEND_URL + "partners";
export const DELETE_PARTNERS = (id) => BACKEND_URL + "partners/" + id;
export const UPDATE_PARTNERS = (id) => BACKEND_URL + "partners/" + id;

// usefulLinks
export const ADD_Links = BACKEND_URL + "usefulLinks";
export const GET_LIST_Links = BACKEND_URL + "usefulLinks";
export const DELETE_Links = (id) => BACKEND_URL + "usefulLinks/" + id;
export const UPDATE_Links = (id) => BACKEND_URL + "usefulLinks/" + id;
// pdfs
export const ADD_PDF = BACKEND_URL + "pdfs";
export const GET_LIST_PDFS = BACKEND_URL + "pdfs";
export const DELETE_PDFS = (id) => BACKEND_URL + "pdfs/" + id;
export const UPDATE_PDFS = (id) => BACKEND_URL + "pdfs/" + id;

// search
export const ADD_SEARCH = BACKEND_URL + "historySearch";
export const GET_SAVED_SEARCH = BACKEND_URL + "historySearch";

// recent activity
export const GET_RECENT_ACTIVITY = BACKEND_URL + "recentActivities";

//settings
export const GET_SETTNGS = BACKEND_URL + "settings";
export const UPDATE_SETTNGS = BACKEND_URL + "settings/update";

// sell
export const ADD_Sell = BACKEND_URL + "sell";
export const GET_LIST_SELL = BACKEND_URL + "sell/getAll";
