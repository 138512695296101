export const LINKS_GET_ALL_REQUEST = 'LINKS_GET_ALL_REQUEST'
export const LINKS_GET_ALL_SUCCESS = 'LINKS_GET_ALL_SUCCESS'
export const LINKS_GET_ALL_FAIL = 'LINKS_GET_ALL_FAIL'
export const LINKS_GET_ALL_RESET = 'LINKS_GET_ALL_RESET'

export const LINKS_ADD_REQUEST = 'LINKS_ADD_REQUEST'
export const LINKS_ADD_SUCCESS = 'LINKS_ADD_SUCCESS'
export const LINKS_ADD_FAIL = 'LINKS_ADD_FAIL'
export const LINKS_ADD_RESET = 'LINKS_ADD_RESET'

export const LINKS_UPDATE_REQUEST = 'LINKS_UPDATE_REQUEST'
export const LINKS_UPDATE_SUCCESS = 'LINKS_UPDATE_SUCCESS'
export const LINKS_UPDATE_FAIL = 'LINKS_UPDATE_FAIL'
export const LINKS_UPDATE_RESET = 'LINKS_UPDATE_RESET'

export const LINKS_DELETE_REQUEST = 'LINKS_DELETE_REQUEST'
export const LINKS_DELETE_SUCCESS = 'LINKS_DELETE_SUCCESS'
export const LINKS_DELETE_FAIL = 'LINKS_DELETE_FAIL'
export const LINKS_DELETE_RESET = 'LINKS_DELETE_RESET'
