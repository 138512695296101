import {
  SETTINGS_GET_ALL_FAIL,
  SETTINGS_GET_ALL_REQUEST,
  SETTINGS_GET_ALL_RESET,
  SETTINGS_GET_ALL_SUCCESS,
  SETTINGS_UPDATE_FAIL,
  SETTINGS_UPDATE_REQUEST,
  SETTINGS_UPDATE_RESET,
  SETTINGS_UPDATE_SUCCESS,
} from "../constants/settingsConstants";

export const updateSettingsReducer = (state = {}, action) => {
  switch (action.type) {
    case SETTINGS_UPDATE_REQUEST:
      return { loading: true };
    case SETTINGS_UPDATE_SUCCESS:
      return { loading: false, setting: action.payload };
    case SETTINGS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case SETTINGS_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const getSettingsListReducer = (state = { settings: [] }, action) => {
  switch (action.type) {
    case SETTINGS_GET_ALL_REQUEST:
      return { loading: true, settings: state.settings };
    case SETTINGS_GET_ALL_SUCCESS:
      return { loading: false, settings: action.payload };
    case SETTINGS_GET_ALL_FAIL:
      return { loading: false, error: action.payload };
    case SETTINGS_GET_ALL_RESET:
      return { settings: [] };
    default:
      return state;
  }
};
