import {
  PDFS_ADD_FAIL,
  PDFS_ADD_REQUEST,
  PDFS_ADD_RESET,
  PDFS_ADD_SUCCESS,
  PDFS_DELETE_FAIL,
  PDFS_DELETE_REQUEST,
  PDFS_DELETE_RESET,
  PDFS_DELETE_SUCCESS,
  PDFS_GET_ALL_FAIL,
  PDFS_GET_ALL_REQUEST,
  PDFS_GET_ALL_RESET,
  PDFS_GET_ALL_SUCCESS,
  PDFS_UPDATE_FAIL,
  PDFS_UPDATE_REQUEST,
  PDFS_UPDATE_RESET,
  PDFS_UPDATE_SUCCESS,
} from "../constants/pdfsConstants";

export const addPDFReducer = (state = {}, action) => {
  switch (action.type) {
    case PDFS_ADD_REQUEST:
      return { loading: true };
    case PDFS_ADD_SUCCESS:
      return { loading: false, pdf: action.payload };
    case PDFS_ADD_FAIL:
      return { loading: false, error: action.payload };
    case PDFS_ADD_RESET:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updatePDFReducer = (state = {}, action) => {
  switch (action.type) {
    case PDFS_UPDATE_REQUEST:
      return { loading: true };
    case PDFS_UPDATE_SUCCESS:
      return { loading: false, pdf: action.payload };
    case PDFS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case PDFS_UPDATE_RESET:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deletePDFReducer = (state = {}, action) => {
  switch (action.type) {
    case PDFS_DELETE_REQUEST:
      return { loading: true };
    case PDFS_DELETE_SUCCESS:
      return { loading: false, pdf: action.payload };
    case PDFS_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case PDFS_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const getPDFsListReducer = (state = { pdfs: [] }, action) => {
  switch (action.type) {
    case PDFS_GET_ALL_REQUEST:
      return { loading: true, pdfs: state.pdfs };
    case PDFS_GET_ALL_SUCCESS:
      return { loading: false, pdfs: action.payload };
    case PDFS_GET_ALL_FAIL:
      return { loading: false, error: action.payload };
    case PDFS_GET_ALL_RESET:
      return { pdfs: [] };
    default:
      return state;
  }
};
