export const PDFS_GET_ALL_REQUEST = "PDFS_GET_ALL_REQUEST";
export const PDFS_GET_ALL_SUCCESS = "PDFS_GET_ALL_SUCCESS";
export const PDFS_GET_ALL_FAIL = "PDFS_GET_ALL_FAIL";
export const PDFS_GET_ALL_RESET = "PDFS_GET_ALL_RESET";

export const PDFS_ADD_REQUEST = "PDFS_ADD_REQUEST";
export const PDFS_ADD_SUCCESS = "PDFS_ADD_SUCCESS";
export const PDFS_ADD_FAIL = "PDFS_ADD_FAIL";
export const PDFS_ADD_RESET = "PDFS_ADD_RESET";

export const PDFS_UPDATE_REQUEST = "PDFS_UPDATE_REQUEST";
export const PDFS_UPDATE_SUCCESS = "PDFS_UPDATE_SUCCESS";
export const PDFS_UPDATE_FAIL = "PDFS_UPDATE_FAIL";
export const PDFS_UPDATE_RESET = "PDFS_UPDATE_RESET";

export const PDFS_DELETE_REQUEST = "PDFS_DELETE_REQUEST";
export const PDFS_DELETE_SUCCESS = "PDFS_DELETE_SUCCESS";
export const PDFS_DELETE_FAIL = "PDFS_DELETE_FAIL";
export const PDFS_DELETE_RESET = "PDFS_DELETE_RESET";
