import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import Swal from 'sweetalert2'
import { alertToast } from '../../components/utils/alertHandler'

import Pagination from '../../components/utils/Pagination'
import { useTranslation } from 'react-i18next'
import {
  addLinkAction,
  deleteLinkAction,
  getlistLinksAction,
  updateLinkAction,
} from '../../actions/usefulLinksActions'
import {
  LINKS_ADD_RESET,
  LINKS_UPDATE_RESET,
} from '../../constants/usefulLinksConstants'

export default function DashboardUsefulLinks() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const getLinksList = useSelector(state => state.getLinksList)
  const { links } = getLinksList

  const addLink = useSelector(state => state.addLink)
  const { link } = addLink

  const updateLink = useSelector(state => state.updateLink)
  const { link: uLink } = updateLink

  const [linkData, setLinkData] = useState({
    title: '',
    url: '',
  })

  const [isAdd, setIsAdd] = useState(true)

  const [currentPage, setCurrentPage] = useState(1)
  const postsPerPage = 6
  const lastPostIndex = currentPage * postsPerPage
  const firstPostIndex = lastPostIndex - postsPerPage
  const currentPartners = links?.slice(firstPostIndex, lastPostIndex)

  useEffect(() => {
    dispatch(getlistLinksAction())
  }, [dispatch])

  const deleleteHandler = id => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(deleteLinkAction(id))
        Swal.fire('Deleted!', 'Your product has been deleted.', 'success')
      }
    })
  }

  useEffect(() => {
    if (uLink) {
      alertToast('success', 'Link updated successfully')
      dispatch({ type: LINKS_UPDATE_RESET })
      document.getElementById('addTT').click()
    }
  }, [uLink, dispatch])

  useEffect(() => {
    if (link) {
      alertToast('success', 'Link added successfully')
      dispatch({ type: LINKS_ADD_RESET })
      document.getElementById('addTT').click()
    }
  }, [link, dispatch])

  const submitPartnerData = () => {
    if (isAdd) {
      dispatch(addLinkAction(linkData))
    } else {
      dispatch(updateLinkAction(linkData, linkData._id))
    }
  }

  return (
    <main id='content' className='bg-gray-01'>
      <div className='px-3 px-lg-6 px-xxl-13 py-5 py-lg-10 invoice-listing'>
        <div className='mb-6'>
          <div className='row'>
            <div className='col-sm-12 col-md-6 d-flex justify-content-md-start justify-content-center'>
              <div className='mr-0 mr-md-auto'>
                <h2 className='mb-0 text-heading fs-22 lh-15'>
                  {t('List of Useful Links')}
                  <span className='badge badge-white badge-pill text-primary fs-18 font-weight-bold ml-2'>
                    {links?.length}
                  </span>
                </h2>
                <p>{t('Access and manage all your partners in one place')}</p>
              </div>
            </div>

            <div className='col-sm-12 col-md-6 d-flex justify-content-md-end justify-content-center mt-md-0 mt-3'>
              <div className='align-self-center' style={{ minWidth: '140px' }}>
                <button
                  className='btn btn-primary btn-lg'
                  tabindex='0'
                  onClick={() => {
                    setIsAdd(true)
                    setLinkData({ title: '', url: '' })
                  }}
                  data-toggle='modal'
                  href='#add_edit-modal'
                  id='addTT'>
                  <span>{t('Add Link')}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='table-responsive'>
          <table className='table table-hover bg-white border rounded-lg'>
            <thead>
              <tr role='row'>
                <th className='py-6'>{t('Title')}</th>
                <th className='py-6'>{t('Link')}</th>

                <th className='no-sort py-6'>{t('Actions')}</th>
              </tr>
            </thead>
            <tbody>
              {currentPartners &&
                currentPartners?.map(link => (
                  <tr role='row'>
                    <td className='align-middle'>{link.title}</td>
                    <td className='align-middle'>
                      <a target='_blank' href={link.url}>
                        {link.title}
                      </a>
                    </td>
                    <td className='align-right'>
                      <div
                        onClick={() => {
                          setIsAdd(false)
                          setLinkData({ ...link })
                        }}
                        data-toggle='modal'
                        href='#add_edit-modal'
                        title='Edit'
                        className='d-inline-block fs-18 text-muted hover-primary mr-5'>
                        <i className='fal fa-pencil-alt'></i>
                      </div>
                      <div
                        onClick={() => deleleteHandler(link._id)}
                        data-toggle='tooltip'
                        title='Delete'
                        className='d-inline-block fs-18 text-muted hover-primary'>
                        <i className='fal fa-trash-alt'></i>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <nav className='mt-6'>
          <Pagination
            totalPosts={links?.length}
            postsPerPage={postsPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </nav>
      </div>
      <div
        className='modal fade'
        id='add_edit-modal'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='add_edit-modal'
        aria-hidden='true'>
        <div
          className='modal-dialog modal-lg modal-dialog-centered '
          role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>{t('Links list')}</h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body p-4 py-sm-7 px-sm-8'>
              <div className='tab-content shadow-none p-0' id='myTabContent'>
                <div
                  className='tab-pane fade show active'
                  id='login'
                  role='tabpanel'
                  aria-labelledby='login-tab'>
                  <section className='py-2'>
                    <form>
                      <div className='row'>
                        <div className='col-md-6 mb-4'>
                          <label>{t('Title')} :</label>
                          <div className='position-relative'>
                            <input
                              type='text'
                              className='form-control border-0 pr-3 h-52 pl-7'
                              value={linkData.title}
                              name='total-amount'
                              onChange={e =>
                                setLinkData({
                                  ...linkData,
                                  title: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className='col-md-6 mb-4'>
                          <label>URL :</label>
                          <div className='position-relative'>
                            <input
                              type='text'
                              className='form-control border-0 pr-3 h-52 pl-7'
                              name='total-amount'
                              value={linkData.url}
                              onChange={e =>
                                setLinkData({
                                  ...linkData,
                                  url: e.target.value,
                                })
                              }
                            />
                            <span className='position-absolute pl-3 pos-fixed-left-center fs-13 font-weight-600'></span>
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-lg-6'>
                          <button
                            className='btn btn-primary fs-14 h-52 px-8'
                            type='button'
                            onClick={e => submitPartnerData()}>
                            {t('Submit')}
                          </button>
                        </div>
                      </div>
                    </form>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
