import React, { useEffect, useState } from "react";
import { BACKEND_IMAGE } from "../../utils/apiConfig";
import { ReactComponent as BedroomIcon } from "../../svg/bedroom.svg";
import { ReactComponent as Shower } from "../../svg/shower.svg";
import { ReactComponent as Squere } from "../../svg/square.svg";
import { ReactComponent as Garage } from "../../svg/Garage.svg";
import { Link } from "react-router-dom";
import { alertToast } from "../utils/alertHandler";
import { useDispatch, useSelector } from "react-redux";
import { addWishListAction } from "../../actions/listingActions";
import { useTranslation } from "react-i18next";
import { LISTING_ADD_WISHLIST_RESET } from "../../constants/listingConstants";
import {
  addCompareAction,
  deleteCompareAction,
} from "../../actions/compareActions";

export default function ListingCard({ listing, animation, isAdminWishList }) {
  const dispatch = useDispatch();
  const [isActive, setisActive] = useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { t } = useTranslation();

  const { userInfo } = userLogin;

  const addWishList = useSelector((state) => state.addWishList);
  const { wishlist, error } = addWishList;

  const [isCompare, setIsCompare] = useState(false);

  const getWishList = useSelector((state) => state.getWishList);
  const { wishlists } = getWishList;

  const getCompare = useSelector((state) => state.getCompare);
  const { compares } = getCompare;

  useEffect(() => {
    if (wishlists) {
      setisActive(
        wishlists.find((wl) => wl.listing._id === listing._id) ? true : false
      );
    }
  }, [listing._id, wishlists]);

  useEffect(() => {
    if (wishlist && wishlist.message.includes("added")) {
      setisActive(true);
      alertToast("success", "WishList Successfully added");
    } else if (wishlist && wishlist.message.includes("removed")) {
      setisActive(false);
      alertToast("warning", "WishList Successfully removed");
    }

    dispatch({
      type: LISTING_ADD_WISHLIST_RESET,
    });
  }, [wishlist]);

  const setInWishList = () => {
    if (userInfo) {
      dispatch(addWishListAction(listing._id));
    } else {
      alertToast("error", "Please Login First");
    }
  };

  useEffect(() => {
    const verif = compares?.find((element) => element._id == listing._id)
      ? true
      : false;
    setIsCompare(verif);
  }, [compares, listing]);

  const addCompare = () => {
    console.log(compares);
    if (isCompare) {
      dispatch(deleteCompareAction(listing));
      alertToast("success", "Property deleted to compare list");
    } else {
      if (compares?.length < 3) {
        dispatch(addCompareAction(listing));
        alertToast("success", "Property added to compare list");
      } else {
        alertToast("warning", "The maximum property to compare is 3");
      }
    }
  };
  return (
    <div className="box" data-animate={animation}>
      <div className="card shadow-hover-2">
        <div
          style={{ textAlign: "center" }}
          className="hover-change-image bg-hover-overlay rounded-lg card-img-top"
        >
          <img
            style={{ height: "275px" }}
            src={BACKEND_IMAGE + listing.images?.split(",")[0]}
            alt={listing.title}
          />
          <div
            style={{ textAlign: "left" }}
            className="card-img-overlay p-2 d-flex flex-column"
          >
            <div>
              <span className="badge mr-2 badge-primary">
                {listing.listedIn}
              </span>
            </div>
            <ul className="list-inline mb-0 mt-auto hover-image">
              <li
                className="list-inline-item mr-2"
                data-toggle="tooltip"
                title={listing.images?.split(",").length + " Images"}
              >
                <Link
                  to={"/listing/" + listing._id}
                  className="text-white hover-primary"
                >
                  <i className="far fa-images"></i>
                  <span className="pl-1">
                    {listing.images?.split(",").length}
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="card-body pt-3">
          <h2 className="card-title fs-16 lh-2 mb-0">
            <Link
              to={"/listing/" + listing._id}
              className="text-dark hover-primary"
            >
              {listing.title}
            </Link>
          </h2>
          <p className="card-text font-weight-500 text-gray-light mb-2">
            {listing.address.adresse}
          </p>
          <ul className="list-inline d-flex mb-0 flex-wrap mr-n5">
            <li
              className="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
              data-toggle="tooltip"
              title={listing.details.bedroom + " " + t("bedrooms")}
            >
              <BedroomIcon className="icon icon-bedroom fs-18 text-primary mr-1" />
              {listing.details.bedroom} Br
            </li>
            <li
              className="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
              data-toggle="tooltip"
              title={listing.details.bathRoom + " " + t("bathrooms")}
            >
              <Shower className="icon icon-bedroom fs-18 text-primary mr-1" />
              {listing.details.bathRoom} Ba
            </li>
            <li
              className="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
              data-toggle="tooltip"
              title={t("Size")}
            >
              <Squere className="icon icon-bedroom fs-18 text-primary mr-1" />
              {listing.details.size} Sq.Ft
            </li>
            <li
              className="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
              data-toggle="tooltip"
              title={listing.details.garage + " Garage"}
            >
              <Garage className="icon icon-bedroom fs-18 text-primary mr-1" />
              {listing.details.garage} Gr
            </li>
          </ul>
          {isAdminWishList && (
            <div style={{ marginTop: "15px" }}>
              <p className="card-text font-weight-500 text-gray-light mb-2">
                User Info
              </p>
              <div className="media d-sm-flex d-block text-sm-left text-center">
                <div className="w-60px h-60 mr-2 bg-gray-01 rounded-circle fs-25 font-weight-500 text-muted d-flex align-items-center justify-content-center text-uppercase mr-sm-4 mb-4 mb-sm-0 mx-auto">
                  {listing.user.firstName.substring(0, 1) +
                    listing.user.lastName.substring(0, 1)}
                </div>
                <div className="media-body">
                  <div className="row mb-1 align-items-center">
                    <div className="col-sm-6 mb-1 mb-sm-0">
                      <h4 className="mb-0 text-heading fs-14">
                        {listing.user.firstName + " " + listing.user.lastName}
                      </h4>
                    </div>
                    <div className="col-sm-6 text-right font-weight-500 text-gray-light">
                      {listing.user.phone}
                    </div>
                  </div>
                  <p className="mb-3 pr-xl-17">{listing.user.email}</p>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="card-footer bg-transparent d-flex justify-content-between align-items-center py-3">
          <p className="fs-17 font-weight-bold text-heading mb-0">
            $ {listing.price.price}
          </p>
          <ul className="list-inline mb-0">
            <li className="list-inline-item">
              <div
                className={
                  (isActive
                    ? "bg-accent border-accent"
                    : "bg-hover-accent border-hover-accent") +
                  " w-40px h-40 border rounded-circle d-inline-flex align-items-center justify-content-center text-secondary"
                }
                data-toggle="tooltip"
                title={t("Wishlist")}
                onClick={() => setInWishList()}
              >
                <i className={(isActive ? "fas" : "far") + " fa-heart"}></i>
              </div>
            </li>
            <li className="list-inline-item">
              <div
                className={
                  (isCompare ? "bg-accent activeColor " : "bg-hover-accent ") +
                  "w-40px h-40 border rounded-circle d-inline-flex align-items-center justify-content-center text-body hover-secondary bg-hover-accent border-hover-accent"
                }
                data-toggle="tooltip"
                onClick={() => addCompare()}
                title={t("Compare")}
              >
                <i className="fas fa-exchange-alt"></i>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
