import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
} from "chart.js";
import { Doughnut, Pie } from "react-chartjs-2";
import { getReservationActions } from "../../actions/dashboardActions";
import { getlistRecentActivitiesAction } from "../../actions/recentActivityActions";
import { LISTING_ADD_RESET } from "../../constants/listingConstants";

ChartJS.register(ArcElement, Tooltip, Legend, LinearScale);

const DashboardHome = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const getDashboard = useSelector((state) => state.getDashboard);
  const { dashboardInfo } = getDashboard;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const getRecentActivity = useSelector((state) => state.getRecentActivity);
  const { recentActivity } = getRecentActivity;

  useEffect(() => {
    dispatch(getlistRecentActivitiesAction());
  }, []);

  const datas = {
    labels: [t("Property for Sale"), t("Property for Rent")],
    datasets: [
      {
        data: [dashboardInfo?.propertySale, dashboardInfo?.propertyRent],
        backgroundColor: ["rgb(255, 99, 132)", "rgb(54, 162, 235)"],
        hoverOffset: 4,
      },
    ],
  };
  const datat = {
    labels: [t("Reservation Sale"), t("Reservation Rent")],
    datasets: [
      {
        data: [dashboardInfo?.reservationSale, dashboardInfo?.reservationRent],
        backgroundColor: ["rgb(255, 99, 132)", "rgb(54, 162, 235)"],
        hoverOffset: 4,
      },
    ],
  };

  const options = {};

  return (
    <>
      <main id="content" className="bg-gray-01">
        <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
          <div className="d-flex flex-wrap flex-md-nowrap mb-6">
            <div className="mr-0 mr-md-auto">
              <h2 className="mb-0 text-heading fs-22 lh-15">
                {t("Welcome back")},
                {userInfo.firstName + " " + userInfo.lastName}
              </h2>
              <p>
                {t(
                  "Optimize performance with your admin dashboard's key stats"
                )}
              </p>
            </div>
            {userInfo && userInfo.isAdmin == true && (
              <div>
                <Link
                  to="/dashboard/addProperty"
                  className="btn btn-primary btn-lg"
                  onClick={()=> dispatch({ type: LISTING_ADD_RESET })}
                >
                  <span> {t("Add New Property")}</span>
                  <span className="d-inline-block ml-1 fs-20 lh-1">
                    <svg className="icon icon-add-new">
                      <use xlinkHref="#icon-add-new"></use>
                    </svg>
                  </span>
                </Link>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-sm-6 col-xxl-3 mb-6">
              <div className="card">
                <div className="card-body row align-items-center px-6 py-7">
                  <div className="col-5">
                    <span className="w-83px h-83 d-flex align-items-center justify-content-center fs-36 badge badge-blue badge-circle">
                      <svg className="icon icon-1">
                        <use xlinkHref="#icon-1"></use>
                      </svg>
                    </span>
                  </div>
                  <div className="col-7 text-center">
                    {dashboardInfo && (
                      <p
                        className="fs-42 lh-12 mb-0 counterup"
                        data-start="0"
                        data-end={dashboardInfo?.listings}
                        data-decimals="0"
                        data-duration="0"
                        data-separator=""
                      >
                        {dashboardInfo?.listings}
                      </p>
                    )}
                    <p>{t("Properties")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xxl-3 mb-6">
              <div className="card">
                <div className="card-body row align-items-center px-6 py-7">
                  <div className="col-5">
                    <span className="w-83px h-83 d-flex align-items-center justify-content-center fs-36 badge badge-green badge-circle">
                      <svg className="icon icon-2">
                        <use xlinkHref="#icon-2"></use>
                      </svg>
                    </span>
                  </div>
                  <div className="col-7 text-center">
                    {dashboardInfo && (
                      <p
                        className="fs-42 lh-12 mb-0 counterup"
                        data-start="0"
                        data-end={dashboardInfo?.nbrViews}
                        data-decimals="0"
                        data-duration="0"
                        data-separator=""
                      >
                        {dashboardInfo?.nbrViews}
                      </p>
                    )}
                    <p>{t("Total views")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xxl-3 mb-6">
              <div className="card">
                <div className="card-body row align-items-center px-6 py-7">
                  <div className="col-4">
                    <span className="w-83px h-83 d-flex align-items-center justify-content-center fs-36 badge badge-yellow badge-circle">
                      <svg className="icon icon-review">
                        <use xlinkHref="#icon-review"></use>
                      </svg>
                    </span>
                  </div>
                  <div className="col-8 text-center">
                    {dashboardInfo && (
                      <p
                        className="fs-42 lh-12 mb-0 counterup"
                        data-start="0"
                        data-end={dashboardInfo?.reviews}
                        data-decimals="0"
                        data-duration="0"
                        data-separator=""
                      >
                        {dashboardInfo?.reviews}
                      </p>
                    )}
                    <p>{t("Total Visitor Reviews")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xxl-3 mb-6">
              <div className="card">
                <div className="card-body row align-items-center px-6 py-7">
                  <div className="col-5">
                    <span className="w-83px h-83 d-flex align-items-center justify-content-center fs-36 badge badge-pink badge-circle">
                      <svg className="icon icon-heart">
                        <use xlinkHref="#icon-heart"></use>
                      </svg>
                    </span>
                  </div>
                  <div className="col-7 text-center">
                    {dashboardInfo && (
                      <p
                        className="fs-42 lh-12 mb-0 counterup"
                        data-start="0"
                        data-end={dashboardInfo?.favorites}
                        data-decimals="0"
                        data-duration="0"
                        data-separator=""
                      >
                        {dashboardInfo?.favorites}
                      </p>
                    )}
                    <p>{t("Total Favorites")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {userInfo && userInfo.isAdmin == true && (
              <div className="col-md-6 mb-6">
                <div className="card px-7 py-6 h-100 chart">
                  <div className="card-body p-0 collapse-tabs"></div>
                  <Doughnut data={datas} options={options}></Doughnut>
                </div>
              </div>
            )}
            {userInfo && userInfo.isAdmin == true && (
              <div className="col-md-6 mb-6">
                <div className="card px-7 py-6 h-100 chart">
                  <div className="card-body p-0 collapse-tabs"></div>
                  <Pie data={datat} options={options}></Pie>
                </div>
              </div>
            )}
            {recentActivity && recentActivity.length > 0 && (
              <div className="col-xxl-4 mb-6">
                <div className="card px-7 py-6 h-100">
                  <div className="card-body p-0">
                    <h2 className="mb-2 text-heading fs-22 lh-15">
                      {t("Recent Activities")}
                    </h2>
                    <ul className="list-group list-group-no-border">
                      {recentActivity &&
                        recentActivity?.map((recentActivit) => (
                          <li className="list-group-item px-0 py-2">
                            <div className="media align-items-center">
                              <div
                                className={
                                  (recentActivit.type == "review"
                                    ? "badge-yellow"
                                    : recentActivit.type == "wishlist"
                                    ? "badge-pink"
                                    : "badge-blue") +
                                  " badge w-40px h-40 d-flex align-items-center justify-content-center property fs-18 mr-3"
                                }
                              >
                                <svg className="icon icon-1">
                                  {recentActivit.type == "reservation" ? (
                                    <use xlinkHref="#icon-1"></use>
                                  ) : recentActivit.type == "review" ? (
                                    <use xlinkHref="#icon-review"></use>
                                  ) : (
                                    <use xlinkHref="#icon-heart"></use>
                                  )}
                                </svg>
                              </div>
                              {recentActivit.type == "review" ? (
                                <div className="media-body">
                                  {recentActivit.user?.firstName}{" "}
                                  {recentActivit.user?.lastName + " "} left a
                                  review on{" "}
                                  <Link
                                    to={
                                      "/listing/" + recentActivit.listing?._id
                                    }
                                    className="text-heading"
                                  >
                                    {" "}
                                    {recentActivit.listing?.title}
                                  </Link>
                                </div>
                              ) : recentActivit.type == "wishlist" ? (
                                <div className="media-body">
                                  {recentActivit.user?.firstName}{" "}
                                  {recentActivit.user?.lastName + " "} favorites
                                  your{" "}
                                  <Link
                                    to={
                                      "/listing/" + recentActivit.listing?._id
                                    }
                                    className="text-heading"
                                  >
                                    {recentActivit.listing?.title}{" "}
                                  </Link>
                                  listing
                                </div>
                              ) : (
                                <div className="media-body">
                                  {recentActivit.user?.firstName}{" "}
                                  {recentActivit.user?.lastName + " "} make a
                                  reservation for{" "}
                                  <Link
                                    to={
                                      "/listing/" + recentActivit.listing?._id
                                    }
                                    className="text-heading"
                                  >
                                    {recentActivit.listing?.title}
                                  </Link>
                                </div>
                              )}
                            </div>
                          </li>
                        ))}
                    </ul>
                    {/* <a
                    className="text-heading d-block text-center mt-4"
                    role="button"
                  >
                    View more
                    <span className="text-primary d-inline-block ml-2">
                      <i className="fal fa-angle-down"></i>
                    </span>
                  </a> */}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default DashboardHome;
