import React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/layout/header";
import { Footer } from "../components/layout/footer";
import LoginRegister from "../components/layout/loginRegister";
import SvgList from "../components/layout/svgList";
import CalculeHypo from "../components/calcule/calculeHypo";
import CalculeMutation from "../components/calcule/calculeMutation";

export default function Welcome({ children }) {
  return (
    <>
      <Header />
      {children}
      <LoginRegister />
      <SvgList />
      <CalculeHypo />
      <CalculeMutation />
      <Footer />
    </>
  );
}
