import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getListingsListActions } from "../actions/listingActions";
import { Helmet } from "react-helmet";
import ListingCard from "../components/listing/listingCard";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BACKEND_IMAGE } from "../utils/apiConfig";
import { Loader } from "../components/utils/loader";
import Select from "react-select";
import { categoryList, listedInList } from "../utils/constants";
import Pagination from "../components/utils/Pagination";

const ListingProperty = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const searchP = location.state;

  const [searchParam, setSearchParam] = useState(
    {
      keyword: "",
      category: "",
      price: {
        min: 0,
        max: 8000000,
      },
      areaSize: {
        min: 0,
        max: 15000,
      },
      listedIn: "",
      amenities: [],
      ...searchP
    }
  );
  const [greed, setGreed] = useState(true);
  const handleClick = () => {
    setGreed(!greed);
  };

  const getListingsList = useSelector((state) => state.getListingsList);
  const { listings } = getListingsList;

  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 8;
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentListings = listings?.listings?.slice(
    firstPostIndex,
    lastPostIndex
  );

  useEffect(() => {
    dispatch(getListingsListActions(searchP));
  }, [dispatch, searchP]);

  const amenityHandler = (id) => {
    let newAmenities = searchParam.amenities;
    if (!searchParam.amenities.find((amenity) => amenity === id)) {
      newAmenities.push(id);
    } else {
      newAmenities = newAmenities.filter((x) => x !== id);
    }
    setSearchParam({ ...searchParam, amenities: newAmenities });
  };

  const searchSubmit = () => {
    const price = document.getElementById("price").value.split(" ");
    let data = searchParam;
    data = {
      ...data,
      price: {
        min: price[0].substring(1, price[0].length).replaceAll(",", ""),
        max: price[2].substring(1, price[2].length).replaceAll(",", ""),
      },
    };

    const sizeArea = document.getElementById("area-size").value.split(" ");

    data = {
      ...data,
      areaSize: {
        min: sizeArea[0],
        max: sizeArea[3],
      },
    };

    setSearchParam(data);
    dispatch(getListingsListActions(data));
  };

  return (
    <>
      {!listings ? (
        <Loader fullScreen={true} />
      ) : (
        <>
          <Helmet>
            <script src="/assets/js/theme.js"></script>
          </Helmet>
          <main id="content">
            <section className="pb-8 page-title shadow">
              <div className="container">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb pt-6 pt-lg-2 lh-15 pb-5">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Listing
                    </li>
                  </ol>
                  <h1 className="fs-30 lh-1 mb-0 text-heading font-weight-600">
                    Listing Property
                  </h1>
                </nav>
              </div>
            </section>
            <section className="pt-8 pb-11">
              <div className="container">
                <div className="row">
                  <div
                    className="col-lg-4 order-2 order-lg-1 primary-sidebar sidebar-sticky"
                    id="sidebar"
                  >
                    <div className="primary-sidebar-inner">
                      <div className="card mb-4">
                        <div className="card-body px-6 py-4">
                          <h4 className="card-title fs-16 lh-2 text-dark mb-3">
                            Find your home
                          </h4>
                          <form>
                            <div className="form-group">
                              <label htmlFor="key-word" className="sr-only">
                                Key Word
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-lg border-0 shadow-none"
                                id="key-word"
                                value={searchParam.keyword}
                                placeholder="Enter keyword..."
                                onChange={(e) =>
                                  setSearchParam({
                                    ...searchParam,
                                    keyword: e.target.value,
                                  })
                                }
                              />
                            </div>

                            <div className="form-group">
                              <label htmlFor="status" className="sr-only">
                                Status
                              </label>
                              <select
                                className="form-control border-0 shadow-none form-control-lg selectpicker"
                                title="All Status"
                                data-style="btn-lg py-2 h-52"
                                id="status"
                              >
                                <option>For Rent</option>
                                <option>For Sale</option>
                              </select>
                            </div>
                            <div className="form-row mb-4">
                              <div className="col">
                                <label htmlFor="bed" className="sr-only">
                                  Beds
                                </label>
                                <select
                                  className="form-control border-0 shadow-none form-control-lg selectpicker"
                                  title="Beds"
                                  data-style="btn-lg py-2 h-52"
                                  id="bed"
                                >
                                  <option>3</option>
                                  <option>4</option>
                                </select>
                              </div>
                              <div className="col">
                                <label htmlFor="baths" className="sr-only">
                                  Baths
                                </label>
                                <select
                                  className="form-control border-0 shadow-none form-control-lg selectpicker"
                                  title="Baths"
                                  data-style="btn-lg py-2 h-52"
                                  id="baths"
                                >
                                  <option>3</option>
                                  <option>4</option>
                                </select>
                              </div>
                            </div>

                            <div className="form-group slider-range slider-range-secondary">
                              <label
                                htmlFor="price"
                                className="mb-4 text-gray-light"
                              >
                                Price Range
                              </label>
                              <div
                                data-slider="true"
                                data-slider-options={
                                  '{"min":0,"max":' +
                                  listings?.maxPrice +
                                  ',"values":[' +
                                  searchParam.price.min +
                                  "," +
                                  searchParam.price.max +
                                  '],"type":"currency"}'
                                }
                              ></div>
                              <div className="text-center mt-2">
                                <input
                                  id="price"
                                  type="text"
                                  readOnly
                                  className="border-0 amount text-center text-body font-weight-500"
                                />
                              </div>
                            </div>
                            <div className="form-group slider-range slider-range-secondary">
                              <label
                                htmlFor="area-size"
                                className="mb-4 text-gray-light"
                              >
                                Area Size
                              </label>
                              <div
                                data-slider="true"
                                data-slider-options={
                                  '{"min":0,"max":' +
                                  listings?.maxSize +
                                  ',"values":[' +
                                  searchParam.areaSize.min +
                                  "," +
                                  searchParam.areaSize.max +
                                  '],"type":"sqrt"}'
                                }
                              ></div>
                              <div className="text-center mt-2">
                                <input
                                  id="area-size"
                                  type="text"
                                  readOnly
                                  className="border-0 amount text-center text-body font-weight-500"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label htmlFor="location" className="sr-only">
                                Category
                              </label>
                              <Select
                                options={categoryList}
                                onChange={(e) =>
                                  setSearchParam({
                                    ...searchParam,
                                    category: e.value,
                                  })
                                }
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="type" className="sr-only">
                                Listed In
                              </label>
                              <Select
                                defaultInputValue={searchParam.listedIn}
                                options={listedInList}
                                onChange={(e) =>
                                  setSearchParam({
                                    ...searchParam,
                                    listedIn: e.value,
                                  })
                                }
                              />
                            </div>

                            <a
                              className="lh-17 d-inline-block"
                              data-toggle="collapse"
                              href="#other-feature"
                              role="button"
                              aria-expanded="false"
                              aria-controls="other-feature"
                            >
                              <span className="text-primary d-inline-block mr-1">
                                <i className="far fa-plus-square"></i>
                              </span>
                              <span className="fs-15 text-heading font-weight-500 hover-primary">
                                Other Features
                              </span>
                            </a>
                            <div className="collapse" id="other-feature">
                              <div className="card card-body border-0 px-0 pb-0 pt-3">
                                <ul className="list-group list-group-no-border">
                                  {listings?.amenities?.map(
                                    (amenity, index) => (
                                      <li
                                        key={index}
                                        className="list-group-item px-0 pt-0 pb-2"
                                      >
                                        <div className="custom-control custom-checkbox">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id={"check" + index}
                                            onChange={() =>
                                              amenityHandler(amenity._id)
                                            }
                                            checked={searchParam.amenities.find(
                                              (a) => a == amenity._id
                                            )}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor={"check" + index}
                                          >
                                            {amenity.title}
                                          </label>
                                        </div>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </div>
                            <button
                              onClick={(e) => searchSubmit()}
                              type="button"
                              className="btn btn-primary btn-lg btn-block shadow-none mt-4"
                            >
                              Search
                            </button>
                          </form>
                        </div>
                      </div>
                      <div className="card property-widget mb-4">
                        <div className="card-body px-6 pt-5 pb-6">
                          <h4 className="card-title fs-16 lh-2 text-dark mb-3">
                            Latest Listing
                          </h4>
                          <div
                            className="slick-slider mx-0"
                            data-slick-options='{"slidesToShow": 1, "autoplay":true}'
                          >
                            {listings?.latestListing?.map((listing, index) => (
                              <div key={index} className="box px-0">
                                <div className="card border-0">
                                  <img
                                    src={
                                      BACKEND_IMAGE +
                                      listing?.images?.split(",")[0]
                                    }
                                    className="card-img"
                                    alt="Villa on Hollywood
													Boulevard"
                                  />
                                  <div className="card-img-overlay d-flex flex-column bg-gradient-3 rounded-lg">
                                    <div className="d-flex mb-auto">
                                      <span className="badge badge-indigo">
                                        {listing?.listedIn}
                                      </span>
                                    </div>
                                    <div className="px-2 pb-2">
                                      <Link
                                        to={"/listing/" + listing._id}
                                        className="text-white"
                                      >
                                        <h5 className="card-title fs-16 lh-2 mb-0">
                                          {listing?.title}
                                        </h5>
                                      </Link>
                                      <p className="card-text text-gray-light mb-0 font-weight-500">
                                        {listing?.address?.adresse}
                                      </p>
                                      <p className="text-white mb-0">
                                        <span className="fs-17 font-weight-bold">
                                          ${listing.price?.price}
                                        </span>
                                        /month
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 mb-8 mb-lg-0 order-1 order-lg-2">
                    <div className="row align-items-sm-center mb-4">
                      <div className="col-md-6">
                        <h2 className="fs-15 text-dark mb-0">
                          We found{" "}
                          <span className="text-primary">
                            {listings?.listings?.length}
                          </span>{" "}
                          properties available for you
                        </h2>
                      </div>
                      <div className="col-md-6 mt-4 mt-md-0">
                        <div className="d-flex justify-content-md-end align-items-center">
                          <div className="input-group border rounded input-group-lg w-auto bg-white mr-3">
                            <label
                              className="input-group-text bg-transparent border-0 text-uppercase letter-spacing-093 pr-1 pl-3"
                              htmlFor="inputGroupSelect01"
                            >
                              <i className="fas fa-align-left fs-16 pr-2"></i>
                              Sortby:
                            </label>
                            <select
                              className="form-control border-0 bg-transparent shadow-none p-0 selectpicker sortby"
                              data-style="bg-transparent border-0 font-weight-600 btn-lg pl-0 pr-3"
                              id="inputGroupSelect01"
                              name="sortby"
                            >
                              <option>Top Selling</option>
                              <option value="1">Most Viewed</option>
                              <option value="2">Price(low to high)</option>
                              <option value="3">Price(high to low)</option>
                            </select>
                          </div>
                          <div className="d-none d-md-block">
                            <button
                              className="fs-sm-18 text-dark"
                              onClick={() => {
                                handleClick();
                              }}
                            >
                              <i className="fas fa-list"></i>
                            </button>
                            <button
                              onClick={() => {
                                handleClick();
                              }}
                              className="fs-sm-18 text-dark opacity-2 ml-5"
                            >
                              <i className="fa fa-th-large"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      {currentListings?.map((listing, index) => (
                        <div key={index} className="col-md-6 mb-6">
                          <ListingCard listing={listing} animation="fadeInUp" />
                        </div>
                      ))}
                    </div>

                    <nav className="pt-4">
                      <Pagination
                        totalPosts={listings?.listings?.length}
                        postsPerPage={postsPerPage}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                      />
                    </nav>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </>
      )}
    </>
  );
};

export default ListingProperty;
