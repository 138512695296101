import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteCompareAction } from "../../actions/compareActions";
import { alertToast } from "../utils/alertHandler";
import { BACKEND_IMAGE } from "../../utils/apiConfig";

import { useTranslation, Trans } from "react-i18next";
export const Footer = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const getCompare = useSelector((state) => state.getCompare);
  const { compares } = getCompare;

  const [isActive, setIsActive] = useState(false);

  const getSettingsList = useSelector((state) => state.getSettingsList);
  const { settings } = getSettingsList;

  const removeCompare = (listing) => {
    dispatch(deleteCompareAction(listing));
    alertToast("success", "Property deleted succesfully");
  };
  return (
    <>
      <div id="compare" className={(isActive ? "show" : "") + " compare"}>
        <button
          className="btn shadow btn-open bg-white bg-hover-accent text-secondary rounded-right-0 d-flex justify-content-center align-items-center w-30px h-140 p-0"
          onClick={() => setIsActive(!isActive)}
        ></button>
        <div className="list-group list-group-no-border bg-dark py-3">
          <Link
            to="/compare"
            className="list-group-item bg-transparent text-white fs-22 text-center py-0"
          >
            <i className="far fa-bars"></i>
          </Link>

          {compares &&
            compares?.map((listing, index) => (
              <div key={index} className="list-group-item card bg-transparent">
                <div className="position-relative hover-change-image bg-hover-overlay">
                  <img
                    src={BACKEND_IMAGE + listing.images?.split(",")[0]}
                    className="card-img"
                    alt={listing.title}
                  />
                  <div className="card-img-overlay">
                    <div
                      onClick={() => removeCompare(listing)}
                      className="text-white hover-image fs-16 lh-1 pos-fixed-top-right position-absolute m-2"
                    >
                      <i className="fal fa-minus-circle"></i>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          <div className="list-group-item bg-transparent">
            <Link
              to="/compare"
              className="btn btn-lg btn-primary w-100 px-0 d-flex justify-content-center"
              style={{ minWidth: "100px" }}
            >
              Compare
            </Link>
          </div>
        </div>
      </div>
      <footer
        className="pt-8 pb-6 footer "
        style={{ backgroundColor: "#f6f6f6" }}
      >
        <div className="container container-xxl">
          <div className="row">
            <div className="col-md-6 col-lg-4 mb-6 mb-md-0">
              <a className="d-block mb-2" href="#">
                <img
                  src={
                    BACKEND_IMAGE +
                    settings?.filter((setting) => setting.key == "logo")[0]
                      ?.value
                  }
                  alt="HomeID"
                />
              </a>
              <div className="lh-26 font-weight-500">
                <p className="mb-0">
                  {
                    settings?.filter(
                      (setting) => setting.key == "admin_address"
                    )[0]?.value
                  }
                </p>
                <a
                  style={{ color: "#696969" }}
                  className="d-block  hover-white "
                  href={
                    "mailto:" +
                    settings?.filter(
                      (setting) => setting.key == "admin_email"
                    )[0]?.value
                  }
                >
                  {
                    settings?.filter(
                      (setting) => setting.key == "admin_email"
                    )[0]?.value
                  }
                </a>
                <a
                  style={{ color: "#0ca5b1" }}
                  className="d-block  font-weight-bold fs-15 hover-white"
                  href="tel:(+68)122109876"
                >
                  {
                    settings?.filter(
                      (setting) => setting.key == "admin_phone"
                    )[0]?.value
                  }
                </a>
                <a
                  style={{ color: "#696969" }}
                  className="d-block  hover-white"
                  href={window.location.hostname}
                >
                  {window.location.hostname}
                </a>
              </div>
            </div>
            <div className="col-md-6 col-lg-2 mb-6 mb-md-0">
              <h4
                className=" fs-16 my-4 font-weight-500"
                style={{ color: "#696969" }}
              >
                {t("Popular Searches")}
              </h4>
              <ul className="list-group list-group-flush list-group-no-border">
                <li className="list-group-item bg-transparent p-0">
                  <a
                    href="#"
                    style={{ color: "#696969" }}
                    className=" lh-26 font-weight-500 hover-white"
                  >
                    Apartment for Rent
                  </a>
                </li>
                <li className="list-group-item bg-transparent p-0">
                  <a
                    href="#"
                    style={{ color: "#696969" }}
                    className="* lh-26 font-weight-500 hover-white"
                  >
                    Apartment Low to hide
                  </a>
                </li>
                <li className="list-group-item bg-transparent p-0">
                  <a
                    href="#"
                    style={{ color: "#696969" }}
                    className=" lh-26 font-weight-500 hover-white"
                  >
                    Offices for Buy
                  </a>
                </li>
                <li className="list-group-item bg-transparent p-0">
                  <a
                    href="#"
                    style={{ color: "#696969" }}
                    className=" lh-26 font-weight-500 hover-white"
                  >
                    Offices for Rent
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-2 "></div>
            <div className="col-md-6 col-lg-4 mb-6 mb-md-0">
              <h4
                className=" fs-16 my-4 font-weight-500"
                style={{ color: "#696969" }}
              >
                {t("Sign Up for Our Plateform")}
              </h4>
              <p
                className="font-weight-500  lh-184"
                style={{ color: "#696969" }}
              >
               
              </p>
              <form>
                <div className="input-group input-group-lg mb-6">
                  <input
                    type="email"
                    name="email"
                    className="form-control bg-white shadow-none border-0 z-index-1"
                    placeholder="Your email"
                  />
                  <div className="input-group-append">
                    <button className="btn btn-primary" type="submit">
                      {t("Subscribe")}
                    </button>
                  </div>
                </div>
              </form>
              <ul className="list-inline mb-0">
                <li className="list-inline-item mr-0">
                  <a
                    href="#"
                    style={{ color: "#0ca5b1" }}
                    className="  fs-25 px-4 opacity-hover-10"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li className="list-inline-item mr-0">
                  <a
                    href="#"
                    style={{ color: "#0ca5b1" }}
                    className="  fs-25 px-4 opacity-hover-10"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li className="list-inline-item mr-0">
                  <a
                    href="#"
                    style={{ color: "#0ca5b1" }}
                    className=" fs-25 px-4 opacity-hover-10"
                  >
                    <i className="fab fa-skype"></i>
                  </a>
                </li>
                <li className="list-inline-item mr-0">
                  <a
                    href="#"
                    style={{ color: "#0ca5b1" }}
                    className="* fs-25 px-4 opacity-hover-10"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="mt-0 mt-md-5 " style={{ width: "100vh" }}>
            <p class=" mb-0 " style={{ width: "100vh" }}>
              © {new Date().getFullYear() } ImmoHabs. {t("All Rights Reserved")}
        
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};
