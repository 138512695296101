import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import {
  deleteReservationAction,
  getReservationActions,
  reservationHandleAction,
} from "../../actions/dashboardActions";
import {
  deletePropertyAction,
  getListingsListActions,
} from "../../actions/listingActions";
import { BACKEND_IMAGE } from "../../utils/apiConfig";
import Pagination from "../../components/utils/Pagination";
import { useTranslation } from "react-i18next";

const DashboardMyReservation = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const getReservation = useSelector((state) => state.getReservation);
  const { data } = getReservation;

  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 6;
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentReservation = data?.slice(firstPostIndex, lastPostIndex);

  useEffect(() => {
    dispatch(getReservationActions());
  }, [dispatch]);

  const deleleteHandler = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteReservationAction(id));
        Swal.fire("Deleted!", "Your product has been deleted.", "success");
      }
    });
  };

  const reservationHandler = (id) => {
    dispatch(reservationHandleAction(id));
  };

  return (
    <>
      <main id="content" className="bg-gray-01">
        <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
          <div className="d-flex flex-wrap flex-md-nowrap mb-6">
            <div className="mr-0 mr-md-auto">
              <h2 className="mb-0 text-heading fs-22 lh-15">
                {t("Reservations")}
                <span className="badge badge-white badge-pill text-primary fs-18 font-weight-bold ml-2">
                  {/* {listings && listings?.listings?.length} */}
                </span>
              </h2>
              <p> {t("Access and manage all your listed Reservation")}</p>
            </div>
            <div className="form-inline justify-content-md-end mx-n2">
              <div className="p-2">
                <div className="input-group input-group-lg bg-white border">
                  <div className="input-group-prepend">
                    <button className="btn pr-0 shadow-none" type="button">
                      <i className="far fa-search"></i>
                    </button>
                  </div>
                  <input
                    type="text"
                    className="form-control bg-transparent border-0 shadow-none text-body"
                    placeholder="Search listing"
                    name="search"
                  />
                </div>
              </div>
              <div className="p-2">
                <div className="input-group input-group-lg bg-white border">
                  <div className="input-group-prepend">
                    <span className="input-group-text bg-transparent letter-spacing-093 border-0 pr-0">
                      <i className="far fa-align-left mr-2"></i> {t("Sort by")}:
                    </span>
                  </div>
                  <select
                    className="form-control bg-transparent pl-0 selectpicker d-flex align-items-center sortby"
                    name="sort-by"
                    data-style="bg-transparent px-1 py-0 lh-1 font-weight-600 text-body"
                    id="status"
                  >
                    <option>Alphabet</option>
                    <option>Price - Low to High</option>
                    <option>Price - High to Low</option>
                    <option>Date - Old to New</option>
                    <option>Date - New to Old</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-hover bg-white border rounded-lg">
              <thead className="thead-sm thead-black">
                <tr>
                  <th scope="col" className="border-top-0 px-6 pt-5 pb-4">
                    {t("Listing title")}
                  </th>
                  {userInfo && userInfo.isAdmin == true && (
                    <th scope="col" className="border-top-0 px-6 pt-5 pb-4">
                      {t("Customer Details")}
                    </th>
                  )}
                  <th scope="col" className="border-top-0 pt-5 pb-4">
                    {t("Date")}
                  </th>
                  <th scope="col" className="border-top-0 pt-5 pb-4">
                    {t("Time")}
                  </th>
                  <th scope="col" className="border-top-0 pt-5 pb-4">
                    {t("Status")}
                  </th>
                  <th scope="col" className="border-top-0 pt-5 pb-4">
                    {t("Action")}
                  </th>
                </tr>
              </thead>
              {currentReservation &&
                currentReservation.map((data) => (
                  <tbody>
                    <tr className="shadow-hover-xs-2 bg-hover-white">
                      <td className="align-middle pt-6 pb-4 px-6">
                        <div className="media">
                          <div className="w-120px mr-4 position-relative">
                            <Link to={"/listing/" + data.listing._id}>
                              <img
                                src={
                                  BACKEND_IMAGE +
                                  data.listing.images?.split(",")[0]
                                }
                                alt="Home in Metric Way"
                              />
                            </Link>
                            <span className="badge badge-indigo position-absolute pos-fixed-top">
                              {data.listing.listedIn}
                            </span>
                          </div>
                          <div className="media-body">
                            <Link
                              to={"/listing/" + data.listing._id}
                              href="single-property-1.html"
                              className="text-dark
                              hover-primary"
                            >
                              <h5 className="fs-16 mb-0 lh-18 font-weight-bold">
                                {data.listing.title}
                              </h5>
                            </Link>
                            <p className="mb-1 font-weight-500 font-weight-bold fs-15">
                              {data.listing.address.adresse}
                            </p>
                            <span className="text-heading lh-15 font-weight-bold fs-17">
                              {data.listing.price.price}
                            </span>
                            {data.listing.listedIn == "Rent" ? (
                              <span className="text-gray-light">
                                / {t("month")}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </td>
                      {userInfo && userInfo.isAdmin == true && (
                        <div className=" mt-3">
                          <td className="align-middle  font-weight-bold fs-15">
                            <h5 className="fs-16 mb-0 lh-18 font-weight-bold">
                              {data.user.firstName + " " + data.user.lastName}
                            </h5>
                            <p className="mb-0 font-weight-500 font-weight-bold fs-15">
                              {data.user.email}
                            </p>
                            <p className="mb-1 font-weight-500 font-weight-bold fs-15">
                              {data.user.phone}
                            </p>
                          </td>
                        </div>
                      )}
                      <td className="align-middle  font-weight-bold fs-15">
                        {data.date}
                      </td>

                      <td className="align-middle font-weight-bold fs-15">
                        {data.time}
                      </td>
                      {data.status == "pending" ? (
                        <td className="align-middle">
                          <span className="badge text-capitalize font-weight-normal fs-12 badge-yellow">
                            {t("Pending")}
                          </span>
                        </td>
                      ) : (
                        <td className="align-middle">
                          <span className="badge text-capitalize font-weight-normal fs-12 badge-green">
                            {t("Approuved")}
                          </span>
                        </td>
                      )}
                      <td className="align-middle">
                        {userInfo &&
                          userInfo.isAdmin == true &&
                          data.status == "pending" && (
                            <Link
                              onClick={() => reservationHandler(data._id)}
                              data-toggle="tooltip"
                              title="Edit"
                              className="d-inline-block fs-18 text-muted hover-primary "
                            >
                              <i className="fas  fa-check"></i>
                            </Link>
                          )}
                        <button
                          onClick={() => deleleteHandler(data._id)}
                          className="btn  d-inline-block fs-18 text-muted hover-primary"
                        >
                          <i className="fal fa-trash-alt"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                ))}
            </table>
          </div>
          <nav className="mt-6">
            <Pagination
              totalPosts={data?.length}
              postsPerPage={postsPerPage}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          </nav>
        </div>
      </main>
    </>
  );
};

export default DashboardMyReservation;
